/* src/app/my-formly/components/formly.global.scss */
.formly-input-container {
  padding-bottom: 8px;
  margin-bottom: 8px;
  position: relative;
}
ion-item {
  align-items: flex-end;
  --highlight-height: 0px;
}
ion-item ion-label {
  transform: translateY(100%);
  --color: var(--ion-color-medium);
}
.input-error {
  position: absolute;
  font-size: 0.7rem;
  padding: 1px 0px 0px;
  z-index: 1002;
  width: 100%;
}
.show-password {
  font-size: 1.2rem;
  color: var(--ion-color-dark);
  opacity: 60%;
  padding-bottom: 4px;
  margin: 0;
}
/*# sourceMappingURL=formly.global.css.map */
