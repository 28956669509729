<app-master-detail class="yf-master-detail" icon="information-circle-outline" title="{{ 'MENU.INFO' | translate }}"
  [(show)]="masterDetailShow" filter="" (compactModeChange)="compactModeChange($event)" [forceCompact]="false">

  <div class="master">
    <div class="m-6 pl-6 oy-a" *ngFor="let chapter of this.chapters$|async;"
      [ngClass]="{ selected: selectedChapter?.id===chapter.id }">
      <ion-item button detail="true" (click)="show(chapter)" [lines]="selectedChapter?.id===chapter.id ? 'none' : ''">
        <ion-icon [name]="chapter.icon? chapter.icon : 'document-outline'" class="item-icon" slot="start"> </ion-icon>
        <ion-label>
          <h3 class="fw-600">{{ chapter.title }}</h3>
          <p>{{ chapter.subtitle }}</p>
        </ion-label>
      </ion-item>
    </div>
  </div>
  <div class="detail" *ngIf="selectedChapter">
    <div [ngClass]="!compactMode ? 'detail-item' : 'detail-item-no-border'">
      <ion-toolbar *ngIf="!!compactMode && selectedChapter.subtitle" class="subtitle selected">
        <ion-buttons slot="end"><ion-button><ion-icon color="dark" slot="icon-only"
              [name]="selectedChapter.icon"></ion-icon></ion-button></ion-buttons>
        <ion-title>{{ selectedChapter.subtitle }}</ion-title>
      </ion-toolbar>

      <div *ngFor="let section of selectedChapter.infoSections;">
        <div class="mt-10 section-title" *ngIf="section.title">{{ section.title }}</div>
        <div class="mt-4 section-subtitle" *ngIf="section.subtitle">{{ section.subtitle }}</div>
        <div class="mt-10 section-img" *ngIf="section.multimedia"><img width="100%" height="auto"
            [src]="getImageUrl(section.multimedia?.name + '.' + section.multimedia?.fileExtension)"
            [alt]="section.multimedia?.name"></div>
        <div class="mt-10 section-html" *ngIf="section.html" [innerHTML]="section.html"></div>
      </div>
    </div>
  </div>
</app-master-detail>