/* src/app/components/master-detail/master-detail.component.scss */
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 576px) {
  .main {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  .main {
    margin-top: env(safe-area-inset-top);
  }
}
.container {
  display: flex;
  flex-direction: row;
  max-height: 100%;
  overflow-y: auto;
  padding: var(--yf-spacing1);
  height: 100%;
}
.master {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.detail {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  flex: 1;
}
.detail-content {
  height: 100%;
}
.hide {
  display: none;
}
/*# sourceMappingURL=master-detail.component.css.map */
