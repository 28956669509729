import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlugType } from 'common_library';

@Component({
    selector: 'yf-filter-connector-selector',
    templateUrl: './filter-connector-selector.component.html',
    styleUrls: ['./filter-connector-selector.component.scss'],
    standalone: false
})
export class FilterConnectorSelectorComponent implements OnInit {
  @Input() selectedConnector: string[] = [];
  plugTypes: string[] = Object.values(PlugType)/* .slice(0, 8) */;
  @Output() filterEvent = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit() {
    //console.log('this.plugTypes', this.plugTypes);
  }

  selectPlug(plug: string) {
    if (this.selectedConnector.includes(plug)) {
      const index = this.selectedConnector.findIndex((p) => p === plug);
      this.selectedConnector.splice(index, 1);
    } else {
      this.selectedConnector.push(plug);
    }
    this.filterEvent.emit(this.selectedConnector);
  }
}
