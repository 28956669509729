import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ISession } from 'common_library';
import { CoreService } from 'src/app/services/core.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { UiService } from 'src/app/services/utils/ui.service';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

export enum SessionToastEventType {
  OpenDetails,
  NavigateTo,
  ShowOnMap,
  CancelReservation
}

export interface ISessionToastEvent {
  type: SessionToastEventType;
  source: ISession;
}

@Component({
    selector: 'yf-session-toast',
    templateUrl: './session-toast.component.html',
    styleUrls: ['./session-toast.component.scss'],
    standalone: false
})
export class SessionToastComponent implements AfterViewInit {

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;
  currentSessionIndex: number = 0;

  swiperConfig: SwiperOptions = {
    speed: 400,
    effect: 'cards',
    spaceBetween: 100
  }

  //swiper!: Swiper;
  @Output() componentEvent = new EventEmitter<ISessionToastEvent>();

  constructor(
    public CS: CoreService,
    public UI: UiService,
    public mainStateService: MainStateService) {
  }

  ngAfterViewInit() {
    this.swiper.nativeElement.swiper.activeIndex = this.currentSessionIndex;
  }

  slideChange(swiper: any) {
    console.log("SWIPER: Active index", swiper.detail[0].activeIndex);
    this.mainStateService.setLastSelectedConnectorId(this.mainStateService.$sessions()[swiper.detail[0].activeIndex]?.connectorId);
    this.currentSessionIndex = swiper.detail[0].activeIndex;
  }

  slidesUpdated(swiper: any) {
    console.log("SWIPER: Slides Update", swiper);
  }

  swiperSlidesUpdated(swiper: any) {
    console.log("SWIPER: Swiper Slides Update", swiper);
  }

  openDetails(evt: Event) {
    evt.stopPropagation();
    this.componentEvent.emit({ type: SessionToastEventType.OpenDetails, source: this.mainStateService.$sessions()[this.currentSessionIndex] });
  }

  navigateTo(evt: Event) {
    evt.stopPropagation();
    this.componentEvent.emit({ type: SessionToastEventType.NavigateTo, source: this.mainStateService.$sessions()[this.currentSessionIndex] });
  }

  showOnMap(evt: Event) {
    evt.stopPropagation();
    this.componentEvent.emit({ type: SessionToastEventType.ShowOnMap, source: this.mainStateService.$sessions()[this.currentSessionIndex] });
  }
}