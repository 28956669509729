"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIProfileType = void 0;
exports.OCPIProfileType = Object.freeze({
  CHEAP: "CHEAP",
  FAST: "FAST",
  GREEN: "GREEN",
  REGULAR: "REGULAR"
});
