import { Component, EventEmitter, Inject, Input, Output, effect } from '@angular/core';
import { GestureDetail, ModalController, ToastController, AlertController } from '@ionic/angular';
import { CPOs, CPOsKeys, ChapterVisualizationPhase, ErrorCode, ISessionResult, IStation } from 'common_library';
import { Subscription } from 'rxjs';
import { MapPosition } from 'src/app/components/map/map.component';
import { CoreService } from 'src/app/services/core.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { UiService } from 'src/app/services/utils/ui.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RxState } from '@rx-angular/state';
import { UserService } from 'src/app/services/entities/user.service';
import { ConfirmStationOpsComponent } from 'src/app/components/confirm-station-ops/confirm-station-ops.component';
import { CdcAuthComponent } from 'src/app/components/master-detail-cdc-authorization/cdc-auth.component';
import { AppService } from 'src/app/services/app.service';
import { _ } from 'src/app/consts';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { TariffService } from 'src/app/services/entities/tariff.service';
import { CmdButton } from 'src/app/types/buttons/button-state.interface';
import { InfoService } from 'src/app/services/entities/info.service';

export interface IInstallationState {
  stationIsMine: boolean;
  headerClass: string;
}

@Component({
    selector: 'yf-installation',
    templateUrl: './installation.component.html',
    styleUrls: ['./installation.component.scss'],
    animations: [
        trigger('openCompact', [
            state('open', style({
                maxHeight: '505px'
            })),
            state('compact', style({
                maxHeight: '0'
            })),
            transition('open => compact', [animate(200)]),
            transition('compact => open', [animate(400)]),
        ])
    ],
    providers: [RxState],
    standalone: false
})
export class InstallationComponent {
  @Input() currentPosition: MapPosition;
  @Output() onSelectNext = new EventEmitter<string>();
  @Output() onSelectPrev = new EventEmitter<string>();
  @Output() openLogin = new EventEmitter();
  subscriptions: Subscription[] = []
  compactMode: boolean;

  constructor(
    public modalCtrl: ModalController,
    private toast: ToastService,
    private toastController: ToastController,
    public UI: UiService,
    public CS: CoreService,
    private userService: UserService,
    private appService: AppService,
    private alertController: AlertController,
    private translate: TranslateService,
    public mainStateService: MainStateService,
    private tariffService: TariffService,
    private infoService: InfoService
  ) {

  }

  async closeModal() {
    await this.mainStateService.setSelectedInstallation(null);
  }

  async sendCmd(btn: CmdButton) {
    if (!this.mainStateService.getUser()) {
      this.openLogin.emit();
      return;
    }

    let tariff = this.mainStateService.$selectedConnectorMineSession()?.tariffJson || this.mainStateService.getSelectedConnector()?.tariff;
    if (!tariff) {
      tariff = await this.tariffService.getConnectorTariff(this.mainStateService.getSelectedConnector()?.id, this.mainStateService.getUser()?.id);
    }

    if (!tariff.free) {
      const paymentAuth = await this.userService.getMyPaymentAuth()
      if (!paymentAuth?.authorizedAt) {
        const rv = await this.toastController.create(
          {
            message: this.translate.instant("APP.INSTALLATION.CREDIT_CARD_NOT_VALID"),
            color: 'danger',
            duration: 2000,
            position: 'top',
            buttons: [{ role: 'add', text: this.translate.instant("BUTTONS.ADD") }],
          });
        await rv.present();
        rv
          .onDidDismiss()
          .then(async (res) => {
            if (res.role === 'add') {
              const modal = await this.modalCtrl.create({
                component: CdcAuthComponent,
                cssClass: 'yf-master-detail-modal',
              });
              await modal.present();
            }
          });
        return;
      }
    }

    // Capitoli obbligatori in questa fase
    // ShowSingleChapter chiude tutte le modali precedenti...quindi al momento si mostra solo un pop up per volta
    // Se i capitoli da accettare in questa fase sono più di uno, vuol dire che verrano mostrati in momenti diversi
    // Se tale situazione non è accettabile, va rivisto il giro
    await this.infoService.getCmsContent();
    let chaptersToShow = await this.userService.getChaptersUserMustAcceptForVisualizationPhase(ChapterVisualizationPhase.BEFORE_STARTING_SESSION);
    if (chaptersToShow && chaptersToShow.length > 0) {
      this.appService.showSingleChapter(chaptersToShow[0].id, false, false);
      return;
    }
    const connectorId = this.mainStateService.$selectedConnector()?.id;
    const sessionIdForConnector = this.mainStateService.$sessions()?.find(c => c.connectorId === connectorId)?.id;
    const connectorCommandInProgress = this.mainStateService.$connectorCommandInProgress();
    if (connectorCommandInProgress) {
      await this.toast.presentBasicToast({ message: this.translate.instant("TOAST_MESSAGES.OPERATION_IN_PROGRESS") });
      return;
    }
    let rv: ISessionResult;
    let message = `Operazione effettuata`;
    let alert: HTMLIonAlertElement;
    let confirmed: boolean = false;
    switch (btn) {
      case CmdButton.Reserve:
        alert = await this.alertController.create(
          {
            header: this.translate.instant("APP.INSTALLATION.CONFIRM_RESERVATION"),
            message: this.translate.instant("APP.INSTALLATION.START_RESERVATION", { value: this.mainStateService.$selectedConnector()?.name ?? this.mainStateService.$selectedConnector()?.cpConnectorId, value2: this.mainStateService.$selectedStation().name }),
            buttons: [
              {
                text: this.translate.instant("BUTTONS.CANCEL"),
                role: 'cancel',
              },
              {
                text: this.translate.instant("BUTTONS.CONFIRM"),
                role: 'confirm',
                handler: () => {
                  confirmed = true;
                  message = this.translate.instant("APP.INSTALLATION.RESERVATION_CONFIRMED");
                }
              }
            ]
          }
        )
        break;
      case CmdButton.CancelReservation:
        alert = await this.alertController.create(
          {
            header: this.translate.instant("APP.INSTALLATION.BUTTONS.CANCEL_RESERVATION"),
            message: this.translate.instant("APP.INSTALLATION.CANCEL_RESERVATION_MESSAGE", { value: this.mainStateService.$selectedConnector()?.name ?? this.mainStateService.$selectedConnector()?.cpConnectorId, value2: this.mainStateService.$selectedStation()?.name }),
            buttons: [
              {
                text: this.translate.instant("BUTTONS.CANCEL"),
                role: 'cancel',
              },
              {
                text: this.translate.instant("BUTTONS.CONFIRM"),
                role: 'confirm',
                handler: async () => {
                  confirmed = true;
                  message = this.translate.instant("APP.INSTALLATION.RESERVATION_CANCELLED");
                }
              }
            ]
          }
        )
        break;
      case CmdButton.StartCharge:
        const modal = await this.modalCtrl.create({
          component: ConfirmStationOpsComponent,
          cssClass: 'yf-master-detail-modal-always-full',
          id: 'pino',
          backdropDismiss: false
        })
        await modal.present();
        return;
      case CmdButton.StopCharge:
        alert = await this.alertController.create(
          {
            header: this.translate.instant("APP.INSTALLATION.STOP_CHARGE"),
            buttons: [
              {
                text: this.translate.instant("BUTTONS.CANCEL"),
                role: 'cancel',
              },
              {
                text: this.translate.instant("BUTTONS.CONFIRM"),
                role: 'confirm',
                handler: async () => {
                  // rv = await this.CS.stopCharge(this.globalState.get("selectedConnector").id);
                  message = this.translate.instant("APP.INSTALLATION.CHARGE_STOPPED_DISCONNECT_PLUG");
                  confirmed = true;
                }
              }
            ]
          }
        )
        break;
      case CmdButton.EndSession:
        rv = await this.CS.endSession(connectorId, sessionIdForConnector);
        message = this.translate.instant("APP.INSTALLATION.SESSION_ENDED");
        break;
      case CmdButton.Login:
        this.openLogin.emit();
        return; // qui esco e non faccio altro
    }
    await alert.present();
    await alert.onDidDismiss();
    if (confirmed) {
      switch (btn) {
        case CmdButton.Reserve:
          rv = await this.CS.reserveConnector(connectorId, this.mainStateService.getLastClientPosition());
          var expiringDate = new Date();
          expiringDate.setSeconds(expiringDate.getSeconds() + _.OCPI_SESSION_TIMEOUT);
          this.mainStateService.updateButtonState(connectorId, { exipresAt: expiringDate, command: CmdButton.Reserve });
          break;
        case CmdButton.CancelReservation:
          rv = await this.CS.cancelReservation(connectorId, sessionIdForConnector);
          break;
        case CmdButton.StopCharge:
          rv = await this.CS.stopCharge(connectorId, sessionIdForConnector);
          break;
        default:
          rv.errorCode = ErrorCode.GeneralError;
          break;
      }

      if (rv && rv?.errorCode !== ErrorCode.NoError) {
        await this.CS.manageErrorCodes(rv.errorCode);
      }
      else {
        await this.toast.presentBasicToast({ message, color: 'tertiary' });
      }

      if (!!rv?.session) {
        this.mainStateService.updateSessionsWithCurrent(rv.session);
      }
    }
  }

  async checkOcpiSessions(cpo?: CPOsKeys): Promise<boolean> {
    const session = this.mainStateService.$sessions().find(session => session?.cpo === CPOs.ENELX);
    if (!!session) {
      await this.toast.presentBasicToast({ message: this.translate.instant("TOAST_MESSAGES.OCPI_SESSION_ACTIVE"), color: 'warning' });
      return true;
    }
    return false;
  }

  cheddarClicked(event: GestureDetail) {
    if (event.deltaY > 0) {
      this.compactMode = true;
    } else if (event.deltaY < 0) {
      this.compactMode = false;
    }
  }

  manageAnimation() {
    if (this.compactMode) {
      return 'compact';
    } else if (!this.compactMode) {
      return 'open';
    }
  }

  selectStation(station: IStation) {
    this.mainStateService.setSelectedStation(station);
  }
}
