/* src/app/components/cheddar/cheddar.component.scss */
.cheddar-outer-container {
  height: 16px;
  display: flex;
  justify-content: center;
  cursor: grab;
}
.cheddar-outer-container:active {
  cursor: grabbing;
}
.cheddar-container {
  height: 65px;
  position: absolute;
  width: 70%;
  padding: 8px 0 0 0;
  z-index: 12;
}
.cheddar-container .cheddar-content {
  background-color: lightgray;
  margin: auto;
  height: 4px;
  width: 60px;
  border-radius: 25px;
}
/*# sourceMappingURL=cheddar.component.css.map */
