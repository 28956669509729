import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, merge, Observable, of, Subject, timer } from 'rxjs';
import { distinctUntilChanged, map, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { AppService } from 'src/app/services/app.service';

@Component({
    selector: 'yf-waiting-toast',
    templateUrl: './waiting-toast.component.html',
    styleUrls: ['./waiting-toast.component.scss'],
    standalone: false
})
export class WaitingToastComponent implements OnInit, OnDestroy {

  @Input() isProgression: BehaviorSubject<boolean>;
  @Input() message: string = 'Attendere';
  @Input() duration: number = 10; // value in seconds

  private _destroy$ = new Subject<void>();
  private _duration$ = new BehaviorSubject<number>(0);
  private _startStop$ = new BehaviorSubject<boolean>(false);
  
  show$: Observable<boolean>;
  progressionValue$: Observable<number>;
  message$ = new BehaviorSubject<string>(null);

  constructor(private appService: AppService) {
  }

  ngOnInit(): void {
    if (!this.isProgression) {
      this.useToastDataFromAppService();
    } else {
      this.useLocalData();
    }

    this.progressionValue$ = this._startStop$.pipe(
      switchMap((start) => {
        if (start) {
          return timer(0, (this._duration$.value / 100) * 1000).pipe(
            map((n) => n / 100),
            takeWhile((n) => n <= 1)
          );
        }
        return of(1);
      })
    );

    this.show$ = merge(this._startStop$, this.progressionValue$).pipe(
      map((v) => v !== null && v !== 1),
      distinctUntilChanged()
    )
  }

  private useLocalData() {
    this._startStop$ = this.isProgression;
    this.message$.next(this.message);
    this._duration$.next(this.duration)
  }

  private useToastDataFromAppService(): void {
    this.appService.waitingToastData.pipe(
      takeUntil(this._destroy$)
    ).subscribe(data => {
      this._duration$.next(data.duration || 10);
      this.message$.next(data.message || 'Attendere');
      this._startStop$.next(data.isProgression);
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next();
  }

}
