import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { SelectedInstallationService } from 'src/app/services/entities/selected-installation.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
    selector: 'yf-cluster',
    templateUrl: './cluster.component.html',
    styleUrls: ['./cluster.component.scss'],
    animations: [
        trigger('openCompact', [
            state('open', style({
                maxHeight: '505px'
            })),
            state('compact', style({
                maxHeight: '150px'
            })),
            transition('open => compact', [animate(200)]),
            transition('compact => open', [animate(400)]),
        ])
    ],
    standalone: false
})
export class ClusterComponent {

  compactMode: boolean = false;

  constructor(
    public AS: AppService,
    public mainStateService: MainStateService,
    private installationService: SelectedInstallationService) { }

  cheddarClicked(event: any) {
    if (event.deltaY > 0) {
      this.compactMode = true;
    } else if (event.deltaY < 0) {
      this.compactMode = false;
    }
  }

  manageAnimation() {
    if (this.compactMode) {
      return 'compact';
    } else if (!this.compactMode) {
      return 'open';
    }
  }

  async selectInstallation(installationId: string) {
    const rv = await this.installationService.fetchInstallation(installationId);
    this.mainStateService.setSelectedInstallation(rv);
  }
}
