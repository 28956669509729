/* src/app/components/splash-screen/splash-screen.component.scss */
.app-splash-screen {
  background:
    linear-gradient(
      225deg,
      rgb(152, 226, 73) 0%,
      rgb(23, 76, 109) 76%);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  transition: left 0.5s;
}
.app-splash-screen .app-splash-screen-inner .app-logo {
  position: relative;
}
.app-splash-screen .app-splash-screen-inner .app-logo .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 160px;
  height: 160px;
  border-color: #fff;
  border-width: 2px;
  border-style: solid;
  opacity: 1;
  animation: border 0.6s ease-in forwards;
}
.app-splash-screen .app-splash-screen-inner .app-logo img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: auto;
  opacity: 1;
  animation: border 1s forwards cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.app-splash-screen .app-splash-screen-inner .app-starting {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  animation: label 1.2s forwards cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.app-splash-screen .app-splash-screen-inner .app-starting ion-label {
  font-size: 28px;
  color: #fff;
  font-weight: 500;
}
.app-splash-screen .app-splash-screen-inner .app-offline {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  animation: label 1.2s forwards cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.app-splash-screen .app-splash-screen-inner .app-offline ion-item {
  font-size: 28px;
  color: #fede71;
  font-weight: 500;
  background-color: rgba(0, 255, 0, 0.2);
  padding: 0px 14px 0px 14px;
  opacity: 0.75;
  border-radius: 12px;
}
@keyframes border {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes label {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 46px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
/*# sourceMappingURL=splash-screen.component.css.map */
