import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { Observable, map, switchMap, iif, of, delay, } from 'rxjs';
import { GlobalState, GLOBAL_RX_STATE } from 'src/app/app.module';
import { MainState } from 'src/app/types/state/app-main-state.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-splash-screen',
    templateUrl: './splash-screen.component.html',
    styleUrls: ['./splash-screen.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class SplashScreenComponent {
  
  show$: Observable<boolean>;
  offline$: Observable<boolean>;

  constructor(
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    private translate: TranslateService) {
    const ms$ = this.globalState.select("mainState");
    this.offline$ = ms$.pipe(map(i => i === MainState.OFFLINE));

    this.show$ = ms$.pipe(
      switchMap(mainState =>
        iif(
          () => mainState === MainState.INIT,
          of(true),
          iif(
            () => mainState === MainState.OFFLINE,
            of(true).pipe(delay(5000)), // guardia sull'offline: se sto offline più di 5 secondi allora si mostra lo splash
            of(false).pipe(delay(2000)) // show minimo: se sto offline (o in init) meno di 2 secondi cmq lo splash si mostra almeno 2 sec
          )
        ))
    );
  }
}