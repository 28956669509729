import { Component, Input } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { CoreService } from 'src/app/services/core.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-debug',
    templateUrl: './debug.component.html',
    styleUrls: ['./debug.component.scss'],
    standalone: false
})
export class DebugComponent {
  
  hide:boolean = !!environment.production;

  constructor(public CS:CoreService, public app:AppService) { 
    
  }

}
