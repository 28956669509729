<div [ngClass]="$isBackgroundHighlighted() ? 'selected-tab-dark' : $isSelected() ? 'selected-tab-light' : ''">
  <ion-button class="btn white" [class.border]="isOfInstallationList">
    <ion-card class="yf-container centered" (click)="stationClick()">
      <ion-card-content>
        <div class="text-container" [ngClass]="{'isMine': !!$userActiveSession()}">
          <ion-text class="fs-12">{{ $station()?.name | StationName }}
          </ion-text>
        </div>
        <yf-state-simple [$station]="$station()" [showIcon]="false">
        </yf-state-simple>
      </ion-card-content>
    </ion-card>
  </ion-button>
</div>