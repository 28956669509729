/* src/app/pages/cluster/cluster.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.installation-container {
  position: fixed;
  bottom: 60px;
  height: 505px;
  background: var(--ion-color-light);
  box-shadow: var(--yf-shadow);
  padding: 0;
  overflow: hidden;
  border-radius: var(--yf-spacing2);
  z-index: 1;
  background-color: #edeef3;
}
@media (max-width: 576px) {
  .installation-container {
    right: var(--yf-spacing1);
    left: var(--yf-spacing1);
    margin-bottom: calc(env(safe-area-inset-bottom));
    width: -moz-available;
  }
}
@media (min-width: 576px) {
  .installation-container {
    left: 72px;
    width: 360px;
  }
}
.installation-container .stations-container {
  height: 230px;
  overflow-y: scroll;
}
.installation-container ion-button[button2] {
  --color: var(--ion-color-medium-shade);
}
.installation-list-container {
  overflow: auto;
  height: 100%;
}
.mini-stations {
  overflow-x: auto;
}
div::-webkit-scrollbar {
  display: none;
}
/*# sourceMappingURL=cluster.component.css.map */
