<div class="yf-container installation-container" *ngIf="!mainStateService.$selectedInstallation() && mainStateService.$selectedCluster() as installationList" [@openCompact]="manageAnimation()">
  <yf-cheddar (cheddarClick)="cheddarClicked($event)"></yf-cheddar>
  <div class="installation-list-container">
    <div *ngFor="let installation of installationList; let i = index" (click)="selectInstallation(installation.id)">
      <yf-installation-header [$installation]="installation">
      </yf-installation-header>
      <div class="ml-8 pt-10">
        <div class="yf-row mini-stations">
          <yf-mini-station *ngFor="let station of installation.stations" [$station]="station" [isOfInstallationList]="true">
          </yf-mini-station>
        </div>
      </div>
      <yf-divider *ngIf="i !== installationList.length - 1"></yf-divider>
    </div>
  </div>
</div>
