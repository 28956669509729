<div class="yf-container yf-padding gap-12">
  @if(mainStateService.$user()){
  <div class="yf-container gap-12">
    @if($chargingFinished()){
    <div class="grow yf-text-center">
      <ion-text color="tertiary" class="fs-16 fw-600">{{ "APP.INSTALLATION.BUTTONS.CHARGING_ENDED" | translate
        }}</ion-text>
    </div>
    <yf-stop-charge></yf-stop-charge>
    }
    @else{
    <div class="yf-row gap-12">
      @if(buttonStateService.$buttonCancelReservation().show){
      <yf-annulla-prenotazione class="flex-80" (cancelReservationEvent)="cancelReservation()">
      </yf-annulla-prenotazione>}
      @else if(buttonStateService.$buttonStartReservation().show){
      <yf-avvia-prenotazione class="flex-80" (reserveEvent)="reserve()"></yf-avvia-prenotazione>
      }

      @if(mainStateService.$selectedStation()?.coord?.coordinates[0] !== 0 &&
      mainStateService.$selectedStation()?.coord?.coordinates[1] !== 0){
      <ion-button class="btn square"
        [ngClass]="mainStateService.$selectedConnectorHasMineSession() ?  'white' : 'secondary'"
        (click)=" this.navService.navigateTo(mainStateService.$selectedStation()?.coord?.coordinates)">
        <ion-icon slot="icon-only" src="assets/app-icons/navigazione.svg"></ion-icon>
      </ion-button>
      }
    </div>

    <div class="yf-row gap-12">
      @if(buttonStateService.$buttonStopCharge().show){
      <yf-interrompi-ricarica class="flex-80" (stopEvent)="stopCharge()">
      </yf-interrompi-ricarica>
      }
      @else if(buttonStateService.$buttonStartCharge().show){
      <yf-avvia-ricarica class="flex-80" (startEvent)="startCharge()">
      </yf-avvia-ricarica>
      }

      @if(mainStateService.$selectedStation().cpo !== 'ENX'){
      <ion-button class="btn square"
        [ngClass]="mainStateService.$selectedConnectorHasMineSession()  ? 'white' : 'secondary'"
        (click)="showRfidModal()">
        <ion-icon name="id-card-outline" slot="icon-only"></ion-icon>
      </ion-button>
      }
    </div>
    }
  </div>
  }
  @else{
  <div class="yf-container gap-6">
    <ion-text color="secondary" class="fs-12">{{ "APP.INSTALLATION.TO_CHARGE_YOU_HAVE_TO_LOGIN" | translate
      }}</ion-text>
    <ion-button class="btn large grow" color="secondary" (click)="login()">
      <ion-icon slot="start" src="assets/app-icons/entra.svg"></ion-icon>{{ "LOGIN.LOGIN" | translate}}
    </ion-button>
  </div>
  }
</div>