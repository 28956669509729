"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPISessionStatus = void 0;
exports.OCPISessionStatus = Object.freeze({
  ACTIVE: "ACTIVE",
  COMPLETED: "COMPLETED",
  INVALID: "INVALID",
  PENDING: "PENDING",
  RESERVATION: "RESERVATION"
});
