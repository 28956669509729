<div class="yf-container">
  <div>
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>{{ "RFID.RFID_DATA" | translate }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <form [formGroup]="form">
          <formly-form [model]="model$ | async" [fields]="fields" [form]="form" [options]="options"
            (keyup.enter)="form.valid && form.dirty ? submit() : null">
          </formly-form>
        </form>
      </ion-card-content>
    </ion-card>
  </div>

  <ion-toolbar>
    <div class="yf-container gap-6">
      <div class="yf-row gap-6">
        <ion-button color="primary" class="btn large grow" (click)="cancelBtn()" fill="outline">{{ "BUTTONS.CANCEL" | translate}}</ion-button>
        <ion-button color="primary" class="btn large grow" (click)="submit()"
          [disabled]="!form.valid || !form.dirty">{{ "BUTTONS.SAVE" | translate }}</ion-button>
      </div>
    </div>
  </ion-toolbar>
</div>