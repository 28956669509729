"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VERSION_URL = exports.OCPI_SUCCESS_STATUS = void 0;
exports.OCPI_SUCCESS_STATUS = Object.freeze({
  'GENERIC': 1000
});
exports.VERSION_URL = '/ocpi/emsp/versions';
