/* src/app/components/star-container/star/star.component.scss */
.star-rating-filled {
  stroke-width: 1px;
}
.star-rating-blank {
  fill: transparent;
  stroke-width: 1px;
}
svg {
  width: 16px;
  height: 16px;
}
/*# sourceMappingURL=star.component.css.map */
