import { Component, OnInit, Input, input } from '@angular/core';
import { OCPITariffDimension } from 'common_library';

@Component({
    selector: 'yf-cost-card',
    templateUrl: './cost-card.component.html',
    styleUrls: ['./cost-card.component.scss'],
    standalone: false
})
export class CostCardComponent {
  @Input() showText: boolean = true;
  $el = input(null); // Cost elements
  TARIFF_DIMENSION_TYPE = OCPITariffDimension
  constructor() { }
}
