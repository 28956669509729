"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VOLTAGE = void 0;
exports.VOLTAGE = Object.freeze({
  VOLTAGE_400: 400,
  VOLTAGE_230: 230,
  VOLTAGE_110: 110
});
