/* src/app/components/station/station-connector-selector/station-connector-selector.component.scss */
.yf-grid-container {
  padding: 6px !important;
  gap: 9px !important;
  justify-content: center;
}
.yf-grid-item {
  width: 108px;
  max-width: unset;
  max-height: unset;
}
/*# sourceMappingURL=station-connector-selector.component.css.map */
