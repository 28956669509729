<ion-menu contentId="main-content" #ionMenu swipeGesture="false">

  <ion-header>
    <ion-toolbar>
      <ion-title>
        {{ 'MENU.MENU' | translate}}
      </ion-title>
      <ion-menu-toggle slot="end">
        <ion-icon name="close-outline"></ion-icon>
      </ion-menu-toggle>
    </ion-toolbar>
  </ion-header>

  <ion-content class="ion-padding-start ion-padding-end ion-padding-bottom" scrollY="false">
    @if(mainStateService.$user(); as user){
    <ion-item>
      <ion-avatar class="item-icon" slot="start">
        <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
      </ion-avatar>
      <ion-label>
        <h3 class="fw-600">{{ user.name + ' ' + user.surname }}</h3>
        <p>{{ user.email }}</p>
      </ion-label>
    </ion-item>
    }
    <ion-list lines="none">
      <ion-menu-toggle>
        <ion-item button detail="false" *ngFor="let item of $items(); let i = index" (click)="itemClick(item)">
          <ion-icon slot="start" [name]="item.iconName"></ion-icon>
          <ion-label *ngIf="item.title !== 'Utente'">{{ item.title | translate}}</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>

    <div class="debug">
      @if(isDevelop$ | async){
      {{'MENU.CID' | translate}}: <b>{{ clientId }}</b> <br>
      {{'MENU.URL' | translate}}: <b>{{ BACKEND_URL }}</b>
      }

      {{'MENU.PLATFORM' | translate}}: <b>{{ mainStateService.$platformType() }}</b> <br>

    </div>
  </ion-content>

  <ion-footer>
    <ion-toolbar>
      <ion-title>
        <small>Vers. {{ $appVersion() }}</small>
      </ion-title>
    </ion-toolbar>
  </ion-footer>

</ion-menu>