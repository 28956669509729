"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UserType = void 0;
exports.UserType = Object.freeze({
  PRIVATO: 'PRIVATO',
  PARTITA_IVA: 'PARTITA_IVA'
});
