/* src/app/components/state-simple/state-simple.component.scss */
.state-container {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.icon {
  font-size: 16px;
}
/*# sourceMappingURL=state-simple.component.css.map */
