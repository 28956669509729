/* src/app/components/map/map.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
mgl-map {
  height: 100%;
  width: 100%;
}
@media (max-width: 576px) {
  mgl-map {
    height: calc(100% - env(safe-area-inset-bottom));
  }
}
mgl-map ::ng-deep .maplibregl-ctrl-bottom-right {
  z-index: 1 !important;
}
mgl-map ::ng-deep .maplibregl-ctrl-bottom-right .maplibregl-ctrl {
  border-radius: 28px !important;
  -webkit-border-radius: 28px !important;
}
mgl-map ::ng-deep .maplibregl-ctrl button {
  width: 48px !important;
  height: 48px !important;
}
mgl-map ::ng-deep .mapboxgl-ctrl-group:not(:empty),
mgl-map ::ng-deep .maplibregl-ctrl-group:not(:empty) {
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.1);
}
mgl-map ::ng-deep .mapboxgl-ctrl button:not(:disabled):hover,
mgl-map ::ng-deep .maplibregl-ctrl button:not(:disabled):hover {
  background-color: unset;
}
/*# sourceMappingURL=map.component.css.map */
