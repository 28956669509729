"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EVSE_STATUS = void 0;
exports.EVSE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  BLOCKED: 'BLOCKED',
  CHARGING: 'CHARGING',
  UNOPERATIVE: 'UNOPERATIVE',
  INOPERATIVE: 'INOPERATIVE',
  OUTOFORDER: 'OUTOFORDER',
  PLANNED: 'PLANNED',
  REMOVED: 'REMOVED',
  RESERVED: 'RESERVED',
  UNKNOWN: 'UNKNOWN'
};
