"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReasonType = void 0;
exports.ReasonType = Object.freeze({
  byGroup: 0,
  byInstallation: 1,
  byPod: 2,
  default: 3,
  unknownUser: 4
});
