"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ROLES = void 0;
exports.ROLES = Object.freeze({
  CPO: "CPO",
  eMSP: "EMSP",
  HUB: "HUB"
});
