/* src/app/components/filter/filter-connector-selector/filter-connector-selector.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.plug-button {
  height: 56px;
  width: auto;
  flex-basis: 25%;
  --border-radius: 0px;
  --box-shadow: transparent;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  margin: 0;
}
.plug-button ion-icon {
  font-size: 28px;
}
/*# sourceMappingURL=filter-connector-selector.component.css.map */
