@if ($show()) {
<div class="app-update-screen">

  <div class="white-text">
    {{ "APP.SOFTWARE_UPDATE.TITLE" | translate }}
  </div>

  <div class="app-logo">
    <img src="assets/images/app-update.png" alt="Update" />
  </div>

  <div class="white-text">
    {{ "APP.SOFTWARE_UPDATE.MESSAGE" | translate }}
  </div>

  @if($showAutomaticUpdateButton()){
  <ion-button (click)="updateApplication()" class="btn large white" debounceClick>
    {{ "APP.SOFTWARE_UPDATE.UPDATE_BUTTON" | translate }}
  </ion-button>
  }
  @else {
  <ion-button (click)="openStoreLink()" class="btn large white" debounceClick>
    {{ "APP.SOFTWARE_UPDATE.UPDATE_BUTTON" | translate }}
  </ion-button>
  }
</div>
}