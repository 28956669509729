import { Component, EventEmitter, Input, Output, Signal, computed, input } from '@angular/core';
import { ISession, IStation } from 'common_library';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
    selector: 'yf-mini-station',
    templateUrl: './mini-station.component.html',
    styleUrls: ['./mini-station.component.scss'],
    standalone: false
})
export class MiniStationComponent {
  $station = input<IStation>(null);

  @Input() isOfInstallationList = false;
  @Output() stationClicked = new EventEmitter<any>();


  $isBackgroundHighlighted: Signal<boolean> = computed(() => {
    return this.$station()?.id === this.mainStateService.$selectedStation()?.id
      && this.mainStateService.$selectedConnectorHasMineSession()
  });

  $userActiveSession: Signal<ISession> = computed(() => {
    const session = this.mainStateService.$sessions()?.find(s => s.connector.stationId === this.$station()?.id);
    return session;
  })

  $isSelected = computed(() => {
    return this.mainStateService.$selectedStation()?.id === this.$station()?.id;
  })

  constructor(
    private mainStateService: MainStateService) { }

  stationClick() {
    this.stationClicked.emit();
  }
}
