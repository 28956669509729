"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnergyManagement = void 0;
exports.EnergyManagement = Object.freeze({
  None: 0,
  Balanced: 1,
  AlmostBalanced: 2,
  FirstStarted: 3
});
