/* src/app/components/photos/photos.component.scss */
.title {
  font-size: 12px;
  color: var(--ion-color-medium);
  padding-top: 8px;
}
.slide-container {
  position: relative;
  min-height: 60px;
}
.icon-container {
  background:
    linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      #fff 30%);
  position: absolute;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 0;
  bottom: 0;
  height: auto;
  width: 90px;
  z-index: 998;
}
.btnAdd {
  --box-shadow: transparent;
}
.image {
  border-radius: 6px;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
/*# sourceMappingURL=photos.component.css.map */
