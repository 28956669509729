import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Camera, CameraDirection, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import { AlertController, IonImg } from '@ionic/angular';
import { IPhoto, IStation } from 'common_library';
import { AuthService } from '../../services/auth/auth.service';
import { PhotoService } from '../../services/entities/photo.service';
import { ToastService } from 'src/app/services/utils/toast.service';

@Component({
    selector: 'yf-photo-container',
    templateUrl: './photos.component.html',
    styleUrls: ['./photos.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class PhotosComponent implements OnChanges {
  images: IPhoto[] = [];
  @Input() stationId: string;

  fileSlideOpts = {
    slidesPerView: 4.5,
    spaceBetween: 10,
    clickable: true,
  };

  isLoading: boolean = false;

  constructor(public photoService: PhotoService, public auth: AuthService) {}

  async ngOnChanges() {
    // this.isLoading = true;
    if (this.images.length > 0) {
      this.images = [];
    }

    this.images = await this.photoService.getAllByStationId(this.stationId);
  }

  public async newPhotoToGallery() {
    // Take a photo
    const image = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      allowEditing: false,
      source: CameraSource.Camera,
      quality: 90,
      direction: CameraDirection.Rear,
    });

    if (image) {
      const blob = await this.convertToBlob(image);
      const uploadRes = await this.photoService.uploadPhoto(this.stationId, blob);
      this.images = await this.photoService.getAllByStationId(this.stationId);
    }
  }

  async convertToBlob(photo: Photo) {
    const response = await fetch(photo.webPath);
    const blob = await response.blob();
    return blob;
  }

  trackByMethod(index: number, el: any): string {
    return el.id;
  }

  fullScreen(id) {
    let image = document.getElementById(id);
    // image.style.objectFit = 'contain';
    image.requestFullscreen();
  }

  // async deletePhoto(id) {
  //   const alert = await this.alertController.create({
  //     header: 'Conferma',
  //     message: 'Vuoi davvero cancellare la foto?',
  //     buttons: [
  //       {
  //         text: 'Cancel',
  //         role: 'cancel',
  //         cssClass: 'secondary',
  //         id: 'cancel-button',
  //         handler: (canc) => {
  //           close();
  //         },
  //       },
  //       {
  //         text: 'Ok',
  //         id: 'confirm-button',
  //         handler: async () => {
  //           await this.photoService.deletePhoto(id);
  //           this.images = await this.photoService.getAllByStationId(this.stationId);
  //           this.toast.presentBasicToast({
  //             message: 'Foto Eliminata',
  //             color: 'secondary',
  //             duration: 1000,
  //           });
  //         },
  //       },
  //     ],
  //   });
  //   await alert.present();
  // }
}
