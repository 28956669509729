<div class="delete-profile-container">
  <div class="yf-container centered gap-12 m-20">
    <ion-text color="primary">
      <h2>{{ "PROFILE.USER_DETAIL.DELETING_PROFILE" | translate }}</h2>
    </ion-text>
  </div>
  <div class="yf-container gap-24 form-container">
    <div class="yf-row spaced">
      <ion-text>
        {{ "PROFILE.USER_DETAIL.DELETING_PROFILE_MESSAGE" | translate}}
      </ion-text>
    </div>
    <yf-divider></yf-divider>
    <div class="yf-row spaced">
      <ion-checkbox #ionCheckbox class="checkbox-square"></ion-checkbox>
      <ion-text class="text">
        {{ "PROFILE.USER_DETAIL.I_CONFIRM_I_WANT_TO_DELETE_PROFILE" | translate }} 
      </ion-text>
    </div>
    <div class="yf-row gap-12 spaced">
      <ion-button class="btn large" color="primary" fill="outline" (click)="goBack()">       
        {{ "BUTTONS.CANCEL" | translate }} </ion-button>
      <ion-button class="btn large" color="danger" (click)="deleteProfile()" [disabled]="!ionCheckbox.checked">       
        {{ "PROFILE.USER_DETAIL.DELETE_PROFILE" | translate }}</ion-button>
    </div>
  </div>
</div>
