import { Component, computed, input } from '@angular/core';
import { ISession, CU } from 'common_library';
import { map, Observable, shareReplay, timer } from 'rxjs';
import { CoreService } from 'src/app/services/core.service';
import { SessionService } from 'src/app/services/entities/session.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { UiService } from 'src/app/services/utils/ui.service';

@Component({
    selector: 'yf-state-simple-timer',
    templateUrl: './state-simple-timer.component.html',
    styleUrls: ['./state-simple-timer.component.scss'],
    standalone: false
})
export class StateSimpleTimerComponent {
  chargeTime$: Observable<any>;
  occupationTime$: Observable<any>;
  occupationPaymentTime$: Observable<any>;
  reservationTime$: Observable<string>;
  timeLeft$: Observable<any>;

  $currentSession = input<ISession>(null);

  constructor(
    private SessionService: SessionService,
    public mainStateService: MainStateService,
    public UI: UiService,
    public CS: CoreService) {

    this.timeLeft$ = timer(0, 1000).pipe(
      map(() => CU.formattedCountdown(new Date(this.$currentSession()?.bookingEndAt))),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.chargeTime$ = timer(0, 1000).pipe(
      map(() => this.SessionService.calcDate(this.$currentSession().startedAt)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.occupationTime$ = timer(0, 1000).pipe(
      map(() => this.SessionService.calcDate(this.$currentSession().stoppedAt)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.occupationPaymentTime$ = timer(0, 1000).pipe(
      map(() => {
        const minuteOccupation = this.$currentSession()?.occupationTime * 60;
        const calcParkingStartDate = CU.addMinutes(new Date(), -minuteOccupation);
        return this.SessionService.calcDate(calcParkingStartDate);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  $sessionClass = computed(() => {
    return this.UI.getSessionStateClass(this.$currentSession()?.state);
  })
}
