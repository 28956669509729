import { Component, OnInit, Signal, computed } from '@angular/core';
import { ErrorCode, ICar, OCPITariffDimension, TariffUtil } from 'common_library';
import { CoreService } from 'src/app/services/core.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { ModalController } from '@ionic/angular';
import { _ } from 'src/app/consts';
import { TranslateConfigService } from 'src/app/services/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { CmdButton } from 'src/app/types/buttons/button-state.interface';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'yf-confirm-station-ops',
    templateUrl: './confirm-station-ops.component.html',
    styleUrls: ['./confirm-station-ops.component.scss'],
    standalone: false
})
export class ConfirmStationOpsComponent implements OnInit {

  currentStep$ = new BehaviorSubject<number>(0);

  $tariffDeposit: Signal<number> = computed(() => {
    return this.mainStateService.$selectedConnector()?.tariff?.deposit;
  });

  $tariffText: Signal<string> = computed(() => {
    let tariff = this.mainStateService.$selectedConnector()?.tariff;
    try {
      const lang = this.translateConfigService.getCurrentLang();

      const descriptions = TariffUtil.getTariffAltTextForConnectorPower(tariff, lang, TariffUtil.getConnectorPower(this.mainStateService.$selectedConnector()));
      const reservationDescription = descriptions.find(c => c.dimension === OCPITariffDimension.RESERVATION);
      const energyDescription = descriptions.find(c => c.dimension === OCPITariffDimension.ENERGY);
      const parkingDescription = descriptions.find(c => c.dimension === OCPITariffDimension.PARKING_TIME);
      let textDescrption = `${reservationDescription.description} ${energyDescription.description} ${parkingDescription.description}`.trim();
      return textDescrption;
    } catch (e) {
      return tariff?.tariffAltText[0]?.text;
    }
  });

  constructor(
    public CS: CoreService,
    private toast: ToastService,
    private modalController: ModalController,
    public mainStateService: MainStateService,
    private translate: TranslateService,
    private translateConfigService: TranslateConfigService,
  ) { }

  ngOnInit(): void {
    this.mainStateService.setSelectedUserCarForSession(this.mainStateService.$selectedUserCar());
  }

  async confirmOperation() {
    if (this.mainStateService.$connectorCommandInProgress()) return;
    const currentConnector = this.mainStateService.$selectedConnector();
    var expiringDate = new Date();
    expiringDate.setSeconds(expiringDate.getSeconds() + _.OCPI_SESSION_TIMEOUT);
    this.mainStateService.updateButtonState(currentConnector.id, { exipresAt: expiringDate, command: CmdButton.StartCharge });
    await this.modalController.dismiss();

    const rv = await this.CS.startCharge(currentConnector.id, this.mainStateService.$selectedConnectorMineSession()?.id, this.mainStateService.getLastClientPosition());

    if (!!rv && rv?.errorCode !== ErrorCode.NoError) {
      await this.CS.manageErrorCodes(rv.errorCode);
    } else if (!rv) {
      await this.toast.presentBasicToast({ message: this.translate.instant(("TOAST_MESSAGES.UNKNOWN_ERROR")), color: 'danger', icon: 'warning' });
    } else {
      const message = currentConnector.innerState === 'Preparing' ? this.translate.instant("APP.INSTALLATION.START_CHARGING") : this.translate.instant("APP.INSTALLATION.CONNECT_PLUG_AND_WAIT_STARTING")
      await this.toast.presentBasicToast({ message, color: 'tertiary', icon: 'flash-outline' });
    }

    if (!!rv.session && rv?.errorCode === ErrorCode.NoError) {
      //
    }
  }

  selectNewCar(car: ICar) {
    this.mainStateService.setSelectedUserCarForSession(car);
  }

  close() {
    this.modalController.dismiss();
  }

  showNextStep() {
    this.currentStep$.next(this.currentStep$.value + 1)
  }

  showPrevStep() {
    this.currentStep$.next(this.currentStep$.value > 0 ? this.currentStep$.value - 1 : 0)
  }
}
