/* src/app/pages/filter/filter.page.scss */
ion-card {
  max-height: calc(100vh - 80px - 24px);
  overflow-y: auto;
  background: var(--ion-color-light);
  box-shadow: var(--yf-shadow);
}
@media (max-width: 576px) {
  ion-card {
    width: -webkit-fill-available;
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  ion-card {
    width: 360px;
  }
}
.error {
  display: flex;
  justify-content: space-between;
  color: #c5000f;
  align-items: center;
}
/*# sourceMappingURL=filter.page.css.map */
