"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TariffUtil = void 0;
var utils_1 = require("./utils");
var revenueEntity_enum_1 = require("./enums/revenueEntity.enum");
var revenueSource_enum_1 = require("./enums/revenueSource.enum");
var currency_enum_1 = require("./enums/currency.enum");
var ocpiCdrDimensionType_enum_1 = require("./ocpi/enums/ocpiCdrDimensionType.enum");
var revenueDimension_enum_1 = require("./enums/revenueDimension.enum");
var ocpiTariffDimension_enum_1 = require("./ocpi/enums/ocpiTariffDimension.enum");
var ocpiReservationRestriction_enum_1 = require("./ocpi/enums/ocpiReservationRestriction.enum");
var TariffUtil;
(function (TariffUtil) {
  // la funzione deve prendere come parametro solo la sessione, dal momento che la tariffa è salvata nella sessione
  // all'interno, poi, invocherà una funzione più "pura" che prenderà come parametri una tariffa e dei valori di durata prenotazione, energia e occupazione in modo che
  // tale funzione interna possa essere anche invocata dal simulatore
  // Questo è l'unico punto dove vengono fatti i calcoli, e gli importi devono essere già con 2 cifre decimali soltanto (con attenzione a non effettuare troppi arrotondamenti successivi)
  function calculateSessionCosts(session, statusNotifications) {
    if (statusNotifications === void 0) {
      statusNotifications = [];
    }
    var reservationTimeInSeconds = 0;
    var energyInWatt = 0;
    var parkingTime = {
      timeInSeconds: 0,
      gracePeriodAlreadyCalcualted: false
    };
    var connectorPower = getConnectorPower(session.connector);
    //if (!statusNotifications) return;
    // #region RESERVATION
    // Calcolo durata prenotazione e relativo costo
    if (!!(session === null || session === void 0 ? void 0 : session.reservedAt)) {
      var reservationEnd = new Date(session.bookingEndAt);
      var weAreOverBookingEnd = utils_1.CU.dateAIsBigger(new Date(), new Date(session.bookingEndAt));
      if (!weAreOverBookingEnd) {
        reservationEnd = new Date();
      }
      // Se l'utente ha avviato la ricarica
      if (!!(session === null || session === void 0 ? void 0 : session.startedAt)) {
        reservationEnd = new Date(session.startedAt);
      }
      reservationTimeInSeconds = utils_1.CU.getDateDiffInSecondsWithOneOnZero(new Date(session.reservedAt), reservationEnd);
    }
    // #endregion RESERVATION
    // #region ENERGY
    // calcolo volume energia    
    energyInWatt = session.energy ? session.energy : 0;
    // #endregion ENERGY
    // #region PARKING
    // calcolo tempo occupazione
    // Se sono in una sessione OCPI, mi arriva il dato del parcheggio già netto ed ha precedenza sui nostri calcoli
    if (session.occupationTime) {
      parkingTime = {
        timeInSeconds: session.occupationTime * 3600,
        gracePeriodAlreadyCalcualted: true
      };
    } else {
      var parkingTimeInSeconds = void 0;
      var secondsFromFinishedChargingToEndSession = 0;
      if (session.stoppedAt) {
        var sessionParkingEnd = session.endedAt ? new Date(session.endedAt) : new Date(); // perchè se session.endedAt è null, new Date(null) è 1/1/1970!
        secondsFromFinishedChargingToEndSession = utils_1.CU.getDateDiffInSeconds(sessionParkingEnd, new Date(session.stoppedAt));
        // Controllo la presenza di eventuali restrictions nella tariffa relativi ad intervalli temporali in cui si paga il parcheggio
        if (session.tariffJson) {
          var sessionTariff = session.tariffJson;
          var parkingPriceComponents = getDimensionElements(sessionTariff, ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME, connectorPower);
          if (parkingPriceComponents && parkingPriceComponents.length > 0) {
            var parikingPriceComponentsWithTimeRestrictions = parkingPriceComponents.filter(function (c) {
              return c.restrictions && c.restrictions.startTime && c.restrictions.endTime;
            });
            if (parikingPriceComponentsWithTimeRestrictions && parikingPriceComponentsWithTimeRestrictions.length > 0) {
              // ci sono delle restrictions (per il momento ne gestiamo una e basta) che agiscono in base alla fascia oraria
              secondsFromFinishedChargingToEndSession = calculateSecondsInTariffElementWithStartTimeEndTime(parikingPriceComponentsWithTimeRestrictions[0], session.stoppedAt, sessionParkingEnd);
            }
          }
        }
      }
      parkingTimeInSeconds += secondsFromFinishedChargingToEndSession;
      parkingTime = {
        timeInSeconds: parkingTimeInSeconds,
        gracePeriodAlreadyCalcualted: false
      };
    }
    /*
    //
    // Per il momento non eseguo tale calcolo, dal momento che dovrei incrociare il calcolo con le restriction della tariffa
    //
          
    // se durante il periodo di ricarica si sono succeduti più eventi
    // "SuspendedEV" -> "Charging", dobbiamo considerare i periodi in cui la sessione è stata in "SuspendedEV" (se la duarata è stata maggiore di una certa soglia, ad es 1 min.)
    // come tempo di occupazione
          let secondsFromSuspendedEVToCharging = 0;
    // ordino gli stati per createdAt
    const statusNotificationsSortedByCreatedAt = statusNotifications.sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    // ciclo gli stati ordinati
    for (let i = 0; i < statusNotificationsSortedByCreatedAt.length; i++) {
      const statusNotification = statusNotificationsSortedByCreatedAt[i];
      const nextstatusNotification = statusNotificationsSortedByCreatedAt[i + 1];
      // trovo la sequenza "SuspendedEV" -> "Charging"
      if (statusNotification?.status === OCPPComunication.InnerState.SuspendedEV && (nextstatusNotification && nextstatusNotification?.status === OCPPComunication.InnerState.Charging)) {
        secondsFromSuspendedEVToCharging += CU.getDateDiffInSeconds(nextstatusNotification.createdAt, statusNotification.createdAt)
      }
    }
    const lastStatusNotification = statusNotificationsSortedByCreatedAt[statusNotificationsSortedByCreatedAt.length - 1];
    if (lastStatusNotification?.status === OCPPComunication.InnerState.SuspendedEV) {
      secondsFromSuspendedEVToCharging += CU.getDateDiffInSeconds(new Date(), lastStatusNotification.createdAt);
    }
    if (secondsFromSuspendedEVToCharging > 60) parkingTimeInSeconds += secondsFromSuspendedEVToCharging;
    parkingTimeInSeconds += secondsFromFinishedChargingToEndSession;
    */
    // #endregion PARKING
    var info = {
      reservationTimeInSeconds: reservationTimeInSeconds,
      energyInWatt: energyInWatt,
      parking: parkingTime,
      tariff: session.tariffJson,
      connectorPower: connectorPower,
      withRevenueIncrement: true
    };
    return calculateCosts(info);
  }
  TariffUtil.calculateSessionCosts = calculateSessionCosts;
  //Deve essere possibile richiamare tale funzione anche da simulatore dell'admin
  function calculateCosts(info) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    if (!info.connectorPower) {
      info.connectorPower = 300000;
    }
    var costs = [];
    var reservationDimensionCosts = {
      price: {
        inclVat: 0,
        exclVat: 0,
        vat: 22
      },
      type: ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.RESERVATION_TIME,
      volume: 0
    };
    var energyDimensionCosts = {
      price: {
        inclVat: 0,
        exclVat: 0,
        vat: 22
      },
      type: ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.ENERGY,
      volume: 0
    };
    var parkingDimensionCosts = {
      price: {
        inclVat: 0,
        exclVat: 0,
        vat: 0
      },
      type: ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.PARKING_TIME,
      volume: 0
    };
    // #region RESERVATION
    // Se la prenotazione ha avuto una durata, cerco di calcolarne il costo
    if (info.reservationTimeInSeconds > 0) {
      // Cerco il blocco "Reservation" all'interno della definizione della tariffa      
      var validReservationElement = getDimensionElements(info.tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION, info.connectorPower);
      // Il tempo trascorso in prenotazione in teoria non dovrebbe mai essere maggiore di quanto indicato nella tariffa, 
      // perchè un cron controlla le prenotazioni più lunghe e le chiude d'ufficio
      if (validReservationElement.length > 0 && ((_a = validReservationElement[0].restrictions) === null || _a === void 0 ? void 0 : _a.maxDuration) && info.reservationTimeInSeconds > ((_b = validReservationElement[0].restrictions) === null || _b === void 0 ? void 0 : _b.maxDuration)) {
        info.reservationTimeInSeconds = (_c = validReservationElement[0].restrictions) === null || _c === void 0 ? void 0 : _c.maxDuration;
      }
      var reservationComponent = (_d = validReservationElement[0]) === null || _d === void 0 ? void 0 : _d.priceComponents[0]; // assumo ci sia un solo element e un solo price component per reservation
      if (reservationComponent) {
        var revenueReservation = getDimensionRevenue(info.tariff, revenueDimension_enum_1.RevenueDimension.RESERVATION);
        var reservationPriceForHour = info.withRevenueIncrement ? calculatePriceWithRevenue(revenueReservation, reservationComponent.price) : reservationComponent.price;
        var reservationVAT = getDimensionVAT(info.tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION, info.connectorPower);
        if (reservationComponent.type === ocpiTariffDimension_enum_1.OCPITariffDimension.FLAT) {
          // Se il tipo è flat, vuol dire che pago la stessa cifra indipendentemente dal tempo trascorso
          var reservationCostExclVatRounded = utils_1.CU.roundNumber(reservationPriceForHour, 2);
          // calcolo il costo dell'energia con vat. Prendo il dato già arrotondato
          // per spiegazione del perchè si prende il prezzo arrotondato vedi sezione energia
          var reservationCostWithVatRounded = utils_1.CU.roundNumber(utils_1.CU.calcPriceWithVat(reservationCostExclVatRounded, reservationVAT));
          reservationDimensionCosts.price.inclVat = reservationCostWithVatRounded;
          reservationDimensionCosts.price.exclVat = reservationCostExclVatRounded;
          reservationDimensionCosts.price.vat = reservationVAT;
          reservationDimensionCosts.volume = info.reservationTimeInSeconds;
        } else {
          // calcolo il numero di secondi che verranno calcolati effettivamente, considerando lo step
          var reservationTimeInSecondsWithStep = Math.ceil(info.reservationTimeInSeconds / reservationComponent.stepSize) * reservationComponent.stepSize;
          // calcolo il prezzo al secondo, visto che sulla tariffa è orario
          var reservationPriceForSecond = reservationPriceForHour / 3600;
          // calcolo il costo senza VAT arrotondato
          var reservationCostExclVatRounded = utils_1.CU.roundNumber(reservationPriceForSecond * reservationTimeInSecondsWithStep, 2);
          // calcolo il costo dell'energia con vat. Prendo il dato già arrotondato
          // per spiegazione del perchè si prende il prezzo arrotondato vedi sezione energia
          var reservationCostWithVatRounded = utils_1.CU.roundNumber(utils_1.CU.calcPriceWithVat(reservationCostExclVatRounded, reservationVAT));
          reservationDimensionCosts.price.inclVat = reservationCostWithVatRounded;
          reservationDimensionCosts.price.exclVat = reservationCostExclVatRounded;
          reservationDimensionCosts.price.vat = reservationVAT;
          reservationDimensionCosts.volume = info.reservationTimeInSeconds;
        }
      }
    }
    costs.push(reservationDimensionCosts);
    // #endregion RESERVATION
    // #region ENERGY
    // Se la prenotazione ha avuto una durata, cerco di calcolarne il costo
    if (info.energyInWatt > 0) {
      var revenueEnergy = getDimensionRevenue(info.tariff, revenueDimension_enum_1.RevenueDimension.ENERGY);
      var energyElements = getDimensionElements(info.tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY, info.connectorPower);
      if (energyElements && energyElements.length > 0) {
        var energyPriceComponent = energyElements[0].priceComponents[0]; // assumo ci sia un solo element e un solo price component per energy
        var energyPriceForKW = info.withRevenueIncrement ? calculatePriceWithRevenue(revenueEnergy, energyPriceComponent.price) : energyPriceComponent.price;
        // calcolo l'energia che verrà calcolata effettivamente, considerando lo step
        var stepSize = (_e = energyPriceComponent.stepSize) !== null && _e !== void 0 ? _e : 1;
        var energyInWattWithStep = Math.ceil(info.energyInWatt / stepSize) * stepSize;
        var energyInKw = energyInWattWithStep / 1000;
        // calcolo il costo dell'energia senza vat 
        var energyCost = energyInKw * energyPriceForKW;
        // calcolo il costo senza VAT arrotondato
        var energyCostExclVatRounded = utils_1.CU.roundNumber(energyCost, 2);
        // calcolo il moltiplicatore di vat
        var energyElementVat = getDimensionVAT(info.tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY, info.connectorPower);
        // calcolo il costo dell'energia con vat. Prendo il dato già arrotondato, altrimenti rischio
        // di avere incoerenza fra dato arrotondato senza VAT e con VAT
        // ad es: se dato senza VAT non arrotondato = 0.5139750000000001
        // dato senza VAT arrotondato = 0.51
        // dato con VAT arrotondato prendendo come base 0.5139750000000001 -> 0.672049 -> 0.63
        // dato con VAT arrotondato prendendo come base 0.51 -> 0.62
        var energyCostInclVatRounded = utils_1.CU.roundNumber(utils_1.CU.calcPriceWithVat(energyCostExclVatRounded, energyElementVat));
        energyDimensionCosts.price.inclVat = energyCostInclVatRounded;
        energyDimensionCosts.price.exclVat = energyCostExclVatRounded;
        energyDimensionCosts.price.vat = energyElementVat;
        energyDimensionCosts.volume = info.energyInWatt;
      }
    }
    costs.push(energyDimensionCosts);
    // #endregion ENERGY
    // #region PARKING
    if (!!info.parking) {
      var parkingCost = 0;
      var parkingCostWithVAT = 0;
      var parkingVAT = 0;
      var parkingTimeSeconds = (_f = info.parking) === null || _f === void 0 ? void 0 : _f.timeInSeconds;
      if (parkingTimeSeconds > 0) {
        var parkingTariffElement = getDimensionElements(info.tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME, info.connectorPower);
        var revenueParking = getDimensionRevenue(info.tariff, revenueDimension_enum_1.RevenueDimension.PARKING);
        parkingTariffElement = parkingTariffElement.sort(compareRestrictionByMinDuration);
        for (var _i = 0, parkingTariffElement_1 = parkingTariffElement; _i < parkingTariffElement_1.length; _i++) {
          var tariffElement = parkingTariffElement_1[_i];
          var priceComponent = tariffElement.priceComponents[0];
          if (priceComponent.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.PARKING_TIME) {
            if (parkingTimeSeconds < 0) continue;
            if (((_g = info.parking) === null || _g === void 0 ? void 0 : _g.gracePeriodAlreadyCalcualted) && priceComponent.price === 0) continue; // Se il Grace Period è stato già considerato, salto i periodi gratuiti
            var parkingTimeVat = getDimensionVAT(info.tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME, info.connectorPower);
            var parkingTimePriceForHour = info.withRevenueIncrement ? calculatePriceWithRevenue(revenueParking, priceComponent.price) : priceComponent.price;
            // calcolo il numero di secondi che verranno calcolati effettivamente, considerando lo step
            var parkingTimeInSecondsWithStep = Math.ceil(parkingTimeSeconds / priceComponent.stepSize) * priceComponent.stepSize;
            // calcolo il prezzo al secondo, visto che sulla tariffa è orario
            var parkingTimePriceForSecond = parkingTimePriceForHour / 3600;
            parkingCost += utils_1.CU.roundNumber(parkingTimeInSecondsWithStep * parkingTimePriceForSecond);
            parkingCostWithVAT += utils_1.CU.calcPriceWithVat(parkingCost, parkingTimeVat);
            parkingTimeSeconds -= (_j = (_h = tariffElement.restrictions) === null || _h === void 0 ? void 0 : _h.maxDuration) !== null && _j !== void 0 ? _j : 0;
            parkingVAT = parkingTimeVat;
          }
        }
      }
      var parkingCostExclVatRounded = utils_1.CU.roundNumber(parkingCost);
      var parkingCostInclVatRounded = utils_1.CU.roundNumber(parkingCostWithVAT);
      parkingDimensionCosts.price.inclVat = parkingCostInclVatRounded;
      parkingDimensionCosts.price.exclVat = parkingCostExclVatRounded;
      parkingDimensionCosts.price.vat = parkingVAT;
      parkingDimensionCosts.volume = (_k = info.parking) === null || _k === void 0 ? void 0 : _k.timeInSeconds;
      costs.push(parkingDimensionCosts);
    }
    // #endregion PARKING
    return costs;
  }
  TariffUtil.calculateCosts = calculateCosts;
  function compareRestrictionByMinDuration(a, b) {
    if (a.restrictions) {
      if (b.restrictions) {
        if (a.restrictions.minDuration < b.restrictions.minDuration) {
          return -1;
        }
        if (a.restrictions.minDuration > b.restrictions.minDuration) {
          return 1;
        }
        return 0;
      } else {
        return -1;
      }
    } else if (!b.restrictions) {
      return 0;
    }
    {
      return 1;
    }
  }
  function compareRestrictionByMaxPower(a, b) {
    if (a.restrictions) {
      if (b.restrictions) {
        if (a.restrictions.maxPower < b.restrictions.maxPower) {
          return -1;
        }
        if (a.restrictions.maxPower > b.restrictions.maxPower) {
          return 1;
        }
        return 0;
      } else {
        return -1;
      }
    } else if (!b.restrictions) {
      return 0;
    }
    {
      return 1;
    }
  }
  function getPowerRestrictionsForConnector(elements, connectorPower) {
    var _a, _b;
    var elementsToReturn = [];
    var connectorPowerInKW = connectorPower / 1000;
    var fallbackElements = elements.filter(function (e) {
      var _a;
      return utils_1.CU.isNullOrUndefined((_a = e.restrictions) === null || _a === void 0 ? void 0 : _a.maxPower);
    });
    var elementsWithPowerRestriction = elements.filter(function (e) {
      var _a, _b;
      return ((_a = e.restrictions) === null || _a === void 0 ? void 0 : _a.maxPower) && connectorPowerInKW <= ((_b = e.restrictions) === null || _b === void 0 ? void 0 : _b.maxPower);
    });
    if (elementsWithPowerRestriction && elementsWithPowerRestriction.length > 0) {
      elementsWithPowerRestriction.sort(function (a, b) {
        return compareRestrictionByMaxPower(a, b);
      });
      var elementsToAnalyze = JSON.parse(JSON.stringify(elementsWithPowerRestriction));
      // Sono già in ordine crescente e filtrati per maxPower > connectorPowerInKW...il primo elemento è per forza valido;
      // devo controllare se ci sono altre restrictions per lo stesso valore
      var firstItemValid = elementsToAnalyze.shift();
      var maxPowerSelected = (_a = firstItemValid.restrictions) === null || _a === void 0 ? void 0 : _a.maxPower;
      elementsToReturn.push(firstItemValid);
      for (var index = 0; index < elementsToAnalyze.length; index++) {
        var element = elementsToAnalyze[index];
        if (((_b = element.restrictions) === null || _b === void 0 ? void 0 : _b.maxPower) === maxPowerSelected) {
          elementsToReturn.push(element);
        }
      }
    } else {
      elementsToReturn = fallbackElements;
    }
    return elementsToReturn;
  }
  function generateCostRevenueSplits(entities, tariff, cdr, connectorPower) {
    var _a, _b, _c;
    var revenueArr = [];
    var info = {
      reservationTimeInSeconds: cdr.totalReservationTime,
      energyInWatt: cdr.totalEnergy,
      parking: {
        timeInSeconds: cdr.totalParkingTime,
        gracePeriodAlreadyCalcualted: false
      },
      tariff: tariff,
      connectorPower: connectorPower,
      withRevenueIncrement: false
    };
    var costs = calculateCosts(info);
    var rCost = (_a = costs.find(function (c) {
      return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.RESERVATION_TIME;
    })) === null || _a === void 0 ? void 0 : _a.price;
    var eCost = (_b = costs.find(function (c) {
      return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.ENERGY;
    })) === null || _b === void 0 ? void 0 : _b.price;
    var pCost = (_c = costs.find(function (c) {
      return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.PARKING_TIME;
    })) === null || _c === void 0 ? void 0 : _c.price;
    var revenues = createRevenues(tariff, rCost, eCost, pCost);
    revenues.forEach(function (element) {
      var _a;
      var amount = utils_1.CU.roundNumber(element.amount);
      revenueArr.push({
        entity: element.entity,
        userId: entities[Object.keys(revenueEntity_enum_1.RevenueEntity)[element.entity]],
        amount: amount,
        isInCredit: amount > 0,
        sessionId: cdr.sessionId,
        cdrId: cdr.id,
        currency: element.currency,
        amountWithVat: element.amountWithVat,
        source: ((_a = tariff.REVENUE) === null || _a === void 0 ? void 0 : _a.find(function (c) {
          return c.type === revenueDimension_enum_1.RevenueDimension.ENERGY;
        })) ? revenueSource_enum_1.RevenueSource.Tariff : revenueSource_enum_1.RevenueSource.Default,
        type: element.type,
        tariffId: tariff.id
      });
    });
    return revenueArr;
  }
  TariffUtil.generateCostRevenueSplits = generateCostRevenueSplits;
  // Questa funzione deve essere possibile richiamarla anche da simulatore dell'admin
  function calculateRevenues(reservationTimeInSeconds, energyInWatt, secondsFromStoppedAt, tariff, connectorPower) {
    var _a, _b, _c;
    var revenueEntities = {
      cpo: 0,
      emsp: 0,
      podOwner: 0,
      brandOwner: 0,
      deviceOwner: 0,
      installationOwner: 0
    };
    var info = {
      reservationTimeInSeconds: reservationTimeInSeconds,
      energyInWatt: energyInWatt,
      parking: {
        timeInSeconds: secondsFromStoppedAt,
        gracePeriodAlreadyCalcualted: false
      },
      tariff: tariff,
      connectorPower: connectorPower,
      withRevenueIncrement: false
    };
    var costs = calculateCosts(info);
    var rCost = (_a = costs.find(function (c) {
      return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.RESERVATION_TIME;
    })) === null || _a === void 0 ? void 0 : _a.price;
    var eCost = (_b = costs.find(function (c) {
      return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.ENERGY;
    })) === null || _b === void 0 ? void 0 : _b.price;
    var pCost = (_c = costs.find(function (c) {
      return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.PARKING_TIME;
    })) === null || _c === void 0 ? void 0 : _c.price;
    var revenues = createRevenues(tariff, rCost, eCost, pCost);
    Object.keys(revenueEntities).forEach(function (element) {
      var keyAmounts = revenues.filter(function (c) {
        return c.key === element;
      });
      var sum = keyAmounts.reduce(function (accumulator, object) {
        return accumulator + object.amountWithVat;
      }, 0);
      revenueEntities[element] = sum;
    });
    return revenueEntities;
  }
  TariffUtil.calculateRevenues = calculateRevenues;
  function createRevenues(tariff, reservationCost, energyCost, parkingCost) {
    var _a, _b, _c;
    var revenueArr = [];
    if (reservationCost) {
      var reservationVAT = getDimensionVAT(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION);
      var revenueReservation = getDimensionRevenue(tariff, revenueDimension_enum_1.RevenueDimension.RESERVATION);
      var revs = calculateRevsWithCost(revenueReservation, reservationCost.exclVat);
      for (var key in revs) {
        if (revs.hasOwnProperty(key)) {
          var entity = revenueEntity_enum_1.RevenueEntity[key];
          var amount = revs[key];
          var amountWithVat = utils_1.CU.roundNumber(utils_1.CU.calcPriceWithVat(amount, reservationVAT), 3);
          if (!(entity in Object.values(revenueEntity_enum_1.RevenueEntity))) {
            throw new Error('No entity found for key: ' + key + '    revs: ' + JSON.stringify(revs) + '   - revenue entity: ' + JSON.stringify(revenueEntity_enum_1.RevenueEntity));
          }
          revenueArr.push({
            key: key,
            entity: entity,
            amount: amount,
            currency: currency_enum_1.Currency.EUR,
            amountWithVat: amountWithVat,
            source: ((_a = tariff.REVENUE) === null || _a === void 0 ? void 0 : _a.find(function (c) {
              return c.type === revenueDimension_enum_1.RevenueDimension.RESERVATION;
            })) ? revenueSource_enum_1.RevenueSource.Tariff : revenueSource_enum_1.RevenueSource.Default,
            type: revenueDimension_enum_1.RevenueDimension.RESERVATION
          });
        }
      }
    }
    if (energyCost) {
      var energyElementVAT = getDimensionVAT(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY);
      var revenueEnergy = getDimensionRevenue(tariff, revenueDimension_enum_1.RevenueDimension.ENERGY);
      var revs = calculateRevsWithCost(revenueEnergy, energyCost.exclVat);
      for (var key in revs) {
        if (revs.hasOwnProperty(key)) {
          var entity = revenueEntity_enum_1.RevenueEntity[key];
          var amount = revs[key];
          var amountWithVat = utils_1.CU.roundNumber(utils_1.CU.calcPriceWithVat(amount, energyElementVAT), 3);
          if (!(entity in Object.values(revenueEntity_enum_1.RevenueEntity))) {
            throw new Error('No entity found for key: ' + key + '    revs: ' + JSON.stringify(revs) + '   - revenue entity: ' + JSON.stringify(revenueEntity_enum_1.RevenueEntity));
          }
          revenueArr.push({
            key: key,
            entity: entity,
            amount: amount,
            currency: currency_enum_1.Currency.EUR,
            amountWithVat: amountWithVat,
            source: ((_b = tariff.REVENUE) === null || _b === void 0 ? void 0 : _b.find(function (c) {
              return c.type === revenueDimension_enum_1.RevenueDimension.ENERGY;
            })) ? revenueSource_enum_1.RevenueSource.Tariff : revenueSource_enum_1.RevenueSource.Default,
            type: revenueDimension_enum_1.RevenueDimension.ENERGY
          });
        }
      }
    }
    if (parkingCost) {
      var parkingElementVAT = getDimensionVAT(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME);
      var revenueParking = getDimensionRevenue(tariff, revenueDimension_enum_1.RevenueDimension.PARKING);
      var revs = calculateRevsWithCost(revenueParking, parkingCost.exclVat);
      for (var key in revs) {
        if (revs.hasOwnProperty(key)) {
          var entity = revenueEntity_enum_1.RevenueEntity[key];
          var amount = revs[key];
          var amountWithVat = utils_1.CU.roundNumber(utils_1.CU.calcPriceWithVat(amount, parkingElementVAT), 3);
          if (!(entity in Object.values(revenueEntity_enum_1.RevenueEntity))) {
            throw new Error('No entity found for key: ' + key + '    revs: ' + JSON.stringify(revs) + '   - revenue entity: ' + JSON.stringify(revenueEntity_enum_1.RevenueEntity));
          }
          revenueArr.push({
            key: key,
            entity: entity,
            amount: amount,
            currency: currency_enum_1.Currency.EUR,
            amountWithVat: amountWithVat,
            source: ((_c = tariff.REVENUE) === null || _c === void 0 ? void 0 : _c.find(function (c) {
              return c.type === revenueDimension_enum_1.RevenueDimension.PARKING;
            })) ? revenueSource_enum_1.RevenueSource.Tariff : revenueSource_enum_1.RevenueSource.Default,
            type: revenueDimension_enum_1.RevenueDimension.PARKING
          });
        }
      }
    }
    return revenueArr;
  }
  TariffUtil.createRevenues = createRevenues;
  function calculatePriceWithRevenue(revenueObj, price) {
    if (!revenueObj) return price;
    if (price <= 0) return 0;
    var totalPercentage = 0;
    for (var key in revenueObj) {
      if (revenueObj.hasOwnProperty(key) && key !== "type") {
        totalPercentage += revenueObj[key];
      }
    }
    if (totalPercentage <= 0) {
      return 0;
    }
    return price * utils_1.CU.roundNumber(totalPercentage / 100);
  }
  TariffUtil.calculatePriceWithRevenue = calculatePriceWithRevenue;
  function getPrices(tariff, connectorPower) {
    var ePriceExclVat = 0;
    var rPriceExclVat = 0;
    var oPriceExclVat = 0;
    var ePriceInclVat = 0;
    var rPriceInclVat = 0;
    var oPriceInclVat = 0;
    var validReservationElements = getDimensionElements(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION, connectorPower);
    if (validReservationElements) {
      var revenueReservation = getDimensionRevenue(tariff, revenueDimension_enum_1.RevenueDimension.RESERVATION);
      var rightReservationVat = getDimensionVAT(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION, connectorPower);
      var reservationPriceComponent = validReservationElements[0].priceComponents.find(function (c) {
        return !utils_1.CU.isNullOrUndefined(c.price);
      });
      rPriceExclVat = calculatePriceWithRevenue(revenueReservation, reservationPriceComponent.price);
      rPriceInclVat = utils_1.CU.calcPriceWithVat(rPriceExclVat, rightReservationVat);
    }
    var validEnergyElements = getDimensionElements(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY, connectorPower);
    if (validEnergyElements) {
      var revenueEnergy = getDimensionRevenue(tariff, revenueDimension_enum_1.RevenueDimension.ENERGY);
      var rightEnergyVat = getDimensionVAT(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY, connectorPower);
      var energyPriceComponent = validEnergyElements[0].priceComponents.find(function (c) {
        return !utils_1.CU.isNullOrUndefined(c.price);
      });
      ePriceExclVat = calculatePriceWithRevenue(revenueEnergy, energyPriceComponent.price);
      ePriceInclVat = utils_1.CU.calcPriceWithVat(ePriceExclVat, rightEnergyVat);
    }
    var validParkingElements = getDimensionElements(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME, connectorPower);
    if (validParkingElements) {
      var revenueParking = getDimensionRevenue(tariff, revenueDimension_enum_1.RevenueDimension.PARKING);
      var rightParkingVat = getDimensionVAT(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME, connectorPower);
      var parkingPriceComponent = validParkingElements[validParkingElements.length - 1].priceComponents.find(function (c) {
        return !utils_1.CU.isNullOrUndefined(c.price);
      });
      oPriceExclVat = calculatePriceWithRevenue(revenueParking, parkingPriceComponent.price);
      oPriceInclVat = utils_1.CU.calcPriceWithVat(oPriceExclVat, rightParkingVat);
    }
    return {
      ePrice: {
        exclVat: ePriceExclVat,
        inclVat: ePriceInclVat
      },
      rPrice: {
        exclVat: rPriceExclVat,
        inclVat: rPriceInclVat
      },
      oPrice: {
        exclVat: oPriceExclVat,
        inclVat: oPriceInclVat
      }
    };
  }
  TariffUtil.getPrices = getPrices;
  function getDimensionVAT(tariff, dimension, connectorPower) {
    var _a;
    var elementVat = 22;
    var validTariffElements = getDimensionElements(tariff, dimension, connectorPower);
    if (validTariffElements) {
      var firstElementWithVat = validTariffElements.find(function (c) {
        return c.priceComponents.some(function (c) {
          return !utils_1.CU.isNullOrUndefined(c.vat);
        });
      });
      if (firstElementWithVat) {
        elementVat = (_a = firstElementWithVat.priceComponents.find(function (c) {
          return !utils_1.CU.isNullOrUndefined(c.vat);
        })) === null || _a === void 0 ? void 0 : _a.vat;
      }
    }
    return elementVat;
  }
  TariffUtil.getDimensionVAT = getDimensionVAT;
  function getDimensionElements(tariff, dimension, connectorPower) {
    var dimensionElements = [];
    switch (dimension) {
      case ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION:
        {
          var validReservationElements = tariff.elements.filter(function (element) {
            var _a;
            return ((_a = element.restrictions) === null || _a === void 0 ? void 0 : _a.reservation) === ocpiReservationRestriction_enum_1.OCPIReservationRestriction.RESERVATION;
          });
          if (validReservationElements) {
            if (connectorPower) {
              dimensionElements = getPowerRestrictionsForConnector(validReservationElements, connectorPower);
            } else {
              dimensionElements = validReservationElements;
            }
          }
        }
        break;
      case ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY:
        {
          var validEnergyElements = tariff.elements.filter(function (p) {
            return p.priceComponents.some(function (o) {
              return o.type === ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY;
            });
          });
          if (validEnergyElements) {
            if (connectorPower) {
              dimensionElements = getPowerRestrictionsForConnector(validEnergyElements, connectorPower);
            } else {
              dimensionElements = validEnergyElements;
            }
          }
        }
        break;
      case ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME:
        {
          var validParkingElements = tariff.elements.filter(function (p) {
            return p.priceComponents.some(function (o) {
              return o.type === ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME;
            });
          });
          if (validParkingElements) {
            if (connectorPower) {
              dimensionElements = getPowerRestrictionsForConnector(validParkingElements, connectorPower);
            } else {
              dimensionElements = validParkingElements;
            }
          }
        }
        break;
      default:
        break;
    }
    return dimensionElements;
  }
  TariffUtil.getDimensionElements = getDimensionElements;
  function getDimensionRevenue(tariff, dimension) {
    var _a, _b, _c, _d, _e, _f, _g;
    var defaultRevenue = (_a = tariff.REVENUE) === null || _a === void 0 ? void 0 : _a.find(function (c) {
      return c.type === revenueDimension_enum_1.RevenueDimension.DEFAULT;
    });
    switch (dimension) {
      case revenueDimension_enum_1.RevenueDimension.RESERVATION:
        return (_c = (_b = tariff.REVENUE) === null || _b === void 0 ? void 0 : _b.find(function (c) {
          return c.type === revenueDimension_enum_1.RevenueDimension.RESERVATION;
        })) !== null && _c !== void 0 ? _c : defaultRevenue;
      case revenueDimension_enum_1.RevenueDimension.ENERGY:
        return (_e = (_d = tariff.REVENUE) === null || _d === void 0 ? void 0 : _d.find(function (c) {
          return c.type === revenueDimension_enum_1.RevenueDimension.ENERGY;
        })) !== null && _e !== void 0 ? _e : defaultRevenue;
      case revenueDimension_enum_1.RevenueDimension.PARKING:
        return (_g = (_f = tariff.REVENUE) === null || _f === void 0 ? void 0 : _f.find(function (c) {
          return c.type === revenueDimension_enum_1.RevenueDimension.PARKING;
        })) !== null && _g !== void 0 ? _g : defaultRevenue;
    }
    return defaultRevenue;
  }
  TariffUtil.getDimensionRevenue = getDimensionRevenue;
  function calculateRevsWithCost(revenue, cost) {
    var splitRevenues = {};
    for (var key in revenue) {
      if (revenue.hasOwnProperty(key) && key !== "type") {
        splitRevenues[key] = cost * utils_1.CU.roundNumber(revenue[key] / 100, 3);
      }
    }
    for (var key in splitRevenues) {
      if (splitRevenues.hasOwnProperty(key) && splitRevenues[key] === 0) {
        delete splitRevenues[key];
      }
    }
    return splitRevenues;
  }
  TariffUtil.calculateRevsWithCost = calculateRevsWithCost;
  function checkCrsValidity(revenues, cost) {
    var sumAmounts = function (arr) {
      return arr.reduce(function (total, item) {
        return total + item.amount;
      }, 0);
    };
    var inCreditRevenues = revenues.filter(function (c) {
      return c.isInCredit;
    });
    var totalCreditAmount = sumAmounts(inCreditRevenues);
    var inDebitRevenues = revenues.filter(function (c) {
      return !c.isInCredit;
    });
    var totalDebitAmount = sumAmounts(inDebitRevenues);
    // aggiungo un minimo di tolleranza, in modo da non essere rigorosi in casi impossibili:
    // ad es: 0.61€ da dividere al 50% fra due parti...verrebbe 0.305, ma visto l'arrotondamento alla seconda cifra,
    // verrebbero due revenue da 0.30 per un totale di 0.60
    var result = cost + -1 * totalDebitAmount - totalCreditAmount;
    var rounderResult = utils_1.CU.roundNumber(result);
    // accetto un risultato con 1% di errore, ossia compreso tra -0.01 e 0.01
    var tolerance = 0.01;
    return rounderResult > -1 * tolerance && rounderResult < tolerance;
  }
  TariffUtil.checkCrsValidity = checkCrsValidity;
  function calculateSecondsInTariffElementWithStartTimeEndTime(tariffElement, startDate, endDate) {
    var restrictionStartHour = tariffElement.restrictions.startTime.split(":")[0];
    var restrictionStartMinute = tariffElement.restrictions.startTime.split(":")[1];
    var restrictionEndHour = tariffElement.restrictions.endTime.split(":")[0];
    var restrictionEndMinute = tariffElement.restrictions.endTime.split(":")[1];
    var restrictionStartSessionBased = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), parseInt(restrictionStartHour), parseInt(restrictionStartMinute));
    var restrictionEndSessionBased = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), parseInt(restrictionEndHour), parseInt(restrictionEndMinute));
    var secondsInsideRestriction = 0;
    // controllo se c'è sovrapposizione tra i due range di date
    if (startDate < restrictionEndSessionBased && restrictionStartSessionBased < endDate) {
      // Devo considerare anche l'intervallo copra più giorni, quindi devo scorrere tutti i giorni
      var dayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0);
      var dayEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 0, 0);
      var dayDifferenceFromStartToEnd = utils_1.CU.getDayDiff(dayStart, dayEnd);
      for (var index = 0; index <= dayDifferenceFromStartToEnd; index++) {
        var sParkingStart = startDate;
        var sParkingEnd = endDate;
        var currentDay = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0);
        currentDay = utils_1.CU.addMinutes(currentDay, 1440 * index);
        var restrictionCurrentDayStart = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), parseInt(restrictionStartHour), parseInt(restrictionStartMinute));
        var restrictionCurrentDayEnd = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), parseInt(restrictionEndHour), parseInt(restrictionEndMinute));
        if (index === 0 && dayDifferenceFromStartToEnd > 0) {
          sParkingEnd = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 23, 59);
        }
        if (index > 0) {
          sParkingStart = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), 0, 0);
          if (index < dayDifferenceFromStartToEnd) {
            sParkingEnd = new Date(currentDay.getFullYear(), currentDay.getMonth(), currentDay.getDate(), 23, 59);
          }
        }
        if (sParkingStart <= restrictionCurrentDayStart) {
          if (sParkingEnd >= restrictionCurrentDayEnd) {
            // sono nel caso in cui tutta la sessione di parking ricade nell'intervallo definito dalla restriction
            secondsInsideRestriction += utils_1.CU.getDateDiffInSeconds(restrictionCurrentDayStart, restrictionCurrentDayEnd);
          } else if (sParkingEnd >= restrictionCurrentDayStart) {
            // sono nel caso in cui la sessione di parking è finita prima della fine delle restriction
            secondsInsideRestriction += utils_1.CU.getDateDiffInSeconds(restrictionCurrentDayStart, sParkingEnd);
          }
        } else if (sParkingStart < restrictionCurrentDayEnd) {
          if (sParkingEnd >= restrictionCurrentDayEnd) {
            // sono nel caso in cui tutta la sessione di parking inizia dopo l'inizio delle restriction e finisce dopo la fine delle restrictions
            secondsInsideRestriction += utils_1.CU.getDateDiffInSeconds(sParkingStart, restrictionCurrentDayEnd);
          } else {
            // sono nel caso in cui tutta la sessione di parking inizia dopo l'inizio delle restriction e finisce entro la fine delle restrictions
            secondsInsideRestriction += utils_1.CU.getDateDiffInSeconds(sParkingStart, sParkingEnd);
          }
        }
      }
    }
    return secondsInsideRestriction;
  }
  TariffUtil.calculateSecondsInTariffElementWithStartTimeEndTime = calculateSecondsInTariffElementWithStartTimeEndTime;
  function extractPlaceholders(tariff) {
    var placeholders = {};
    function traverseObject(obj, path) {
      if (path === void 0) {
        path = "";
      }
      for (var key in obj) {
        var newPath = path ? "".concat(path, ".").concat(key) : key;
        if (typeof obj[key] === "string" && obj[key].startsWith("#") && obj[key].endsWith("#")) {
          placeholders[obj[key]] = null;
        } else if (typeof obj[key] === "object") {
          traverseObject(obj[key], newPath);
        }
      }
    }
    traverseObject(tariff);
    return placeholders;
  }
  TariffUtil.extractPlaceholders = extractPlaceholders;
  function replacePlaceholders(tariff, placeholderValues) {
    function traverseObject(obj) {
      for (var key in obj) {
        if (typeof obj[key] === "string" && obj[key].startsWith("#") && obj[key].endsWith("#")) {
          var placeholder = obj[key];
          if (placeholderValues.hasOwnProperty(placeholder)) {
            var placeholderType = placeholder.slice(1, -1).split("|")[1];
            if (placeholderType === "text") {
              obj[key] = String(placeholderValues[placeholder]);
            } else {
              obj[key] = placeholderValues[placeholder];
            }
          }
        } else if (typeof obj[key] === "object") {
          if (Array.isArray(obj[key])) {
            obj[key].forEach(function (element) {
              traverseObject(element);
            });
          } else {
            traverseObject(obj[key]);
          }
        }
      }
    }
    traverseObject(tariff);
    return tariff;
  }
  TariffUtil.replacePlaceholders = replacePlaceholders;
  function transformPlaceholder(placeholder) {
    var words = placeholder.slice(1, -1).split('|')[0].split('_').map(function (word, index) {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    });
    return words.join(' ');
  }
  TariffUtil.transformPlaceholder = transformPlaceholder;
  function createTariffAltText(tariff) {
    var _a, _b, _c, _d, _e, _f, _g;
    var tariffAltText = [];
    var tariffAltTextDescriptions_en = [];
    var tariffAltTextDescriptions_it = [];
    var energyPowers = tariff.elements.filter(function (c) {
      return c.priceComponents.some(function (p) {
        return p.type === ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY;
      });
    });
    energyPowers = energyPowers.sort(function (a, b) {
      return compareRestrictionByMaxPower(a, b);
    });
    var lastMaxPower = null;
    for (var _i = 0, energyPowers_1 = energyPowers; _i < energyPowers_1.length; _i++) {
      var element = energyPowers_1[_i];
      for (var _h = 0, _j = element.priceComponents; _h < _j.length; _h++) {
        var pc = _j[_h];
        if (pc.type === ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY) {
          var maxPower = (_a = element.restrictions) === null || _a === void 0 ? void 0 : _a.maxPower;
          var rangeDescriptions = [];
          var connectorPower = 300000;
          if (maxPower) {
            connectorPower = maxPower * 1000;
            rangeDescriptions.push({
              lang: "it",
              text: lastMaxPower ? "Da ".concat(lastMaxPower, "kW a ").concat(maxPower, "kW:") : "Fino a ".concat(maxPower, "kW:")
            });
            rangeDescriptions.push({
              lang: "en",
              text: lastMaxPower ? "From ".concat(lastMaxPower, "kW to ").concat(maxPower, "kW:") : "Up to ".concat(maxPower, "kW:")
            });
            lastMaxPower = maxPower;
          } else {
            rangeDescriptions.push({
              lang: "it",
              text: lastMaxPower ? "Da ".concat(lastMaxPower, "kW: ") : ''
            });
            rangeDescriptions.push({
              lang: "en",
              text: lastMaxPower ? "From ".concat(lastMaxPower, "kW: ") : ''
            });
          }
          var rangeDescription_it = rangeDescriptions.find(function (c) {
            return c.lang == 'it';
          });
          var descriptions_it = getTariffAltTextForConnectorPower(tariff, "it", connectorPower);
          var reservationDescription_it = descriptions_it.find(function (c) {
            return c.dimension === ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION;
          });
          var energyDescription_it = descriptions_it.find(function (c) {
            return c.dimension === ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY;
          });
          var parkingDescription_it = descriptions_it.find(function (c) {
            return c.dimension === ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME;
          });
          tariffAltTextDescriptions_it.push("".concat(rangeDescription_it === null || rangeDescription_it === void 0 ? void 0 : rangeDescription_it.text).concat((_b = reservationDescription_it === null || reservationDescription_it === void 0 ? void 0 : reservationDescription_it.description) !== null && _b !== void 0 ? _b : '', " ").concat((_c = energyDescription_it.description) !== null && _c !== void 0 ? _c : '', " ").concat((_d = parkingDescription_it === null || parkingDescription_it === void 0 ? void 0 : parkingDescription_it.description) !== null && _d !== void 0 ? _d : '').trim());
          var rangeDescription_en = rangeDescriptions.find(function (c) {
            return c.lang == 'en';
          });
          var descriptions_en = getTariffAltTextForConnectorPower(tariff, "en", connectorPower);
          var reservationDescription_en = descriptions_en.find(function (c) {
            return c.dimension === ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION;
          });
          var energyDescription_en = descriptions_en.find(function (c) {
            return c.dimension === ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY;
          });
          var parkingDescription_en = descriptions_en.find(function (c) {
            return c.dimension === ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME;
          });
          tariffAltTextDescriptions_en.push("".concat(rangeDescription_en === null || rangeDescription_en === void 0 ? void 0 : rangeDescription_en.text).concat((_e = reservationDescription_en === null || reservationDescription_en === void 0 ? void 0 : reservationDescription_en.description) !== null && _e !== void 0 ? _e : '', " ").concat((_f = energyDescription_en === null || energyDescription_en === void 0 ? void 0 : energyDescription_en.description) !== null && _f !== void 0 ? _f : '', " ").concat((_g = parkingDescription_en === null || parkingDescription_en === void 0 ? void 0 : parkingDescription_en.description) !== null && _g !== void 0 ? _g : '').trim());
        }
      }
    }
    tariffAltText.push({
      language: "it",
      text: tariffAltTextDescriptions_it.join(". ")
    });
    tariffAltText.push({
      language: "en",
      text: tariffAltTextDescriptions_en.join(". ")
    });
    return tariffAltText;
  }
  TariffUtil.createTariffAltText = createTariffAltText;
  function getTariffAltTextForConnectorPower(tariff, lang, connectorPower) {
    var _a, _b, _c, _d;
    if (lang === void 0) {
      lang = "it";
    }
    if (lang !== "it" && lang !== "en") {
      lang = "en";
    }
    if (!connectorPower) {
      connectorPower = 500000; // metto il valore massimo perchè in questo modo comprende le tariffe che hanno più potenze e nella default non hanno il maxPower specificato
    }
    var descriptionItems = [];
    // Descrizione tariffa
    // Occupazione
    // Si assume che ci siano al massimo 2 elementi, uno per la parte gratuita (opzionale) e uno a pagamento per i minuti
    // Se ci sono più periodi a pagamento, va rivisto l'algoritmo
    var costs = [];
    var gracePeriodDurationInSeconds = 3600;
    var parkingTariffElement = getDimensionElements(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME, connectorPower);
    if (parkingTariffElement && parkingTariffElement.length > 0) {
      var parkingText_it = "";
      var quickParkingText_it = "";
      var parkingText_en = "";
      var quickParkingText_en = "";
      parkingTariffElement = parkingTariffElement.sort(compareRestrictionByMinDuration);
      for (var index = 0; index < parkingTariffElement.length; index++) {
        var element = parkingTariffElement[index];
        if (element.priceComponents[0].price === 0) {
          // è definito il graceperiod
          if (parkingTariffElement.length === 1) {
            // se c'è solo il grace period...è sempre gratis!
            parkingText_it = "Il parcheggio \u00E8 gratuito.";
            parkingText_en = "Parking is free.";
            quickParkingText_it = "Gratis";
            quickParkingText_en = "Free";
          } else {
            gracePeriodDurationInSeconds = (_a = element.restrictions) === null || _a === void 0 ? void 0 : _a.maxDuration;
            if (gracePeriodDurationInSeconds && gracePeriodDurationInSeconds > 0) {
              var maxDurationInMinutes = gracePeriodDurationInSeconds / 60;
              parkingText_it = "Il parcheggio \u00E8 gratuito per i primi ".concat(maxDurationInMinutes, " minuti");
              quickParkingText_it = "Gratis ".concat(maxDurationInMinutes, " min");
              parkingText_en = "Parking is free for the first ".concat(maxDurationInMinutes, " minutes");
              quickParkingText_en = "".concat(maxDurationInMinutes, " min free");
            }
          }
        } else {
          var info = {
            reservationTimeInSeconds: 60,
            energyInWatt: 1000,
            parking: {
              timeInSeconds: 60,
              gracePeriodAlreadyCalcualted: true
            },
            tariff: tariff,
            connectorPower: connectorPower,
            withRevenueIncrement: true
          };
          costs = calculateCosts(info);
          var pCost = (_b = costs.find(function (c) {
            return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.PARKING_TIME;
          })) === null || _b === void 0 ? void 0 : _b.price;
          if (parkingText_it) {
            parkingText_it = "".concat(parkingText_it, ", poi ha un costo di ").concat(pCost.inclVat, "\u20AC/min.");
            quickParkingText_it = "".concat(quickParkingText_it, ". Poi ").concat(pCost.inclVat, "\u20AC/min.");
            parkingText_en = "".concat(parkingText_en, ", then it costs ").concat(pCost.inclVat, "\u20AC/min.");
            quickParkingText_en = "".concat(quickParkingText_en, ". Then ").concat(pCost.inclVat, "\u20AC/min.");
          } else {
            parkingText_it = "Il parcheggio ha un costo di ".concat(pCost.inclVat, "\u20AC/min.");
            quickParkingText_it = "".concat(pCost.inclVat, "\u20AC/min.");
            parkingText_en = "Parking has a cost of ".concat(pCost.inclVat, "\u20AC/min.");
            quickParkingText_en = "".concat(pCost.inclVat, "\u20AC/min.");
          }
        }
      }
      if (parkingText_it) {
        descriptionItems.push({
          lang: "it",
          dimension: ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME,
          description: parkingText_it,
          quickDescription: quickParkingText_it
        });
      }
      if (parkingText_en) {
        descriptionItems.push({
          lang: "en",
          dimension: ocpiTariffDimension_enum_1.OCPITariffDimension.PARKING_TIME,
          description: parkingText_en,
          quickDescription: quickParkingText_en
        });
      }
    }
    // se non sono entrato nella parte a pagamento del parcheggio (e quindi non ho popolato costs), devo calcolare gli altri costi
    if (costs.length === 0) {
      var info = {
        reservationTimeInSeconds: 60,
        energyInWatt: 1000,
        parking: {
          timeInSeconds: 0,
          gracePeriodAlreadyCalcualted: false
        },
        tariff: tariff,
        connectorPower: connectorPower,
        withRevenueIncrement: true
      };
      costs = calculateCosts(info);
    }
    // Prenotazione
    var validReservationElement = getDimensionElements(tariff, ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION, connectorPower);
    if (validReservationElement && validReservationElement.length > 0) {
      var rCost = (_c = costs.find(function (c) {
        return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.RESERVATION_TIME;
      })) === null || _c === void 0 ? void 0 : _c.price;
      var reservationComponent = validReservationElement[0].priceComponents[0]; // assumo ce ne sia solo uno
      var reservationRestrictions = validReservationElement[0].restrictions;
      var reservationText_it = "La prenotazione";
      var quickReservationText_it = "";
      var reservationText_en = "The reservation";
      var quickReservationText_en = "";
      if (reservationRestrictions && reservationRestrictions.maxDuration) {
        var reservationDurationInMinutes = reservationRestrictions.maxDuration / 60;
        reservationText_it = "".concat(reservationText_it, " \u00E8 valida per ").concat(reservationDurationInMinutes, " minuti");
        quickReservationText_it = "".concat(reservationDurationInMinutes, " minuti");
        reservationText_en = "".concat(reservationText_en, " is valid for ").concat(reservationDurationInMinutes, " minutes");
        quickReservationText_en = "".concat(reservationDurationInMinutes, " minutes");
      }
      if (rCost.inclVat > 0) {
        if (reservationComponent && reservationComponent.type === ocpiTariffDimension_enum_1.OCPITariffDimension.FLAT) {
          reservationText_it = "".concat(reservationText_it, " ed ha un costo fisso pari a ").concat(rCost.inclVat, "\u20AC.");
          reservationText_en = "".concat(reservationText_en, " and has a fixed cost of ").concat(rCost.inclVat, "\u20AC.");
        } else if (reservationComponent && reservationComponent.type === ocpiTariffDimension_enum_1.OCPITariffDimension.TIME) {
          reservationText_it = "".concat(reservationText_it, " ed ha un costo di ").concat(rCost.inclVat, "\u20AC/min.");
          reservationText_en = "".concat(reservationText_en, " and it costs ").concat(rCost.inclVat, "\u20AC/min.");
        }
      } else {
        reservationText_it = "".concat(reservationText_it, " ed \u00E8 gratuita.");
        reservationText_en = "".concat(reservationText_en, " and is free.");
      }
      if (reservationText_it) {
        descriptionItems.push({
          lang: "it",
          dimension: ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION,
          description: reservationText_it,
          quickDescription: quickReservationText_it
        });
      }
      if (reservationText_en) {
        descriptionItems.push({
          lang: "en",
          dimension: ocpiTariffDimension_enum_1.OCPITariffDimension.RESERVATION,
          description: reservationText_en,
          quickDescription: quickReservationText_en
        });
      }
    }
    // Energia
    var eCost = (_d = costs.find(function (c) {
      return c.type === ocpiCdrDimensionType_enum_1.OCPICdrDimensionType.ENERGY;
    })) === null || _d === void 0 ? void 0 : _d.price;
    var energyText_it = "Il costo dell'energia \u00E8 di ".concat(eCost.inclVat, "\u20AC/kWh.");
    var quickEnergyText_it = "".concat(eCost.inclVat, "\u20AC/kWh");
    var energyText_en = "The cost of energy is ".concat(eCost.inclVat, "\u20AC/kWh.");
    var quickEnergyText_en = "".concat(eCost.inclVat, "\u20AC/kWh");
    descriptionItems.push({
      lang: "it",
      dimension: ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY,
      description: energyText_it,
      quickDescription: quickEnergyText_it
    });
    descriptionItems.push({
      lang: "en",
      dimension: ocpiTariffDimension_enum_1.OCPITariffDimension.ENERGY,
      description: energyText_en,
      quickDescription: quickEnergyText_en
    });
    return descriptionItems.filter(function (c) {
      return c.lang === lang;
    });
  }
  TariffUtil.getTariffAltTextForConnectorPower = getTariffAltTextForConnectorPower;
  function getConnectorPower(connector) {
    return (connector === null || connector === void 0 ? void 0 : connector.powerLimit) ? connector.powerLimit : connector === null || connector === void 0 ? void 0 : connector.power;
  }
  TariffUtil.getConnectorPower = getConnectorPower;
})(TariffUtil || (exports.TariffUtil = TariffUtil = {}));
