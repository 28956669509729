"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InvoiceState = void 0;
exports.InvoiceState = Object.freeze({
  New: 0,
  Processing: 1,
  Reprocessing: 2,
  Completed: 3,
  Failed: 4,
  Rejected: 5,
  Unnecessary: 6
});
