/* src/app/components/slider/slider.component.scss */
.title {
  margin-top: 8px;
}
.btn {
  margin: 12px;
}
.slider-container {
  position: relative;
  margin: auto;
  text-align: center;
  width: 60%;
  border-radius: 15px;
  box-shadow: 0 6px 12px #dfe1e1;
}
@media (max-width: 450px) {
  .slider-container {
    width: 80%;
  }
}
.slider-container img {
  display: none;
  border-radius: 15px;
}
.slider-container img.image-active {
  display: block;
  width: 100%;
  height: 100%;
}
.caption {
  display: none;
}
.caption-active {
  display: block;
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 48px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  border: #fff solid;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  padding: 12px;
  word-wrap: normal;
}
.slider-dot-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-bottom: 12px;
}
.dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 0 4px;
  background-color: rgb(12, 12, 12);
  border-radius: 50%;
  display: inline-block;
  transition: opacity 0.6s ease;
  opacity: 0.5;
}
.active,
.dot:hover {
  opacity: 1;
}
.fade {
  animation-name: fade;
  animation-duration: 0.8s;
}
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.btn-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  text-align: center;
  background: 0 0;
  padding: 0;
  cursor: pointer;
}
.btn-prev {
  left: -32px;
}
.btn-next {
  right: -32px;
}
.icon-slider {
  font-size: 48pt;
  opacity: 0.5;
  transition: all 0.15s ease;
}
.icon-slider:hover {
  opacity: 0.8;
}
.icon-slider:active {
  opacity: 0.5;
}
/*# sourceMappingURL=slider.component.css.map */
