"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PARKING_TYPES = void 0;
exports.PARKING_TYPES = Object.freeze({
  ALONG_MOTORWAY: "ALONG_MOTORWAY",
  PARKING_GARAGE: "PARKING_GARAGE",
  PARKING_LOT: "PARKING_LOT",
  ON_DRIVEWAY: "ON_DRIVEWAY"
});
