/* src/app/components/filter/power-range/power-range.component.scss */
.value-container {
  justify-content: space-between;
  width: 250px;
  margin: 0 auto;
  padding-top: 6px;
}
.value-container ion-text {
  font-size: 10px;
  font-weight: 600;
  width: 46px;
  text-align: center;
}
ion-range {
  padding: 0;
  --bar-background: #ced1db;
  --bar-background-active: var(--ion-color-secondary);
  --bar-height: 4px;
  --knob-background:
    radial-gradient(
      circle,
      rgba(255, 255, 255, 1) 40%,
      rgba(41, 126, 178, 1) 60%);
  --knob-box-shadow: var(--yf-shadow);
  --knob-size: 28px;
}
ion-range ion-icon {
  color: #a2a5ad;
}
ion-range ion-icon:first-child {
  margin-right: 24px;
}
ion-range ion-icon:nth-child(2) {
  margin-left: 24px;
}
/*# sourceMappingURL=power-range.component.css.map */
