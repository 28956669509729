"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PARKING_RESTRICTIONS = void 0;
exports.PARKING_RESTRICTIONS = Object.freeze({
  EV_ONLY: "EV_ONLY",
  PLUGGED: "PLUGGED",
  DISABLED: "DISABLED",
  CUSTOMERS: "CUSTOMERS",
  MOTORCYCLES: "MOTORCYCLES"
});
