/* src/app/components/master-details-notification/notification-details.component.scss */
.master {
  min-width: 360px;
}
.detail {
  min-width: 360px;
}
.yf-master-detail .master {
  width: 100% !important;
}
.yf-master-detail .detail {
  width: 100% !important;
}
.master-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  border: 1px solid rgb(99, 99, 99);
  padding: 10px;
}
.detail {
  height: 100%;
  min-width: 360px;
}
.detail-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  padding: 10px;
  height: 100%;
}
.list-item {
  border-radius: 8px;
}
.selected {
  background: #f2f2f9;
}
.item-icon {
  margin-inline-end: 10px !important;
  font-size: 12px;
}
.notification-type-icon {
  margin-inline-end: 10px !important;
  font-size: 26px;
}
ion-card {
  padding-top: 0px;
}
ion-item-divider {
  padding-left: 0px;
}
ion-toolbar {
  --background: none;
}
.section-title {
  font-weight: bold;
  font-size: large;
}
.section-subtitle {
  font-size: smaller;
  color: #666;
}
.arrived {
  color: #92949c;
}
.title-item {
  color: #297EB2;
  font-size: 16px;
}
ion-content {
  --padding-bottom: 0;
}
/*# sourceMappingURL=notification-details.component.css.map */
