<div class="state-container yf-state gap-2" [ngClass]="$sessionClass()">
  <ng-container [ngSwitch]="$currentSession()?.state">
    <div class="timers yf-row center gap-2 ml-8" *ngSwitchCase="2">
      <ion-icon src="assets/app-icons/cronometro.svg" [color]="'dark'"></ion-icon>
      <ion-text class="fs-12 fw-600">{{'APP.INSTALLATION.STATE_RESERVE_ENDING_DETAILED'|translate}} {{timeLeft$ |
        async}} </ion-text>
    </div>
    <div class="timers yf-row center gap-2 ml-8" *ngSwitchCase="3">
      <ion-icon src="assets/app-icons/cronometro.svg" color="dark"></ion-icon>
      <ion-text class="fs-12 fw-600">{{'APP.INSTALLATION.STATE_CHARGING_DETAILED'|translate}} {{ chargeTime$ | async }}
      </ion-text>
    </div>
    <div class="timers yf-row center gap-2 ml-8" *ngSwitchCase="4">
      @if(this.$currentSession()?.occupationTime){
      <ion-icon src="assets/app-icons/cronometro.svg" color="light" class="fw-600 occupation-timer"></ion-icon>
      <ion-text class="fs-12 fw-600">{{'APP.INSTALLATION.STATE_SESSION_ENDED_PAYMENT_DETAILED'|translate}}
        {{(occupationPaymentTime$ | async)}}</ion-text>
      }
      @else{
      <ion-icon src="assets/app-icons/cronometro.svg" color="light" class="fw-600 occupation-timer"></ion-icon>
      <ion-text class="fs-12 fw-600">{{'APP.INSTALLATION.STATE_SESSION_ENDED_DETAILED'|translate}} {{(occupationTime$ |
        async)}}</ion-text>
      }
    </div>
  </ng-container>
</div>
