import { Component, Input, computed, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISession, OCPITariffDimension, TariffUtil } from 'common_library';
import { TranslateConfigService } from 'src/app/services/translate.service';
import { UiService } from 'src/app/services/utils/ui.service';

@Component({
    selector: 'yf-reservation-text',
    templateUrl: './reservation-text.component.html',
    styleUrls: ['./reservation-text.component.scss'],
    standalone: false
})
export class ReservationTextComponent {
  constructor(public UI: UiService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService) { }

  $currentSession = input<ISession>(null);
  $textClass = input<string>('fs-13');

  $reservationQuickText = computed(() => {
    if (this.$currentSession()) {
      const lang = this.translateConfigService.getCurrentLang();
      const descriptions = TariffUtil.getTariffAltTextForConnectorPower(this.$currentSession()?.tariff, lang, TariffUtil.getConnectorPower(this.$currentSession()?.connector));
      const reservationDescription = descriptions.find(c => c.dimension === OCPITariffDimension.RESERVATION);
      return reservationDescription?.quickDescription;
    }
    return null;
  });
}
