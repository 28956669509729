import { Component, input } from '@angular/core';

@Component({
    selector: 'yf-charge-info',
    templateUrl: './charge-info.component.html',
    styleUrls: ['./charge-info.component.scss'],
    standalone: false
})
export class ChargeInfoComponent {
  constructor() { }

  $energy = input<number>(null);
  $textClass = input<string>('fs-13');
  $umClass = input<string>('fs-11');
}
