import { Component, EventEmitter, Inject, OnInit, Output, NgZone, signal } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { Keyboard, } from '@capacitor/keyboard';
import { AlertController, IonicSafeString } from '@ionic/angular';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { ICreditCardDTO, PlatformType } from 'common_library';
import { of } from 'rxjs';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { GLOBAL_RX_STATE, GlobalState, IFormCdc } from './../../app.module';
import { RxState } from '@rx-angular/state';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

export declare type ICreditCardFields = {
  nameOnCard: string;
  number: string;
  expiryMonth: number;
  expiryYear: number;
  securityCode: number;
};

@Component({
    selector: 'yfl-credit-card-form',
    templateUrl: './credit-card-form.component.html',
    styleUrls: ['./credit-card-form.component.scss'],
    standalone: false
})
export class CreditCardFormComponent implements OnInit {
  @Output() cdcSubmitted: EventEmitter<ICreditCardDTO> = new EventEmitter();

  form: UntypedFormGroup = new UntypedFormGroup({});
  model: any = {};
  options: FormlyFormOptions = {
    formState: {
      awesomeIsForced: false,
    },
  };

  fields: FormlyFieldConfig[];
  $footerVisible = signal<boolean>(true);

  constructor(
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    private alertController: AlertController,
    private auth: AuthService,
    private mainStateService: MainStateService,
    private ngZone: NgZone,
    private translate: TranslateService) {
      if (this.mainStateService.getNative()) {
        this.listenKeyboardVisibility();
      }
  }

  ngOnInit() {
    this.fields = [
      {
        fieldGroup: [
          {
            fieldGroupClassName: 'display-flex-column',
            fieldGroup: [
              {
                key: 'number',
                type: 'f-ccredit',
                className: 'flex-1',
                name: 'cc-number',
                templateOptions: {
                  attributes: {
                    autocomplete: 'cc-number',
                  },
                  required: true,
                },
                validators: {
                  validation: [{ name: 'luhnValidator' }],
                },
              },
              {
                key: 'owner',
                type: 'f-input',
                name: 'cc-name',
                className: 'flex-1',
                templateOptions: {
                  attributes: {
                    autocomplete: 'cc-name'
                  },
                  label: this.translate.instant("CDC_AUTHENTICATION.OWNER"),
                  required: true,
                },
              },
            ],
          },
          {
            fieldGroupClassName: 'display-flex-row',
            fieldGroup: [
              {
                key: 'expiryMonth',
                type: 'f-select',
                name: 'cc-exp-month',
                className: 'flex-1',
                templateOptions: {
                  attributes: {
                    autocomplete: 'cc-exp-month'
                  },
                  label: this.translate.instant("DATES.MONTH"),
                  required: true,
                  options: of(this._getMonthCDC())
                },
              },
              {
                key: 'expiryYear',
                type: 'f-select',
                name: 'cc-exp-year',
                className: 'flex-1',
                templateOptions: {
                  attributes: {
                    autocomplete: 'cc-exp-year'
                  },
                  label: this.translate.instant("DATES.YEAR"),
                  required: true,
                  options: of(this._getYearCDC())
                },
              },
              {
                key: 'securityCode',
                name: 'cc-csc',
                type: 'f-input',
                className: 'flex-1',
                templateOptions: {
                  attributes: {
                    autocomplete: 'cc-csc'
                  },
                  label: 'CVV ',
                  required: true
                },
                validators: {
                  cvv: {
                    expression: (c: AbstractControl) => c.value.length === 3,
                    message: () => this.translate.instant("FORM.VALIDATION.CVV_LENGTH_WARNING"),
                  },
                },
              },

            ],
          },
        ],
      },
    ];
    this.model = {
      owner: this.mainStateService.$user().name + ' ' + this.mainStateService.$user().surname,
    }

    if (this.globalState.get('formCdc')) {
      this.model = this.globalState.get('formCdc');
    }
    this.form.valueChanges.subscribe((value: IFormCdc) => {
      this.globalState.set({ formCdc: value });
    });


    if (!environment.production) {
      this.fields = [
        MyFormlyHelpers.selectField('selectCDC', this.translate.instant("CDC_AUTHENTICATION.SELECT_CDC"), {
          required: false,
          options: of(this._getDebugCDC()),
          onChange: (select) => {
            const cdc = this._getDebugCDC().find(cdc => cdc.name === select);
            this.form.get('number').setValue(cdc.value.number);
            this.form.get('owner').setValue('dev');
            this.form.get('expiryMonth').setValue(cdc.value.expMonth);
            this.form.get('expiryYear').setValue(cdc.value.expYear);
            this.form.get('securityCode').setValue(cdc.value.cvv);
          }
        }),
        ...this.fields
      ];
    }
  }

  async submit() {
    if (this.form.valid) {
      let confirmed = false;
      const alert = await this.alertController.create(
        {
          header: this.translate.instant("TOAST_MESSAGES.WARNING"),
          message: new IonicSafeString(this.translate.instant("CDC_AUTHENTICATION.CHECK_CARD_VALIDITY_WARNING_MESSAGE")),
          buttons: [
            {
              text: this.translate.instant("BUTTONS.CANCEL"),
              role: 'cancel',
            },
            {
              text: this.translate.instant("BUTTONS.CONFIRM"),
              role: 'confirm',
              handler: () => {
                confirmed = true;
              }
            }
          ],
          cssClass: 'alert-cdcform-css'
        }
      );
      alert.animated = true;
      alert.animate([
        { transform: 'scale(0.2)', opacity: 0 },
        { transform: 'scale(0.6)', opacity: 0.5 },
        { transform: 'scale(1)', opacity: 1 }
      ], {
        duration: 200,
        fill: 'forwards',
        easing: 'ease-out'
      });
      await alert.present();
      await alert.onDidDismiss();
      confirmed ? this.cdcSubmitted.emit(this.model) : null;
    }
  }

  private _getMonthCDC() {
    let months: Array<{ name: string, value: number }> = [];
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        months.push({ name: `0${i}`, value: i })
      } else {
        months.push({ name: `${i}`, value: i })
      }
    }
    return months;
  }

  private _getYearCDC() {
    let currentYear = new Date().getFullYear();
    let years: Array<{ name: string, value: number }> = [];
    for (let i = 0; i <= 15; i++) {
      years.push({ name: `${currentYear}`, value: currentYear })
      currentYear += 1;
    }
    return years;
  }

  private _getDebugCDC() {
    return [
      {
        name: '3DS2 - Frictionless flow',
        value: {
          number: '4012000000003010',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: '3DS2 - Challenge flow*',
        value: {
          number: '4012000000003101',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: '3DS2 - NPA',
        value: {
          number: '4012000000003077',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: '3DS2 - Attempted',
        value: {
          number: '4012000000003036',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: '3DS2 - Failed authentication',
        value: {
          number: '4012000000003028',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: 'VISA - Always authorizes',
        value: {
          number: '4775718800002026',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: 'VISA - Always refuses',
        value: {
          number: '4775718800003024',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: 'MASTERCARD - Always authorizes',
        value: {
          number: '5413330002001015',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
      {
        name: 'MASTERCARD - Always refuses',
        value: {
          number: '5413330089700034',
          expMonth: '05',
          expYear: '2027',
          cvv: '111'
        }
      },
    ];
  }

  listenKeyboardVisibility() {
    Keyboard.addListener('keyboardWillShow', () => {
      this.ngZone.run(() => {
        this.$footerVisible.set(false);
      });
    });

    Keyboard.addListener('keyboardDidHide', () => {
      this.ngZone.run(() => {
        this.$footerVisible.set(true);
      });
    });
  }
}
