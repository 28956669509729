import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'yf-installation-footer',
    templateUrl: './installation-footer.component.html',
    styleUrls: ['./installation-footer.component.scss'],
    standalone: false
})
export class InstallationFooterComponent {
  @Output() clickPrev = new EventEmitter();
  @Output() clickNext = new EventEmitter();
  @Input() isMine:boolean;
  @Input() compactMode: boolean = true;
  @Output() compactModeChange = new EventEmitter<boolean>();

  constructor() { }

  prev() {
    this.clickPrev.emit();
  }

  next() {
    this.clickNext.emit();
  }
}
