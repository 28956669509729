"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SideMenuAboutItems = exports.SideMenuContentItems = exports.SideMenuItems = void 0;
exports.SideMenuItems = Object.freeze({
  WELCOME: "welcome",
  USERS: "users",
  GESTORE: "gestore",
  MAP: "map",
  PODS: "pods",
  INSTALLATIONS: "installations",
  STATIONS: "stations",
  SESSIONS: "sessions",
  CHARGE_DETAIL_RECORD: "charge-detail-record",
  PAYMENTS: "payments",
  COST_REVENUE_SPLIT: "cost-revenue-split",
  INVOICE: "invoice",
  TARIFFS: "tariffs",
  GROUPS: "groups",
  GATEWAYS: "gateways"
});
exports.SideMenuContentItems = Object.freeze({
  INFO_CONTENT: "info-content",
  INFO_CHAPTER: "info-chapter",
  INFO_SECTION: "info-section",
  INFO_MULTIMEDIA: "info-multimedia"
});
exports.SideMenuAboutItems = Object.freeze({
  ABOUT_LEGENDA: "about-legenda",
  ABOUT_VERSIONS: "about-versions"
});
