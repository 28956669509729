<ion-toolbar style="--background: transparent">
  <div class="yf-row center gap-8">
    <yf-logo-thumbnail class="ml-8" size="60px" [stationCpo]="$stationIcon()"></yf-logo-thumbnail>
    <div class="yf-container align-left centered spaced grow gap-6">
      <ion-text [color]="$textColor()" class="fs-16">{{ mainStateService.$selectedInstallation()?.name }}</ion-text>
    </div>
    @if(mainStateService.$selectedConnectorHasMineSession()){
    <div class="yf-container align-right spaced">
      <ion-icon (click)="showinfoReservationParking()" src="assets/app-icons/info.svg" class="fs-27 mr-16"
        color="light"></ion-icon>
    </div>
    }
  </div>
</ion-toolbar>