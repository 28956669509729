<ion-card>
  <div class="yf-container gap-12">
    <ion-text color="primary">
      <h2>{{ "CAR.CAR_DETAIL.DETAIL" | translate }}</h2>
    </ion-text>
    <ion-text *ngIf="!car?.model?.id"><p>{{ "CAR.CAR_DETAIL.FILL_PROPERTIES" | translate }}</p></ion-text>
    <div *ngIf="!!car?.model?.id" class="yf-container gap-12">
      <div class="yf-row gap-12">
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.BATTERY" | translate }}:</p>
          <ion-text color="dark">
            <h4>{{ car?.model.battery }} kWh</h4>
          </ion-text>
          <hr />
        </div>
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.RANGE" | translate }}:</p>
          <ion-text color="dark">
            <h4>{{ car?.model.range }} Km</h4>
          </ion-text>
          <hr />
        </div>
      </div>
      <div class="yf-row gap-12">
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.CHARGING_SPEED" | translate}}:</p>
          <ion-text color="dark">
            <h4>{{ car?.model.chargeSpeed }} kWh</h4>
          </ion-text>
          <hr />
        </div>
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.ENGINE_EFFICIENCY" | translate }}:</p>
          <ion-text color="dark">
            <h4>{{ car?.model.efficiency }} Wh/Km</h4>
          </ion-text>
          <hr />
        </div>
      </div>
      <div class="yf-row gap-12">
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.SEGMENT" | translate}}:</p>
          <ion-text color="dark">
            <h4>
              {{ car?.model.segment }}
            </h4>
          </ion-text>
          <hr />
        </div>
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.SEATS" | translate}}:</p>
          <ion-text color="dark">
            <h4>
              {{ car?.model.seats }}
            </h4>
          </ion-text>
          <hr />
        </div>
      </div>
      <div class="yf-row gap-12">
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.MAX_SPEED" | translate}}:</p>
          <ion-text color="dark">
            <h4>{{ car?.model.speed }} km/h</h4>
          </ion-text>
          <hr />
        </div>
        <div class="yf-container balance">
          <p>{{ "CAR.CAR_DETAIL.ACCELERATION" | translate}}:</p>
          <ion-text color="dark">
            <h4>{{ car?.model.acceleration }}"</h4>
          </ion-text>
          <hr />
        </div>
      </div>
      <div class="yf-row gap-12 wrap">
        <p>
          {{ "CAR.CAR_DETAIL.PLUGS" | translate }}: <b *ngFor="let plug of car?.plugTypes">{{ plug }} </b>
        </p>
      </div>
    </div>
  </div>
</ion-card>
