"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIConnectorType = void 0;
exports.OCPIConnectorType = Object.freeze({
  CHADEMO: 'CHADEMO',
  DOMESTIC_A: 'DOMESTIC_A',
  DOMESTIC_B: 'DOMESTIC_B',
  DOMESTIC_C: 'DOMESTIC_C',
  DOMESTIC_D: 'DOMESTIC_D',
  DOMESTIC_E: 'DOMESTIC_E',
  DOMESTIC_F: 'DOMESTIC_F',
  DOMESTIC_G: 'DOMESTIC_G',
  DOMESTIC_H: 'DOMESTIC_H',
  DOMESTIC_I: 'DOMESTIC_I',
  DOMESTIC_J: 'DOMESTIC_J',
  DOMESTIC_K: 'DOMESTIC_K',
  DOMESTIC_L: 'DOMESTIC_L',
  IEC_60309_2_SINGLE_16: 'IEC_60309_2_single_16',
  IEC_60309_2_THREE_16: 'IEC_60309_2_three_16',
  IEC_60309_2_THREE_32: 'IEC_60309_2_three_32',
  IEC_60309_2_THREE_64: 'IEC_60309_2_three_64',
  IEC_62196_T1: 'IEC_62196_T1',
  IEC_62196_T1_COMBO: 'IEC_62196_T1_COMBO',
  IEC_62196_T2: 'IEC_62196_T2',
  IEC_62196_T2_COMBO: 'IEC_62196_T2_COMBO',
  IEC_62196_T3A: 'IEC_62196_T3A',
  IEC_62196_T3C: 'IEC_62196_T3C',
  PANTOGRAPH_BOTTOM_UP: 'PANTOGRAPH_BOTTOM_UP',
  PANTOGRAPH_TOP_DOWN: 'PANTOGRAPH_TOP_DOWN',
  TESLA_R: 'TESLA_R',
  TESLA_S: 'TESLA_S'
});
