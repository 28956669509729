"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CdrState = void 0;
exports.CdrState = Object.freeze({
  New: 0,
  Processing: 5,
  Done: 6,
  Failed: 7,
  NotExpected: 8,
  Review: 9,
  Reprocessing: 10
});
