<ion-toolbar [color]="isMine ? 'secondary' : 'light'">
  <ion-buttons slot="start">
    <ion-button color="dark" (click)="prev()" [color]="!isMine ? 'secondary' : 'light'">
      <ion-icon slot="icon-only" src="assets/app-icons/arrow_back.svg"></ion-icon>
    </ion-button>
  </ion-buttons>
  <ion-buttons slot="end">
    <ion-button color="dark" (click)="next()" [color]="!isMine ? 'secondary' : 'light'">
      <ion-icon slot="icon-only" src="assets/app-icons/arrow_forward.svg"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
