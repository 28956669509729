<svg *ngIf="isClickable" [ngStyle]="{
    'star-rating-filled': rating >= starNumber,
    'star-rating-blank': rating < starNumber
  }" (mouseenter)="starEnter.emit(starNumber)" (mouseleave)="starLeave.emit()" (click)="starClicked.emit(starNumber)"
  width="32" height="31" viewBox="0 0 32 31" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M31.4893 11.2546H20.3964C20.289 11.2545 20.1843 11.2205 20.0974 11.1574C20.0105 11.0942 19.9457 11.0053 19.9124 10.9031L16.484 0.352773C16.4505 0.250852 16.3856 0.162108 16.2987 0.0991952C16.2118 0.036282 16.1073 0.00241089 16 0.00241089C15.8927 0.00241089 15.7882 0.036282 15.7013 0.0991952C15.6144 0.162108 15.5495 0.250852 15.516 0.352773L12.0876 10.9031C12.0543 11.0053 11.9895 11.0942 11.9026 11.1574C11.8157 11.2205 11.711 11.2545 11.6036 11.2546H0.509429C0.401728 11.2545 0.296761 11.2885 0.209605 11.3518C0.122449 11.4151 0.0575879 11.5043 0.0243374 11.6068C-0.00891307 11.7092 -0.00884367 11.8196 0.0245391 11.922C0.0579218 12.0244 0.122899 12.1135 0.210137 12.1767L9.18506 18.6962C9.27223 18.7595 9.33707 18.8489 9.37025 18.9514C9.40343 19.0539 9.40325 19.1643 9.36973 19.2667L5.94253 29.8158C5.90955 29.918 5.90972 30.028 5.943 30.1301C5.97628 30.2322 6.04097 30.3212 6.12783 30.3843C6.2147 30.4474 6.31929 30.4815 6.42668 30.4817C6.53406 30.4818 6.63875 30.448 6.72578 30.3851L15.6969 23.8656C15.7839 23.8025 15.8887 23.7684 15.9962 23.7684C16.1037 23.7684 16.2085 23.8025 16.2955 23.8656L25.2691 30.3851C25.3561 30.4485 25.461 30.4827 25.5687 30.4829C25.6763 30.483 25.7813 30.449 25.8684 30.3857C25.9556 30.3225 26.0204 30.2333 26.0537 30.1309C26.087 30.0285 26.087 29.9182 26.0536 29.8158L22.6252 19.2667C22.5918 19.1642 22.5918 19.0537 22.6252 18.9512C22.6586 18.8486 22.7237 18.7593 22.8111 18.6962L31.7886 12.1767C31.8758 12.1135 31.9408 12.0244 31.9742 11.922C32.0076 11.8196 32.0076 11.7092 31.9744 11.6068C31.9411 11.5043 31.8763 11.4151 31.7891 11.3518C31.702 11.2885 31.597 11.2545 31.4893 11.2546" />
</svg>
<svg *ngIf="!isClickable" [ngClass]="{
    'star-rating-filled': rating >= starNumber,
    'star-rating-blank': rating < starNumber
  }" width="32" height="31" viewBox="0 0 32 31" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M31.4893 11.2546H20.3964C20.289 11.2545 20.1843 11.2205 20.0974 11.1574C20.0105 11.0942 19.9457 11.0053 19.9124 10.9031L16.484 0.352773C16.4505 0.250852 16.3856 0.162108 16.2987 0.0991952C16.2118 0.036282 16.1073 0.00241089 16 0.00241089C15.8927 0.00241089 15.7882 0.036282 15.7013 0.0991952C15.6144 0.162108 15.5495 0.250852 15.516 0.352773L12.0876 10.9031C12.0543 11.0053 11.9895 11.0942 11.9026 11.1574C11.8157 11.2205 11.711 11.2545 11.6036 11.2546H0.509429C0.401728 11.2545 0.296761 11.2885 0.209605 11.3518C0.122449 11.4151 0.0575879 11.5043 0.0243374 11.6068C-0.00891307 11.7092 -0.00884367 11.8196 0.0245391 11.922C0.0579218 12.0244 0.122899 12.1135 0.210137 12.1767L9.18506 18.6962C9.27223 18.7595 9.33707 18.8489 9.37025 18.9514C9.40343 19.0539 9.40325 19.1643 9.36973 19.2667L5.94253 29.8158C5.90955 29.918 5.90972 30.028 5.943 30.1301C5.97628 30.2322 6.04097 30.3212 6.12783 30.3843C6.2147 30.4474 6.31929 30.4815 6.42668 30.4817C6.53406 30.4818 6.63875 30.448 6.72578 30.3851L15.6969 23.8656C15.7839 23.8025 15.8887 23.7684 15.9962 23.7684C16.1037 23.7684 16.2085 23.8025 16.2955 23.8656L25.2691 30.3851C25.3561 30.4485 25.461 30.4827 25.5687 30.4829C25.6763 30.483 25.7813 30.449 25.8684 30.3857C25.9556 30.3225 26.0204 30.2333 26.0537 30.1309C26.087 30.0285 26.087 29.9182 26.0536 29.8158L22.6252 19.2667C22.5918 19.1642 22.5918 19.0537 22.6252 18.9512C22.6586 18.8486 22.7237 18.7593 22.8111 18.6962L31.7886 12.1767C31.8758 12.1135 31.9408 12.0244 31.9742 11.922C32.0076 11.8196 32.0076 11.7092 31.9744 11.6068C31.9411 11.5043 31.8763 11.4151 31.7891 11.3518C31.702 11.2885 31.597 11.2545 31.4893 11.2546" />
</svg>
