<div>
  <ion-item>
    <ion-label>{{ to.label }}</ion-label>
    <ion-checkbox slot="end" [formControl]="ngControl"></ion-checkbox>
  </ion-item>
  <span *ngIf="formControl.touched" class="input-error">
    <ion-text color="danger">
      <formly-validation-message [field]="field"></formly-validation-message>
    </ion-text>
  </span>
</div>
