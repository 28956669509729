
<div class="main">
  <ion-toolbar color="light" class="mt-20">
      <!-- <ion-buttons slot="start">
        <ion-button (click)="cancel()">Cancel</ion-button>
      </ion-buttons>   -->
      <!-- <ion-buttons slot="end">
        <ion-button (click)="confirm()" [strong]="true">Confirm</ion-button>
      </ion-buttons> -->
      <ion-title>
        <div class="yf-row gap-1 centered">
          <div class="yf-container gap-1">
            <ion-text class="fs-16">Profilo</ion-text>
          </div>
        </div>
      </ion-title>
    </ion-toolbar>


  <ion-content *ngIf="mainStateService.$user(); as user" class="yf-content-padding">
      <div class="yf-container" style="gap: 36px">
        <ion-list style="width: 100%">
          <ion-text color="primary"><h5 style="font-weight: 600">Modifica Nome</h5></ion-text>
          <form *ngIf="!!userFrm" [formGroup]="userFrm.form">
            <formly-form
              [model]="userFrm.model"
              [fields]="userFrm.fields"
              [options]="userFrm.options"
              [form]="userFrm.form"></formly-form>
          </form>
        </ion-list>
      </div>
  </ion-content>
  <ion-footer class="ion-no-border yf-footer" *ngIf="userFrm.form">
    <div class="yf-row gap-6">
      <ion-button color="primary" class="btn large grow" (click)="cancel()" fill="outline" [disabled]="!userFrm.form.dirty">{{'BUTTONS.CANCEL'|translate}}</ion-button>
      <ion-button color="primary" class="btn large grow" (click)="update()" [disabled]="!userFrm.form.valid"> {{'BUTTONS.SAVE'|translate}}</ion-button>
    </div>
  </ion-footer>
</div>
