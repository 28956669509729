"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ActionType = void 0;
exports.ActionType = Object.freeze({
  Unknown: "Sconosciuta",
  ForgotPassword: "ForgotPassword",
  ChangedPassword: "ChangedPassword",
  RegisterNewUser: "RegisterNewUser",
  CdCAuthFailed: "CdCAuthFailed",
  CdCAuthSuccess: "CdCAuthSuccess"
});
