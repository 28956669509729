<app-master-detail class="yf-master-detail" icon="id-card-outline" title="{{'RFID.TITLE'|translate}}"
  subtitle="{{'RFID.SUBTITLE'|translate}}" titleMaster="{{'RFID.MASTER_TITLE'|translate}}"
  titleDetail="{{'RFID.DETAIL_TITLE'|translate}}" [(show)]="masterDetailShow" filter=""
  (compactModeChange)="compactModeChange($event)" [forceCompact]="false">

  <div class="master">


    @if(mainStateService.$userRfids()?.length===0){
    <div class="yf-container centered">
      <ion-icon name="alert-circle-outline" class="fs-30" color="dark"></ion-icon>
      <ion-card class="text-center">
        <ion-card-header>
          <ion-card-title color="primary" class="fw-600 fs-22">
            {{ "RFID.NO_RFID_CONNECTED" | translate }}
          </ion-card-title>
          <ion-card-subtitle>
            {{ "RFID.YOU_CAN_ALWAYS_CONNECT_ONE" | translate }}
          </ion-card-subtitle>
        </ion-card-header>
        <ion-card-content class="yf-container centered">
          <ion-text>
            {{ "RFID.HOW_TO_CONNECT_INSTRUCTION1" | translate}}
            <ion-icon name="id-card-outline" slot=""> </ion-icon>,
            {{ "RFID.HOW_TO_CONNECT_INSTRUCTION2" | translate}}
          </ion-text>
        </ion-card-content>
      </ion-card>
    </div>
    }
    @else{
    <div class="m-6" *ngFor="let rfid of mainStateService.$userRfids()">
      <ion-item button detail="true" [ngClass]="{ selected: isSelected(rfid.code)}" (click)="pickAnRfid(rfid)">
        <ion-icon name="id-card-outline" slot="start" class="item-rfid-icon"></ion-icon>
        <ion-label>
          <h3 class="fw-600">{{ rfid.name }}</h3>
          <p>{{ rfid.createdAt | date }}</p>
        </ion-label>
        <ion-icon src="assets/app-icons/macchina.svg" class="item-car-icon"> </ion-icon>
        <ion-label>
          <h3>{{ rfid.car.model.brand.name }}</h3>
          <p>{{ rfid.car.model.name }}</p>
        </ion-label>
        <ion-icon name="{{rfid.valid ? 'checkmark-circle-outline' : 'close-circle-outline'}}" class="item-car-icon"
          color="{{rfid.valid ? 'success' : 'danger'}}">
        </ion-icon>
      </ion-item>
    </div>
    }

    <!-- SOTTO C'E UNA ION-TOOLBAR PERCHE SENNO SLOT END NON FUNZIONA -->
    @if(!!station){
    <ion-toolbar class="yf-footer">
      <ion-button color="primary" (click)="addRfid()" class="add-button" expand="block">{{ "BUTTONS.ADD" | translate}}
      </ion-button>
    </ion-toolbar>
    }
  </div>

  <div class="detail" *ngIf="selectedRfid$ | async as selectedRfid">
    <div class="detail-item">
      <yf-rfid-form (submitForm)="submitForm($event)" [selectedRfid]="selectedRfid"
        (backBtnEvent)="backBtn()"></yf-rfid-form>
      <ion-footer class="ion-no-border yf-footer" *ngIf="footerVisible">
        <div class="yf-footer gap-6">
          <ion-button color="primary" size="default" (click)="toggleValidity(selectedRfid)" expand="block">
            {{selectedRfid.valid ? ('RFID.DISABLE_RFID' | translate) : ('RFID.ENABLE_RFID' | translate)}} {{
            "RFID.RFID_TAG" | translate }}
          </ion-button>
          <ion-button color="danger" size="default" (click)="deleteRfid(selectedRfid)" expand="block">
            <div class="ftitle mt-5">
              {{ "RFID.DELETE_RFID" | translate}}
            </div>
          </ion-button>
        </div>
      </ion-footer>
    </div>
  </div>
</app-master-detail>