"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RangeCardinality = void 0;
exports.RangeCardinality = Object.freeze({
  MAX: 'max',
  MIN: 'min'
});
