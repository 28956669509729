import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i3 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i1 from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import * as i4 from '@ngx-formly/ionic/form-field';
import { FormlyFormFieldModule } from '@ngx-formly/ionic/form-field';
class FormlyFieldInput extends FieldType {}
FormlyFieldInput.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyFieldInput,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
FormlyFieldInput.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "13.3.12",
  type: FormlyFieldInput,
  selector: "formly-field-ion-input",
  usesInheritance: true,
  ngImport: i0,
  template: `
    <ion-input
      *ngIf="props.type !== 'number'; else numberTmp"
      [type]="props.type || 'text'"
      [label]="props.label"
      [formControl]="formControl"
      [ionFormlyAttributes]="field"
    ></ion-input>
    <ng-template #numberTmp>
      <ion-input
        type="number"
        [label]="props.label"
        [formControl]="formControl"
        [ionFormlyAttributes]="field"
      ></ion-input>
    </ng-template>
  `,
  isInline: true,
  styles: [":host{display:inherit}\n"],
  components: [{
    type: i1.IonInput,
    selector: "ion-input",
    inputs: ["accept", "autocapitalize", "autocomplete", "autocorrect", "autofocus", "clearInput", "clearOnEdit", "color", "debounce", "disabled", "enterkeyhint", "inputmode", "max", "maxlength", "min", "minlength", "mode", "multiple", "name", "pattern", "placeholder", "readonly", "required", "size", "spellcheck", "step", "type", "value"]
  }],
  directives: [{
    type: i2.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }, {
    type: i1.TextValueAccessor,
    selector: "ion-input:not([type=number]),ion-textarea,ion-searchbar"
  }, {
    type: i3.NgControlStatus,
    selector: "[formControlName],[ngModel],[formControl]"
  }, {
    type: i3.FormControlDirective,
    selector: "[formControl]",
    inputs: ["formControl", "disabled", "ngModel"],
    outputs: ["ngModelChange"],
    exportAs: ["ngForm"]
  }, {
    type: i4.ɵIonFormlyAttributes,
    selector: "[ionFormlyAttributes]",
    inputs: ["ionFormlyAttributes"]
  }, {
    type: i1.NumericValueAccessor,
    selector: "ion-input[type=number]"
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyFieldInput,
  decorators: [{
    type: Component,
    args: [{
      selector: 'formly-field-ion-input',
      template: `
    <ion-input
      *ngIf="props.type !== 'number'; else numberTmp"
      [type]="props.type || 'text'"
      [label]="props.label"
      [formControl]="formControl"
      [ionFormlyAttributes]="field"
    ></ion-input>
    <ng-template #numberTmp>
      <ion-input
        type="number"
        [label]="props.label"
        [formControl]="formControl"
        [ionFormlyAttributes]="field"
      ></ion-input>
    </ng-template>
  `,
      styles: [':host { display: inherit; }'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }]
});
class FormlyInputModule {}
FormlyInputModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyInputModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
FormlyInputModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyInputModule,
  declarations: [FormlyFieldInput],
  imports: [CommonModule, ReactiveFormsModule, IonicModule, FormlyFormFieldModule, i1$1.FormlyModule]
});
FormlyInputModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyInputModule,
  imports: [[CommonModule, ReactiveFormsModule, IonicModule, FormlyFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'input',
      component: FormlyFieldInput,
      wrappers: ['form-field']
    }, {
      name: 'string',
      extends: 'input'
    }, {
      name: 'number',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }, {
      name: 'integer',
      extends: 'input',
      defaultOptions: {
        props: {
          type: 'number'
        }
      }
    }]
  })]]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyInputModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldInput],
      imports: [CommonModule, ReactiveFormsModule, IonicModule, FormlyFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'input',
          component: FormlyFieldInput,
          wrappers: ['form-field']
        }, {
          name: 'string',
          extends: 'input'
        }, {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }, {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            props: {
              type: 'number'
            }
          }
        }]
      })],
      schemas: [NO_ERRORS_SCHEMA]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyFieldInput, FormlyInputModule };
