import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxMapLibreGLModule } from '@maplibre/ngx-maplibre-gl';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { FormlyIonicModule } from '@ngx-formly/ionic';
import { FormlyCreditCardComponent } from '../my-formly/components/credit-card/formly-credit-card-component';
import { FormlyCheckboxComponent } from '../my-formly/components/formly-checkbox/formly-checkbox.component';
import { FormlyInputComponent } from '../my-formly/components/formly-input/formly-input.component';
import { FormlySelectComponent } from '../my-formly/components/formly-select.ts/formly-select.component';
import { FormlyTextareaComponent } from '../my-formly/components/formly-textarea/formly-textarea.component';
import { MyFormlyHelpers } from '../my-formly/my-formly.helpers';
import { CarButtonComponent } from './car-button/car-button.component';
import { CarDetailsComponent } from './car-details/car-details.component';
import { CreditCardFormComponent } from './credit-card-form/credit-card-form.component';
import { DebugComponent } from './debug/debug.component';
import { FilterConnectorSelectorComponent } from './filter/filter-connector-selector/filter-connector-selector.component';
import { PowerRangeComponent } from './filter/power-range/power-range.component';
import { QuickFiltersComponent } from './filter/quick-filters/quick-filters.component';
import { HeaderComponent } from './header/header.component';
import { InstallationFooterComponent } from './installation-footer/installation-footer.component';
import { InstallationHeaderComponent } from './installation-header/installation-header.component';
import { LogoThumbnailComponent } from './logo-thumbnail/logo-thumbnail.component';
import { MapComponent } from './map/map.component';
import { CdcAuthComponent } from './master-detail-cdc-authorization/cdc-auth.component';
import { MasterDetailComponent } from './master-detail/master-detail.component';
import { PhotosComponent } from './photos/photos.component';
import { SliderComponent } from './slider/slider.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { StarContainerComponent } from './star-container/star-container.component';
import { StarComponent } from './star-container/star/star.component';
import { StationButtonsComponent } from './station/station-buttons/station-buttons.component';
import { StationConnectorSelectorComponent } from './station/station-connector-selector/station-connector-selector.component';
import { StationConnectorComponent } from './station/station-connector/station-connector.component';
import { StationInfoComponent } from './station/station-info/station-info.component';
import { UserCardComponent } from './user-card/user-card.component';
import { ValueComponent } from './value/value.component';
import { WaitingToastComponent } from './waiting-toast/waiting-toast.component';
import { DebugFullComponent } from './debug-full/debug-full.component';
import { StateSimpleComponent } from './state-simple/state-simple.component';
import { StateSimpleTimerComponent } from './state-simple-timer/state-simple-timer.component';
import { CostInfoComponent} from './session-info/cost-info/cost-info.component';
import { ChargeInfoComponent } from './session-info/charge-info/charge-info.component';
import { ChargePercentageInfoComponent } from './session-info/charge-percentage-info/charge-percentage-info.component';
import { ChargeSpeedInfoComponent } from './session-info/charge-speed-info/charge-speed-info.component';
import { ParkingTextComponent } from './session-info/parking-text/parking-text.component';
import { ReservationTextComponent } from './session-info/reservation-text/reservation-text.component';
import { RfidDetailsComponent } from './master-details-rfid/rfid-details.component';
import { RfidFormComponent } from './rfid-form/rfid-form.component';
import { NotificationDetailsComponent } from './master-details-notification/notification-details.component';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { AppInfoComponent } from './master-details-app-info/app-info.component';
import { UserProfileComponent } from './master-detail-user-profile/user-profile.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { CarEditComponent } from './car-edit/car-edit.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
// import { DirectivesModule, HumanReadableTime, PipesModule } from 'yf-lib';
import { ConfirmStationOpsComponent } from './confirm-station-ops/confirm-station-ops.component';
import { DividerComponent } from './divider/divider.component';
import { CheddarComponent } from './cheddar/cheddar.component';
import { MiniStationComponent } from './mini-station/mini-station.component';
import { CdcWaitingComponent } from './master-detail-cdc-authorization/components/cdc-waiting/cdc-waiting.component';
import { AppStatusLedsComponent } from './app-status-leds/app-status-leds.component';
import { SessionsComponent } from './master-detail-sessions/sessions.component';
import { FormlyCvvComponent } from '../my-formly/components/cvv/formly-cvv-component';
import { ModalCompleteNameComponent } from './modal-complete-name/modal-complete-name.component';
import { ModalChapterUpdatedComponent } from './modal-chapter-updated/modal-chapter-updated.component';
import { StateLabelComponent } from './state-label/state-label.component';
import { CostDetailComponent } from './cost-detail/cost-detail.component';
import { CostCardComponent } from './cost-detail/cost-card/cost-card.component';
import { StopChargeComponent } from './station/stop-charge/stop-charge.component';
import { ProfileDeletionComponent } from './profile-deletion/profile-deletion.component';
import { UnpaidSessionBannerComponent } from './unpaid-session-banner/unpaid-session-banner.component';
import { PipesModule } from '../pipes/pipes.module';
import { SessionToastDetailComponent } from './session-toast-detail/session-toast-detail.component';
import { AvviaRicaricaComponent } from './buttons/avvia-ricarica/avvia-ricarica.component';
import { AvviaPrenotazioneComponent } from './buttons/avvia-prenotazione/avvia-prenotazione.component';
import { AnnullaPrenotazioneComponent } from './buttons/annulla-prenotazione/annulla-prenotazione.component';
import { InterrompiRicaricaComponent } from './buttons/interrompi-ricarica/interrompi-ricarica.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DebounceClickDirective } from '../directives/no-double-click.directive';
import { UpdateScreenComponent } from './update-screen/update-screen.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarcodeScanningModalComponent } from './barcode-scanning-modal/barcode-scanning-modal.component';
import { TextScanComponent } from './text-scan/text-scan.component';
import { FilterCpoSelectorComponent } from './filter-cpo-selector/filter-cpo-selector.component';



const COMPONENTS = [
  AppStatusLedsComponent,
  MapComponent,
  PhotosComponent,
  StarComponent,
  StarContainerComponent,
  LogoThumbnailComponent,
  StateSimpleComponent,
  StateSimpleTimerComponent,
  StationInfoComponent,
  StationConnectorComponent,
  StationConnectorSelectorComponent,
  HeaderComponent,
  UserCardComponent,
  CarButtonComponent,
  CarDetailsComponent,
  SplashScreenComponent,
  UpdateScreenComponent,
  PowerRangeComponent,
  FilterConnectorSelectorComponent,
  StationButtonsComponent,
  QuickFiltersComponent,
  InstallationFooterComponent,
  InstallationHeaderComponent,
  DebugComponent,
  DebugFullComponent,
  MasterDetailComponent,
  SessionsComponent,
  SliderComponent,
  ValueComponent,
  CreditCardFormComponent,
  CdcAuthComponent,
  WaitingToastComponent,
  CostInfoComponent,
  ChargeInfoComponent,
  ChargePercentageInfoComponent,
  ChargeSpeedInfoComponent,
  ParkingTextComponent,
  ReservationTextComponent,
  RfidDetailsComponent,
  RfidFormComponent,
  NotificationDetailsComponent,
  SearchPanelComponent,
  AppInfoComponent,
  UserProfileComponent,
  UserEditComponent,
  CarEditComponent,
  MiniStationComponent,
  ConfirmStationOpsComponent,
  DividerComponent,
  CheddarComponent,
  MainMenuComponent,
  CdcWaitingComponent,
  ModalCompleteNameComponent,
  ModalChapterUpdatedComponent,
  StateLabelComponent,
  CostDetailComponent,
  CostCardComponent,
  StopChargeComponent,
  ProfileDeletionComponent,
  UnpaidSessionBannerComponent,
  SessionToastDetailComponent,
  AvviaRicaricaComponent,
  AvviaPrenotazioneComponent,
  AnnullaPrenotazioneComponent,
  InterrompiRicaricaComponent,
  DebounceClickDirective,
  FilterCpoSelectorComponent,
  BarcodeScanningModalComponent,
  TextScanComponent
];

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    BrowserModule,
    NgxMapLibreGLModule,
    FormsModule,
    NgxMapLibreGLModule,
    ReactiveFormsModule,
    FormlyIonicModule,
    FormlyModule.forRoot({
      validators: [
        { name: 'fieldMatch', validation: MyFormlyHelpers.PasswordMatchValidator },
        { name: 'luhnValidator', validation: MyFormlyHelpers.luhnValidator },
      ],
      validationMessages: [
        { name: 'email', message: MyFormlyHelpers.emailValidationMessage },
        { name: 'minlength', message: MyFormlyHelpers.minlengthValidationMessages },
      ],
      extras: { lazyRender: true },
      types: [
        { name: 'f-input', component: FormlyInputComponent },
        { name: 'f-select', component: FormlySelectComponent },
        { name: 'f-checkbox', component: FormlyCheckboxComponent },
        { name: 'f-textarea', component: FormlyTextareaComponent },
        { name: 'f-ccredit', component: FormlyCreditCardComponent },
        { name: 'f-cvv', component: FormlyCvvComponent },
      ],
    }),
    TranslateModule.forChild({ extend: true, isolate: false }),
    PipesModule
  ],
  providers: [{ provide: FORMLY_CONFIG, multi: true, useFactory: MyFormlyHelpers.formlyValidationConfig, deps: [TranslateService] }],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class ComponentsModule { }
