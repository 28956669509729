@if(!mainStateService.$selectedConnectorHasMineSession()){
<div class="yf-container yf-padding-inline gap-6">
  @if($tariffDescription()?.reservationDescription){
  <div class="yf-row centered gap-12">
    <div class="yf-container">
      <ion-icon src="assets/app-icons/cronometro.svg" style="font-size: 22px" color="secondary"> </ion-icon>
    </div>
    <div class="grow">
      <ion-text class="fs-12 fw-500" color="secondary">
        {{$tariffDescription().reservationDescription}}</ion-text>
    </div>
  </div>
  }
  @if($tariffDescription()?.parkingDescription){
  <div class="yf-row centered gap-12">
    <div class="yf-container">
      <ion-icon src="assets/app-icons/parcheggio.svg" style="font-size: 22px" color="secondary"> </ion-icon>
    </div>
    <div class="grow">
      <ion-text class="fs-12 fw-500" color="secondary">
        {{$tariffDescription().parkingDescription}}</ion-text>
    </div>
  </div>
  }
</div>
}
