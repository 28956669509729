"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPPComunication = void 0;
var OCPPComunication;
(function (OCPPComunication) {
  OCPPComunication.CpRequests = Object.freeze({
    CancelReservation: 'CancelReservation',
    ChangeAvailability: 'ChangeAvailability',
    ChangeConfiguration: 'ChangeConfiguration',
    ClearCache: 'ClearCache',
    ClearChargingProfile: 'ClearChargingProfile',
    DataTransfer: 'DataTransfer',
    GetCompositeSchedule: 'GetCompositeSchedule',
    GetConfiguration: 'GetConfiguration',
    GetDiagnostics: 'GetDiagnostics',
    GetLocalListVersion: 'GetLocalListVersion',
    RemoteStartTransaction: 'RemoteStartTransaction',
    RemoteStopTransaction: 'RemoteStopTransaction',
    ReserveNow: 'ReserveNow',
    Reset: 'Reset',
    SendLocalList: 'SendLocalList',
    SetChargingProfile: 'SetChargingProfile',
    TriggerMessage: 'TriggerMessage',
    UnlockConnector: 'UnlockConnector',
    UpdateFirmware: 'UpdateFirmware'
  });
  OCPPComunication.InnerState = Object.freeze({
    Available: "Available",
    Preparing: "Preparing",
    Charging: "Charging",
    SuspendedEVSE: "SuspendedEVSE",
    SuspendedEV: "SuspendedEV",
    Finishing: "Finishing",
    Reserved: "Reserved",
    Unavailable: "Unavailable",
    Faulted: "Faulted"
  });
})(OCPPComunication || (exports.OCPPComunication = OCPPComunication = {}));
