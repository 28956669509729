import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i1$1 from '@ngx-formly/core';
import { FieldType, FormlyModule } from '@ngx-formly/core';
import * as i2 from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import * as i1 from '@ionic/angular';
import { IonicModule } from '@ionic/angular';
import * as i3 from '@ngx-formly/ionic/form-field';
import { FormlyFormFieldModule } from '@ngx-formly/ionic/form-field';
class FormlyFieldCheckbox extends FieldType {}
FormlyFieldCheckbox.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyFieldCheckbox,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
FormlyFieldCheckbox.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "12.0.0",
  version: "13.3.12",
  type: FormlyFieldCheckbox,
  selector: "formly-field-ion-checkbox",
  usesInheritance: true,
  ngImport: i0,
  template: `
    <ion-checkbox [formControl]="formControl" [ionFormlyAttributes]="field">
      {{ props.label }}
    </ion-checkbox>
  `,
  isInline: true,
  components: [{
    type: i1.IonCheckbox,
    selector: "ion-checkbox",
    inputs: ["checked", "color", "disabled", "indeterminate", "mode", "name", "value"]
  }],
  directives: [{
    type: i1.BooleanValueAccessor,
    selector: "ion-checkbox,ion-toggle"
  }, {
    type: i2.NgControlStatus,
    selector: "[formControlName],[ngModel],[formControl]"
  }, {
    type: i2.FormControlDirective,
    selector: "[formControl]",
    inputs: ["formControl", "disabled", "ngModel"],
    outputs: ["ngModelChange"],
    exportAs: ["ngForm"]
  }, {
    type: i3.ɵIonFormlyAttributes,
    selector: "[ionFormlyAttributes]",
    inputs: ["ionFormlyAttributes"]
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyFieldCheckbox,
  decorators: [{
    type: Component,
    args: [{
      selector: 'formly-field-ion-checkbox',
      template: `
    <ion-checkbox [formControl]="formControl" [ionFormlyAttributes]="field">
      {{ props.label }}
    </ion-checkbox>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }]
});
class FormlyCheckboxModule {}
FormlyCheckboxModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyCheckboxModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
FormlyCheckboxModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyCheckboxModule,
  declarations: [FormlyFieldCheckbox],
  imports: [CommonModule, ReactiveFormsModule, IonicModule, FormlyFormFieldModule, i1$1.FormlyModule]
});
FormlyCheckboxModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyCheckboxModule,
  imports: [[CommonModule, ReactiveFormsModule, IonicModule, FormlyFormFieldModule, FormlyModule.forChild({
    types: [{
      name: 'checkbox',
      component: FormlyFieldCheckbox,
      wrappers: ['form-field']
    }, {
      name: 'boolean',
      extends: 'checkbox'
    }]
  })]]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "13.3.12",
  ngImport: i0,
  type: FormlyCheckboxModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: [FormlyFieldCheckbox],
      imports: [CommonModule, ReactiveFormsModule, IonicModule, FormlyFormFieldModule, FormlyModule.forChild({
        types: [{
          name: 'checkbox',
          component: FormlyFieldCheckbox,
          wrappers: ['form-field']
        }, {
          name: 'boolean',
          extends: 'checkbox'
        }]
      })]
    }]
  }]
});

/**
 * Generated bundle index. Do not edit.
 */

export { FormlyCheckboxModule, FormlyFieldCheckbox };
