import { Component, Inject, Input } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { map, Observable } from 'rxjs';
import { GlobalState, GLOBAL_RX_STATE } from 'src/app/app.module';
import { MainState } from 'src/app/types/state/app-main-state.interface';

@Component({
    selector: 'yf-app-status-leds',
    templateUrl: './app-status-leds.component.html',
    styleUrls: ['./app-status-leds.component.scss'],
    standalone: false
})
export class AppStatusLedsComponent {

  online$: Observable<boolean>;
  ws$: Observable<boolean>;
  http$: Observable<boolean>;

  constructor(@Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>) {
    this.online$ = globalState.select("mainState").pipe(map(ms => ms === MainState.ONLINE));
    this.ws$ = globalState.select("wsComm");
    this.http$ = globalState.select("httpComm");
  }

}