<app-master-detail class="yf-master-detail" icon="history.svg" [svg]="true"
  [title]="masterDetailShow==='detail' ? (selectedSession?.createdAt).toString()  : ('SESSIONS_HISTORY.TITLE' | translate)"
  [typeValue]="masterDetailShow==='detail' ? 'dateWithHour' : null" subtitle="Archivio storico" titleMaster=""
  titleDetail="" [(show)]="masterDetailShow" filter="" (compactModeChange)="compactModeChange($event)"
  [forceCompact]="false">

  <div class="master">
    @if(sessions.length>0){
    <ion-content [fullscreen]="true">
      <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list>
        <div *ngFor="let sessionHistory of sessions">
          <ion-item-divider class="mb-6 border-top-radius">
            <ion-label> {{ getMonthForTitle(sessionHistory.month)}} </ion-label>
          </ion-item-divider>
          <div class="m-6" *ngFor="let session of sessionHistory.sessions; let i = index" (click)="details(session)">
            <ion-item button detail="true">
              <ion-icon name="car-sport-outline" slot="start"></ion-icon>
              <ion-label>
                <h2 class="date-item"><b>{{ session.createdAt | date :'d MMM y - HH:mm' }}</b></h2>
                <p>{{ session.energy | energy}} kWh</p>
                <p>{{ session.cdr?.totalCost?.inclVat | payment}}
                </p>
              </ion-label>
              <ion-icon name="warning-outline" color="danger"
                *ngIf="session?.cdr?.bankTransaction?.state ===  4"></ion-icon>
            </ion-item>
          </div>
          <div *ngIf="sessionHistory.isLastMonth" class="mt-24 yf-container centered">
            <ion-icon name="alert-circle-outline" class="fs-30" color="dark"></ion-icon>
            <ion-text color="primary" class="fw-300 fs-16 mt-6">
              {{ "SESSIONS_HISTORY.NO_OTHER_SESSIONS" | translate}}
            </ion-text>
          </div>
        </div>
        <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
          <ion-infinite-scroll-content></ion-infinite-scroll-content>
        </ion-infinite-scroll>
      </ion-list>
    </ion-content>
    }

    @if(!loading && sessions.length===0){
    <div class="yf-container centered">
      <ion-icon name="alert-circle-outline" class="fs-30" color="dark"></ion-icon>
      <ion-card>
        <ion-card-header>
          <ion-card-title color="primary" class="fw-600 fs-22 yf-text-center">
            {{ "SESSIONS_HISTORY.WHEN_YOULL_HAVE_ONE_YOULL_FIND_IT_HERE" | translate}}
          </ion-card-title>
        </ion-card-header>
      </ion-card>
    </div>
    }
  </div>

  <div class="detail">
    @if(selectedSession){
    <div class="detail-item">
      <div class="m-6">
        @if(selectedSession?.cdr?.bankTransaction?.state === 4 && !(tryngPayment$ | async)){
        <div class="mb-16">
          <yf-unpaid-session-banner (payNow)="payNowHandler()"></yf-unpaid-session-banner>
        </div>
        }
        <ion-row>
          <ion-col text-center class="t-center">
            <div class="detail-card-item ion-text-center">
              <ion-grid>
                <ion-row class="ion-justify-content-center">
                  <div class="detail-icon">
                    <ion-icon src="assets/app-icons/cronometro.svg"></ion-icon>
                  </div>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text class="fs-11 detail-label" color="medium">{{ "SESSIONS_HISTORY.CHARGED_FOR" | translate
                    }}</ion-text>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text class="text-detail">{{this.calcDate(
                    selectedSession?.startedAt,
                    selectedSession?.stoppedAt
                    ? selectedSession?.stoppedAt
                    : selectedSession?.endedAt
                    )}}</ion-text>
                </ion-row>
              </ion-grid>
            </div>
          </ion-col>
          <ion-col class="t-center">
            <div class="detail-card-item ion-text-center">
              <ion-grid>
                <ion-row class="ion-justify-content-center">
                  <div class="detail-icon">
                    <ion-icon src="assets/app-icons/coin.svg"></ion-icon>
                  </div>

                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text class="fs-11 detail-label" color="medium">{{ "SESSIONS_HISTORY.TOTAL_COST" |
                    translate}}</ion-text>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text class="text-detail">{{selectedSession.cdr?.totalCost?.inclVat| payment }}</ion-text>
                </ion-row>
              </ion-grid>
            </div>
          </ion-col>
          <ion-col class="t-center">
            <div class="detail-card-item ion-text-center">
              <ion-grid>
                <ion-row class="ion-justify-content-center">
                  <div class="detail-icon">
                    <ion-icon src="assets/app-icons/electrical.svg"></ion-icon>
                  </div>

                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text class="fs-11 detail-label" color="medium">{{ "SESSIONS_HISTORY.ENERGY" |
                    translate}}</ion-text>
                </ion-row>
                <ion-row class="ion-justify-content-center">
                  <ion-text class="text-detail">{{ selectedSession?.energy | energy }} kWh</ion-text>
                </ion-row>
              </ion-grid>
            </div>
          </ion-col>
        </ion-row>
      </div>

      <div>
        <div class="m-6">
          <hr>
          <ion-grid>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text class="title-row"><b> {{ "SESSIONS_HISTORY.TOTAL_COST" | translate
                    }}</b></ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="title-row"><b>{{ selectedSession.cdr?.totalCost?.inclVat |
                    payment}}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "SESSIONS_HISTORY.TOTAL_COST_RESERVATION" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ selectedSession.cdr?.totalReservationCost?.inclVat |
                  payment
                  }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.TOTAL_COST_ENERGY" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ selectedSession.cdr?.totalEnergyCost?.inclVat | payment }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "SESSIONS_HISTORY.TOTAL_COST_PARKING" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ selectedSession.cdr?.totalParkingCost?.inclVat | payment
                  }}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <div class="m-6">
          <hr>
          <ion-grid>
            <ion-row class="ion-justify-content-start">
              <ion-col size="auto"> <ion-text class="title-row"><b>{{ "SESSIONS_HISTORY.TAX_DETAIL" | translate
                    }}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col> <ion-text color="medium"><b>{{ "SESSIONS_HISTORY.RESERVATION" | translate }}</b></ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text"><b>{{ selectedSession.cdr?.totalReservationCost?.inclVat |
                    payment }}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.AMOUNT_EXCLUDING_VAT" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ selectedSession.cdr?.totalReservationCost?.exclVat |
                  payment
                  }}</ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.VAT" | translate }}
                  ({{selectedSession?.cdr?.totalReservationCost?.vat ?? 22}}%)</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ getReservationVATValue(selectedSession) | payment }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-start pt-10">
              <ion-col> <ion-text color="medium"><b>{{ "SESSIONS_HISTORY.ENERGY" | translate}}</b></ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text"><b>{{ selectedSession.cdr?.totalEnergyCost?.inclVat |
                    payment }}</b>
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.AMOUNT_EXCLUDING_VAT" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ selectedSession.cdr?.totalEnergyCost?.exclVat | payment
                  }}</ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.VAT" | translate }}
                  ({{selectedSession?.cdr?.totalEnergyCost?.vat ?? 22}}%)</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ getEnergyVATValue(selectedSession) | payment }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between pt-10">
              <ion-col> <ion-text color="medium"><b>{{ "SESSIONS_HISTORY.PARKING" | translate }}</b></ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text"><b>{{ selectedSession.cdr?.totalParkingCost?.inclVat |
                    payment }}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.AMOUNT_EXCLUDING_VAT" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ selectedSession.cdr?.totalParkingCost?.exclVat | payment
                  }}</ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.VAT" | translate }}
                  ({{selectedSession?.cdr?.totalParkingCost?.vat ?? 0}}%)</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ getParkingVATValue(selectedSession) | payment }}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <div class="m-6">
          <hr>
          <ion-grid>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text class="title-row"><b>{{ "SESSIONS_HISTORY.DATE" |
                    translate}}</b></ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="title-row"><b>{{selectedSession?.endedAt?.toLocaleString() | date:
                    "d MMM y" }}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium">{{ "SESSIONS_HISTORY.RESERVATION_DATE_TIME" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{
                  dateRange(
                  selectedSession?.reservedAt,
                  selectedSession?.startedAt ? selectedSession.startedAt : selectedSession?.bookingEndAt,
                  selectedSession
                  )
                  }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "SESSIONS_HISTORY.CHARGING_DATE_TIME" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{
                  dateRange(
                  selectedSession?.startedAt,
                  selectedSession?.stoppedAt ? selectedSession?.stoppedAt : selectedSession?.endedAt,
                  selectedSession
                  )
                  }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              @if(selectedSession?.occupationTime){
              <ion-col size="auto">
                <ion-text color="medium"> {{ "SESSIONS_HISTORY.PAYMENT_PARKING_AFTER_CHARGE" | translate }}</ion-text>
              </ion-col>
              <ion-col size="auto">
                <ion-text class="text">
                  {{
                  occpuationTimeDuration(selectedSession)
                  }}
                </ion-text>
              </ion-col>
              }
              @else{
              <ion-col size="auto">
                <ion-text color="medium"> {{ "SESSIONS_HISTORY.PARKING_AFTER_CHARGE" | translate }}</ion-text>
              </ion-col>
              <ion-col size="auto">
                <ion-text class="text">
                  {{
                  dateRange(selectedSession?.stoppedAt, selectedSession?.endedAt, selectedSession)
                  }}
                </ion-text>
              </ion-col>
              }
            </ion-row>
          </ion-grid>
        </div>

        <div class="m-6">
          <hr>
          <ion-grid>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text class="title-row"><b>{{ "SESSIONS_HISTORY.STATION" | translate
                    }}</b></ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text
                  class="title-row"><b>{{this.truncate(selectedSession?.connector?.station?.name, 24) }}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "SESSIONS_HISTORY.ADDRESS" | translate }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{ selectedSession?.connector?.station?.installation?.name ||
                  ''
                  }}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "SESSIONS_HISTORY.POWER" | translate }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{this.getConnectorPower(selectedSession) | power}} kW
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "SESSIONS_HISTORY.SUPPLIER" | translate }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{selectedSession?.connector?.station?.cpo}}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "SESSIONS_HISTORY.CONNECTOR" | translate }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{selectedSession?.connector?.plugTypes[0]}}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <div class="m-6">
          <hr>
          <ion-grid>
            <ion-row class="ion-justify-content-start">
              <ion-col size="auto"> <ion-text class="title-row"><b>{{ "SESSIONS_HISTORY.VEHICLE" | translate
                    }}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "CAR.CAR_DETAIL.VEHICLE_BRAND" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{selectedSession?.car?.model?.brand?.name}}
                </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-between">
              <ion-col size="auto"> <ion-text color="medium"> {{ "CAR.CAR_DETAIL.VEHICLE_MODEL" | translate
                  }}</ion-text>
              </ion-col>
              <ion-col size="auto"> <ion-text class="text">{{selectedSession?.car?.model?.name}}
                </ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>

        <div class="m-6">
          <hr>
          <ion-grid>
            <ion-row class="ion-justify-content-start">
              <ion-col size="auto"> <ion-text class="title-row"><b>{{ "SESSIONS_HISTORY.TARIFF" | translate
                    }}</b></ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-start">
              <ion-col size="auto"> <ion-text color="medium"> {{ getTariffDescription(selectedSession) }}</ion-text>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>
    }
  </div>
</app-master-detail>