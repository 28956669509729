/* src/app/components/cost-detail/cost-card/cost-card.component.scss */
.text-container {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--yf-white);
  color: black;
}
ion-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ion-card {
  width: 100px;
  height: 80px;
  padding: 0px;
  margin: 4px;
  background-color: #174C6D;
  box-shadow: var(--yf-shadow);
}
ion-card-content {
  width: 100%;
  padding: 0;
  height: 55px;
}
.yf-container {
  height: 100%;
}
/*# sourceMappingURL=cost-card.component.css.map */
