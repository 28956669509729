"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupTypeCategory = void 0;
exports.GroupTypeCategory = Object.freeze({
  Users: 'Users',
  Stations: 'Stations',
  Tariff: 'Tariff',
  Visibility: 'Visibility'
});
