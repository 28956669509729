import { Injectable } from '@angular/core';
import {
  ClientEvents,
  IClientSocket,
  IInstallation,
  WsNamespaces,
} from 'common_library';
import { SocketIoService } from '../communication/socket-io.service';
import { InstallationProperties } from 'src/app/components/map/map.component';
import { SelectedClusterService } from './selected-cluster.service';
import { MainStateService } from '../state/app-main-state.service';
import { HttpIoService } from '../communication/http-io.service';

@Injectable({ providedIn: 'root' })
export class SelectedInstallationService {

  constructor(
    private SIS: SocketIoService,
    private ScS: SelectedClusterService,
    private mainStateService: MainStateService, 
    private HIO: HttpIoService,
  ) {

    this.SIS.fromEvent<InstallationProperties[]>(
      WsNamespaces.Client,
      IClientSocket.ServerEvents.InstallationsUpdates
    ).subscribe(async (iu: InstallationProperties[]) => {
      console.log('SelectedInstallationService : installations_updates', iu);
      const selectedCluster = this.mainStateService.getSelectedCluster();
      if (selectedCluster) {
        this.ScS.updateCluster(iu);
      }
      const selectedInstallation = this.mainStateService.getSelectedInstallation();
      if (!selectedInstallation) return;
      if (iu.find((i) => i.id === selectedInstallation.id)) {
        console.log("***Update selected installation***", selectedInstallation.name)
        const rv = await this.fetchInstallation(selectedInstallation.id);
        this.mainStateService.setSelectedInstallation(rv);
      }
    });
  }

  async fetchInstallation(id: string): Promise<IInstallation> {
    try {
      console.log(`fetching ${id} ...`);
      console.time(`fetched ${id}`);
      let rv: IInstallation;
      rv = await this.getInstallationFromSocket(id);
      console.timeEnd(`fetched ${id}`);
      return rv;
    } catch (error) {
      console.log('🐱️ : error', error);
    }
  }

  async getInstallationFromSocket(id: string): Promise<IInstallation> {
    let user = this.mainStateService.getUser();
    let installation = await this.SIS.sendRequest<{ installationId: string, userId?: string }, IInstallation>(
      WsNamespaces.Client,
      ClientEvents.GetInstallationById,
      {
        installationId: id,
        userId: user?.id
      }
    );
    const userVisibityGroups = this.mainStateService.getUserVisibilityGroups();
    const uvg = userVisibityGroups ? userVisibityGroups.groups : [];
    installation.stations = installation.stations.filter(s => s.visible || (s.visibilityGroups.filter(value => uvg.includes(value))).length > 0);
    return installation;
  }

  async presentInstallationInfo(id: string): Promise<void> {
    const installation = await this.fetchInstallation(id);
    this.mainStateService.setSelectedInstallation(installation);
  }

  async getInstallationIdByStationId(stationId: string): Promise<{id: string | null}> {
    return await this.HIO.get<{id: string | null}>(`station/installation-id/${stationId.trim()}`);
  }
}
