"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatoCorrispettivoElettronico = void 0;
exports.StatoCorrispettivoElettronico = Object.freeze({
  New: 0,
  Processing: 1,
  Done: 2,
  Failed: 3,
  Reprocessing: 4,
  Canceled: 5,
  Unnecessary: 6,
  Canceling: 7
});
