import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'yf-value',
    templateUrl: './value.component.html',
    styleUrls: ['./value.component.scss'],
    standalone: false
})
export class ValueComponent implements OnInit {

    @Input() value: number | string;
    @Input() label: string;
    @Input() icon: string;
    @Input() type?: string = 'number';
    @Input() marked?: boolean = true;
    @Input() plug?: string = '';
    
    constructor() {
     
    }

    ngOnInit() {      
    }

}
