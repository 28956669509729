import { Component, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IReview, IStation } from 'common_library';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { AppService } from 'src/app/services/app.service';
import { ReviewService } from '../../services/entities/review.service';
import { ToastService } from '../../services/utils/toast.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

type CommentForm = {
  comment: string;
  rating?: number;
};

@Component({
    selector: 'app-reviews',
    templateUrl: './reviews.page.html',
    styleUrls: ['./reviews.page.scss'],
    standalone: false
})
export class ReviewsPage implements OnInit {
  rating = 1;
  showComment = false;
  stationReviews: IReview[];
  @Input() station: IStation;
  commentFrm: MyFormlyHelpers.FormlyForm<CommentForm>;
  myReview: any;
  modifyReviewFab: boolean;
  isLoading: boolean = false;

  constructor(
    private reviewService: ReviewService,
    private toast: ToastService,
    private modalCtrl: ModalController,
    public app: AppService,
    public mainStateService: MainStateService
  ) { }

  async ngOnInit() {
    this.isLoading = true;

    await this.populateComment();
    this.isLoading = false;

    // this.calculateAverage();
  }

  viewMore(id: number) {
    const text = document.getElementById(id.toString());
    const btn = document.getElementById('btn' + id.toString());
    text.style.overflow = 'visible';
    text.style.webkitLineClamp = 'unset';
    // text.style.whiteSpace = 'normal';
    btn.style.display = 'none';
  }

  textOverflowCalc(id: number): boolean {
    const text = document.getElementById(id.toString());
    return text.scrollHeight > text.offsetHeight;
  }

  async newComment() {
    if (!this.myReview) {
      this.commentFrm.model.rating = this.rating;
      await this.reviewService.create(
        this.commentFrm.model.rating,
        this.commentFrm.model.comment,
        this.station.id
      );

      this.modifyReviewFab = true;
    } else {
      try {
        await this.reviewService.deleteReview(this.myReview.id);
      } catch (error) {
        console.log('🚀 : error', error);
      }
      this.commentFrm.model.rating = this.rating;
      await this.reviewService.create(
        this.commentFrm.model.rating,
        this.commentFrm.model.comment,
        this.station.id
      );
    }
    await this.populateComment();
    this.toast.presentBasicToast({ message: `Commento creato`, color: 'secondary' });
    this.showComment = !this.showComment;
  }

  changeState() {
    this.showComment = !this.showComment;
  }

  async populateComment() {
    this.stationReviews = await this.reviewService.getAllByStationId(this.station.id);
    if (this.mainStateService.getUser()) {
      this.myReview = this.stationReviews.find((i) => i.user.id === this.mainStateService.getUser().id);
    }

    if (!this.myReview) {
      this.modifyReviewFab = false;
      this.commentFrm = new MyFormlyHelpers.FormlyForm<CommentForm>(
        {
          comment: '',
        },
        [MyFormlyHelpers.textarea('comment', 'Inserisci il tuo commento...', 2)]
      );
    } else {
      this.modifyReviewFab = true;
      this.rating = this.myReview.rating;
      this.commentFrm = new MyFormlyHelpers.FormlyForm<CommentForm>(
        {
          comment: this.myReview.comment,
        },
        [MyFormlyHelpers.textarea('comment', 'Modifica il tuo commento...', 2)]
      );
    }
  }

  closeMe() {
    this.modalCtrl.dismiss();
  }

  // async calculateAverage(): Promise<string> {
  //   let totalStar = 0;
  //   let res = await this.stationReviews.map((a) => a.rating);
  //   for (var i = 0; i < res.length; i++) {
  //     totalStar += res[i];
  //   }
  //   let average = totalStar / this.stationReviews.length;
  //   return average.toFixed(1);
  // }
}
