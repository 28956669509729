/* src/app/components/station/station-connector/station-connector.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.connector-card {
  margin-left: 4px;
  margin-right: 4px;
  border-radius: 8px;
  width: 120px;
  font-family: var(--ion-font-family, Arial, sans-serif);
  background-color: #fff;
  cursor: pointer;
}
.connector-card.isSelected {
  border: 2px solid;
}
.connector-card.isSelected.available {
  border-color: var(--ion-color-primary);
}
.connector-card.isSelected.reserved {
  border-color: var(--ion-color-tertiary);
}
.connector-card.isSelected.charging {
  border-color: var(--ion-color-warning);
}
.connector-card.isSelected.parking {
  border-color: var(--ion-color-danger);
}
.connector-card.isSelected.unavailable {
  border-color: var(--ion-color-medium);
}
.connector-card.isNotSelected {
  border: 1px solid #E9E9EA;
}
.connector-card ion-card-header {
  padding-left: 6px;
  padding-bottom: 4px;
  padding-top: 4px;
}
.connector-card ion-card-header.isSelected {
  font-weight: bold;
}
.connector-card ion-card-header .title {
  font-size: 11px;
}
.connector-card ion-card-content {
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  padding-top: 6px;
  color: var(--ion-color-dark);
}
.connector-card ion-card-content.isNotSelected {
  opacity: 0.6;
}
.connector-card ion-card-content .icon-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.connector-card ion-card-content .icon-row .icon {
  font-size: 16px;
  margin-right: 8px;
}
.connector-card ion-card-content .icon-row .connector-type {
  font-size: 13px;
  font-weight: 600;
}
.connector-card ion-card-content .info-row {
  margin-bottom: 6px;
}
.connector-card ion-card-content .info-row .info-text {
  display: block;
  font-size: 12px;
}
.connector-card ion-card-content .info-row .info-text .info-value {
  font-weight: bold;
}
.connector-card ion-card-content .status-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.connector-card ion-card-content .status-row .status-text {
  font-size: 10px;
  margin-right: 8px;
  color: #000;
}
.connector-card ion-card-content .status-row .status-icon {
  font-size: 10px;
}
.connector-card ion-card-content .status-row .status-icon.available {
  color: var(--ion-color-primary);
}
.connector-card ion-card-content .status-row .status-icon.reserved {
  color: var(--ion-color-tertiary);
}
.connector-card ion-card-content .status-row .status-icon.charging {
  color: var(--ion-color-warning);
}
.connector-card ion-card-content .status-row .status-icon.parking {
  color: var(--ion-color-danger);
}
.connector-card ion-card-content .status-row .status-icon.unavailable {
  color: var(--ion-color-medium);
}
/*# sourceMappingURL=station-connector.component.css.map */
