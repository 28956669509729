/* src/app/components/main-menu/main-menu.component.scss */
ion-menu::part(container) {
  border-radius: 0 20px 20px 0;
}
@media (max-width: 576px) {
  ion-menu {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  ion-menu {
    margin-top: env(safe-area-inset-top);
  }
}
ion-menu ion-icon {
  font-size: 25px;
  cursor: pointer;
}
ion-header ion-toolbar {
  padding: 5px 10px 5px 10px;
  --border-width: 0;
  --background: #fff;
}
ion-header ion-title {
  padding: 0 10px 0;
  width: auto;
  font-size: 25px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.24);
}
.debug {
  position: absolute;
  bottom: 2px;
  left: 6px;
  z-index: 10000;
  padding: 0px;
  font-size: 10pt;
  color: #888;
}
/*# sourceMappingURL=main-menu.component.css.map */
