"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOKEN_TYPE = void 0;
exports.TOKEN_TYPE = Object.freeze({
  /**
   * One time use Token ID generated by a server (or App)
   * The eMSP uses this to bind a Sessiont o a customer, probably an app user.
   */
  AD_HOC_USER: 'AD_HOC_USER',
  /**
   * Token ID generated by a server (or App) to identify a user of an App.
   * The same user uses the same Token for every Session
   */
  APP_USER: 'APP_USER',
  OTHER: 'OTHER',
  RFID: 'RFID'
});
