import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { getValidationConfigFromCardNo } from './cvv.helper';

@Component({
    selector: 'formly-cvv',
    templateUrl: 'formly-cvv-component.html',
    styleUrls: ['formly-cvv-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormlyCvvComponent extends FieldType implements OnInit {
  // formControl: FormControl;

  get ngControl() {
    return this.formControl as UntypedFormControl;
  }

  showPassword = false;

  ngOnInit() {}

  /*   getCardNumberControl(): AbstractControl | null {
    return this.form && this.form.get('cardNumber');
  } */
  cardMaskFunction(rawValue: string): Array<RegExp> {
    const card = getValidationConfigFromCardNo(rawValue);
    if (card) {
      return card.mask;
    }
    return [/\d/];
  }
}
