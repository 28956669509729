/* src/app/components/master-detail-cdc-authorization/components/cdc-waiting/cdc-waiting.component.scss */
.loading-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.loading-container .loading-text {
  padding-top: 20px;
  font-size: 16px;
  text-align: center;
}
/*# sourceMappingURL=cdc-waiting.component.css.map */
