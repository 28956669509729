/* src/app/components/app-status-leds/app-status-leds.component.scss */
.leds {
  position: absolute;
  bottom: 50%;
  left: 3px;
  z-index: 10000;
}
.led {
  width: 7px;
  height: 7px;
  border: 1px solid #FFF;
  border-radius: 3px;
  margin: 1px;
}
/*# sourceMappingURL=app-status-leds.component.css.map */
