/* src/app/components/rfid-form/rfid-form.component.scss */
ion-toolbar {
  --background: none;
  padding-top: 10px;
}
.yf-container {
  overflow-y: auto;
  padding-bottom: 18px;
}
@media (max-width: 768px) {
  .yf-container {
    max-height: calc(100vh - 220px);
  }
}
@media (min-width: 768px) {
  .yf-container {
    max-height: calc(100vh - 250px);
  }
}
@media (min-width: 992px) {
  .yf-container {
    max-height: calc(100vh - 370px);
  }
}
.yf-container ion-card {
  border-radius: 0px !important;
  padding: 16px;
  border: 1px solid rgba(162, 165, 173, 0.4);
  border-left: 8px solid var(--ion-color-primary);
  margin: 8px;
}
.yf-container ion-card.missing-data {
  border-left: 8px solid var(--ion-color-danger) !important;
}
.yf-container ion-card-header {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 12px;
}
.yf-container ion-card-content {
  width: 100%;
  padding: 0px;
}
/*# sourceMappingURL=rfid-form.component.css.map */
