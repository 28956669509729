"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIAuthMethod = void 0;
exports.OCPIAuthMethod = Object.freeze({
  AUTH_REQUEST: "AUTH_REQUEST",
  COMMAND: "COMMAND",
  WHITELIST: "WHITELIST"
});
