import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'HumanReadableTime',
    standalone: false
})
export class HumanReadableTime implements PipeTransform {

  transform(value: number, ...args: unknown[]): string {
    let seconds = value * 60;
    let interval = seconds / 31536000;

    if (interval >= 1) {
      return Math.floor(interval) + " anni";
    }
    interval = seconds / 2592000;
    if (interval >= 1) {
      return Math.floor(interval) + " mesi";
    }
    interval = seconds / 86400;
    if (interval >= 1) {
      return Math.floor(interval) + " giorni";
    }
    interval = seconds / 3600;
    if (interval >= 1 && interval < 2) {
      return Math.floor(interval) + " ora";
    }
    if (interval >= 1) {
      return Math.floor(interval) + " ore";
    }
    interval = seconds / 60;
    if (interval >= 1) {
      return Math.floor(interval) + " minuti";
    }
    return Math.floor(seconds) + " secondi";
  }
}
