"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IEntityTypeLog = void 0;
exports.IEntityTypeLog = Object.freeze({
  STATION: 'station',
  INSTALLATION: 'installation',
  SESSION: 'session',
  GATEWAY: 'gateway'
});
