<div class="state-container yf-state gap-2" [ngClass]="$stationClass()">
  @if(!$station()?.online && !isInstallation){
  @if(showIcon){
  <ion-icon class="icon" src="assets/app-icons/wifi_off.svg"></ion-icon>
  }
  <ion-text class="fs-11 fw-600">
    {{ 'Offline' | titlecase }}
  </ion-text>
  }
  @else{
  <ion-text class="fs-11 fw-600">
    {{ $stateLabel() }}
  </ion-text>
  }
</div>