@if(show$ | async){
<div class="app-splash-screen">
  <div class="app-splash-screen-inner">

    <div class="app-logo">
      <span class="circle"></span>
      <img src="assets/logos/logo_bianco_yourfill.svg" alt="YourFill logo" />
    </div>

    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>

    @if(offline$ | async){
    <!--     <ion-item lines="none">
      <ion-icon slot="start" name="cloud-offline-outline" size="large" color="warning"></ion-icon>
      <ion-label>{{'SPLASH.CONNECTING'|translate}}</ion-label>
    </ion-item> -->
    <div class="app-starting">
      <ion-label>{{'SPLASH.CONNECTING'|translate}}</ion-label>
    </div>
    }
    @else {
    <div class="app-starting">
      <ion-label>{{'SPLASH.STARTING'|translate}}</ion-label>
    </div>
    }
  </div>
</div>
}