"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIConnectorFormat = void 0;
exports.OCPIConnectorFormat = Object.freeze({
  SOCKET: 'SOCKET',
  CABLE: 'CABLE'
});
