<ion-card class="card">
  <ion-card-content>
    @if(!!$el()){
    <yf-state-label [label]="$el().label" [showText]="showText" [class]="$el().class">
    </yf-state-label>
    <div class="yf-container centered">
      @if($el().type === TARIFF_DIMENSION_TYPE.ENERGY){
      <ion-text class="fs-13" color="light">
        <b>{{ $el().energy | energy }}</b> <span [class]="$el().power?'fs-9':'fs-12'"> kWh</span>
      </ion-text>
      @if($el().power){
      <ion-text class="fs-13" color="warning">
        <b>{{ $el().power | power }}</b> <span class="fs-9"> kW</span>
      </ion-text>
      }
      <ion-text class="fw-600" [class]="$el().power?'fs-13':'fs-18'" color="tertiary">
        {{ $el().cost | number:'1.2-2'}} €
      </ion-text>
      }
      @else {
      <ion-text class="fw-600 fs-12" color="light">
        {{ $el().timeSpan }}
      </ion-text>
      <ion-text class="fw-600 fs-18" color="tertiary">
        {{ $el().cost | number:'1.2-2'}} €
      </ion-text>
      }
    </div>
    }
  </ion-card-content>
</ion-card>