<div class="yf-row align-left yf-header-padding">
  <ion-button
    *ngIf="buttonPosition === 'left'"
    class="btn transparent square"
    (click)="leftButtonEvent.emit()">
    <ion-icon [src]="'assets/app-icons/' + leftButtonName + '.svg'"></ion-icon>
  </ion-button>
  <div class="grow" [style]="{ 'text-align': buttonPosition === 'left' ? 'right' : 'left' }">
    <ion-text color="primary" style="font-weight: 600; font-size: 28px">{{ label }}</ion-text>
  </div>
  <ion-button
    *ngIf="buttonPosition === 'right'"
    class="btn transparent square"
    (click)="rightButtonEvent.emit()">
    <ion-icon [src]="'assets/app-icons/' + rightButtonName + '.svg'"></ion-icon>
  </ion-button>
</div>
