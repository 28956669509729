<ion-header class="pr-20">
  <ion-toolbar color="light" class="pt-20">
    <ion-buttons slot="start">
      @if((currentStep$|async)>0){
      <ion-button (click)="showPrevStep()">
        <ion-icon slot="icon-only" name="chevron-back-outline" size="large"></ion-icon>
      </ion-button>
      }
    </ion-buttons>
    <ion-title>
      {{ 'APP.INSTALLATION.BUTTONS.START_CHARGING' | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button color="dark" (click)="close()">
        <ion-icon slot="icon-only" name="close-outline" size="large"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="ion-padding">
  @if ((currentStep$|async)===0) {
  <div>
    <div *ngIf="mainStateService.$selectedStation() as station">
      <ion-text class="fw-600 mt-8">{{ "APP.INSTALLATION.DEVICE" | translate }}</ion-text>
      <div class="centered-content mt-8"><ion-badge color="primary" class="p-10" style="font-size: 18pt">{{
          station.name }}</ion-badge></div>
    </div>
    <!-- TUTTI I CONNETTORI DELLA STAZIONE -->

    <yf-divider></yf-divider>

    <div *ngIf="mainStateService.$selectedConnector() as connector">
      <ion-text class="fw-600 mt-8">{{ "INSTALLATION.CONNECTOR" | translate }}</ion-text>
      <div class="centered-content mt-8">
        <ion-badge color="primary" class="p-10" style="font-size: 18pt">{{ connector?.name || connector.cpConnectorId
          }}</ion-badge>
      </div>
    </div>

    <yf-divider></yf-divider>
    <!-- VEICOLI DELL'UTENTE  -->
    <div>
      <ion-text class="fw-600 mt-8">
        {{ "APP.INSTALLATION.VEHICLE" | translate }}
      </ion-text>
      <div class="mt-8">
        <ion-grid>
          <ion-row>
            <ion-col size="6" *ngFor="let car of mainStateService.$userCars()">
              <app-car-button [name]="car.model.brand.name" [model]="car.model.name"
                [isSelected]="car.id === mainStateService.$selectedUserCarForSession()?.id" (carButtonEvent)="selectNewCar(car)">
              </app-car-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>

    <yf-divider></yf-divider>

    <div>
      <ion-text class="fw-600 mt-8">
        {{ "INSTALLATION.TARIFF" | translate}}
      </ion-text>
      <div class="yf-row wrap gap-6 mt-8">
        {{$tariffText()}}
      </div>
    </div>

    <yf-divider></yf-divider>

    @if($tariffDeposit() && $tariffDeposit()>0){
    <div>
      <ion-text class="fw-600 mt-8">
        {{ "INSTALLATION.DEPOSIT_PAYMENT" | translate}}
      </ion-text>
      <div class="yf-row wrap gap-6 mt-8">
        {{ "INSTALLATION.DEPOSIT_TOT" | translate: { deposit: $tariffDeposit().toFixed(2) } }}
      </div>
    </div>

    <yf-divider></yf-divider>
    }
  </div>
  }

  @if ((currentStep$|async)===1) {
  <div>
    <div class="mt-8 centered-content">
      <img src="assets/images/illustrazione_ricarica.svg" alt="Charging illustration">
    </div>

    <div style="margin-top: 50px;">
      <ion-grid>
        <ion-row>
          <ion-col size="auto"><ion-chip class="fs-16 fw-600">1</ion-chip></ion-col>
          <ion-col><ion-text class="fs-16">{{ "APP.INSTALLATION.CHECK_YOU_ARE_USING_THE_DEVICE" | translate}} <b>{{
                mainStateService.$selectedStation()?.name }}</b></ion-text></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="auto"><ion-chip class="fs-16 fw-600">2</ion-chip></ion-col>
          <ion-col><ion-text class="fs-16">{{ "APP.INSTALLATION.CHECK_YOU_ARE_USING_THE_CONNECTOR" | translate}} <b>{{
                mainStateService.$selectedConnector()?.name || mainStateService.$selectedConnector()?.cpConnectorId
                }}</b></ion-text></ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="auto"><ion-chip class="fs-16 fw-600">3</ion-chip></ion-col>
          <ion-col><ion-text class="fs-16">{{ "APP.INSTALLATION.INSERT_PLUG_AND_START_CHARGING_USING_THIS_BUTTON" |
              translate }}</ion-text></ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
  }
</ion-content>

<ion-footer collapse="fade" class="ion-no-border ion-padding">
  @if ((currentStep$|async)===0) {
  <ion-button class="btn-yf" color="secondary" expand="block" (click)="showNextStep()">{{
    "BUTTONS.NEXT" | translate}}
    <ion-icon slot="end" src="assets/app-icons/arrow_forward.svg"></ion-icon>
  </ion-button>
  }

  @if ((currentStep$|async)===1){
  <ion-button class="btn-yf" color="secondary" expand="block" (click)="confirmOperation()"
    [disabled]="mainStateService.$connectorCommandInProgress()">
    @if(mainStateService.$connectorCommandInProgress()){
    <ion-spinner></ion-spinner>
    }
    @else {
    <ion-icon slot="start" src="assets/app-icons/ricarica.svg"></ion-icon>
    <ion-label class="ion-align-items-center"> {{ "APP.INSTALLATION.BUTTONS.START_CHARGING" | translate
      }}</ion-label>
    }
  </ion-button>
  }
</ion-footer>