<div class="formly-input-container">
    <ion-item>
        <input [formControl]="ngControl" [minlength]="to.minLength" type="text" [mask]="$translatedMask()"
      placeholder="{{'CDC_AUTHENTICATION.CARD_NUMBER'|translate}}" (keyup)="cardMaskFunction(ngControl.value)"/>

    </ion-item>

    <div *ngIf="ngControl.dirty">
        <p *ngIf="ngControl.invalid" class="invalid">{{ "CDC_AUTHENTICATION.CARD_NOT_VALID" | translate }}</p>
        <p *ngIf="ngControl.valid" class="valid">{{ "CDC_AUTHENTICATION.CARD_VALID" | translate }}</p>
    </div>
</div>