/* src/app/components/station/station-buttons/station-buttons.component.scss */
.sid {
  font-size: 8px;
  color: var(--ion-color-medium);
  padding: 0px;
  text-align: center;
}
.flex-80 {
  flex: 80%;
}
/*# sourceMappingURL=station-buttons.component.css.map */
