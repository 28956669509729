<div class="swiper">
  <swiper-container appSwiper #swiper [config]="swiperConfig" (swiperslidechange)="slideChange($event)"
    (slidesUpdated)="slidesUpdated($event)" (swiperslidesUpdated)="swiperSlidesUpdated($event)" effect="cards"
    grab-cursor="true">
    <swiper-slide *ngFor="let session of this.mainStateService.$sessions()">
      <div class="swiper-slide">
        <yf-session-toast-detail [$currentSession]="session" (onMap)="showOnMap($event)" (details)="openDetails($event)"
          (navigate)="navigateTo($event)">
        </yf-session-toast-detail>
      </div>
    </swiper-slide>
  </swiper-container>
</div>