"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BankTransactionState = void 0;
exports.BankTransactionState = Object.freeze({
  New: 0,
  Processing: 1,
  Reprocessing: 2,
  Completed: 3,
  Failed: 4,
  Unnecessary: 5
});
