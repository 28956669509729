/* src/app/pages/installation/installation.component.scss */
.installation-container {
  position: fixed;
  bottom: 60px;
  max-height: calc(100vh - 80px - 12px);
  background: var(--ion-color-light);
  box-shadow: var(--yf-shadow);
  overflow: auto;
  padding: 0;
  border-radius: var(--yf-spacing2);
  z-index: 1;
}
@media (max-width: 576px) {
  .installation-container {
    right: var(--yf-spacing1);
    left: var(--yf-spacing1);
    margin-bottom: calc(env(safe-area-inset-bottom));
    width: -moz-available;
  }
}
@media (min-width: 576px) {
  .installation-container {
    left: var(--yf-spacing1);
    width: 360px;
  }
}
.installation-container .stations-container {
  height: 230px;
  overflow-y: scroll;
}
.installation-container ion-button[button2] {
  --color: var(--ion-color-medium-shade);
}
.mini-stations {
  overflow-x: auto;
}
.mini-stations ::-webkit-scrollbar {
  display: none;
}
div::-webkit-scrollbar {
  display: none;
}
div {
  overflow: hidden;
}
.connector-selector {
  overflow-x: scroll;
  width: 100%;
}
::-webkit-scrollbar {
  display: none;
}
/*# sourceMappingURL=installation.component.css.map */
