"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RevenueSource = void 0;
exports.RevenueSource = Object.freeze({
  Default: 0,
  Tariff: 1
});
