<div class="state-container yf-state gap-2" [ngClass]="class">
  @if(showText){
  <ion-text class="fs-11 fw-700">
    {{ label | titlecase }}
  </ion-text>
  }
  @else{
  <ion-text class="fs-11 fw-700" color="light">
    {{ label | titlecase }}
  </ion-text>
  }
  <ng-content select="content">

  </ng-content>
</div>