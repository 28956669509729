import { Component, Input, computed, input } from '@angular/core';
import { TariffUtil, OCPITariffDimension, IInstallation } from 'common_library';
import { CoreService } from 'src/app/services/core.service';
import { UiService } from 'src/app/services/utils/ui.service';
import { AlertService } from 'src/app/services/utils/alert.service';
import { TranslateConfigService } from 'src/app/services/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { SelectedInstallationService } from 'src/app/services/entities/selected-installation.service';

@Component({
    selector: 'yf-installation-header',
    templateUrl: './installation-header.component.html',
    styleUrls: ['./installation-header.component.scss'],
    standalone: false
})
export class InstallationHeaderComponent {
  @Input() thumbnailClasses: string = 'ml-8 mt-12';
  @Input() contrast: boolean = false;

  $installation = input<IInstallation>(null);

  $tariffDescription = computed(() => {
    const lang = this.translateConfigService.getCurrentLang();
    const descriptions = TariffUtil.getTariffAltTextForConnectorPower(this.mainStateService.$selectedConnectorMineSession()?.tariffJson, lang, TariffUtil.getConnectorPower(this.mainStateService.$selectedConnector()));
    const reservationDescription = descriptions.find(c => c.dimension === OCPITariffDimension.RESERVATION);
    const energyDescription = descriptions.find(c => c.dimension === OCPITariffDimension.ENERGY);
    const parkingDescription = descriptions.find(c => c.dimension === OCPITariffDimension.PARKING_TIME);
    return `${reservationDescription.description} ${energyDescription.description} ${parkingDescription.description}`.trim();
  })

  $stationIcon = computed(() => {
    if (this.$installation()?.stations?.some((station) => station.cpo === 'YFL')) {
      return 'yfl';
    }
    else if (this.$installation()?.stations?.some((station) => station.cpo === 'ENX')) {
      return 'enx';
    }
    else if (this.$installation()?.stations?.some((station) => station.cpo === 'F2X')) {
      return 'f2x';
    }
    return 'not_yfl';
  });

  $textColor = computed(() => {
    return this.UI.getTextColor(this.mainStateService.$selectedConnectorHasMineSession())
  });

  constructor(
    public UI: UiService,
    public CS: CoreService,
    public alert: AlertService,
    private translateConfigService: TranslateConfigService,
    public mainStateService: MainStateService,
    private selectedInstallationService: SelectedInstallationService,
    private translate: TranslateService) {

  }
  /*
  ngOnChanges(changes: SimpleChanges): void {
  if (this.currentPosition) this.refreshDistance();
  }
  
  refreshDistance() {
  if (!!this.currentPosition && !!this.installation()) {
    this.distance = this.calcDistance(
      this.currentPosition.lat,
      this.currentPosition.lng,
      this.installation().coord.coordinates[1],
      this.installation().coord.coordinates[0]
    );
  }
  }
  
  // km
  calcDistance(lat1, lon1, lat2, lon2): number {
  var p = 0.017453292519943295; // Math.PI / 180
  var c = Math.cos;
  var a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
  return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }
  */

  async showinfoReservationParking() {
    this.alert.infoReservationParking(this.translate.instant("APP.INSTALLATION.ALERT_INFO_TARIFF"), this.$tariffDescription());
    //await this.selectedInstallationService.fetchInstallation(this.$installation()?.id, "Manual update");
  }
}
