
<ion-header>
  <ion-toolbar>
    <ion-title>Text scan</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="content">
  <div class="video-container">
      <video #videoElement autoplay playsinline muted [ngStyle]="{
        'visibility': $showVideo() ? 'visible' : 'hidden',
      }"></video>
    <div class="overlay-rectangle"></div>
  </div>
  <div class="content-wrapper">
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>{{'QR_OCR.OR' | translate}}</ion-card-subtitle> 
      </ion-card-header>
      
      <ion-card-content> 
        <ion-label class="yf-text-center">{{'QR_OCR.TEXT' | translate}}</ion-label>
        <ion-item>
          <!-- DEVO FERMARE L'OCR QUANDO QUALCUNO STA SCRIVENDO  -->
          <ion-input (ionBlur)="textElementBlur($event)" (ionFocus)="textElementFocus($event)" type="text" [(ngModel)]="text" (ionInput)="textChange($event)" [clearInput]="true">
            @if(textPatternValid$ | async){
              <ion-icon slot="end" name="checkmark-circle-outline" aria-hidden="true" class="fs-24" color="success" ></ion-icon>
            } @else {
              <ion-icon slot="end" name="close-circle-outline" aria-hidden="true" class="fs-24" color="danger"></ion-icon>
            }
          </ion-input>
        </ion-item> 
        <ion-button expand="block" class="btn-avanti" (click)="closeModal()">
          {{'BUTTONS.NEXT' | translate}}
        </ion-button> 
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>
<!-- @if (false) {
<ion-fab slot="fixed" horizontal="end" vertical="bottom">
  <ion-fab-button (click)="toggleTorch()">
    <ion-icon name="flashlight"></ion-icon>
  </ion-fab-button>
</ion-fab>
} -->