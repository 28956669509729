"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStateLabelByNumber = exports.State = void 0;
exports.State = Object.freeze({
  UNAVAILABLE: 0,
  // non disponibile
  AVAILABLE: 1,
  // disponibile
  RESERVED: 2,
  // prenotato
  CHARGING: 3,
  // in carica
  OCCUPIED: 4,
  // occupato
  PREPARING: 5 // occupato
});
function getStateLabelByNumber(state) {
  var keys = Object.keys(exports.State).filter(function (k) {
    return typeof exports.State[k] === "number";
  });
  return keys.find(function (key) {
    return exports.State[key] === state;
  });
}
exports.getStateLabelByNumber = getStateLabelByNumber;
