/* src/app/components/session-toast-detail/session-toast-detail.component.scss */
.toast-container {
  position: fixed;
  top: 72px;
}
.toast-container .toast-card {
  max-height: calc(100vh - 80px - 24px);
  background-color: var(--ion-color-secondary);
  box-shadow: var(--yf-shadow);
  padding: 0;
  border-radius: var(--yf-border-radius);
}
@media (max-width: 576px) {
  .toast-container {
    margin-top: env(safe-area-inset-top);
    right: var(--yf-spacing1);
    left: var(--yf-spacing1);
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 576px) and (max-width: 576px) {
  .toast-container .toast-card {
    width: 100vw;
  }
}
@media (max-width: 576px) and (min-width: 576px) {
  .toast-container .toast-card {
    width: 380px;
  }
}
@media (min-width: 576px) {
  .toast-container {
    margin-top: env(safe-area-inset-top);
    left: 72px;
  }
  .toast-container .toast-card {
    width: 380px;
  }
}
.navigate-btn {
  --background: #174c6d;
}
.centra-btn {
  --background: #174c6d;
  margin-top: 4px !important;
}
.close-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
ion-button {
  --padding-top: 11px;
  --padding-bottom: 11px;
  --padding-end: 11px;
  --padding-start: 11px;
}
.session-stars {
  fill: white;
  stroke: white;
}
.bigger-icon {
  height: 26px;
  width: 26px;
}
.installation-detail {
  width: 100%;
}
ion-icon[battery-charge-outline] {
  font-size: 20px;
}
.balance {
  width: 100%;
}
.occupation-timer {
  stroke: white;
}
.swiper-slide-next {
  background-color: transparent !important;
}
.swiper-slide-prev {
  background-color: transparent !important;
}
.logoColumn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.priceColumn {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
}
/*# sourceMappingURL=session-toast-detail.component.css.map */
