"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SocialProvider = void 0;
exports.SocialProvider = Object.freeze({
  // YOURFILL = 'YOURFILL',
  GOOGLE: 'GOOGLE',
  FACEBOOOK: 'FACEBOOOK',
  AMAZON: 'AMAZON',
  MICROSOFT: 'MICROSOFT',
  APPLE: 'APPLE'
});
