import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { Observable } from 'rxjs';

export interface Option {
  id: number;
  name: string;
}

@Component({
    selector: 'formly-select',
    templateUrl: './formly-select.component.html',
    styleUrls: ['../formly.global.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormlySelectComponent extends FieldType implements OnInit {
  myOptions$: Observable<any[]>;

  get ngControl() {
    return this.formControl as UntypedFormControl;
  }

  ngOnInit(): void {
    this.myOptions$ = this.to.options as Observable<any[]>;

    this.ngControl.valueChanges
      // .pipe(
      //   withLatestFrom(this.myOptions$),
      //   map(([value, options]) => {
      //     return options.find((option) => option.id === value);
      //   })
      // )
      .subscribe((value) => {
        if (value && typeof this.to.onChange === 'function') {
          this.to.onChange(value);
        }
      });
  }
}
