/* src/app/components/unpaid-session-banner/unpaid-session-banner.component.scss */
.unpaid-banner ion-card {
  color: #fff;
  background-color: rgb(255, 0, 0) !important;
  border-top-left-radius: var(--yf-spacing1);
  border-top-right-radius: var(--yf-spacing1);
  padding: 16px;
  cursor: pointer;
}
.unpaid-banner ion-card-header {
  padding-left: 0px;
  padding-top: 0px;
  padding-bottom: 12px;
}
.unpaid-banner ion-card-title {
  color: #fff;
}
.unpaid-banner ion-card-content {
  width: 100%;
  padding-top: 0px;
  padding-left: 0px;
  padding-bottom: 16px;
}
.unpaid-banner ion-button {
  --color: #fff;
  --border-color: #fff;
  --border-style: solid;
  --border-width: 1px;
}
/*# sourceMappingURL=unpaid-session-banner.component.css.map */
