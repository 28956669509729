import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'yf-state-label',
    templateUrl: './state-label.component.html',
    styleUrls: ['./state-label.component.scss'],
    standalone: false
})
export class StateLabelComponent implements OnInit {
  @Input() label: string;
  @Input() showText: boolean;
  @Input() class: any;
  constructor() { }

  ngOnInit() {}

}
