<ion-card>
  <ion-header>
    <ion-grid class="ion-no-padding width-max m-0">
      <ion-row class="ion-no-padding gap-12">
        <ion-col size="auto">
          <img src="assets/logos/yourfill-logo-white-bg.svg" alt="Your Fill" />
        </ion-col>
        <ion-col>
          <div class="titles">{{ header.title }}</div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-header>

  <ion-card-content class="yf-container form-container main-grid">
    <ion-grid class="m-0 pb-0 pt-0">
      <ion-row *ngIf="type === FormType.ForgotPw" class="mt-15">
        {{ 'LOGIN.YOULL_RECEIVE_AN_EMAIL' | translate}}
      </ion-row>

      <ion-row>
        <ion-col>
          <form *ngIf="!!form" [formGroup]="form.form" (keyup.enter)="onSubmit()">
            <formly-form [model]="form.model" [fields]="form.fields" [options]="form.options"
              [form]="form.form"></formly-form>
          </form>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="type !== FormType.Register">
        <ion-button class="btn large" color="primary" (click)="onSubmit()" [disabled]="this.form.form.invalid">
          {{ submitButton.label }}
        </ion-button>
      </ion-row>

      <ion-row *ngIf="forward" class="yf-container centered" style="margin-top: 15px;">
        <a style="font-size: 0.8rem; margin-bottom: 8px;" (click)="changeForm.emit(forward.type)" href="#">{{
          forward.text
          }}</a>
      </ion-row>

      <ng-container *ngIf="type == FormType.Register">
        <ion-row class="mb-15">
          <ion-col size="auto">
            <ion-checkbox #ionCheckBox class="checkbox-square" [(ngModel)]="contractAccepted"
              (change)="checkContract()"></ion-checkbox>
          </ion-col>
          <ion-col>
            <ion-text class="text">
              {{ "LOGIN.TERMS_AND_CONDITION_MESSAGE.CONFIRM" | translate}}
              <b class="info pointer" (click)="openTermsAndConditions()">{{
                "LOGIN.TERMS_AND_CONDITION_MESSAGE.TERMS_AND_CONDITIONS" | translate}}</b>
              {{ "LOGIN.TERMS_AND_CONDITION_MESSAGE.AND_THE" | translate}}
              <b class="info pointer" (click)="openInfoPrivacy()">{{ "LOGIN.TERMS_AND_CONDITION_MESSAGE.PRIVACY_INFO"
                | translate}}</b>
            </ion-text>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-button class="btn large" color="primary" (click)="onSubmit()"
            [disabled]="(type == FormType.Register && this.form.form.invalid) || (!contractAccepted)">
            {{ submitButton.label }}</ion-button>
        </ion-row>
      </ng-container>

      <div *ngIf="type === FormType.Register || type === FormType.Login" class="pt-8" scrollable="false">
        <ion-row class="pb-4 pt-4" *ngIf="type === FormType.Register">
          <ion-col><img src="assets/icon/vector.svg"></ion-col>
          <ion-col style="font-size: 0.8rem;" size="auto">
            {{ 'LOGIN.OR_LOGIN_WITH' | translate }}
          </ion-col>
          <ion-col style="text-align: end;"><img src="assets/icon/vector.svg"></ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button class="grow" color="primary" fill="outline" expand="full" shape="round"
              (click)="onGoogleLogin()">
              <ion-icon name="logo-google"></ion-icon>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button class="grow" color="primary" fill="outline" expand="full" shape="round"
              (click)="onAppleLogin()">
              <ion-icon name="logo-apple"></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </div>

      <ion-row *ngIf="back" class="yf-container centered" style="margin-top: 15px;">
        <a style="font-size: 0.8rem" (click)="changeForm.emit(back.type)" href="#">{{ back.text }}</a>
      </ion-row>

      <ng-container *ngIf="type == FormType.Login">
        <ion-row class="pt-8 pb-4">
          <ion-col><img src="assets/icon/vector.svg"></ion-col>
          <ion-col style="font-size: 0.8rem;" size="auto">
            {{ 'LOGIN.DONT_HAVE_AN_ACCOUNT' | translate}}
          </ion-col>
          <ion-col style="text-align: end;"><img src="assets/icon/vector.svg"></ion-col>
        </ion-row>
        <ion-row>
          <ion-button class="btn large" color="primary" fill="outline" (click)="changeForm.emit(FormType.Register)" href="#">
            {{ registerButton.label }}</ion-button>
        </ion-row>
      </ng-container>

    </ion-grid>
  </ion-card-content>
</ion-card>
