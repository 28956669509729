import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ICar, IUser } from 'common_library';
import { CoreService } from 'src/app/services/core.service';

import { AuthService } from '../../services/auth/auth.service';
import { CarPage } from '../car/car.page';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

type UserFormType = {
  email: string;
  name?: string;
  surname?: string;
};

type SettingsFormType = {
  disabled?: boolean;
  canUploadPhotos?: boolean;
  canReview?: boolean;
};

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.page.html',
    styleUrls: ['./user-profile.page.scss'],
    standalone: false
})
export class UserProfilePage {
  constructor(
    public modalCtrl: ModalController,
    public auth: AuthService,
    public CS: CoreService,
    public mainStateService: MainStateService
  ) { }

  async addVehicle() {
    const addVehicleModal = await this.modalCtrl.create({
      component: CarPage,
      cssClass: 'yf-modal',
    });
    addVehicleModal.present();
  }

  async editVehicle(car: ICar) {
    const editVehicleModal = await this.modalCtrl.create({
      component: CarPage,
      componentProps: { car },
      cssClass: 'yf-modal',
    });
    editVehicleModal.present();
  }

  async closeMe() {
    await this.modalCtrl.dismiss();
  }
}
