/* src/app/components/waiting-toast/waiting-toast.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.backdrop {
  position: fixed;
  inset: 0;
  z-index: 100000;
  background-color: #0d2c40;
  opacity: 0.6;
}
.waiting-toast-container {
  position: fixed;
  z-index: 100001;
  bottom: 24px;
  max-height: calc(100vh - 80px - 24px);
  background: #fff;
  box-shadow: var(--yf-shadow);
  border-radius: var(--yf-border-radius);
  padding: 24px;
  gap: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.waiting-toast-container .title {
  font-size: 18px;
  font-weight: 600;
  color: var(--ion-color-primary);
}
.waiting-toast-container .message {
  font-size: 12px;
  font-weight: 400;
  color: var(--ion-color-medium);
}
.waiting-toast-container ion-progress-bar {
  height: 8px;
}
@media (max-width: 576px) {
  .waiting-toast-container {
    right: var(--yf-spacing1);
    left: var(--yf-spacing1);
    max-width: 360px;
  }
}
@media (min-width: 576px) {
  .waiting-toast-container {
    left: 50%;
    transform: translate(-50%);
    width: 360px;
  }
}
/*# sourceMappingURL=waiting-toast.component.css.map */
