import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { IRfid, IUpdateRfidDTO } from 'common_library';
import { BehaviorSubject, filter, Subscription } from 'rxjs';
import { CarService } from 'src/app/services/entities/car.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
    selector: 'yf-rfid-form',
    templateUrl: './rfid-form.component.html',
    styleUrls: ['./rfid-form.component.scss'],
    standalone: false
})
export class RfidFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedRfid: IRfid;
  rfid: IRfid
  @Output() submitForm = new EventEmitter<IUpdateRfidDTO>();
  @Output() validityBtn = new EventEmitter<any>();
  @Output() cancelBtnEvent = new EventEmitter<any>();
  model$ = new BehaviorSubject<{ name: string, carId: number }>(null);
  selectedRfid$ = new BehaviorSubject<IRfid>(null);

  sub: Subscription;

  form: UntypedFormGroup = new UntypedFormGroup({});
  options: FormlyFormOptions = {};

  fields: FormlyFieldConfig[] = this.getUpdatedFields();

  private langChangeSubscription: Subscription;

  constructor(
    private carService: CarService,
    private mainStateService: MainStateService,
    private translate: TranslateService) {

    this.langChangeSubscription = this.translate.onLangChange.subscribe(() => {
      this.fields = this.getUpdatedFields();
    });
  }
  ngOnInit(): void {
    this.sub = this.selectedRfid$.pipe(
      filter(rfid => !!rfid),
    ).subscribe((rfid) => {
      this.model$.next(
        {
          name: rfid?.name,
          carId: rfid?.carId
        }
      )

      this.fields.forEach(element => {
        element.formControl?.markAsUntouched();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedRfid?.currentValue?.id !== changes.selectedRfid?.previousValue?.id) {
      this.selectedRfid$.next(this.selectedRfid);
    }
  }

  getUpdatedFields(): FormlyFieldConfig[] {
    return [
      {
        fieldGroup: [
          {
            key: "name",
            type: "f-input",
            templateOptions: {
              label: this.translate.instant("RFID.RFID_NAME"),
              type: 'text',
              required: true,
              maxLength: 12,
              placeholder: this.translate.instant("RFID.INSERT_NAME")
            },
            validators: {
              existingName: {
                expression: (c: AbstractControl) => {
                  return !(this.mainStateService.getUserRfids().some((a) => a.name === c.value && this.selectedRfid$.value?.id !== a.id));
                },
                message: () => this.translate.instant("RFID.RFID_NAME_ALREADY_EXIST"),
              }
            }
          },
          {
            key: "carId",
            type: "f-select",
            props: {
              valueProp: 'id',
              labelProp: 'name',
              type: null,
              label: this.translate.instant("RFID.RFID_VEHICLE"),
              required: true,
              placeholder: this.translate.instant("RFID.SELECT_VEHICLE"),
              options: this.carService.$simpleUserCars
            },
          }
        ],
        fieldGroupClassName: "fg"
      }
    ];
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.langChangeSubscription.unsubscribe();
  }

  submit() {
    const rfid: IUpdateRfidDTO = {
      id: this.selectedRfid$.value.id,
      name: this.form.get('name').value as string,
      carId: this.form.get('carId').value,
    }
    this.submitForm.emit(rfid);
  }

  cancelBtn() {
    let oldVersion = this.mainStateService.getUserRfids().find(c => c.id === this.selectedRfid$.value.id);
    this.selectedRfid$.next(oldVersion);
  }
}
