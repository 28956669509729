"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPITariffType = void 0;
exports.OCPITariffType = Object.freeze({
  AD_HOC_PAYMENT: 'AD_HOC_PAYMENT',
  PROFILE_CHEAP: 'PROFILE_CHEAP',
  PROFILE_FAST: 'PROFILE_FAST',
  PROFILE_GREEN: 'PROFILE_GREEN',
  REGULAR: 'REGULAR'
});
