<ion-header>
  <yf-header
    *ngIf="car"
    [label]="'Modifica Veicolo'"
    buttonPosition="left"
    (leftButtonEvent)="closeMe()"></yf-header>
  <yf-header
    *ngIf="!car"
    [label]="'Aggiungi Veicolo'"
    buttonPosition="left"
    (leftButtonEvent)="closeMe()"></yf-header>
</ion-header>
<ion-content class="yf-content-padding">
  <ion-spinner *ngIf="isLoading"></ion-spinner>
  <div *ngIf="!isLoading" class="yf-container gap-12">
    <form *ngIf="!!carFrm" [formGroup]="carFrm.form">
      <formly-form
        [model]="carFrm.model"
        [fields]="carFrm.fields"
        [options]="carFrm.options"
        [form]="carFrm.form"></formly-form>
    </form>
    <app-car-details [car]="car"></app-car-details>
  </div>
  <!-- <pre>{{ this.carFrm?.model | json}}</pre> -->
</ion-content>
<ion-footer class="ion-no-border yf-footer-padding">
  <div class="yf-container gap-6">
    <ion-button class="btn large grow" color="secondary" (click)="car ? updateCar(car) : addCar()"
      >{{'BUTTONS.SAVE_VEHICLE'|translate}}</ion-button
    >
    <ion-button *ngIf="car" class="btn large grow" color="danger" (click)="deleteCar()"
      >{{'BUTTONS.REMOVE_VEHICLE'|translate}}
      <ion-icon slot="start" src="assets/app-icons/elimina.svg"></ion-icon>
    </ion-button>
  </div>
</ion-footer>

<!-- <ion-fab *ngIf="!viewAddCar" vertical="bottom" horizontal="end" slot="fixed">
    <ion-fab-button color="secondary" (click)="addNewCar()">
      <ion-icon name="add"></ion-icon>
    </ion-fab-button>
  </ion-fab> -->

<!-- <app-my-form-simple
      *ngIf="!car"
      [header]="{title:'', subtitle:  ''}"
      [submitButton]="{label: 'Aggiungi', icon: 'add-outline'}"
      [form]="this.carFrm"
      (submitEvent)="addCar()"></app-my-form-simple> -->
<!-- <app-my-form-simple
      *ngIf="car"
      [header]="{title:'', subtitle:  ''}"
      [submitButton]="{label: 'Modifica', icon: 'pencil-outline'}"
      [form]="this.carFrm"
      (submitEvent)="updateCar(car)"></app-my-form-simple> -->

<!-- <div *ngIf="!viewAddCar" class="container">
    <div class="autoCard" *ngFor="let car of carService.myCars$ | async">
      <div class="headerContainer">
        <ion-card-header style="width: 60%">
          <ion-card-subtitle>{{car?.name}}</ion-card-subtitle>
          <ion-card-title>{{car?.model?.brand.name}}</ion-card-title>
          <ion-card-subtitle>{{car?.model?.name}}</ion-card-subtitle>
        </ion-card-header>
        <div class="brandLogo" style="width: 40%; margin: 0 16px">
          <img
            style="width: 50px; height: 50px; margin-right: 25%"
            src="assets/brands/{{car?.model?.brand.name.toLowerCase()}}.png"
            alt="" />
        </div>
        <ion-button class="editBtn" (click)="(formGenerate(car))">
          <ion-icon slot="icon-only" color="primary" name="create-outline"></ion-icon>
        </ion-button>
      </div>

      <div class="detailContainer">
        <div class="rowDetails">
          <ion-label>Batteria: </ion-label>
          <p><b>{{ car?.model?.battery }} kWh</b></p>
          <ion-label>Autonomia:</ion-label>
          <p><b>{{ car?.model?.range }} Km</b></p>
        </div>
        <div class="dividerLineContainer">
          <div class="dividerLine"></div>
          <div class="dividerLine"></div>
        </div>
        <div class="rowDetails">
          <ion-label>Velocità ricarica:</ion-label>
          <p><b>{{ car?.model?.chargeSpeed }} km/h</b></p>
          <ion-label>Efficienza:</ion-label>
          <p><b>{{ car?.model?.efficiency }} Wh/Km</b></p>
        </div>
        <div class="dividerLineContainer">
          <div class="dividerLine"></div>
          <div class="dividerLine"></div>
        </div>
        <div class="rowDetails">
          <ion-label>Segmento:</ion-label>
          <p><b>{{ car?.model?.segment }}</b></p>
          <ion-label>Posti:</ion-label>
          <p><b>{{ car?.model?.seats }}</b></p>
        </div>
        <div class="dividerLineContainer">
          <div class="dividerLine"></div>
          <div class="dividerLine"></div>
        </div>
        <div class="rowDetails">
          <ion-label>Velocità massima:</ion-label>
          <p><b>{{ car?.model?.speed }} km/h</b></p>
          <ion-label>Accellerazione:</ion-label>
          <p><b>{{ car?.model?.acceleration }}"</b></p>
        </div>
        <div class="dividerLineContainer">
          <div class="dividerLine"></div>
          <div class="dividerLine"></div>
        </div>
        <div class="rowDetails">
          <ion-label>Tipo plug: <b *ngFor="let plug of car?.plugTypes">{{ plug }} </b> </ion-label>
        </div>
        <div class="rowDetails"></div>
      </div>
    </div>
  </div> -->
