<div class="formly-input-container">
  <ion-item>
    <ion-select [multiple]="to.multiple" [formControl]="ngControl" label="{{ to.label }} {{ to.required ? '' : '' }}"
    labelPlacement="floating">
      <ion-select-option *ngFor="let option of myOptions$ | async" [value]="option.id">{{
        option.name
        }}</ion-select-option>
    </ion-select>
  </ion-item>
  <span *ngIf="formControl.touched" class="input-error">
    <ion-text color="danger">
      <formly-validation-message [field]="field"></formly-validation-message>
    </ion-text>
  </span>
</div>
