<ion-header>
  <yf-header
    label="{{'BUTTONS.EDIT_PROFILE'|translate}}"
    buttonPosition="left"
    (leftButtonEvent)="closeMe()"></yf-header>
</ion-header>
<ion-content *ngIf="this.mainStateService.$user()" class="yf-content-padding">
  <div class="yf-container centered yf-padding">
    <img src="assets/logos/logo_colore_yourfill.svg" style="width: 80px" />
  </div>
  <div class="yf-container" style="gap: 36px">
    <ion-list style="width: 100%">
      <form *ngIf="!!userFrm" [formGroup]="userFrm.form">
        <formly-form
          [model]="userFrm.model"
          [fields]="userFrm.fields"
          [options]="userFrm.options"
          [form]="userFrm.form"></formly-form>
      </form>
    </ion-list>
    <ion-list style="width: 100%">
      <ion-text color="primary"><h5 style="font-weight: 600">Modifica Password</h5></ion-text>
      <form *ngIf="!!userFrm" [formGroup]="passwordFrm.form">
        <formly-form
          [model]="passwordFrm.model"
          [fields]="passwordFrm.fields"
          [options]="passwordFrm.options"
          [form]="passwordFrm.form"></formly-form>
      </form>
    </ion-list>
  </div>
</ion-content>
<ion-footer class="ion-no-border yf-footer-padding">
  <div class="yf-containter centered">
    <ion-button color="secondary" class="btn large grow" (click)="update()"> {{'BUTTONS.SAVE'|translate}}</ion-button>
  </div>
</ion-footer>

<!-- <ion-accordion-group>
      <ion-accordion value="colors">
        <ion-item slot="header">
          <ion-label>Impostazioni</ion-label>
        </ion-item>
        <ion-list slot="content">
          <form *ngIf="!!settingsFrm" [formGroup]="userFrm.form">
            <formly-form
              [model]="settingsFrm.model"
              [fields]="settingsFrm.fields"
              [options]="settingsFrm.options"
              [form]="settingsFrm.form"></formly-form>
          </form>
        </ion-list>
      </ion-accordion>
    </ion-accordion-group> -->
