<ion-header *ngIf="chapterToAccept$ | async as chapter">
  <ion-toolbar>
    <ion-title> {{chapter?.title}} </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content [fullscreen]="true" class="ion-padding" *ngIf="chapterToAccept$ | async as chapter">
  <div *ngFor="let section of chapter.infoSections">
    @if(section.subtitle){
    <div class="mt-4 section-subtitle">{{ section.subtitle }}</div>
    }
    @if(section.multimedia){
    <div class="mt-10 section-img"><img width="auto" height="auto"
        [src]="getImageUrl(section.multimedia?.name + '.' + section.multimedia?.fileExtension)"
        [alt]="section.multimedia?.name">
    </div>
    }
    @if(section.html){
    <div class="mt-10 section-html" [innerHTML]="section.html"></div>
    }
  </div>
</ion-content>
<ion-footer collapse="fade" class="ion-no-border">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        @if(showCancelButton){
        <ion-col> <ion-button fill="outline" color="primary" class="btn large grow" (click)="cancel()">{{"BUTTONS.DENY"
            | translate}}</ion-button></ion-col>
        }
        <ion-col> <ion-button color="primary" class="btn large grow"
            (click)="update()">{{confirmButtonText$|async}}</ion-button></ion-col>
      </ion-row>
    </ion-grid></ion-toolbar>
</ion-footer>