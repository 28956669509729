"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ILevelLog = void 0;
exports.ILevelLog = Object.freeze({
  INFO: "INFO",
  WARNING: "WARNING",
  ERROR: "ERROR",
  DEBUG: "DEBUG"
});
