"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StationType = void 0;
exports.StationType = Object.freeze({
  Unknown: 'unknown',
  Type1: 'tipo1',
  Type2: 'tipo2',
  Type3: 'tipo3'
});
