<div class="user-card-container yf-row">
  <ion-thumbnail class="yf-container centered">
    <ion-spinner color="light" *ngIf="isLoading"></ion-spinner>
    <img
      *ngIf="!isLoading"
      #userPhoto
      [src]="src"
      onerror="this.onerror=null; this.src='assets/images/User-Placeholder.png'" />
    <ion-button class="upload-button only-icon-button" color="light" (click)="uploadUserPhoto()">
      <ion-icon src="assets/app-icons/upload.svg" color="secondary"></ion-icon>
    </ion-button>
  </ion-thumbnail>
  <div class="yf-container yf-padding grow">
    <div class="yf-container grow" style="gap: 3px">
      <ion-text color="light"
        ><h5>{{ user?.name }} {{ user?.surname }}</h5></ion-text
      >
      <ion-text color="light"
        ><p>{{ user?.email }}</p></ion-text
      >
      <ion-text color="light"
        ><small>{{ user?.id }}</small></ion-text
      >
    </div>
    <div class="yf-row spaced">
      <ion-icon class="user-icons" src="assets/app-icons/check.svg" color="tertiary"></ion-icon>
      <!-- <ion-button class="btn square transparent" (click)="editProfile()"> -->
      <ion-icon
        class="user-icons"
        src="assets/app-icons/edit.svg"
        color="light"
        (click)="editProfile()"
        button></ion-icon>
      <!-- </ion-button> -->
    </div>
  </div>
</div>
