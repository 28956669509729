"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChapterType = void 0;
exports.ChapterType = Object.freeze({
  PRIVACY: 'PRIVACY',
  TERMS_AND_CONDITIONS: 'TERMS AND CONDITIONS',
  CONTACTS: 'CONTACTS',
  PAYMENT_INSTRUCTIONS: 'PAYMENT INSTRUCTIONS',
  ACCOUNT_DELETION: 'ACCOUNT DELETION'
});
