/* src/app/my-formly/components/credit-card/formly-credit-card-component.scss */
.formly-input-container {
  padding-bottom: 8px;
  margin-bottom: 8px;
  position: relative;
}
input {
  border: none;
  border-bottom: 2px;
  border-color: #aeaeaf;
  margin: 0 8px;
  height: 36px;
  font-size: 16px;
  background: transparent;
  outline-width: 0px;
  outline: none;
}
input::placeholder {
  color: #aeaeaf;
  margin-left: 2px;
}
p {
  text-align: center;
}
p.valid {
  color: rgb(0, 198, 0);
}
p.invalid {
  color: red;
}
/*# sourceMappingURL=formly-credit-card-component.css.map */
