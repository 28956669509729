"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.QuickFilter = void 0;
exports.QuickFilter = Object.freeze({
  Disponibile: 'Disponibile',
  YourFill: 'YourFill',
  H24: 'H24',
  Pubblico: 'Pubblico',
  Privato: 'Privato',
  Ospiti: 'Ospiti'
});
