/* src/app/components/text-scan/text-scan.component.scss */
.content {
  position: relative;
  height: 100%;
}
.video-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: -1;
  overflow: hidden;
}
.overlay-rectangle {
  position: absolute;
  top: 40%;
  left: 50%;
  width: 90%;
  height: 10%;
  transform: translate(-50%, -50%);
  border: 8px dashed #ffffff;
  -webkit-transform: translate(-50%, -50%);
  box-sizing: border-box;
  pointer-events: none;
}
video {
  object-fit: cover;
}
.yf-text-center {
  text-align: center;
}
ion-item {
  margin-top: 10px;
}
ion-card {
  background: white;
  color: black;
}
ion-content {
  --background: transparent;
}
.content-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
}
/*# sourceMappingURL=text-scan.component.css.map */
