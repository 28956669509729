<div class="yf-row wrap spaced">
  @for(cpo of cpos; track cpo.key){
    @if(cpo.value !== '???'){
      <ion-button
        fill="clear"
        class="cpo-button"
        (click)="selectCpo(cpo.value)"
        [style]="{ opacity: selectedCpos.includes(cpo.value) ? '1' : '0.5' }">
        <div class="yf-container centered gap-6">
          <ion-icon
            [src]="cpo.image"
            [color]="selectedCpos.includes(cpo?.value) ? '' : ''"></ion-icon>
          <ion-text class="fs-12" [color]="selectedCpos.includes(cpo.value) ? 'secondary' : 'medium'">{{cpo.value}}</ion-text>
        </div>
      </ion-button>
    }
  }
</div>
