<div class="formly-input-container">
    <ion-item>
        <ion-label position="floating" color="medium">{{ to.label }} {{ to.required ? '*' : '' }}</ion-label>
        <input [formControl]="ngControl" mask="000"
            placeholder="CVV" [minlength]="to.minLength">

    </ion-item>

    <div *ngIf="ngControl.dirty">
        <p [class.invalid]="ngControl.invalid"></p>
        <p [class.valid]="ngControl.valid"></p>
    </div>
</div>