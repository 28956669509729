import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'yf-cdc-waiting',
    templateUrl: './cdc-waiting.component.html',
    styleUrls: ['./cdc-waiting.component.scss'],
    standalone: false
})
export class CdcWaitingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
