/* src/app/components/installation-header/installation-header.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.header-container {
  height: 90px;
  padding: var(--yf-spacing1);
}
.installation-white-stars {
  fill: white;
  stroke: white;
}
.installation-black-stars {
  fill: var(--ion-color-secondary);
  stroke: var(--ion-color-secondary);
}
.yf-container {
  cursor: pointer;
}
/*# sourceMappingURL=installation-header.component.css.map */
