/* src/app/components/logo-thumbnail/logo-thumbnail.component.scss */
.logo-container {
  background: var(--ion-color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.logo-container .logoImg {
  padding: 20%;
}
/*# sourceMappingURL=logo-thumbnail.component.css.map */
