<ion-header>
  <ion-toolbar>
    <ion-title>QR Code</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div #square class="square"></div>
  <!-- Lascio la torcia forse un giorno servirà -->
  <!-- @if (isTorchAvailable) {
  <ion-fab slot="fixed" horizontal="end" vertical="bottom">
    <ion-fab-button (click)="toggleTorch()">
      <ion-icon name="flashlight"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  } -->
  <!-- <div class="text-fallback-bottom">
    <p class="yf-text-center">oppure</p>
    <ion-item>
      <ion-input type="text" [(ngModel)]="text"></ion-input>
    </ion-item>
  </div> -->
  <div class="content-wrapper">
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>{{'QR_OCR.OR' | translate}}</ion-card-subtitle> 
      </ion-card-header>
      
      <ion-card-content> 
        <ion-label class="yf-text-center">{{'QR_OCR.TEXT' | translate}}</ion-label>
        <ion-item>
          <ion-input type="text" [(ngModel)]="text"></ion-input>
        </ion-item> 
        <ion-button expand="block" class="btn-avanti" (click)="stopScan()">
          {{'BUTTONS.NEXT' | translate}}
        </ion-button> 
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>