<ion-card class="yf-container toast-card" (click)="openDetails($event)">
  <div class="mb-2 pl-12 pr-12 pt-12 pb-8" style="background-color: var(--ion-color-secondary)">
    <!-- TODO: Componentizzare sotto -->
    <ion-grid style="padding: 0px!important;">
      <ion-row class="pb-10">
        <!-- INSTALLATION NAME -->
        <div class="yf-row gap-6">
          <!-- <ion-icon slot="start" color="light" name="map-outline" class="fs-24"></ion-icon> -->
          <ion-text color="light" class="fs-16">{{ $currentSession()?.connector?.station?.installation?.name
            }}</ion-text>
        </div>
      </ion-row>

      <ion-row>
        <ion-col class="ion-no-padding">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <!-- LOGO  -->
              <ion-col size="auto" class="align-left logoColumn">
                <yf-logo-thumbnail class="m-0" size="50px" [stationCpo]="$stationIcon()"></yf-logo-thumbnail>
              </ion-col>
              <!-- COST INFO -->
              <ion-col class="priceColumn">
                <yf-cost-info [$session]="$currentSession()" [$class]="'costText'">
                </yf-cost-info>
              </ion-col>
            </ion-row>

            <ion-row class="mt-12">
              <!-- STATION NAME -->
              <ion-col>
                <div class="yf-row center gap-6">
                  <ion-icon slot="start" color="light" src="assets/stations/ev-charging-station.svg"
                    class="detailTextStation"></ion-icon>
                  <ion-text color="light" class="detailTextStation">{{
                    $currentSession()?.updatedBy === 'ENX' ? 'ENELX' :
                    $currentSession()?.updatedBy === 'F2X' ? 'Free To X':
                    $currentSession()?.connector.station.name}}</ion-text>
                </div>
              </ion-col>

              <!-- PLUG TYPE -->
              <ion-col>
                <div class="yf-row center gap-6">
                  <ion-icon slot="start" color="light"
                    src="assets/plugTypes/presa_{{$currentSession()?.connector.plugTypes[0].toLowerCase()}}.svg"
                    class="detailTextStation"></ion-icon>
                  <ion-text color="light" class="detailTextStation">{{$currentSession()?.connector.name || '#' +
                    $currentSession()?.connector.cpConnectorId}}</ion-text>
                </div>
              </ion-col>
            </ion-row>

            <ion-row class="mt-8">
              <!-- RESERVATION INFO -->
              @if($currentSession()?.state === 2){
              <ion-col size="auto">
                <yf-reservation-text [$currentSession]="$currentSession()" [$textClass]="'detailText'">
                </yf-reservation-text>
              </ion-col>
              }

              <!-- CHARGE INFO -->
              @if($currentSession()?.state === 3){
              @if($currentSession()?.soc && $currentSession()?.soc>0){
              <ion-col>
                <ion-grid class="ion-no-padding">
                  <ion-row class="ion-no-padding ion-justify-content-between">
                    <ion-col size="auto">
                      <yf-charge-info [$energy]="$currentSession()?.energy" [$textClass]="'detailText'"
                        [$umClass]="'detailUmText'">
                      </yf-charge-info>
                    </ion-col>
                    <ion-col size="auto" class="pl-8 pr-8">
                      <yf-charge-percentage-info [$percentage]="$currentSession()?.soc" [$textClass]="'detailText'"
                        [$umClass]="'detailUmText'">
                      </yf-charge-percentage-info>
                    </ion-col>
                    <ion-col size="auto" class="pr-16">
                      <yf-charge-speed-info [$power]="$currentSession()?.power" [$textClass]="'detailText'"
                        [$umClass]="'detailUmText'">
                      </yf-charge-speed-info>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              }
              @else{
              <ion-col>
                <yf-charge-info [$energy]="$currentSession()?.energy" [$textClass]="'detailText'"
                  [$umClass]="'detailUmText'">
                </yf-charge-info>
              </ion-col>
              <ion-col>
                <yf-charge-speed-info [$power]="$currentSession()?.power" [$textClass]="'detailText'"
                  [$umClass]="'detailUmText'">
                </yf-charge-speed-info>
              </ion-col>
              }
              }

              @if($currentSession()?.state === 4){
              <!-- PARKING INFO -->
              <ion-col size="auto">
                <yf-parking-text [$currentSession]="$currentSession()" [$textClass]="'detailText'">
                </yf-parking-text>
              </ion-col>
              }
            </ion-row>
          </ion-grid>
        </ion-col>
        <!-- BUTTONS -->
        <ion-col class="yf-container align-right m-0" size="auto" style="padding: 0px; display: flex; gap: 8px;">
          <!-- PORTA SU GOOGLE -->
          <ion-button class="btn square-50 navigate-btn mt-12" (click)="navigateTo($event)">
            <ion-icon slot="icon-only" src="assets/app-icons/navigazione.svg" color="light"
              class="bigger-icon"></ion-icon>
          </ion-button>

          <!-- PORTA SULLA STATION IN MAPPA -->
          <ion-button class="btn square-50 centra-btn p-0" (click)="showOnMap($event)">
            <ion-icon slot="icon-only" src="assets/app-icons/centra_marker.svg" color="light"></ion-icon>
          </ion-button>

        </ion-col>
      </ion-row>
    </ion-grid>
  </div>

  <!-- STATO SOTTO -->
  <yf-state-simple-timer [$currentSession]="$currentSession()">
  </yf-state-simple-timer>
</ion-card>