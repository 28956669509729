import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Gesture, GestureController, GestureDetail } from '@ionic/angular';

@Component({
    selector: 'yf-cheddar',
    templateUrl: './cheddar.component.html',
    styleUrls: ['./cheddar.component.scss'],
    standalone: false
})
export class CheddarComponent implements OnInit {

  @ViewChild('cheddarContainer') cheddar: ElementRef;
  @Output() cheddarClick: EventEmitter<GestureDetail> = new EventEmitter<GestureDetail>();

  constructor(private gestureController: GestureController) { }

  ngOnInit() {}

  ngAfterViewInit() {
    const gesture: Gesture = this.gestureController.create({
      el: this.cheddar.nativeElement,
      gestureName: 'swipe-down',
      threshold: 30,
      direction: 'y',
      onMove: (detail) => this.manageGesture(detail),
    }, true)

    gesture.enable(true);
  }

  private manageGesture(detail: GestureDetail) {
    this.cheddarClick.emit(detail);
  }
}
