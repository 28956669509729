import { Component, computed } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { UiService } from 'src/app/services/utils/ui.service';
import { TariffUtil, OCPITariffDimension } from 'common_library';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from 'src/app/services/translate.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

export interface TariffDescription {
  reservationDescription: "";
  parkingDescription: "";
}

@Component({
    selector: 'yf-station-info',
    templateUrl: './station-info.component.html',
    styleUrls: ['./station-info.component.scss'],
    standalone: false
})
export class StationInfoComponent {
  $tariffDescription = computed(() => {
    let tariff = this.mainStateService.$selectedConnector()?.tariff;
    if (tariff) {
      let descriptions;
      const lang = this.translateConfigService.getCurrentLang();
      descriptions = TariffUtil.getTariffAltTextForConnectorPower(tariff, lang, TariffUtil.getConnectorPower(this.mainStateService.$selectedConnector()));
      const tariffDescription: TariffDescription = {
        reservationDescription: descriptions.find(c => c.dimension === OCPITariffDimension.RESERVATION)?.description,
        parkingDescription: descriptions.find(c => c.dimension === OCPITariffDimension.PARKING_TIME)?.description
      }
      return tariffDescription;
    }
    return null;
  })

  constructor(
    public UI: UiService,
    public CS: CoreService,
    public mainStateService: MainStateService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService) { }
}
