import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'yf-logo-thumbnail',
    templateUrl: './logo-thumbnail.component.html',
    styleUrls: ['./logo-thumbnail.component.scss'],
    standalone: false
})
export class LogoThumbnailComponent {
  @Input() stationCpo: string;
  @Input() size: string = "62px";


}
