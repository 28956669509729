import { ChangeDetectionStrategy, Component, OnInit, signal } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { getValidationConfigFromCardNo } from './card.helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'formly-credit-card',
    templateUrl: 'formly-credit-card-component.html',
    styleUrls: ['formly-credit-card-component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class FormlyCreditCardComponent extends FieldType implements OnInit {
  // formControl: FormControl;
  $translatedMask = signal('0000 0000 0000 0000');

  get ngControl() {
    return this.formControl as UntypedFormControl;
  }

  showPassword = false;
  creditCardLabel: string;

  constructor(
    private translate: TranslateService) {
    super();
  }

  ngOnInit() { }

  /*   getCardNumberControl(): AbstractControl | null {
    return this.form && this.form.get('cardNumber');
  } */
  cardMaskFunction(rawValue: string) {
    const card = getValidationConfigFromCardNo(rawValue);
    this.translateCardMaskFunction(card?.mask);
  }

  translateCardMaskFunction(cardMaskArray: Array<RegExp | string>) {
    let translatedMask: string;
    if (cardMaskArray) {
      translatedMask = this.addOptionalDigitToTheMask(cardMaskArray)
    }
    if (translatedMask) {
      this.$translatedMask.set(translatedMask);
    }
  }

  /**
   * Per la mask di ngx-mask '0' -> numero obbligatorio, '9' -> numero opzionale.
   * Alcune carte di credito possono avere da 12 a 19 numeri, perciò gli ultimi numeri (dopo il dodicesimo) sono popzionali, proprio perché alcune carte non li anni
   * @param cardMaskArray 
   * @returns 
   */
  addOptionalDigitToTheMask(cardMaskArray: Array<RegExp | string>): string {
    return cardMaskArray.map((regex, index) => (regex.toString() === ' ' ? ' ' : index < 15 ? '0' : '9')).join('')
  }
}
