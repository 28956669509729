/* src/app/components/debug-full/debug-full.component.scss */
div.container {
  position: absolute;
  bottom: 20px;
  right: 5px;
  z-index: 10000;
  padding: 0px;
  font-size: 9pt;
  color: rgb(233, 17, 17);
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.24);
  width: 400px;
  height: 600px;
  overflow-y: auto;
}
/*# sourceMappingURL=debug-full.component.css.map */
