import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { sliderImage } from 'src/app/services/utils/tutorial.service';

@Component({
    selector: 'app-slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.scss'],
    standalone: false
})
export class SliderComponent implements OnInit {
  @Input() images: sliderImage[] = [];
  @Input() indicators = true;
  @Input() controls = true;
  @Input() autoSlide = false;
  @Input() slideInterval = 3000;
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() modalCloseButton = false;

  selectedIndex = 0;
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    if (!!this.autoSlide) {
      this.autoSlideImages();
    }
  }
  autoSlideImages() {
    setInterval(() => {
      this.onNextClick();
    }, this.slideInterval);
  }

  selectImage(index: number) {
    this.selectedIndex = index;
  }
  onPrevClick() {
    if (this.selectedIndex === 0) {
      return;
    } else {
      this.selectedIndex--;
    }
  }
  onNextClick() {
    if (this.selectedIndex === this.images.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex++;
    }
  }

  closeMe() {
    this.modalCtrl.dismiss();
  }
}
