"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ENERGY_SOURCE_CATEGORY = void 0;
exports.ENERGY_SOURCE_CATEGORY = Object.freeze({
  NUCLEAR: "NUCLEAR",
  GENERAL_FOSSIL: "GENERAL_FOSSIL",
  COAL: "COAL",
  GAS: "GAS",
  GENERAL_GREEN: "GENERAL_GREEN",
  SOLAR: "SOLAR",
  WIND: "WIND",
  WATER: "WATER"
});
