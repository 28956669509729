"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ResetType = exports.TriggerMessage = exports.TroubleshootingRequests = void 0;
exports.TroubleshootingRequests = Object.freeze({
  Reset: 'Reset',
  GetDiagnostics: 'GetDiagnostics',
  TriggerMessage: 'TriggerMessage',
  UnlockConnector: 'UnlockConnector',
  UpdateFirmare: 'UpdateFirmare',
  SetChargingProfile: 'SetChargingProfile',
  StopSession: 'StopSession',
  GetConfiguration: 'GetConfiguration',
  ChangeConfiguration: 'ChangeConfiguration',
  DataTransfer: 'DataTransfer'
});
exports.TriggerMessage = Object.freeze({
  BootNotification: 'BootNotification',
  DiagnosticsStatusNotification: 'DiagnosticsStatusNotification',
  FirmwareStatusNotification: 'FirmwareStatusNotification',
  Heartbeat: 'Heartbeat',
  MeterValues: 'MeterValues',
  StatusNotification: 'StatusNotification'
});
exports.ResetType = Object.freeze({
  Hard: 'Hard',
  Soft: 'Soft'
});
