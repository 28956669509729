/* src/app/components/car-details/car-details.component.scss */
ion-card {
  margin: 0;
  padding: 0px;
  border-radius: 0px;
  --color: var(--ion-color-medium);
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}
/*# sourceMappingURL=car-details.component.css.map */
