<div class="yf-container installation-container"
  [style]="{ background: mainStateService.$selectedConnectorHasMineSession() ? 'var(--ion-color-secondary)' : 'var(--ion-color-light)' }">
  <div class="toast-header"
    [style]="{ background:mainStateService.$selectedConnectorHasMineSession() ? 'var(--ion-color-secondary-tint)' : 'var(--ion-color-light-shade)' }">
    <yf-cheddar (cheddarClick)="cheddarClicked($event)"></yf-cheddar>
    <yf-installation-header [$installation]="mainStateService.$selectedInstallation()"
      [currentPosition]="this.currentPosition">
    </yf-installation-header>
    <div class="ml-8 pt-10" *ngIf="!compactMode">
      <div class="yf-row mini-stations">
        <yf-mini-station *ngFor="let station of mainStateService.$selectedInstallation()?.stations" [$station]="station"
          (stationClicked)="selectStation(station)">
        </yf-mini-station>
      </div>
    </div>
  </div>

  <div>
    <div [@openCompact]="manageAnimation()">
      @if(mainStateService.$selectedStation()){
      <div class="yf-dflex">
        <yf-station-connector-selector class="connector-selector">
        </yf-station-connector-selector>
      </div>
      <div>
        @if(mainStateService.$selectedConnectorHasMineSession()){
        <yf-cost-detail></yf-cost-detail>
        }
        @else {
        <yf-station-info></yf-station-info>
        }
        <yf-station-buttons [isMine]="mainStateService.$selectedConnectorHasMineSession()"
          (clickButton)="sendCmd($event)">
        </yf-station-buttons>
      </div>
      }
    </div>
    @if(mainStateService.$selectedConnectorHasMineSession()){
    <yf-state-simple-timer [$currentSession]="mainStateService.$selectedConnectorMineSession()">
    </yf-state-simple-timer>
    }
    @else if(compactMode){
    <yf-state-simple [isInstallation]="true"></yf-state-simple>
    }
  </div>

</div>