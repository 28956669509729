import { Injectable, Inject } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { IAuthState, IChangePasswordDTO, IError, ILoginDTO, IOnlyEmailDTO, IRefreshTokenDTO, IRegisterDTO, IUser, ILoginSocialDTO, Language } from 'common_library';
import { interval, merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { _ } from '../../consts';
import { GLOBAL_RX_STATE, GlobalState } from '../../app.module';
import { StoreService } from '../utils/store.service';
import { HttpIoService } from '../communication/http-io.service';
import { Capacitor } from "@capacitor/core";
import { FirebaseAuthentication, SignInResult } from '@capacitor-firebase/authentication';
import { ModalController } from '@ionic/angular';
import { MainState } from 'src/app/types/state/app-main-state.interface';
import { MainStateService } from '../state/app-main-state.service';

export interface IAppleUser {
  email?: string,
  identityToken?: string,
  familyName?: string,
  user?: string,
  givenName?: string,
  authorizationCode?: string
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _authState: IAuthState;

  constructor(
    @Inject(GLOBAL_RX_STATE) private globalState: RxState<GlobalState>,
    private store: StoreService,
    private HIO: HttpIoService,
    public modalCtrl: ModalController,
    private mainStateService: MainStateService
  ) {
    this._authState = this.store.get<IAuthState>(_.AUTHSTATE_KEY);
    const backVisibleEvent$ = this.globalState.select("visible").pipe(filter(v => !!v));
    const backOnlineEvent$ = this.globalState.select("mainState").pipe(filter(ms => ms === MainState.ONLINE));
    const needToRefreshToken$ = merge(backVisibleEvent$, backOnlineEvent$, interval(10 * 60 * 1000)); // quando torno visibile o online oppure ogni 10 minuti
    needToRefreshToken$.subscribe(this.refresh.bind(this));
  }

  get authState(): IAuthState {
    return this._authState;
  }

  set authState(value: IAuthState) {
    this._authState = value;
    if (!!value) {
      this.store.set(_.AUTHSTATE_KEY, value);
      this.mainStateService.setUser(value.user);
    }
    else {
      this.store.remove(_.AUTHSTATE_KEY);
      this.mainStateService.setUser(null);
    }
  }

  get isAuthenticated(): boolean {
    return !!this.authState ? !!this.authState.user : false;
  }

  async refresh() {
    if (!!this.authState) {
      try {
        console.log("REFRESH TOKEN", this.authState.refreshToken.substring(this.authState.refreshToken.length - 4));
        //TODO: AGGIUNGERE USER AGENT O VALORE ENUM
        const platformType = this.mainStateService.getPlatformType();
        const dto: IRefreshTokenDTO = { refreshToken: this.authState.refreshToken, clientId: this.mainStateService.getClientId(), userAgent: navigator.userAgent, platformType };
        const rv = await this.HIO.post<IAuthState | IError, IRefreshTokenDTO>('auth/refresh', dto);
        if ('code' in rv) {
          console.log("REFRESH TOKEN FAILED", rv);
          this.authState = null;
        } else {
          console.log("REFRESH TOKEN SUCCESS", rv.refreshToken.substring(rv.refreshToken.length - 4));
          this.authState = rv;
        }
      }
      catch (err) {
        console.log('refresh error', err);
      }
    }
  }

  async login(email: string, password: string): Promise<IUser> {
    try {
      const platformType = this.mainStateService.getPlatformType();
      const dto: ILoginDTO = { email, password, clientId: this.mainStateService.getClientId(), userAgent: navigator.userAgent, platformType };
      this.authState = await this.HIO.post<IAuthState, ILoginDTO>('auth/login', dto);
      return this.authState?.user;
    } catch (error) {
      console.log('login error', error);
    }
  }

  // per ora il silentLogout è identico al logout
  async silentLogoutIfLogged() {
    await this.logout();
  }

  async logout(): Promise<void> {
    try {
      if (!!this.authState) {
        await this.HIO.get<boolean>('auth/logout');
        this.authState = null;
      }
    } catch (err) {
      console.log('logout error', err);
    }
  }

  async register(email: string, password: string, name: string, surname: string, lang: Language): Promise<boolean | IError> {
    try {
      const dto: IRegisterDTO = { email, password, name, surname, lang };
      return await this.HIO.post<boolean, IRegisterDTO>(`auth/register`, dto);
    } catch (err) {
      console.log('register error', err);
    }
  }

  async forgotPassword(email: string): Promise<boolean> {
    try {
      const dto: IOnlyEmailDTO = { email };
      return await this.HIO.post<boolean, IOnlyEmailDTO>(`auth/forgotPw`, dto);
    } catch (err) {
      console.log('forgotPassword error', err);
    }
  }

  async changePassword(email: string, password: string): Promise<boolean> {
    try {
      const dto: IChangePasswordDTO = { email, password };
      return await this.HIO.post<boolean, IChangePasswordDTO>(`auth/changePw`, dto);
    } catch (error) {
      console.log('changePassword error', error);
    }
  }

  async openGoogleSignIn(): Promise<IUser | IError> {
    const platformType = this.mainStateService.getPlatformType();
    if (Capacitor.getPlatform() === 'web') {
      //Se l'integrazione è sufficiente, si può asciare tutto insieme
      try {
        const result: SignInResult = await FirebaseAuthentication.signInWithGoogle();

        if (!result) return { code: 10, message: 'Login non riuscito' }

        const dto: ILoginSocialDTO = {
          clientId: this.mainStateService.getClientId(),
          userAgent: navigator.userAgent,
          signInResponse: result,
          platformType
        }

        const rv: IAuthState | IError = await this.HIO.post<any, any>(`firebase/google-login/`, dto);

        if ('code' in rv) return rv;
        this.authState = rv;

        return this.authState?.user;
      } catch (err) {
        if (err.code === 'auth/popup-closed-by-user') return { code: 10, message: 'Procedura di login annullata' }
        return { code: 10, message: 'Login non riuscito' }
      }
    } else {
      try {
        const result: SignInResult = await FirebaseAuthentication.signInWithGoogle();

        if (!result) return { code: 10, message: 'Login non riuscito' }

        const dto: ILoginSocialDTO = {
          clientId: this.mainStateService.getClientId(),
          userAgent: navigator.userAgent,
          signInResponse: result,
          platformType
        }

        const rv: IAuthState | IError = await this.HIO.post<any, any>(`firebase/google-login/`, dto);

        if ('code' in rv) return rv;
        this.authState = rv;

        return this.authState?.user;
      } catch (err) {
        if (err.code === 'auth/popup-closed-by-user') return { code: 10, message: 'Procedura di login annullata' }
        return { code: 10, message: 'Login non riuscito' }
      }
    }
  }

  async openAppleSignIn(): Promise<IUser | IError> {
    const platformType = this.mainStateService.getPlatformType();
    if (Capacitor.getPlatform() === 'web') {
      try {
        const result: SignInResult = await FirebaseAuthentication.signInWithApple({
          scopes: [
            "email",
            "name",
          ],
          customParameters: [
            {
              key: "locale",
              value: "it_IT"
            }
          ]
        });
        if (!result) {
          return { code: 10, message: 'Login non riuscito' }
        }
        const dto: ILoginSocialDTO = {
          clientId: this.mainStateService.getClientId(),
          userAgent: navigator.userAgent,
          signInResponse: result,
          platformType
        }
        const rv: IAuthState | IError = await this.HIO.post<any, any>(`firebase/apple-login/`, dto);

        if ('code' in rv) return rv;
        this.authState = rv;

        return this.authState?.user;
      } catch (err) {

        if (err.code === 'auth/popup-closed-by-user') return { code: 10, message: 'Procedura di login annullata' }
        return { code: 10, message: 'Login non riuscito' }
      }
    } else {
      try {
        const result: SignInResult = await FirebaseAuthentication.signInWithApple({
          scopes: [
            "email",
            "name",
          ],
        });
        if (!result) return { code: 10, message: 'Login non riuscito' }

        const dto: ILoginSocialDTO = {
          clientId: this.mainStateService.getClientId(),
          userAgent: navigator.userAgent,
          signInResponse: result,
          platformType
        }

        const rv: IAuthState | IError = await this.HIO.post<any, any>(`firebase/apple-login/`, dto);

        if ('code' in rv) return rv;
        this.authState = rv;

        return this.authState?.user;
      } catch (err) {

        if (err.code === 'auth/popup-closed-by-user') return { code: 10, message: 'Procedura di login annullata' }
        return { code: 10, message: 'Login non riuscito' }
      }
    }
  }

  // async checkAuthAndPlanRefresh() {
  //   //console.log('checkAuthAndPlanRefresh');
  //   if (!this.state) {
  //     return;
  //   }

  //   const accessExpired = this.jwtHelper.isTokenExpired(this.state.accessToken);
  //   if (accessExpired) {
  //     return this.refreshAuth();
  //   }

  //   this.planRefresh(this.state.accessToken);
  //   this.user$.next(this.state.user);
  // }

  // async refreshAuth() {
  //   if (this.state) {
  //     // Timeout necessario altrimenti -> Error: providers are not ready yet
  //     /*if (this.state.isSocial) this.refreshSocial();
  //     else*/   // --ipts
  //     await this.refreshStandard(this.state.refreshToken);
  //   }
  // }

  // private planRefresh(accessToken: string) {
  //   const timeoutEdge = 60000; // 10 minuti
  //   const tokenExpirationDate: Date = this.jwtHelper.getTokenExpirationDate(accessToken);
  //   const tokenExpTimestamp = tokenExpirationDate.getTime();
  //   const nowTimestamp = new Date().getTime();

  //   let timeout = tokenExpTimestamp - nowTimestamp; /* - 60 * 1000 */
  //   // console.log('🐱️ : timeout in minuti', timeout / 60000);
  //   if(timeout<=timeoutEdge){
  //     timeout = 100
  //   }else{
  //     timeout = timeoutEdge
  //   }
  //   if (this.refreshTimer) {
  //     clearTimeout(this.refreshTimer);
  //   }

  //   this.refreshTimer = setTimeout(() => {
  //     this.refreshAuth();
  //   }, timeout);
  // }

  // private stopRefreshTokenTimer() {
  //   clearTimeout(this.refreshTimer);
  // }



  // async refreshStandard(refreshToken: string): Promise<void> {
  //   console.log('🐱️ : refresh STANDARD - START');
  //   try {
  //     const dto: IRefreshTokenDTO = { refreshToken, clientId:this.app.clientId, userAgent:navigator.userAgent  };
  //     const rv = await lastValueFrom(this.http.post<IAuthState | number>(`${this.app.apiUrl}/auth/refresh`, dto));

  //     if(typeof rv === "number") {
  //       console.log("RefreshToken Return Value",rv);
  //       this.logoutAndClearState();
  //     } else {
  //       this.updateState(rv);
  //     }

  //   } catch (error) {
  //     console.log('🐱️ : error', error);
  //     // if (error.status === 401) {
  //     //   this.logoutAndClearState();
  //     // }
  //     // this.throwError(error);
  //   }
  //   console.log('🐱️ : refresh STANDARD - END');
  // }
  /*
  async loginSocial() {
    // console.log('🐱️ : login SOCIAL');
    try {
      const socialUser = await this.socialAuth.signIn(GoogleLoginProvider.PROVIDER_ID);
      return socialUser.email;
    } catch (error) {
      console.log('🐱️ : error', error);
      this.throwError(error);
    }
  }
  */
  /*
  async refreshSocial() {
    // console.log('🐱️ : refresh SOCIAL');
    try {
      await this.loading.presentLoading({ message: 'Login in corso' });
      setTimeout(() => this.socialAuth.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID), 2000);
    } catch (error) {
      console.log('🐱️ : error', error);
      this.logoutAndClearState();
      this.throwError(error);
    }
  }
  */
  /*
  async updateSocial(socialUser: SocialUser): Promise<IUser> {
    // console.log('🐱️ : updateSocial');
    try {
      const dto: ISocialLoginDTO = {
        token: socialUser.idToken,
        social: socialUser.provider,
        clientId: this.app.clientId,
      };
      const state = await this.http
        .post<IAuthState>(`${this.app.apiUrl}/auth/social-login`, dto)
        .toPromise();
      this.updateState(state);
      return this.state.user;
    } catch (error) {
      console.log('🐱️ : error', error);
      this.throwError(error);
    }
  }
  */

}
