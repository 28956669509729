<div class="container">
  
  <ng-container *ngIf="type == 'number'">
    <ion-icon class="icon" [name]="icon"></ion-icon>
    <span *ngIf="type == 'number'" class="value">{{ value | number: '1.1-1' }}</span>
    <span class="label">{{ label }}</span>
  </ng-container>

  <ng-container  *ngIf="type == 'string'">
    <ion-icon  *ngIf="icon" class="icon" [name]="icon"></ion-icon>
    <span *ngIf="type == 'string'" class="text">{{ value  }}</span>
    <span class="label">{{ label }}</span>
  </ng-container>
  
  <ng-container *ngIf="type == 'time-range'">
    <ion-icon class="icon" [name]="icon"></ion-icon>
    <span  [ngClass]="marked? 'value' : 'text' ">{{ value  }}</span>
  </ng-container>
 
  <ng-container *ngIf="type == 'currency'">
    <ion-icon class="icon" [name]="icon"></ion-icon>
    <span  [ngClass]="marked? 'value' : 'text' ">{{ value | currency: 'EUR'   }}</span>
  </ng-container>

  <ng-container *ngIf="type == 'date'">
    <ion-icon class="icon" [name]="icon"></ion-icon>
    <span  [ngClass]="marked? 'value' : 'label' ">{{ value  | date: "d MMM y" }}</span>
  </ng-container>

  <ng-container *ngIf="type == 'dateWithHour'">
    <ion-icon class="icon" [name]="icon"></ion-icon>
    <ion-text color="medium" [ngClass]="marked? 'title-value' : 'label' ">{{ value  | date: "d MMM y - HH:mm" }}</ion-text>
  </ng-container>

  <ng-container *ngIf="type == 'connector'">
    <ion-icon class="icon" [src]="'assets/plugTypes/presa_' + plug + '.svg'" ></ion-icon>
    <span class="text">{{ value }}</span>
  </ng-container>
 
</div>
