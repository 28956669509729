"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChapterVisualizationPhase = void 0;
exports.ChapterVisualizationPhase = Object.freeze({
  APP_START: 1,
  LOGIN: 2,
  BEFORE_STARTING_SESSION: 3,
  ONLY_INFO_SECTION: 10
});
