<div class="yf-ue-container">
  <div class="mb-18">
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>{{ "PROFILE.USER_DETAIL.INFO" | translate }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <form *ngIf="!!userFrm" [formGroup]="userFrm?.form">
          <formly-form [model]="userFrm?.model" [fields]="userFrm?.fields" [options]="userFrm?.options"
            [form]="userFrm?.form"></formly-form>
        </form>
      </ion-card-content>
    </ion-card>
  </div>

  <div class="mb-18">
    <!--     <ion-card [class]="!user.isCompleted && doesUserWantsInvoice? 'missing-data' : ''"> -->
    <ion-card>
      <ion-checkbox #ionCheckBox class="checkbox-square" labelPlacement="end" [(ngModel)]="doesUserWantsInvoice"
        (ngModelChange)="checkboxChange()">
        {{ "PROFILE.USER_DETAIL.I_WANT_THE_INVOICE" | translate }}
      </ion-checkbox>

      <ion-card-header *ngIf="doesUserWantsInvoice" class="mt-24">
        <ion-card-subtitle>
          {{ "PROFILE.USER_DETAIL.BILLING_INFO" | translate }}
        </ion-card-subtitle>
      </ion-card-header>

      <ion-card-content *ngIf="doesUserWantsInvoice">
        <form *ngIf="!!userInfoFrm" [formGroup]="userInfoFrm?.form">
          <formly-form [model]="userInfoFrm?.model" [fields]="userInfoFrm?.fields" [options]="userInfoFrm?.options"
            [form]="userInfoFrm?.form">
          </formly-form>
        </form>
      </ion-card-content>
    </ion-card>
  </div>

  <ion-card>
    <ion-card-header>
      <ion-card-subtitle>{{ "PROFILE.USER_DETAIL.CHANGE_PASSWORD" | translate }}</ion-card-subtitle>
    </ion-card-header>

    <ion-card-content>
      <form *ngIf="!!passwordFrm" [formGroup]="passwordFrm?.form">
        <formly-form [model]="passwordFrm?.model" [fields]="passwordFrm?.fields" [options]="passwordFrm?.options"
          [form]="passwordFrm?.form"></formly-form>
      </form>
    </ion-card-content>
  </ion-card>
</div>

<ion-footer class="ion-no-border yf-ue-footer" *ngIf="footerVisible">
  <div class="yf-row gap-6">
    <ion-button color="primary" class="btn large grow" (click)="cancel()" fill="outline"
      [disabled]="!userFrm?.form.dirty && !passwordFrm?.form.dirty && !userInfoFrm?.form.dirty">{{ "BUTTONS.CANCEL" |
      translate}}</ion-button>
    <ion-button color="primary" class="btn large grow" (click)="update()"
      [disabled]="!userFrm?.form.valid || !passwordFrm?.form.valid || (!userInfoFrm?.form.valid && doesUserWantsInvoice)">
      {{ "BUTTONS.SAVE" | translate }}
    </ion-button>
  </div>
  <div class="mt-16">
    <ion-button color="danger" class="btn large grow" (click)="deleteProfile()">{{ "PROFILE.USER_DETAIL.DELETE_PROFILE"
      | translate }}</ion-button>
  </div>
</ion-footer>