"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RevenueState = void 0;
exports.RevenueState = Object.freeze({
  New: 0,
  Processing: 1,
  Done: 2,
  Failed: 3,
  Reprocessing: 4
});
