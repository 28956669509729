import { Component, NgZone } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CU, IRfid, IStation, IUpdateRfidDTO, IUpdateStationDTO } from 'common_library';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { MasterDetailShowType } from '../master-detail/master-detail.component';
import { ToastService } from 'src/app/services/utils/toast.service';
import { ActionSheetService, ACTION_SHEET_ROLES } from 'src/app/services/utils/actionsheet.service';
import { Keyboard } from '@capacitor/keyboard';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { BehaviorSubject } from 'rxjs';

const CONTROLLER_ROOT = 'rfid/user-request';

@Component({
    selector: 'yf-rfid-details',
    templateUrl: './rfid-details.component.html',
    styleUrls: ['./rfid-details.component.scss'],
    standalone: false
})
export class RfidDetailsComponent {

  result: string;
  station: IStation;
  masterDetailShow: MasterDetailShowType = 'master';
  compactMode: boolean;
  currentStep = 0;
  footerVisible: boolean = true;
  pageTitle: string;
  detailTitle: string;
  pageSubtitle: string;
  masterTitle: string;

  selectedRfid$ = new BehaviorSubject<IRfid>(null);

  execStep(id: number) {
    this.currentStep = id;
    this.masterDetailShow = 'detail';
  }

  rfidData: IRfid;

  constructor(
    private modalCtrl: ModalController,
    private HIO: HttpIoService,
    public mainStateService: MainStateService,
    private toast: ToastService,
    private actionSheetService: ActionSheetService,
    private ngZone: NgZone,
    private translate: TranslateService
  ) {
    if (this.mainStateService.getNative()) {
      this.listenKeyboardVisibility();
    }
  }

  pickAnRfid(rfid: IRfid) {
    this.selectedRfid$.next(rfid);
    this.masterDetailShow = 'detail';
  }

  compactModeChange(compactMode: boolean) {
    this.compactMode = compactMode;
  }

  async toggleValidity(rfid: IRfid) {
    const userChoice = await this.actionSheetService.actionSheetFunc(this.translate.instant("RFID.CONFIRM_UPDATE"), `${rfid.name} ${this.translate.instant("RFID.WILL_BE")} ${this.validationType(rfid.valid)}`);
    if (userChoice === ACTION_SHEET_ROLES.confirm) {
      try {
        const dto: IUpdateRfidDTO = {
          id: rfid.id,
          valid: !rfid.valid
        }
        const rv = await this.HIO.post<IRfid, IUpdateRfidDTO>('rfid/edit', dto);
        await this.toast.presentBasicToast({ message: CU.capitalizeFirstLetter(`${this.translate.instant("RFID.RFID_TAG")} ${this.validationType(rfid.valid)}`), color: 'success', icon: 'id-card-outline' });
        this.selectedRfid$.next(rv);
      } catch (err) {
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.CANT_UPDATE"), color: 'danger', icon: 'warning' });
      }
    }
  }

  async deleteRfid(rfid: IRfid) {
    const userChoice = await this.actionSheetService.actionSheetFunc(this.translate.instant("RFID.CONFIRM_DELETION"), `${rfid.name} ${this.translate.instant("RFID.WILL_BE_DELETED")}`)
    if (userChoice === ACTION_SHEET_ROLES.confirm) {
      try {
        await this.HIO.deleteItem('rfid/delete', rfid.id);
        this.masterDetailShow = 'master';
        this.selectedRfid$.next(null);
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.DELETED_MESSAGE"), color: 'success', icon: 'id-card-outline' });
      } catch (err) {
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.CANT_DELETE"), color: 'danger', icon: 'warning' });
      }
    }
  }

  isSelected(code) {
    return code === this.selectedRfid$?.value?.code;
  }

  closeMe() {
    this.modalCtrl.dismiss();
  }

  backBtn() {
    if (this.compactMode) {
      this.masterDetailShow = 'master'
    } else {
      this.closeMe()
    }
  }

  async addRfid() {
    if (this.station?.id) {
      const rv = await this.postRfidRequest();
      if (rv) {
        await this.toast.presentBasicToast({ message: this.translate.instant("RFID.PASS_RFID_ON_STATION_READER"), color: 'success', icon: 'id-card-outline' });
      } else {
        await this.toast.presentBasicToast({ message: `${this.translate.instant("RFID.CANT_CONNECT")}, ${this.translate.instant("TOAST_MESSAGES.TRY_AGAIN_LATER")}`, color: 'primary', icon: 'id-card-outline' });
      }
    } else {
      await this.toast.presentBasicToast({ message: `${this.translate.instant("TOAST_MESSAGES.SELECT_A_STATION")}`, color: 'warning', icon: 'id-card-outline' });
    }
  }

  async submitForm(rfidForm: IUpdateRfidDTO) {
    try {
      const dto: IUpdateRfidDTO = {
        id: rfidForm.id,
        name: rfidForm.name,
        carId: rfidForm.carId
      }
      await this.HIO.post<IRfid, IUpdateRfidDTO>('rfid/edit', dto);
      await this.toast.presentBasicToast({ message: this.translate.instant("RFID.UPDATED_SUCCESSFULLY"), color: 'success', icon: 'id-card-outline' });
    } catch (err) {
      await this.toast.presentBasicToast({ message: this.translate.instant("RFID.CANT_UPDATE"), color: 'danger', icon: 'warning' });
    }
  }

  private async postRfidRequest() {
    let date = new Date();
    date.setSeconds(date.getSeconds() + 60);

    const dto: IUpdateStationDTO = {
      rfidTimeOut: date,
      rfidUserId: this.mainStateService.getUser().id,
    }
    return await this.HIO.patchItem<IStation, IUpdateStationDTO>(`station/station-rfid`, this.station.id, dto);
  }

  validationType(bool: boolean) {
    if (bool) {
      return this.translate.instant("RFID.DISABLED_RFID");
    } else {
      return this.translate.instant("RFID.ENABLED_RFID");
    }
  }

  listenKeyboardVisibility() {
    Keyboard.addListener('keyboardWillShow', () => {
      this.ngZone.run(() => {
        this.footerVisible = false;
      });
    });

    Keyboard.addListener('keyboardDidHide', () => {
      this.ngZone.run(() => {
        this.footerVisible = true;
      });
    });
  }
}