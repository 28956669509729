<ion-header>
  <yf-header [label]="'Profilo'" (rightButtonEvent)="closeMe()"></yf-header>
</ion-header>

<ion-content class="yf-content-padding">
  <div class="yf-container gap-12" style="height: 100%">
    <div class="yf-row">
      <app-user-card style="width: 100%" [user]="mainStateService.$user()"></app-user-card>
    </div>
    <div class="yf-row">
      <div class="yf-container gap-12" style="margin-top: 24px">
        <div class="yf-row spaced">
          <ion-text color="medium" class="fs-28 fw-600">{{'PROFILE.MY_CARS'|translate}} </ion-text>
        </div>
        <div class="yf-row align-left wrap gap-12">
          <ion-button class="btn medium" style="width: fit-content" color="primary"
            (click)="addVehicle()">{{'BUTTONS.ADD_VEHICLE'|translate}}
            <ion-icon slot="start" src="assets/app-icons/aggiungi.svg"></ion-icon>
          </ion-button>
          <app-car-button *ngFor="let car of mainStateService.$userCars()" [name]="car.model.brand.name"
            [model]="car.model.name" (click)="editVehicle(car)"></app-car-button>
        </div>
      </div>
    </div>
    <hr />
    <div class="yf-row">
      <div class="yf-container gap-12" style="margin-top: 24px">
        <ion-text color="medium" class="fs-28 fw-600"> Piano di pagamento </ion-text>
        <ion-text color="medium">
          <p>
            Aggiungi una carta di credito / debito e seleziona un piano di pagamento per ricaricare
            I tuoi veicoli
          </p>
        </ion-text>
        <div>
          <ion-button class="btn medium" color="secondary">{{'BUTTONS.ADD_CREDIT_CARD'|translate}}
            <ion-icon slot="start" src="assets/app-icons/carta_di_credito.svg"></ion-icon>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<ion-footer style="height: 24px" class="ion-no-border"></ion-footer>
