import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'payment',
    standalone: false
})
export class PaymentPipe implements PipeTransform {

  currencyPipe = new CurrencyPipe('it');

  transform(value: number, ...args: unknown[]): string | null {
    const emptyValue = '0,00 €';
    if (!value) return emptyValue;
    const price = this.currencyPipe.transform(value, 'EUR', 'symbol', '1.2-2');
    return value !== null ? price : emptyValue;
  }
}
