/* src/app/pages/reviews/reviews.page.scss */
ion-card {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}
ion-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.input-container {
  margin: var(--yf-spacing2) var(--yf-spacing1);
  padding: var(--yf-spacing1) var(--yf-spacing1);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: var(--yf-border-radius);
  box-shadow: var(--yf-shadow);
  transition: all 0.2s;
}
.input-container .close {
  position: absolute;
  top: 0px;
  right: 0px;
}
.input-container .text-input-field {
  width: 100%;
}
.review .review-text {
  margin: var(--yf-spacing1);
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.text-review {
  width: 80%;
}
.avatar-user-date {
  margin: var(--yf-spacing1);
  font-size: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.avatar-user-date .user-date {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: var(--yf-spacing1);
}
.star {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
.comments-container {
  display: flex;
  flex-direction: column-reverse;
}
.comment {
  margin: var(--yf-spacing1);
  padding: var(--yf-spacing1);
  border-radius: var(--yf-border-radius);
}
.review-container {
  display: flex;
  flex-direction: column;
}
.subtitle {
  margin-top: -18px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  font-size: 10px;
}
.divider {
  height: 1px;
  width: 100%;
  background-color: var(--ion-color-medium);
}
#star {
  margin-top: -2px;
  margin-right: 3px;
  margin-left: 3px;
}
.btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.btns ion-button {
  width: 98px;
  flex-grow: unset !important;
}
.myBackground {
  background: var(--ion-color-light);
}
.station-info {
  width: 100%;
  display: flex;
  align-items: center;
  gap: var(--yf-spacing1);
  padding-left: var(--yf-spacing2);
  padding-right: var(--yf-spacing2);
}
.station-info .info-review {
  width: 100%;
  display: flex;
  flex-direction: column;
}
/*# sourceMappingURL=reviews.page.css.map */
