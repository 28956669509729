import { Component, EventEmitter,  Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { ButtonStateService } from 'src/app/services/state/button-state.service';

@Component({
    selector: 'yf-avvia-ricarica',
    template: `
    <ion-button [disabled]="this.buttonStateService.$buttonStartCharge().disabled" (click)="avvia()" class="btn large grow" [ngClass]="{
            secondary: !this.mainStateService.$selectedConnectorHasMineSession(),
            white: this.mainStateService.$selectedConnectorHasMineSession(),
            disabled: this.buttonStateService.$buttonStartCharge().disabled
            }"
            debounceClick>
      <ion-icon slot="start" src="assets/app-icons/ricarica.svg"></ion-icon>
      {{ "APP.INSTALLATION.BUTTONS.START_CHARGING" | translate }}
  </ion-button>
  `,
    styles: [`
    
  `],
    standalone: false
})
export class AvviaRicaricaComponent {  
  @Output() startEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public mainStateService: MainStateService,
    public buttonStateService: ButtonStateService,
    private translate: TranslateService
  ) {
    
  }

  avvia() {
    this.startEvent.emit();
  }
}
