"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GroupEntityType = void 0;
exports.GroupEntityType = Object.freeze({
  'U': 'user',
  'UG': 'usergroup',
  'S': 'station',
  'SG': 'stationgroup',
  'G': 'group',
  'P': 'pod',
  'I': 'installation',
  'T': 'tariff'
});
