import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CU, ICar, ICarBrand, ICarModel, IUpdateCarDTO, PlugType, PlugTypeValues } from 'common_library';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { CarService } from 'src/app/services/entities/car.service';
import { ActionSheetService, ACTION_SHEET_ROLES } from 'src/app/services/utils/actionsheet.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { TranslateService } from '@ngx-translate/core';

export declare type ICarFields = {
  // name: string;
  modelId: number;
  brandId: number;
  plugTypes: PlugTypeValues[];
};
@Component({
    selector: 'yf-car-edit',
    templateUrl: './car-edit.component.html',
    styleUrls: ['./car-edit.component.scss'],
    standalone: false
})
export class CarEditComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  @Input() car$: BehaviorSubject<ICar>;
  @Input() savingNewCar: boolean;
  @Output() formChanges = new EventEmitter<any>();
  carFrm: MyFormlyHelpers.FormlyForm<ICarFields>;

  carModel: ICarFields = { brandId: null, modelId: null, plugTypes: [] };
  @Output() cancelEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();
  car: ICar;

  constructor(
    public modalCtrl: ModalController,
    private HIO: HttpIoService,
    private toast: ToastService,
    public carService: CarService,
    private actionSheetService: ActionSheetService,
    private translate: TranslateService
  ) { }

  async getCarBrand(id: any): Promise<ICarBrand> {
    return await this.HIO.get<ICarBrand>(`car-brand/${id}`);
  }

  async getCarModel(id): Promise<ICarModel> {
    return await this.HIO.get<ICarModel>(`car-model/model/${id}`);
  }

  brands$ = new BehaviorSubject<ICarBrand[]>([]);
  models$ = new BehaviorSubject<ICarModel[]>([]);

  get newVehicle() {
    return this.carService.newVehicle;
  }
  set newVehicle(car) {
    this.carService.newVehicle = car;
  }

  get cancelButtonState() {
    if (!this.carFrm.form.dirty && this.car) {
      return true;
    }
    if (this.savingNewCar) {
      return false
    }
  }


  async ngOnInit() {
    const brands = await this.HIO.get<ICarBrand[]>(`car-brand`);
    this.brands$.next(brands);

    this.carFrm = new MyFormlyHelpers.FormlyForm<ICarFields>(this.carModel, [
      MyFormlyHelpers.selectField('brandId', this.translate.instant("CAR.CAR_DETAIL.VEHICLE_BRAND"), {
        multiple: false,
        options: this.brands$,
        onChange: async (brandId) => {
          let models = await this.HIO.get<ICarModel[]>(`car-model/${brandId}`);
          models = models.sort((a, b) => a.name.localeCompare(b.name));
          this.models$.next(models);
          if (brandId && !this.car) {
            const brand = await this.getCarBrand(this.carFrm.form.get('brandId').value);
            const car: ICar = {
              ...this.newVehicle,
              model: {
                ...this.newVehicle?.model,
                brand: brand
              }
            }
            this.newVehicle = car
          }
        },
      }),
      MyFormlyHelpers.selectField(
        'modelId',
        this.translate.instant("CAR.CAR_DETAIL.VEHICLE_MODEL"),
        {
          multiple: false,
          label: this.translate.instant("CAR.CAR_DETAIL.VEHICLE_MODEL"),
          options: this.models$,
          onChange: async (modelId) => {
            const carModel = await this.getCarModel(modelId);
            if (!!modelId && !this.car) {
              const car: ICar = {
                ...this.newVehicle,
                model: {
                  ...carModel,
                  brand: this.newVehicle.model.brand
                }
              }
              this.newVehicle = car;
              this.carFrm.form.get('plugTypes').patchValue(carModel.plugTypes)
            }
            if (this.car && modelId !== this.car.model) {
              this.car = {
                ...this.car,
                model: {
                  ...carModel,
                  brand: this.car.model.brand
                }
              }
            }
          }
        },
        {
          'templateOptions.disabled': () => !this.carFrm.form.get('brandId').valid,
        }
      ),
      MyFormlyHelpers.selectField('plugTypes', this.translate.instant("CAR.CAR_DETAIL.PLUGS"), {
        multiple: true,
        options: of(CU.ToArray(PlugType)),
        onChange: async (plugTypes) => {
          if (plugTypes && !this.car) {
            this.newVehicle = {
              ...this.newVehicle,
              plugTypes: plugTypes
            }
          }
          if (plugTypes && this.car) {
            this.car.plugTypes = plugTypes
          }
        }
      }),
    ]);

    this.subscriptions.push(this.car$.subscribe(async (car) => {
      this.car = car;
      if (car?.model.brand.id) {
        const models = await this.HIO.get<ICarModel[]>(`car-model/${car.model.brand.id}`);
        this.models$.next(models);
      }
      await this.patchValues();
    }));
  }

  async patchValues() {
    if (!!this.car) {
      this.carFrm.form.get('brandId').patchValue(this.car?.model?.brand?.id);
      this.carFrm.form.get('modelId').patchValue(this.car?.modelId)
      this.carFrm.form.get('plugTypes').patchValue(this.car?.plugTypes)
    } else if (this.newVehicle) {
      if (!!this.newVehicle?.model?.brand.id) {
        const models = await this.HIO.get<ICarModel[]>(`car-model/${this.newVehicle.model.brand.id}`);
        this.models$.next(models);
      }
      this.carFrm.form.get('brandId').patchValue(this.newVehicle?.model?.brand?.id || null)
      this.carFrm.form.get('modelId').patchValue(this.newVehicle?.model?.id || null)
      this.carFrm.form.get('plugTypes').patchValue(this.newVehicle?.plugTypes || null)
    } else {
      this.carFrm.form.get('brandId').patchValue(null)
      this.carFrm.form.get('modelId').patchValue(null)
      this.carFrm.form.get('plugTypes').patchValue(null)
      this.carFrm.options.resetModel();
    }
  }

  async addCar() {
    if (!this.carFrm.form.valid) {
      return;
    }
    const uc = await this.actionSheetService.actionSheetFunc(this.translate.instant("BUTTONS.CONFIRM"), this.translate.instant("CAR.CAR_EDIT.THE_VEHICLE_WILL_BE_ADDED")); //user choice
    if (uc === ACTION_SHEET_ROLES.confirm) {
      try {
        const b = await this.carService.add(
          // this.carFrm.model.name,
          +this.carFrm.model.modelId,
          this.carFrm.model.plugTypes
        );
        await this.carService.fetchCars();
        await this.afterSave(b);
        this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.NEW_CAR_ADDED"), color: 'secondary', icon: 'car-outline' });
      } catch (err) {
        this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CANT_ADD_NEW_CAR"), color: 'danger', icon: 'warning', });
      }
    }
  }

  async updateCar(car) {
    if (!this.carFrm.form.valid) {
      return;
    }
    const uc = await this.actionSheetService.actionSheetFunc(this.translate.instant("BUTTONS.CONFIRM"), this.translate.instant("CAR.CAR_EDIT.THE_VEHICLE_WILL_BE_UPDATED"));
    if (uc === ACTION_SHEET_ROLES.confirm) {
      try {
        const dto: IUpdateCarDTO = {
          modelId: this.carFrm.model.modelId,
          plugTypes: this.carFrm.model.plugTypes,
        };
        const rv = await this.carService.update(car.id, dto);
        await this.afterSave(rv)
        this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CAR_UPDATED"), color: 'secondary', icon: 'car-outline' });
      } catch (err) {
        this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CANT_UPDATE_CAR"), color: 'danger', icon: 'warning' });
      }
    }
  }

  async deleteCar() {
    const uc = await this.actionSheetService.actionSheetFunc(this.translate.instant("BUTTONS.CONFIRM"), this.translate.instant("CAR.CAR_EDIT.CAR_WILL_BE_DELETED"))
    if (uc === ACTION_SHEET_ROLES.confirm) {
      const rv = await this.carService.delete(this.car.id);

      if (rv) this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CAR_DELETED"), color: 'secondary', icon: 'car-outline' });
      else this.toast.presentBasicToast({ message: this.translate.instant("CAR.CAR_EDIT.CANT_DELETE_CAR"), color: 'danger', icon: 'warning' });

      this.car = null;
      await this.patchValues();
      this.deleteEvent.emit();
    }
  }

  async cancelChanges() {
    if (!this.car) {
      this.cancelEvent.emit()
    }
    else {
      this.cancelEvent.emit(this.car)
    }
  }

  async afterSave(car: ICar) {
    this.cancelEvent.emit(car);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

}
