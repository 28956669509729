import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUpdateUserDTO, IUser, IUpdatePassword } from 'common_library';
import { MyFormlyHelpers } from 'src/app/my-formly/my-formly.helpers';
import { AuthService } from '../../services/auth/auth.service';
import { ToastService } from '../../services/utils/toast.service';
import { UserService } from '../../services/entities/user.service';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

type UserFormType = {
  email: string;
  name?: string;
  surname?: string;
};

// type SettingsFormType = {
//   disabled?: boolean;
//   canUploadPhotos?: boolean;
//   canReview?: boolean;
// };

type passwordChangeType = {
  oldPassword?: string;
  password?: string;
  passwordConfirm?: string;
};

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.page.html',
    styleUrls: ['./edit-profile.page.scss'],
    standalone: false
})
export class EditProfilePage implements OnInit {
  constructor(
    public modalCtrl: ModalController,
    public auth: AuthService,
    private toast: ToastService,
    private userService: UserService,
    public mainStateService: MainStateService,
    private translate: TranslateService
  ) {

  }

  userFrm: MyFormlyHelpers.FormlyForm<UserFormType>;
  // settingsFrm: MyFormlyHelpers.FormlyForm<SettingsFormType>;
  passwordFrm: MyFormlyHelpers.FormlyForm<passwordChangeType>;
  ngOnInit() {
    const user = this.mainStateService.$user();

    if (user) {
      this.userFrm = new MyFormlyHelpers.FormlyForm<UserFormType>(
        {
          email: user.email,
          name: user.name,
          surname: user.surname,
        },
        [
          MyFormlyHelpers.emailField(),
          MyFormlyHelpers.inputField('name', this.translate.instant("PROFILE.USER_DETAIL.NAME")),
          MyFormlyHelpers.inputField('surname', this.translate.instant("PROFILE.USER_DETAIL.SURNAME")),
        ]
      );

      this.passwordFrm = new MyFormlyHelpers.FormlyForm<passwordChangeType>(
        {
          oldPassword: '',
          password: '',
          passwordConfirm: '',
        },
        [MyFormlyHelpers.confirmPasswordFields()]
      );

      // this.settingsFrm = new MyFormlyHelpers.FormlyForm<SettingsFormType>(
      //   {
      //     disabled: this.user.disabled ? this.user.disabled : false,
      //     canUploadPhotos: this.user.settings ? this.user.settings.canUploadPhotos : false,
      //     canReview: this.user.settings ? this.user.settings.canReview : false,
      //   },
      //   [
      //     MyFormlyHelpers.ckeckboxField('disabled'),
      //     MyFormlyHelpers.ckeckboxField('canUploadPhotos'),
      //     MyFormlyHelpers.ckeckboxField('canReview'),
      //   ]
      // );
    }
  }

  async update() {
    if (!this.userFrm.form.valid) {
      return;
    }

    try {
      const dto: IUpdateUserDTO = {
        email: this.userFrm.model.email,
        name: this.userFrm.model.name,
        surname: this.userFrm.model.surname,
      };
      const user = await this.userService.updateMe(dto);
      this.mainStateService.setUser(user);
      this.toast.presentBasicToast({
        message: this.translate.instant("PROFILE.USER_EDIT.USER_UPDATED"),
        color: 'secondary',
        icon: 'person-outline'
      });
      this.closeMe();
    } catch (err) {
      this.toast.presentBasicToast({ message: this.translate.instant("PROFILE.USER_EDIT.CANT_UPDATE_USER"), color: 'danger', icon: 'warning' });
    }

    if (this.passwordFrm.form.valid) {
      try {
        const dto: IUpdatePassword = {
          email: this.userFrm.model.email,

          password: this.passwordFrm.model.passwordConfirm,
          // disabled: this.settingsFrm.model.disabled,
          // settings: {
          //   canUploadPhotos: this.settingsFrm.model.canUploadPhotos,
          //   canReview: this.settingsFrm.model.canReview,
          // },
        };
        await this.auth.changePassword(dto.email, dto.password);

        this.toast.presentBasicToast({
          message: this.translate.instant("PROFILE.USER_EDIT.UPDATED_PASSWORD"),
          color: 'secondary',
          icon: 'person-outline'
        });
        this.closeMe();
      } catch (err) {
        this.toast.presentBasicToast({ message: this.translate.instant("PROFILE.USER_EDIT.CANT_UPDATE_PASSWORD"), color: 'danger', icon: 'warning' });
      }
    }
  }
  async closeMe() {
    await this.modalCtrl.dismiss();
  }
}
