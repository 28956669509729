"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlatformType = void 0;
exports.PlatformType = Object.freeze({
  Desktop: "desktop",
  iOSMobileWeb: "ios web",
  AndroidMobileWeb: "android web",
  iOSNative: "ios native",
  AndroidNative: "android native"
});
