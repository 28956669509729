/* src/app/components/cost-detail/cost-detail.component.scss */
.container {
  display: flex;
  overflow-x: scroll;
  height: 90px;
}
::-webkit-scrollbar {
  display: none;
}
/*# sourceMappingURL=cost-detail.component.css.map */
