"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PlugType = void 0;
exports.PlugType = Object.freeze({
  Type1: 'Type1',
  Type2: 'Type2',
  Type2s: 'Type2s',
  Type3a: 'Type3a',
  Type3c: 'Type3c',
  // TeslaR = 'TeslaR',
  // Tesla = 'Tesla',
  CHAdeMO: 'CHAdeMO',
  Combo1: 'Combo1',
  Combo2: 'Combo2'
  // EUSchuko = 'EU-Schuko',
  // Mema515 = 'Mema5-15',
  // Mema1550 = 'Mema15-50',
  // UKDomestic = 'UKDomestic',
  // AUDomestic = 'AUDomestic',
  // InsustrialMonophasic = 'InsustrialMonophasic',
  // InsustrialTriphasic = 'InsustrialTriphasic',
});
