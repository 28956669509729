/* src/app/components/credit-card-form/credit-card-form.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.container {
  margin-top: 24px;
  width: 90%;
  height: 100%;
  margin-left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 576px) {
  .container {
    width: 100% !important;
    margin-left: unset !important;
  }
}
@media (max-width: 450px) {
  .container {
    margin-left: unset !important;
    width: 100% !important;
  }
}
.btn-container {
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  z-index: 2;
}
.btn-container ion-button {
  width: 46%;
}
form {
  width: 100%;
}
.yf-footer {
  position: absolute;
  left: 0;
  bottom: calc(env(safe-area-inset-bottom) + 48px);
  right: 0;
}
.yf-footer ion-button {
  padding-left: var(--yf-spacing2);
  padding-right: var(--yf-spacing2);
}
@media (min-width: 576px) {
  .yf-footer {
    position: fixed !important;
  }
}
@media (max-width: 450px) {
  .yf-footer {
    position: fixed !important;
  }
}
@media (min-width: 768px) {
  .yf-footer {
    position: absolute !important;
  }
}
::ng-deep .alert-cdcform-css .alert-message {
  max-height: 280px !important;
}
/*# sourceMappingURL=credit-card-form.component.css.map */
