<ion-toolbar>

  <ion-buttons slot="start">
    <ion-button color="primary">
      <ion-icon slot="icon-only" name="information-circle"></ion-icon>
    </ion-button>
  </ion-buttons>

  <ion-title>

    <div class="yf-row gap-2">
      <div class="yf-container">
        <ion-text class="title fs-16">{{ title }}</ion-text>
        <ion-text class="fs-10" color="medium">{{ subtitle }}</ion-text>
      </div>
    </div>

  </ion-title>

  <ion-buttons slot="end">
    <ion-button color="danger" (click)="closeMe()">
      <ion-icon slot="icon-only" name="close-outline"></ion-icon>
    </ion-button>
  </ion-buttons>

</ion-toolbar>

<div class="slider-container" *ngIf="images && images.length > 0">
  <img
    *ngFor="let image of images; let i = index"
    [src]="image.imageSrc"
    [alt]="image.caption"
    [ngClass]="{ 'image-active': selectedIndex === i }"
    class="fade" />

  <div class="slider-dot-container" *ngIf="indicators">
    <span
      *ngFor="let dot of images; let i = index"
      class="dot"
      [ngClass]="{ active: selectedIndex === i }"
      (click)="selectImage(i)">
      <div
        *ngIf="dot.caption != ''"
        class="caption"
        [ngClass]="{ 'caption-active': selectedIndex === i }">
        {{ images[i].caption }}
      </div>
    </span>
  </div>
  <button
    (click)="onPrevClick()"
    *ngIf="controls && selectedIndex != 0"
    class="btn-slider btn-prev">
    <ion-icon class="icon-slider icon-prev" name="chevron-back-circle-outline"></ion-icon>
  </button>
  <button
    (click)="onNextClick()"
    *ngIf="controls && selectedIndex != images.length - 1"
    class="btn-slider btn-next">
    <ion-icon class="icon-slider icon-next" name="chevron-forward-circle-outline"></ion-icon>
  </button>
</div>
