<div class="formly-input-container">
  <ion-item>
    @if(to.type !== 'password'){
    <ion-input type="text" [maxlength]="to.maxLength" [pattern]="to.type === 'number' ? '[0-9]*' : ''"
      [formControl]="ngControl" label="{{ to.label }} {{ to.required ? '*' : '' }}"
      labelPlacement="floating"></ion-input>
    }
    @else {
    <ion-input [type]="showPassword ? 'text' : 'password'" [formControl]="ngControl"
      label="{{ to.label }} {{ to.required ? '*' : '' }}" labelPlacement="floating"></ion-input>
    <ion-icon *ngIf="to.type === 'password'" class="show-password"
      [name]="showPassword ? 'eye-outline' : 'eye-off-outline'" slot="end" (click)="showPassword = !showPassword">
    </ion-icon>
    }
  </ion-item>
  @if(formControl.touched){
  <span class="input-error">
    <ion-text color="danger">
      <formly-validation-message [field]="field"></formly-validation-message>
    </ion-text>
  </span>
  }
</div>