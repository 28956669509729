<div class="formly-input-container">
  <ion-item>
    <ion-label position="stacked">{{ to.label }} {{ to.required ? '' : '' }}</ion-label>
    <ion-textarea [rows]="to.row ? to.row : 3" [cols]="to.col ? to.col : 20" type="text" [placeholder]="to.placeholder"
      [formControl]="ngControl"></ion-textarea>
  </ion-item>
  <span *ngIf="formControl.touched" class="input-error">
    <ion-text color="danger">
      <formly-validation-message [field]="field"></formly-validation-message>
    </ion-text>
  </span>
</div>
