"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CPOs = void 0;
exports.CPOs = Object.freeze({
  UNKNOWN: "???",
  YOURFILL: "YFL",
  ENELX: "ENX",
  FREETOX: "F2X"
});
