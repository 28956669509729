import { Component, Input, OnInit } from '@angular/core';
import { ICar, ICarBrand, ICarModel } from 'common_library';

@Component({
    selector: 'app-car-details',
    templateUrl: './car-details.component.html',
    styleUrls: ['./car-details.component.scss'],
    standalone: false
})
export class CarDetailsComponent implements OnInit {
  @Input() car: ICar;

  constructor() {}

  ngOnInit() {}
}
