import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IUser } from 'common_library';
import { AppService } from '../../services/app.service';
import { PhotoService } from '../../services/entities/photo.service';
import { EditProfilePage } from '../../pages/edit-profile/edit-profile.page';

@Component({
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
    standalone: false
})
export class UserCardComponent implements OnInit {

  constructor(
    private photoService: PhotoService,
    public modalCtrl: ModalController,
    private app: AppService
  ) {}

  src: string;
  isLoading = false;

  @Input() user: IUser;

  public async uploadUserPhoto() {
    this.isLoading = true;
    const photoBlob = await this.photoService.capturePhoto();
    if (photoBlob) {
      const uploadRes = await this.photoService.uploadUserPhoto(photoBlob);
      this.src = `${this.app.apiUrl}/photo/userPhoto/${this.user.id}?${new Date().getTime()}`;
      this.isLoading = false;
    }
  }

  ngOnInit() {
    this.src = `${this.app.apiUrl}/photo/userPhoto/${this.user.id}?${new Date().getTime()}`;
  }

  async editProfile() {
    const modal = await this.modalCtrl.create({
      component: EditProfilePage,
      cssClass: 'yf-modal',
    });
    await modal.present();
  }
}
