/* src/app/pages/session-toast/session-toast.component.scss */
.swiper {
  max-height: calc(100vh - 60px - 24px);
  position: fixed;
  top: calc(env(safe-area-inset-top) + 72px);
  z-index: 1;
}
@media (max-width: 576px) {
  .swiper {
    width: 100vw;
  }
}
@media (min-width: 576px) {
  .swiper {
    width: 380px;
  }
}
/*# sourceMappingURL=session-toast.component.css.map */
