import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { QuickFilter } from 'common_library';

@Component({
    selector: 'yf-quick-filters',
    templateUrl: './quick-filters.component.html',
    styleUrls: ['./quick-filters.component.scss'],
    standalone: false
})
export class QuickFiltersComponent implements OnInit {
  @Input() selectedFilters: string[] = [];
  rapidFilters: string[] = Object.values(QuickFilter);
  @Output() filterEvent = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit() {
  }

  selectFilter(filter: string) {
    if (this.selectedFilters.includes(filter)) {
      const index = this.selectedFilters.findIndex((f) => f === filter);
      this.selectedFilters.splice(index, 1);
    } else {
      this.selectedFilters.push(filter);
    }
    this.filterEvent.emit(this.selectedFilters);
  }
}
