<div class="yf-container">
  <div>
    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>{{ "CAR.CAR_DETAIL.INFO" | translate }}</ion-card-subtitle>
      </ion-card-header>

      <ion-card-content>
        <form *ngIf="!!carFrm" [formGroup]="carFrm.form">
          <formly-form [model]="carFrm.model" [fields]="carFrm.fields" [options]="carFrm.options"
            [form]="carFrm.form"></formly-form>
        </form>
        <div class="mt-16">
          <app-car-details [car]="car || newVehicle"></app-car-details>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</div>

<ion-footer class="yf-footer" *ngIf="carFrm?.form">
  <div>
    <div class="yf-row gap-6 mb-18">
      <ion-button [disabled]="cancelButtonState" class="btn large grow" color="primary" fill="outline"
        (click)="cancelChanges()">
        {{ "BUTTONS.CANCEL" | translate }}
      </ion-button>
      <ion-button class="btn large grow" color="primary" (click)="car ? updateCar(car) : addCar()"
        [disabled]="!carFrm.form.valid">
        {{ "BUTTONS.SAVE" | translate }}
      </ion-button>
    </div>
    <ion-button *ngIf="!!car" class="btn large grow yf-margin" color="danger" (click)="deleteCar()">{{ "CAR.CAR_DETAIL.REMOVE_CAR" | translate}}
    </ion-button>
  </div>
</ion-footer>