/* src/app/components/master-details-app-info/app-info.component.scss */
.master {
  min-width: 360px;
}
.yf-master-detail .master {
  width: 100% !important;
}
.yf-master-detail .detail {
  width: 100% !important;
}
.oy-a {
  overflow-y: auto;
}
.master-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  border: 1px solid rgb(99, 99, 99);
  padding: 10px;
}
.detail {
  height: 100%;
  min-width: 360px;
  overflow-y: auto;
}
.detail-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  border-left: 1px solid #eee;
  padding: 10px;
  height: 100%;
}
.detail-item-no-border {
  font-size: medium;
  color: var(--ion-color-secondary);
  padding: 10px;
  height: 100%;
}
.subtitle {
  border-radius: 8px;
}
.selected {
  background: #f2f2f9;
}
.item-icon {
  margin-inline-end: 10px !important;
}
ion-card {
  padding-top: 0px;
}
ion-item-divider {
  padding-left: 0px;
}
ion-toolbar {
  --background: none;
}
.section-title {
  font-weight: bold;
  font-size: large;
}
.section-subtitle {
  font-size: smaller;
  color: #666;
}
.section-img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
.section-html {
  padding-bottom: 48px;
}
/*# sourceMappingURL=app-info.component.css.map */
