<app-master-detail class="yf-master-detail" icon="notifications-outline" title="{{'NOTIFICATIONS.TITLE'|translate}}"
  subtitle="{{'NOTIFICATIONS.SUBTITLE'|translate}}" [titleMaster]="''" titleDetail="{{'NOTIFICATIONS.DETAIL_TITLE'|translate}}" [(show)]="masterDetailShow" filter=""
  (compactModeChange)="compactModeChange($event)" [forceCompact]="false">

  <div class="master">
    <ng-container *ngIf="notifications.length>=1">
      <ion-content [fullscreen]="true">
        <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
          <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <ion-list>
          <div class="m-6 pl-6 list-item" *ngFor="let notification of notifications; let i = index;"
            [ngClass]="{ selected: selectedNotification?.id === notification.id }">

            <ion-item button detail="true" (click)="pickAnNotification(notification)"
              [lines]="selectedNotification?.id===notification.id ? 'none' : ''" text-wrap>
              <ion-icon [attr.name]="notification.readedAt? '' : 'ellipse'" slot="start" class="item-icon"
                color="primary"></ion-icon>
              <ion-icon
                [name]="notification.notificationType===NotificationTypes?.WEBPUSH ? 'notifications-circle-outline' : 'mail-outline' "
                class="notification-type-icon" size="medium"></ion-icon>
              <ion-label>
                <h2 class="title-item"><b>{{ notification?.notification?.title }}</b></h2>
                <p>{{ notification.notification.body }}</p>
              </ion-label>
              <p class="arrived">{{ this._utilityFuncs.timeSince(notification.createdAt)}}</p>
            </ion-item>
          </div>
          <div *ngIf="totalNotificationsNumber === notifications.length" class="mt-24 yf-container centered">
            <ion-icon name="alert-circle-outline" class="fs-30" color="dark"></ion-icon>
            <ion-text color="primary" class="fw-300 fs-16 mt-6">
              {{ "NOTIFICATIONS.NO_OTHER_NOTIFICATIONS" | translate}}
            </ion-text>
          </div>
          <ion-infinite-scroll (ionInfinite)="onIonInfinite($event)">
            <ion-infinite-scroll-content></ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-list>
      </ion-content>
    </ng-container>
    <div *ngIf="!loading && notifications.length===0" class="yf-container centered">
      <ion-icon name="alert-circle-outline" class="fs-30" color="dark"></ion-icon>
      <ion-card>
        <ion-card-header>
          <ion-card-title color="primary" class="fw-600 fs-22 yf-text-center">
            {{ "NOTIFICATIONS.WHEN_YOULL_GET_ONE_YOULL_FIND_IT_HERE" | translate}}
          </ion-card-title>
        </ion-card-header>
      </ion-card>
    </div>
  </div>

  <div class="detail" *ngIf="notificationService.selectedNotification$ | async as selectedNotification">
    <div class="detail-item">
      <div class="mt-10 section-title title-item" *ngIf="selectedNotification.notification.title">{{
        selectedNotification.notification.title }}</div>
      <div class="mt-4 section-subtitle" *ngIf="selectedNotification.createdAt">{{ selectedNotification.createdAt |
        date:'dd/MM/yyyy HH:mm:ss' }}</div>
      <div class="mt-10 section-html" *ngIf="selectedNotification.notification.body"
        [innerHTML]="selectedNotification.notification.body"></div>
    </div>
  </div>

</app-master-detail>