import { Component, effect, EventEmitter, Input, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MyFormlyHelpers } from '../../../my-formly/my-formly.helpers';
import { FormType } from '../login.page';
import { ChapterType, IInfoChapter, IInfoContent } from 'common_library';
import { AppService, ModalSource } from 'src/app/services/app.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
    selector: '[yf-login-form-template]',
    templateUrl: './login-form-template.component.html',
    styleUrls: ['./login-form-template.component.scss'],
    standalone: false
})
export class LoginFormTemplateComponent {
  readonly FormType = FormType;

  @Output() submitEvent = new EventEmitter();
  @Output() appleLoginEvent = new EventEmitter();
  @Output() googleLoginEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() changeForm = new EventEmitter<FormType>();

  @Input() header: { title: string; subtitle?: string; subtitle2?: string };
  @Input() forward: { text: string; type: FormType };
  @Input() back: { text: string; type: FormType };
  @Input() submitButton: { label: string; icon?: string };
  @Input() registerButton: { label: string; icon?: string };
  @Input() cancelButton: { label: string; icon: string };
  @Input() type?: FormType = null;

  @Input() form: MyFormlyHelpers.FormlyForm<any>;

  contractAccepted: boolean = false;
  privacy: IInfoChapter;
  termsAndConditions: IInfoChapter;

  constructor(
    private mainStateService: MainStateService,
    public modalCtrl: ModalController,
    private appService: AppService) {
    effect(() => {
      const res: IInfoContent = this.mainStateService.$cmsContent();
      if (res) {
        this.privacy = res.infoChapters.find(c => c.type === ChapterType.PRIVACY);
        this.termsAndConditions = res.infoChapters.find(c => c.type === ChapterType.TERMS_AND_CONDITIONS);
      }
    });
  }

  async onSubmit() {
    if (this.FormType.Register) this.modalCtrl.dismiss();
    this.submitEvent.emit();
  }

  async onAppleLogin() {
    this.appleLoginEvent.emit();
  }

  async onGoogleLogin() {
    this.googleLoginEvent.emit();
  }

  async onCancel() {
    this.cancelEvent.emit();
  }

  checkContract() {
    this.contractAccepted = !this.contractAccepted;
  }

  async openTermsAndConditions() {
    if (this.termsAndConditions) {
      await this.appService.showAppInfo(this.termsAndConditions?.id, ModalSource.Registration);
    }
  }

  async openInfoPrivacy() {
    if (this.privacy) {
      await this.appService.showAppInfo(this.privacy?.id, ModalSource.Registration);
    }
  }
}
