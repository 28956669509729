/* src/app/components/user-card/user-card.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.user-card-container {
  background-color: var(--ion-color-secondary);
  border-radius: var(--yf-border-radius);
}
.user-icons {
  height: 24px;
  width: 24px;
}
ion-thumbnail {
  --size: 122px;
  --border-radius: var(--yf-border-radius);
  position: relative;
}
ion-thumbnail .upload-button {
  position: absolute;
  bottom: 1px;
  right: 1px;
}
/*# sourceMappingURL=user-card.component.css.map */
