"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SessionCdrState = void 0;
exports.SessionCdrState = Object.freeze({
  NotCreated: "NotCreated",
  Created: "Created",
  NotExpected: "NotExpected"
});
