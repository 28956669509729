/* src/app/components/modal-complete-name/modal-complete-name.component.scss */
.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: var(--ion-color-light);
}
@media (max-width: 576px) {
  .main {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  .main {
    margin-top: env(safe-area-inset-top);
  }
}
ion-content {
  --background: var(--ion-color-light);
  background: var(--ion-color-light);
}
ion-footer {
  background: var(--ion-color-light);
  --background: var(--ion-color-light);
}
.container {
  display: flex;
  flex-direction: row;
  max-height: 100%;
  overflow-y: auto;
  padding: var(--yf-spacing1);
  height: 100%;
}
.master {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.master-content {
  overflow-y: auto !important;
}
.detail {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  flex: 1;
}
.detail-content {
  overflow-y: auto !important;
  height: 100%;
}
.hide {
  display: none;
}
.yf-footer {
  position: absolute;
  bottom: calc(env(safe-area-inset-bottom) + 48px);
}
.yf-footer ion-button {
  padding-left: var(--yf-spacing2);
  padding-right: var(--yf-spacing2);
}
@media (min-width: 576px) {
  .yf-footer {
    position: fixed !important;
  }
}
@media (max-width: 450px) {
  .yf-footer {
    position: fixed !important;
  }
}
@media (min-width: 768px) {
  .yf-footer {
    position: absolute !important;
  }
}
/*# sourceMappingURL=modal-complete-name.component.css.map */
