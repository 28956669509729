"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MODULE_ID = void 0;
exports.MODULE_ID = Object.freeze({
  Cdrs: 'cdrs',
  ChargingProfiles: 'chargingprofiles',
  Commands: 'commands',
  CredentialsRegistration: 'credentials',
  //HubClientInfo = 'hubclientinfo',
  Locations: 'locations',
  Sessions: 'sessions',
  Tariffs: 'tariffs',
  Tokens: 'tokens'
});
