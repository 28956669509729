import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'SmartDate',
    standalone: false
})
export class SameDatePipe implements PipeTransform {

  datePipe = new DatePipe('it');

  transform(value: string | Date, mainDate = new Date(), milliseconds?: Boolean) {

    if (value === null) return '--:--:--';

    if (typeof value === 'string') {
      value = new Date(value);
    }

    if (typeof mainDate === 'string') {
      mainDate = new Date(mainDate);
    }

    if (!mainDate) {
      return this.datePipe.transform(value, 'd MMM HH:mm');
    }

    if (milliseconds) {
      return this.millisecondDates(value, mainDate);
    }

    if (this.checkSameDate(value, mainDate)) {
      return this.datePipe.transform(value, 'HH:mm:ss');
    }

    return this.datePipe.transform(value, 'd MMM HH:mm');
  }

  checkSameDate(start: Date, end?: Date): boolean {
    if (end &&
      start.getFullYear() === end.getFullYear() &&
      start.getMonth() === end.getMonth() &&
      start.getDate() === end.getDate()
    ) {
      return true;
    }
    return false;
  }

  millisecondDates(data1: Date, data2?: Date) {
    if (this.checkSameDate(data1, data2)) {
      const data = this.datePipe.transform(data1, 'HH:mm:ss');
      return data
    } else {
      const data = this.datePipe.transform(data1, 'd MMM HH:mm:ss');
      return data;
    }
  }

}
