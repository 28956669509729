/* src/app/components/debug/debug.component.scss */
div.container {
  position: absolute;
  bottom: -1px;
  right: 2px;
  z-index: 10000;
  padding: 0px;
  font-size: 6pt;
  color: #888;
}
/*# sourceMappingURL=debug.component.css.map */
