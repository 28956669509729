<ion-button
  class="btn car-button white"
  (click)="carButtonEvent.emit()"
  [color]="isSelected ? 'secondary' : ''"
  [style]="{ '--box-shadow': noShadow ? 'none' : '' }">
  <div class="yf-row gap-12" style="width: 100%">
    <!-- <ion-icon src="assets/app-icons/macchina.svg"> </ion-icon> -->
    <ion-icon name="car-outline"> </ion-icon>
    <ion-text style="text-align: start; overflow-x: clip">
      <h6>{{ name }}</h6>
      <p>{{ model }}</p>
    </ion-text>
  </div>
</ion-button>
