/* src/app/components/state-simple-timer/state-simple-timer.component.scss */
.state-container {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.icon {
  font-size: 16px;
}
/*# sourceMappingURL=state-simple-timer.component.css.map */
