"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectInvoiceState = exports.SelectGroupCategory = exports.SelectCorrispettivoState = exports.SelectPaymentState = exports.SelectCDRState = exports.SelectOnlineStationState = exports.SelectIsAdmin = exports.SelectCpo = exports.SelectNumberOfConnector = exports.SelectSessionTime = exports.SelectSessionState = exports.SelectStationAndInstallationState = exports.SortingOptionsShort = exports.SortingOptions = void 0;
exports.SortingOptions = Object.freeze({
  ASC: "asc",
  DESC: "desc",
  NEW: "new",
  OLD: "old"
});
exports.SortingOptionsShort = Object.freeze({
  NEW: "new",
  OLD: "old"
});
exports.SelectStationAndInstallationState = Object.freeze({
  NONE: -1,
  NOT_AVAILABLE: 0,
  AVAILABLE: 1,
  RESERVED: 2,
  IN_CHARGING: 3,
  OCCUPIED: 4,
  PREPARING: 5
});
exports.SelectSessionState = Object.freeze({
  ALL: "all",
  ACTIVE: "active",
  NOT_ACTIVE: "not-active"
});
exports.SelectSessionTime = Object.freeze({
  TODAY: "today",
  YESTERDAY: "yesterday",
  THIS_WEEK: "thisWeek",
  LAST_WEEK: "lastWeek",
  CUSTOM_RANGE: "customRange"
});
exports.SelectNumberOfConnector = Object.freeze({
  NONE: -1,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4
});
exports.SelectCpo = Object.freeze({
  NONE: "none",
  YFL: "YFL",
  ENX: "ENX",
  F2X: "F2X"
});
exports.SelectIsAdmin = Object.freeze({
  NONE: "none",
  TRUE: "true",
  FALSE: "false"
});
exports.SelectOnlineStationState = Object.freeze({
  NONE: "none",
  ONLINE: "online",
  OFFLINE: "offline"
});
exports.SelectCDRState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 5,
  DONE: 6,
  FAILED: 7,
  NOT_DUE: 8,
  IN_REVIEW: 9,
  IN_REPROCESSING: 10
});
exports.SelectPaymentState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 1,
  IN_REPROCESSING: 2,
  DONE: 3,
  FAILED: 4,
  NOT_NECESSARY: 5
});
exports.SelectCorrispettivoState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 1,
  DONE: 2,
  FAILED: 3,
  IN_REPROCESSING: 4,
  CANCELED: 5,
  NOT_NECESSARY: 6,
  IN_CANCELLATION: 7
});
exports.SelectGroupCategory = Object.freeze({
  NONE: "none",
  USERS: "30F",
  STATIONS: "60F",
  RATE: "70F",
  VISIBILITY: "80F",
  GROUPS: "50F"
});
exports.SelectInvoiceState = Object.freeze({
  NONE: -1,
  NEW: 0,
  IN_PROGRESS: 1,
  IN_REPROCESSING: 2,
  DONE: 3,
  FAILED: 4,
  REJECTED: 5,
  NOT_NECESSARY: 6
});
