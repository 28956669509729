import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, inject, signal } from '@angular/core';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { ModalController } from '@ionic/angular';
import { Ocr, TextDetections } from '@capacitor-community/image-to-text';
import { BehaviorSubject, combineLatest, filter, map, switchMap } from 'rxjs';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'yf-text-scan',
  templateUrl: './text-scan.component.html',
  styleUrls: ['./text-scan.component.scss'],
  standalone: false,
})
export class TextScanComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoElement', { static: true }) videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('textElement', { static: true }) textElement!: ElementRef<HTMLDivElement>;
  $showVideo = signal<boolean>(false);

  canvasElement: HTMLCanvasElement | null = null;
  context: CanvasRenderingContext2D | null = null;
  videoStream: MediaStream | null = null;
  captureInterval: any;
  text: string;
  $debug = signal<string>('Debug');
  canWriteOCRText$ = new BehaviorSubject<boolean>(true);
  ocrTextResult$ = new BehaviorSubject<string>('');
  // Può sembrare molto macchinoso, ma così non mi devo ingarbugliare in mezzo ai form e ai binding, che non mi interessano
  // Inoltre, così posso fare il binding solo quando ho effettivamente del testo da scrivere e non ci sta scrivendo l'utente, altrimenti sarà l'utente a scrivere 
  // e non il testo dell'OCR
  ocrText$ = this.ocrTextResult$.pipe(
    filter(() => this.canWriteOCRText$.value),
  );
  appService = inject(AppService);  
  textPatternValid$ = this.ocrTextResult$.pipe(
    map(text => {
      const knownTextPatterns = this.appService.checkQrCodeTextKnownPatterns(text);
      return !!knownTextPatterns;
    })
  );

  modalController = inject(ModalController);
  constructor() {
    this.ocrText$.subscribe(text => {
      console.log('OCR text:', text);
      this.text = text;
    });
  }

  ngOnInit(): void {
    // this.setupVideoCapture();
  }
  ngAfterViewInit(): void {
    this.setupVideoCapture();
    // this.addInputEventListeners();
  }
  ngOnDestroy(): void {
    if (this.captureInterval) {
      clearInterval(this.captureInterval);
    }
    if (this.videoStream) {
      const tracks = this.videoStream.getTracks();
      tracks.forEach(track => track.stop());
    }
  }

  private async setupVideoCapture() {
    if (!this.videoElement || !this.videoElement.nativeElement) {
      console.error('Video element not found');
      return;
    }

    try {
      this.videoStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      });

      this.videoElement.nativeElement.srcObject = this.videoStream;
      this.videoElement.nativeElement.play();
      this.videoElement.nativeElement.onloadedmetadata = () => {
        this.videoElement.nativeElement.width = this.videoElement.nativeElement.videoWidth;
        this.videoElement.nativeElement.height = this.videoElement.nativeElement.videoHeight;
        this.canvasElement = document.createElement('canvas');
        this.context = this.canvasElement.getContext('2d')!;
        this.startPeriodicCapture();
        this.$showVideo.set(true);
      };
    } catch (error) {
      console.error('Error accessing camera: ', error);
      this.$showVideo.set(false);
    }
  }

  private startPeriodicCapture() {
    this.captureInterval = setInterval(() => {
      if (this.videoElement && this.canvasElement && this.context) {
        console.log('Capturing frame');
        this.captureFrame();
      }
    }, 2000);
  }

  private captureFrame() {
    const getBase64StringFromDataURL = (dataURL: string): string => {
      console.log('getBase64StringFromDataURL', dataURL);
      return dataURL.replace('data:', '').replace(/^.+,/, '');
    };

    if (this.videoElement && this.canvasElement && this.context) {
      const videoRect = this.videoElement.nativeElement.getBoundingClientRect();
      const overlayRect = document.querySelector('.overlay-rectangle')!.getBoundingClientRect();

      const scale = this.videoElement.nativeElement.videoWidth / videoRect.width;

      const offsetX = (overlayRect.left - videoRect.left) * scale;
      const offsetY = (overlayRect.top - videoRect.top) * scale;
      const width = overlayRect.width * scale;
      const height = overlayRect.height * scale;

      this.canvasElement.width = Math.floor(width);
      this.canvasElement.height = Math.floor(height);

      this.context.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

      this.context.drawImage(
        this.videoElement.nativeElement,
        offsetX,
        offsetY,
        width,
        height,
        0,
        0,
        this.canvasElement.width,
        this.canvasElement.height
      );

      const base64image = this.canvasElement.toDataURL('image/jpeg');
      this.detectText(getBase64StringFromDataURL(base64image));
    }
  }


  private async detectText(base64Image: string) {
    console.log('Detecting text');
    try {
      const data: TextDetections = await Ocr.detectText({
        base64: base64Image,
      });
      console.log('Text detected', JSON.stringify(data), data.textDetections);
      if (data) {
        console.log('Detected text:', data.textDetections[0].text);
        const textValue = data.textDetections.map(detection => detection.text).join('\n');
        this.ocrTextResult$.next(textValue);
      }
      for (const detection of data.textDetections) {
        console.log('Detected text++:', detection.text);
      }
    } catch (e) {
      console.error('Error detecting text:', e);
    }
  }

  async closeModal() {
    await this.modalController.dismiss({
      text: this.text,
    });
  }
  async textElementBlur(event: any) {
    console.log('Ion blur event:', event);
    this.canWriteOCRText$.next(true);
    this.$debug.set('Blur event');
  }
  async textElementFocus(event: any) {
    console.log('Input event:', event);
    this.canWriteOCRText$.next(false);
    this.$debug.set('Focus event');
  }

  async textChange(event:any) {
    console.log('Text change event:', event.detail.value);
    this.ocrTextResult$.next(event.detail.value);
  }
}
