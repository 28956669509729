<div class="unpaid-banner">
  <ion-card>
    <ion-card-header>
      <ion-card-title>{{ "TOAST_MESSAGES.WARNING" | translate }}</ion-card-title>
    </ion-card-header>

    <ion-card-content>
      {{ "SESSIONS_HISTORY.PAYMENT_FOR_THIS_CHARGING_SESSION_FAILED" | translate}}
    </ion-card-content>

    <ion-button fill="outline" size="small" (click)="payNowClicked()">{{ "SESSIONS_HISTORY.TRY_PAYMENT_AGAIN" | translate }}</ion-button>
  </ion-card>
</div>