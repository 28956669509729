<div class="yf-row wrap gap-6">
  <div *ngFor="let filter of rapidFilters">
    <ion-button
      (click)="selectFilter(filter)"
      [color]="!selectedFilters.includes(filter) ? 'light' : 'secondary'"
      class="chip-button"
      ><ion-text class="fs-12">{{ filter }}</ion-text></ion-button
    >
    <!-- [color]="!filter.value ? 'light' : 'secondary'" -->
  </div>
</div>
