<div class="yf-row wrap spaced">
  <ion-button
    *ngFor="let plug of plugTypes"
    (click)="selectPlug(plug)"
    fill="clear"
    class="plug-button"
    [style]="{ opacity: selectedConnector.includes(plug) ? '1' : '0.5' }">
    <div class="yf-container centered gap-6">
      <ion-icon
        [src]="'assets/plugTypes/presa_' + plug.toLowerCase() + '.svg'"
        [color]="selectedConnector.includes(plug) ? 'secondary' : 'medium'"></ion-icon>
      <ion-text class="fs-12" [color]="selectedConnector.includes(plug) ? 'secondary' : 'medium'">{{
        plug
      }}</ion-text>
    </div>
  </ion-button>
</div>
