"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.NotificationType = void 0;
exports.NotificationType = Object.freeze({
  WEBPUSH: 'WEBPUSH',
  EMAIL: 'EMAIL'
});
