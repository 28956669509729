<div>
  <div class="yf-row value-container">
    <ion-text *ngFor="let value of valueArray" color="secondary">{{ value }}kW</ion-text>
  </div>
  <!-- <div class="value_container">
    <p>< 22kW</p>
    <b>{{ customSteps.lower }}kW / {{ customSteps.upper }}kW</b>
    <p>350kW ></p>
  </div> -->
  <ion-range
    min="0"
    [max]="valueArray.length - 1"
    [(ngModel)]="knobValues"
    (ionKnobMoveEnd)="selectRange()"
    dualKnobs="true"
    snaps="true"
    ticks="true"
    mode="md">
    <!-- <ion-range
      [(ngModel)]="knobValues"
      pin="true"
      dualKnobs="true"
      min="22"
      max="350"
      step="1"
      snaps="false"
      mode="md"> -->
    <ion-icon size="large" slot="start" src="assets/app-icons/tartaruga.svg"></ion-icon>
    <!-- [color]="knobValues.lower != 0 ? 'secondary' : 'medium'" -->
    <ion-icon size="large" slot="end" src="assets/app-icons/coniglio.svg"></ion-icon>
    <!-- [color]="knobValues.upper != valueArray.length - 1 ? 'secondary' : 'medium'" -->
  </ion-range>
</div>
