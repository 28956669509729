"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIReservationRestriction = void 0;
exports.OCPIReservationRestriction = Object.freeze({
  RESERVATION: "RESERVATION",
  /**
   *  Used in TariffElements to describe costs for a reservation that expires (i.e. driver does not start a
   * charging session before expiry_date of the reservation).
   */
  RESERVATION_EXPIRES: "RESERVATION_EXPIRES"
});
