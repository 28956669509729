/* src/app/pages/login/login-form-template/login-form-template.component.scss */
:root {
  --yf-border-radius: 12px;
  --yf-shadow: 5px 5px 10px #0000001f;
  --yf-spacing0: 6px;
  --yf-spacing1: 12px;
  --yf-spacing2: 24px;
  --yf-spacing3: 36px;
  --yf-spacing4: 48px;
  --yf-spacing5: 60px;
  --yf-primary-light: #9edaff;
  --yf-primary: #297eb2;
  --yf-primary-dark: #174c6d;
  --yf-secondary: #0d2c40;
  --yf-accent: #c2fc84;
  --yf-white: #FFFF;
}
.icon-style {
  height: 100%;
  width: auto;
  vertical-align: baseline;
}
.social-login {
  margin-top: 6px;
  margin-bottom: 6px;
}
.titles {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 22px;
  text-align: left;
  vertical-align: baseline;
  padding-left: 8px;
}
.hidescroll::-webkit-scrollbar {
  display: none;
  width: 0px;
  height: 0px;
}
.card {
  display: flex;
  padding: 4px 4px 8px 4px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 100%;
}
.main-grid {
  max-height: 60vh;
  min-height: 30vh;
  padding: 0px;
  margin-bottom: 0px;
  width: 100%;
}
.checkbox-square {
  --size: 20px;
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
}
ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #92949c;
}
.info {
  color: black;
}
.text {
  color: var(--ion-color-medium-tint);
  font-size: 0.8rem;
}
.pointer {
  cursor: pointer;
}
ion-header {
  align-items: baseline;
  width: 100%;
}
.width-max {
  width: 100%;
}
/*# sourceMappingURL=login-form-template.component.css.map */
