"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthorizationMethod = void 0;
exports.AuthorizationMethod = Object.freeze({
  GROUP: 'GROUP',
  RFID: 'RFID',
  APP: 'APP',
  OCPI: 'OCPI'
});
