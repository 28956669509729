import { Component, computed, effect, EventEmitter, inject, Output, Signal } from '@angular/core';
import { CarFilters, CPOs, CPOsKeys, CPOsValues, IMapBounds } from 'common_library';
import { _ } from 'src/app/consts';
import { CoreService } from 'src/app/services/core.service';
import { StoreService } from 'src/app/services/utils/store.service';
import { AuthService } from '../../services/auth/auth.service';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app.service';

export const DEFAULT_FILTER: CarFilters = {
  quick: [],
  carId: null,
  plugs: ["Type1", "Type2", "Type2s", "Type3a", "Type3c", "CHAdeMO", "Combo1", "Combo2"],
  range: { lower: 2, upper: 350 },
  cpos: [...Object.values(CPOs)],
};

@Component({
  selector: 'yf-filter-modal',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
  standalone: false
})
export class FilterPage {
  $filterState: Signal<CarFilters> = this.mainStateService.$filterButtonState;
  appService = inject(AppService);
  $text = this.appService.$text;

  cpos = CPOs;
  @Output() filterChange = new EventEmitter<IMapBounds>();

  constructor(
    public auth: AuthService,
    public CS: CoreService,
    private store: StoreService,
    private mainStateService: MainStateService,
    ) {
    effect(() => {
      const state = this.$filterState();
      this.store.set(_.SELECTED_FILTER, state);
    })
  }

  async updateModel(event: string[] | { lower: number, upper: number }, type: string) {
    let buttonState: CarFilters = this.mainStateService.getFilterButtonState();
    this.mainStateService.setFilterButtonState({ ...buttonState, [type]: event });
    this.filterChange.emit();
  }
}
