"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthType = void 0;
exports.AuthType = Object.freeze({
  /**
   * One time use Token ID generated by a server (or App)
   * The eMSP uses this to bind a Sessiont o a customer, probably an app user.
   */
  AD_HOC_USER: 0,
  /**
   * Token ID generated by a server (or App) to identify a user of an App.
   * The same user uses the same Token for every Session
   */
  APP_USER: 1,
  OTHER: 2,
  RFID: 3,
  ADMIN: 4
});
