"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIChargingPreferencesResponse = void 0;
exports.OCPIChargingPreferencesResponse = Object.freeze({
  ACCEPTED: "ACCEPTED",
  DEPARTURE_REQUIRED: "DEPARTURE_REQUIRED",
  ENERGY_NEED_REQUIRED: "ENERGY_NEED_REQUIRED",
  NOT_POSSIBLE: "NOT_POSSIBLE",
  PROFILE_TYPE_NOT_SUPPORTED: "PROFILE_TYPE_NOT_SUPPORTED"
});
