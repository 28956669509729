<div class="container">
  <form [formGroup]="form">
    <formly-form [model]="model" [fields]="fields" [form]="form" [options]="options"></formly-form>
  </form>
  <div class="m-16">
    <a href="https://www.cvvnumber.com/cvv.html" target="_blank" style="font-size:14px">{{
      "CDC_AUTHENTICATION.WHAT_IS_CVV_CODE" | translate }}</a>
  </div>

  @if($footerVisible()){
  <ion-footer class="ion-no-border yf-footer">
    <div class="yf-row gap-6">
      <ion-button class="btn large grow" fill="outline" (click)="options.resetModel()">{{ "BUTTONS.CANCEL" | translate
        }}</ion-button>
      <ion-button class="btn large grow" (click)="submit()" [disabled]="!form.valid">{{
        "CDC_AUTHENTICATION.BUTTONS.CHECK_CARD_VALIDITY" | translate }}</ion-button>
    </div>
  </ion-footer>
  }
</div>