/* src/app/app.component.scss */
.fab-container-left {
  position: fixed;
  top: var(--yf-spacing0);
  left: var(--yf-spacing1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--yf-spacing1);
  z-index: 1;
  padding: var(--yf-spacing0);
}
@media (max-width: 576px) {
  .fab-container-left {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  .fab-container-left {
    margin-top: env(safe-area-inset-top);
  }
}
.fab-container-left .funnel-fab {
  margin-left: auto;
}
.fab-container-left ion-fab {
  position: unset;
}
.fab-container-left .left-side {
  display: flex;
  gap: var(--yf-spacing1);
}
@media (max-width: 450px) {
  .fab-container-left .left-side {
    flex-grow: 1;
  }
}
yf-filter-modal {
  position: fixed;
  top: 72px;
  z-index: 2;
}
@media (max-width: 576px) {
  yf-filter-modal {
    margin-top: env(safe-area-inset-top);
    right: var(--yf-spacing2);
    left: var(--yf-spacing2);
  }
}
@media (min-width: 576px) {
  yf-filter-modal {
    margin-top: env(safe-area-inset-top);
    right: var(--yf-spacing1);
  }
}
.fab-button-disabled {
  opacity: 0.8;
}
.button-disabled {
  opacity: 0.8;
}
ion-buttons {
  padding-left: var(--yf-spacing1);
  padding-right: var(--yf-spacing1);
  justify-content: space-around;
}
ion-button {
  margin-left: 4px;
  margin-right: 4px;
}
ion-content {
  margin-bottom: env(safe-area-inset-bottom);
}
ion-menu-toggle ion-icon {
  font-size: 30px;
  vertical-align: middle;
}
ion-footer ion-toolbar {
  border-radius: var(--yf-spacing1) var(--yf-spacing1) 0 0;
  position: fixed;
  bottom: 0;
  height: calc(52px + env(safe-area-inset-bottom));
}
.footer-button-container {
  flex-grow: 2;
  display: flex;
  justify-content: space-around;
  left: var(--yf-spacing2);
  padding: 0 var(--yf-spacing2);
}
.app-navbar-notch {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: env(safe-area-inset-top);
  --webkit-backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}
.fab-container-right {
  position: fixed;
  top: var(--yf-spacing0);
  right: var(--yf-spacing1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--yf-spacing1);
  z-index: 1;
  padding: var(--yf-spacing0);
}
@media (max-width: 576px) {
  .fab-container-right {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  .fab-container-right {
    margin-top: env(safe-area-inset-top);
  }
}
.fab-container-right .funnel-fab {
  margin-left: auto;
}
.fab-container-right ion-fab {
  position: unset;
}
::ng-deep ion-toast.toast-filter-error {
  --background: var(--ion-color-warning);
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --color: #4b4a50;
}
::ng-deep ion-toast.toast-filter-error::part(message) {
  font-style: italic;
}
::ng-deep ion-toast.toast-filter-error::part(button) {
  border-left: 1px solid #d2d2d2;
  color: #030207;
  font-size: 15px;
}
.fab-container-bottom-left {
  position: fixed;
  bottom: calc(52px + env(safe-area-inset-bottom));
  left: var(--yf-spacing1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--yf-spacing1);
  z-index: 1;
  padding: var(--yf-spacing0);
}
.fab-container-bottom-left .funnel-fab {
  margin-left: auto;
}
.fab-container-bottom-left ion-fab {
  position: unset;
}
.fab-container-bottom-left .left-side {
  display: flex;
  gap: var(--yf-spacing1);
}
@media (max-width: 450px) {
  .fab-container-bottom-left .left-side {
    flex-grow: 1;
  }
}
.fab-container-bottom-left ion-icon {
  position: absolute;
}
@media (max-width: 576px) {
  .fab-container-bottom-left {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  .fab-container-bottom-left {
    margin-top: env(safe-area-inset-top);
  }
}
.fab-container-bottom-left ion-icon:nth-of-type(2) {
  font-size: var(--yf-spacing2);
}
ion-fab-button {
  height: 48px;
  width: 48px;
}
/*# sourceMappingURL=app.component.css.map */
