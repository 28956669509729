"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GestoreUserRole = void 0;
exports.GestoreUserRole = Object.freeze({
  ADMINISTRATOR: 0,
  REPORT: 1,
  FINANCE: 2
});
