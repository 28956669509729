"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPICdrDimensionType = void 0;
exports.OCPICdrDimensionType = Object.freeze({
  CURRENT: "CURRENT",
  ENERGY: "ENERGY",
  ENERGY_EXPORT: "ENERGY_EXPORT",
  ENERGY_IMPORT: "ENERGY_IMPORT",
  MAX_CURRENT: "MAX_CURRENT",
  MIN_CURRENT: "MIN_CURRENT",
  MAX_POWER: "MAX_POWER",
  MIN_POWER: "MIN_POWER",
  PARKING_TIME: "PARKING_TIME",
  POWER: "POWER",
  RESERVATION_TIME: "RESERVATION_TIME",
  STATE_OF_CHARGE: "STATE_OF_CHARGE",
  TIME: "TIME"
});
