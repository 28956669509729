"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROFILE = exports.WHITE_LIST = exports.COMMANDS_RESULT = exports.COMMANDS_RESPONSE = exports.COMMANDS = void 0;
exports.COMMANDS = {
  CANCEL_RESERVATION: 'CANCEL_RESERVATION',
  RESERVE_NOW: 'RESERVE_NOW',
  START_SESSION: 'START_SESSION',
  STOP_SESSION: 'STOP_SESSION',
  UNLOCK_CONNECTOR: 'UNLOCK_CONNECTOR'
};
exports.COMMANDS_RESPONSE = {
  NOT_SUPPORTED: 'NOT_SUPPORTED',
  REJECTED: 'REJECTED',
  ACCEPTED: 'ACCEPTED',
  UNKNOWN_SESION: 'UNKNOWN_SESSION'
};
exports.COMMANDS_RESULT = {
  ACCEPTED: 'ACCEPTED',
  CANCELED_RESERVATION: 'CANCELED_RESERVATION',
  EVSE_OCCUPIED: 'EVSE_OCCUPIED',
  EVSE_INOPERATIVE: 'EVSE_INOPERATIVE',
  FAILED: 'FAILED',
  NOT_SUPPORTED: 'NOT_SUPPORTED',
  REJECTED: 'REJECTED',
  TIMEOUT: 'TIMEOUT',
  UNKNOWN_RESERVATION: 'UNKNOWN_RESERVATION'
};
exports.WHITE_LIST = {
  /**
   * Token always has to be whitelisted, realtime authorization is not possible/allowed.
   * CPO shall always allow any use of this Token.
   */
  ALWAYS: 'ALWAYS',
  /**
   * It is allowed to whitelist the token, realtime authorization is also allowed.
   * The CPO may choose which version of authorization to use.
   */
  ALLOWED: 'ALLOWED',
  /**
   * In normal situations realtime authorization shall be used.
   * But when the CPO cannot get a response from the eMSP (communication between CPO and eMSP is offline)
   * the CPO shall allow this Token to be used.
   */
  ALLOWD_OFFLINE: 'ALLOWED_OFFLINE',
  /**
   * Whitelisting is forbidden, only realtime authorization is allowed.
   * CPO shall always send a realtime authorization for any use of this Token to the eMSP
   */
  NEVER: 'NEVER'
};
exports.PROFILE = {
  /**
   * Driver wants to use the cheapest charging profile possible.
   */
  CHEAP: 'CHEAP',
  /**
   * Driver wants his EV charged as quickly as possible and is willing to pay a premium for this if needed
   */
  FAST: 'FAST',
  /**
   * Driver wants his EV charged with as much regenerative (green) energy as possible.
   */
  GREEN: 'GREEN',
  /**
   * Driver does not have special preferences
   */
  REGULAR: 'REGULAR'
};
