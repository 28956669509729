"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SessionCmd = void 0;
exports.SessionCmd = Object.freeze({
  Reserve: "RESERVE",
  CancelReservation: "CANCEL_RESERVATION",
  StartCharge: "START_CHARGE",
  StopCharge: "STOP_CHARGE",
  EndSession: "END_SESSION",
  SetPowerLimit: "SET_POWER_LIMIT",
  ClearPowerLimit: "CLEAR_POWER_LIMIT"
});
