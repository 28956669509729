import { Component, Input, input } from '@angular/core';

@Component({
    selector: 'yf-charge-percentage-info',
    templateUrl: './charge-percentage-info.component.html',
    styleUrls: ['./charge-percentage-info.component.scss'],
    standalone: false
})
export class ChargePercentageInfoComponent  {
  @Input() percentage: number;
  $textClass = input<string>('fs-13');
  $umClass = input<string>('fs-11');

  constructor() { }

  $percentage = input<number>(null);
}
