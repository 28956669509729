<app-master-detail class="yf-master-detail" icon="cash-outline"
  [title]="masterOrDetail!=='master' ? ('MENU.PAYMENT_METHOD' | translate) : ''"
  titleMaster="{{'MENU.PAYMENT_METHOD' | translate}}" [titleDetail]="(currentStepDetail$ | async)?.title"
  [(show)]="masterOrDetail" filter="" (compactModeChange)="compactModeChange($event)" [forceCompact]="false">

  <div class="master">
    @if(waiting$ | async){
    <div [ngClass]="!compactMode ? 'detail-item' : 'detail-item-no-border'">
      <yf-cdc-waiting></yf-cdc-waiting>
    </div>
    }
    @else {
    <div class="m-6" *ngFor="let step of this.stepItems$|async; let index = index;"
      [ngClass]="{ selected: (currentStepDetail$ | async)?.id===step.id }">
      <ion-item button detail="true" [disabled]="!step.previuosStepsAccepted" (click)="showStepItem(step)">
        <ion-icon [name]="step.icon? step.icon : 'document-outline'" class="item-icon" slot="start"> </ion-icon>
        <ion-label>
          <h3 class="fw-600">{{ step.title }}</h3>
        </ion-label>
      </ion-item>
    </div>
    }
  </div>

  @if(currentStepDetail$ | async; as currentStep){
  <div class="pl-6 detail">
    @if(currentStep.stepFromCms){
    <div class="yf-container">
      <div>
        <div *ngFor="let section of currentStep.sections">
          <div class="mt-4 section-subtitle" *ngIf="section.subtitle">{{ currentStep.subtitle }}</div>
          <div class="mt-10 section-html" *ngIf="section.html" [innerHTML]="section.html"></div>
        </div>
      </div>
    </div>
    <ion-footer class="ion-no-border yf-footer">
      <ion-item class="pl-18 pb-18" *ngIf="currentStep.mustBeAccetped" (click)="toggleAcceptStepItem()">
        <ion-checkbox #ionCheckBox [checked]="currentStep.accepted" [disabled]="currentStep.accepted"></ion-checkbox>
        <ion-label>{{ "CDC_AUTHENTICATION.BUTTONS.ACCEPT_TERMS" | translate }}</ion-label>
      </ion-item>
      <ion-button
        [disabled]="currentStep.mustBeAccetped && (currentStepDetailAccepted$ | async)!==true && (loadingNextStep$|async)===false"
        class="btn-yf" color="secondary" expand="block" (click)="showNextStepItem(currentStep)">{{ "BUTTONS.NEXT" |
        translate }}
        <ion-icon slot="end" src="assets/app-icons/arrow_forward.svg"></ion-icon>
      </ion-button>
    </ion-footer>
    }
    @else{
    <div *ngIf="currentStep.type==='CREDIT_CARD'" [ngClass]="!compactMode ? 'detail-item' : 'detail-item-no-border'">
      @if (paymentAuth$ | async; as paymentAuth) {
      <div class="recap-card-container">
        <div>
          <div class="ion-padding">
            <ion-text>
              {{ ("CDC_AUTHENTICATION.AUTHENTICATION_MESSAGE" | translate:{value: paymentAuth.authorizedAt |
              date:'dd/MM/yyyy', value2: (paymentAuth.amount || 50) | number:'1.1-1' }) }}
            </ion-text>
          </div>
          <ion-grid class="credit-card-details ion-padding ion-margin">
            <ion-row>
              <ion-col fixed>
                <ion-text color="dark" class="title">{{"CDC_AUTHENTICATION.CARD_NUMBER" | translate}}</ion-text>
                <ion-text color="dark" class="subtitle">**** **** **** {{ paymentAuth.token }}</ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="ion-padding-top">
              <ion-col>
                <ion-text color="dark" class="title">{{ "CDC_AUTHENTICATION.CARD_EXPIRATION" | translate
                  }}</ion-text>
                <ion-text color="dark" class="subtitle">{{ paymentAuth.cdcExpiryMonth }}/{{
                  paymentAuth.cdcExpiryYear
                  }}</ion-text>
              </ion-col>
              <ion-col class="ion-align-self-end" size="2">
                <ion-img [src]="CU.getLogoByCdcType(paymentAuth.cdcType)"></ion-img>
              </ion-col>
            </ion-row>
          </ion-grid>

          <div class="ion-padding">
            <ion-text>
              {{ "CDC_AUTHENTICATION.REVOKE_MESSAGE" | translate}}
            </ion-text>
          </div>

          <div class="ion-padding">
            <ion-text>
              {{"CDC_AUTHENTICATION.NEXT_AUTHORIZATION_MESSAGE" | translate}}
            </ion-text>
          </div>

        </div>
        <div class="ion-padding-horizontal" class="footer">
          @if($sessionInProgress()){
          <div class="revoke-banner">
            <ion-card>
              <ion-card-content>
                {{ "CDC_AUTHENTICATION.CANT_REVOKE_AUTHORIZATION" | translate}}
              </ion-card-content>
            </ion-card>
          </div>
          }
          @else {
          <ion-button expand="block" color="danger" [disabled]="$sessionInProgress()" (click)="cdcRevocation()">{{
            "CDC_AUTHENTICATION.BUTTONS.REVOKE_AUTORIZATION" | translate }}</ion-button>
          }
        </div>
      </div>
      }

      @else{
      <div class="credit-card-form-container">
        <ion-text>{{ "CDC_AUTHENTICATION.CREDIT_CARD" | translate }}</ion-text>
        <yfl-credit-card-form (cdcSubmitted)="cdcSubmitted($event)"></yfl-credit-card-form>
      </div>
      }

    </div>
    }
  </div>
  }
</app-master-detail>