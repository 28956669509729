import { Component, Input, input, model, OnInit, output, ɵINPUT_SIGNAL_BRAND_WRITE_TYPE } from '@angular/core'
import { CPOs, CPOsKeys, CPOsValues } from 'common_library';


@Component({
    selector: 'yf-filter-cpo-selector',
    templateUrl: './filter-cpo-selector.component.html',
    styleUrls: ['./filter-cpo-selector.component.scss'],
    standalone: false
})
export class FilterCpoSelectorComponent implements OnInit {

  @Input({ required: true, transform: (value: any) => Object.keys(value).map((key) => {
    console.log("Transforming", key, value, CPOs[key]);
    const cpoValue = CPOs[key];
    // Per ora le unknown le lasciamo sempre fuori tramite UI, ma verranno considerate come CPO nel filtro...
    return {
      key,
      value: cpoValue,
      image: `assets/stations/${cpoValue.toLocaleLowerCase()}logo.svg`
    }
  })
}) cpos;
  @Input() selectedCpos = []
  cposOutput = output<string[]>();
  images = CPOs;
  constructor() { }

  ngOnInit() {
    console.log("Starting on init", this.selectedCpos);
    console.log("Just tracking...", this.cpos);
  }
  cposWithImages(cpos) {
    return Object.keys(cpos).map((key) => {
      return { key, value: cpos[key] };
    });
  }
  selectCpo(cpo: string) {
    if (this.selectedCpos.includes(cpo)) {
      this.selectedCpos.splice(this.selectedCpos.findIndex((p) => p === cpo), 1);
      this.emitCpos();
    } else {
      this.selectedCpos.push(cpo);
      this.emitCpos();
    }
  }

  emitCpos() {
    this.cposOutput.emit(this.selectedCpos);
  }
}
