import * as i0 from '@angular/core';
import { Directive, HostListener, Component, ChangeDetectionStrategy, Attribute, Optional, SkipSelf, ViewChild, ContentChild, ContentChildren, forwardRef, Injectable, inject, Injector, EnvironmentInjector, APP_INITIALIZER, NgZone, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, MaxValidator, MinValidator } from '@angular/forms';
import * as i2$1 from '@ionic/angular/common';
import { ValueAccessor, setIonicClasses, IonRouterOutlet as IonRouterOutlet$1, IonTabs as IonTabs$1, IonBackButton as IonBackButton$1, IonNav as IonNav$1, RouterLinkDelegateDirective as RouterLinkDelegateDirective$1, RouterLinkWithHrefDelegateDirective as RouterLinkWithHrefDelegateDirective$1, IonModal as IonModal$1, IonPopover as IonPopover$1, OverlayBaseController, MenuController as MenuController$1, AngularDelegate, raf, ConfigToken, provideComponentInputBinding } from '@ionic/angular/common';
export { AngularDelegate, Config, DomController, IonicRouteStrategy, NavController, NavParams, Platform } from '@ionic/angular/common';
import { __decorate } from 'tslib';
import { fromEvent } from 'rxjs';
import * as i1 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i2 from '@angular/router';
import { alertController, createAnimation, getTimeGivenProgression, actionSheetController, createGesture, loadingController, menuController, modalController, pickerController, popoverController, toastController, setupConfig } from '@ionic/core';
export { IonicSafeString, IonicSlides, createAnimation, createGesture, getIonPageElement, getPlatforms, getTimeGivenProgression, iosTransitionAnimation, isPlatform, mdTransitionAnimation, openURL } from '@ionic/core';
import { applyPolyfills, defineCustomElements } from '@ionic/core/loader';
class BooleanValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  writeValue(value) {
    this.elementRef.nativeElement.checked = this.lastValue = value;
    setIonicClasses(this.elementRef);
  }
  _handleIonChange(el) {
    this.handleValueChange(el, el.checked);
  }
}
/** @nocollapse */
BooleanValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: BooleanValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
BooleanValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: BooleanValueAccessorDirective,
  selector: "ion-checkbox,ion-toggle",
  host: {
    listeners: {
      "ionChange": "_handleIonChange($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: BooleanValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: BooleanValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-checkbox,ion-toggle',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleIonChange: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});
class NumericValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  handleInputEvent(el) {
    this.handleValueChange(el, el.value);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      fn(value === '' ? null : parseFloat(value));
    });
  }
}
/** @nocollapse */
NumericValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: NumericValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
NumericValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: NumericValueAccessorDirective,
  selector: "ion-input[type=number]",
  host: {
    listeners: {
      "ionInput": "handleInputEvent($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: NumericValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: NumericValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-input[type=number]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumericValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    handleInputEvent: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  }
});
class RadioValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleIonSelect(el) {
    /**
     * The `el` type is any to access the `checked` state property
     * that is not exposed on the type interface.
     */
    this.handleValueChange(el, el.checked);
  }
}
/** @nocollapse */
RadioValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: RadioValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
RadioValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: RadioValueAccessorDirective,
  selector: "ion-radio",
  host: {
    listeners: {
      "ionSelect": "_handleIonSelect($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: RadioValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: RadioValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'ion-radio',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: RadioValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleIonSelect: [{
      type: HostListener,
      args: ['ionSelect', ['$event.target']]
    }]
  }
});
class SelectValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleChangeEvent(el) {
    this.handleValueChange(el, el.value);
  }
}
/** @nocollapse */
SelectValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: SelectValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
SelectValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: SelectValueAccessorDirective,
  selector: "ion-select, ion-radio-group, ion-segment, ion-datetime",
  host: {
    listeners: {
      "ionChange": "_handleChangeEvent($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SelectValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: SelectValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 'ion-select, ion-radio-group, ion-segment, ion-datetime',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleChangeEvent: [{
      type: HostListener,
      args: ['ionChange', ['$event.target']]
    }]
  }
});

// TODO(FW-5495): rename class since range isn't a text component
class TextValueAccessorDirective extends ValueAccessor {
  constructor(injector, el) {
    super(injector, el);
  }
  _handleInputEvent(el) {
    this.handleValueChange(el, el.value);
  }
}
/** @nocollapse */
TextValueAccessorDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: TextValueAccessorDirective,
  deps: [{
    token: i0.Injector
  }, {
    token: i0.ElementRef
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
TextValueAccessorDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: TextValueAccessorDirective,
  selector: "ion-input:not([type=number]),ion-textarea,ion-searchbar,ion-range",
  host: {
    listeners: {
      "ionInput": "_handleInputEvent($event.target)"
    }
  },
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: TextValueAccessorDirective,
    multi: true
  }],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: TextValueAccessorDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-input:not([type=number]),ion-textarea,ion-searchbar,ion-range',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessorDirective,
        multi: true
      }]
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.Injector
    }, {
      type: i0.ElementRef
    }];
  },
  propDecorators: {
    _handleInputEvent: [{
      type: HostListener,
      args: ['ionInput', ['$event.target']]
    }]
  }
});

/* eslint-disable */
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let IonAccordion = class IonAccordion {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAccordion.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordion,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAccordion.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAccordion,
  selector: "ion-accordion",
  inputs: {
    disabled: "disabled",
    mode: "mode",
    readonly: "readonly",
    toggleIcon: "toggleIcon",
    toggleIconSlot: "toggleIconSlot",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAccordion = __decorate([ProxyCmp({
  inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
})], IonAccordion);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordion,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-accordion',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'mode', 'readonly', 'toggleIcon', 'toggleIconSlot', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAccordionGroup = class IonAccordionGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonAccordionGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordionGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAccordionGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAccordionGroup,
  selector: "ion-accordion-group",
  inputs: {
    animated: "animated",
    disabled: "disabled",
    expand: "expand",
    mode: "mode",
    multiple: "multiple",
    readonly: "readonly",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAccordionGroup = __decorate([ProxyCmp({
  inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
})], IonAccordionGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAccordionGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-accordion-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'disabled', 'expand', 'mode', 'multiple', 'readonly', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonActionSheet = class IonActionSheet {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionActionSheetDidPresent', 'ionActionSheetWillPresent', 'ionActionSheetWillDismiss', 'ionActionSheetDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonActionSheet.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonActionSheet,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonActionSheet.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonActionSheet,
  selector: "ion-action-sheet",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonActionSheet = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonActionSheet);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonActionSheet,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-action-sheet',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'subHeader', 'translucent', 'trigger']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAlert = class IonAlert {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionAlertDidPresent', 'ionAlertWillPresent', 'ionAlertWillDismiss', 'ionAlertDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonAlert.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAlert,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAlert.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAlert,
  selector: "ion-alert",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    cssClass: "cssClass",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    inputs: "inputs",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    subHeader: "subHeader",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAlert = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonAlert);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAlert,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-alert',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'cssClass', 'enterAnimation', 'header', 'htmlAttributes', 'inputs', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'subHeader', 'translucent', 'trigger']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonApp = class IonApp {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonApp.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonApp,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonApp.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonApp,
  selector: "ion-app",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonApp = __decorate([ProxyCmp({})], IonApp);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonApp,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-app',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonAvatar = class IonAvatar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonAvatar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAvatar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonAvatar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonAvatar,
  selector: "ion-avatar",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonAvatar = __decorate([ProxyCmp({})], IonAvatar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonAvatar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-avatar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBackdrop = class IonBackdrop {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionBackdropTap']);
  }
};
/** @nocollapse */
IonBackdrop.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackdrop,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBackdrop.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBackdrop,
  selector: "ion-backdrop",
  inputs: {
    stopPropagation: "stopPropagation",
    tappable: "tappable",
    visible: "visible"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBackdrop = __decorate([ProxyCmp({
  inputs: ['stopPropagation', 'tappable', 'visible']
})], IonBackdrop);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackdrop,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-backdrop',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['stopPropagation', 'tappable', 'visible']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBadge = class IonBadge {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonBadge.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBadge,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBadge.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBadge,
  selector: "ion-badge",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBadge = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonBadge);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBadge,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBreadcrumb = class IonBreadcrumb {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonBreadcrumb.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumb,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBreadcrumb.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBreadcrumb,
  selector: "ion-breadcrumb",
  inputs: {
    active: "active",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    separator: "separator",
    target: "target"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBreadcrumb = __decorate([ProxyCmp({
  inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
})], IonBreadcrumb);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumb,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'separator', 'target']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonBreadcrumbs = class IonBreadcrumbs {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCollapsedClick']);
  }
};
/** @nocollapse */
IonBreadcrumbs.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumbs,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBreadcrumbs.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBreadcrumbs,
  selector: "ion-breadcrumbs",
  inputs: {
    color: "color",
    itemsAfterCollapse: "itemsAfterCollapse",
    itemsBeforeCollapse: "itemsBeforeCollapse",
    maxItems: "maxItems",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonBreadcrumbs = __decorate([ProxyCmp({
  inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
})], IonBreadcrumbs);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBreadcrumbs,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-breadcrumbs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'itemsAfterCollapse', 'itemsBeforeCollapse', 'maxItems', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonButton = class IonButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonButton,
  selector: "ion-button",
  inputs: {
    buttonType: "buttonType",
    color: "color",
    disabled: "disabled",
    download: "download",
    expand: "expand",
    fill: "fill",
    form: "form",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    size: "size",
    strong: "strong",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonButton = __decorate([ProxyCmp({
  inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
})], IonButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buttonType', 'color', 'disabled', 'download', 'expand', 'fill', 'form', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'size', 'strong', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonButtons = class IonButtons {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonButtons.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButtons,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonButtons.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonButtons,
  selector: "ion-buttons",
  inputs: {
    collapse: "collapse"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonButtons = __decorate([ProxyCmp({
  inputs: ['collapse']
})], IonButtons);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonButtons,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-buttons',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCard = class IonCard {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCard.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCard,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCard.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCard,
  selector: "ion-card",
  inputs: {
    button: "button",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCard = __decorate([ProxyCmp({
  inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
})], IonCard);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCard,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardContent = class IonCardContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardContent,
  selector: "ion-card-content",
  inputs: {
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardContent = __decorate([ProxyCmp({
  inputs: ['mode']
})], IonCardContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardHeader = class IonCardHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardHeader,
  selector: "ion-card-header",
  inputs: {
    color: "color",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardHeader = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'translucent']
})], IonCardHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardSubtitle = class IonCardSubtitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardSubtitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardSubtitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardSubtitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardSubtitle,
  selector: "ion-card-subtitle",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardSubtitle = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonCardSubtitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardSubtitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-subtitle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCardTitle = class IonCardTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCardTitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardTitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCardTitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCardTitle,
  selector: "ion-card-title",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCardTitle = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonCardTitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCardTitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-card-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCheckbox = class IonCheckbox {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonCheckbox.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCheckbox,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCheckbox.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCheckbox,
  selector: "ion-checkbox",
  inputs: {
    alignment: "alignment",
    checked: "checked",
    color: "color",
    disabled: "disabled",
    indeterminate: "indeterminate",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCheckbox = __decorate([ProxyCmp({
  inputs: ['alignment', 'checked', 'color', 'disabled', 'indeterminate', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
})], IonCheckbox);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCheckbox,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'checked', 'color', 'disabled', 'indeterminate', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonChip = class IonChip {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonChip.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonChip,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonChip.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonChip,
  selector: "ion-chip",
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    outline: "outline"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonChip = __decorate([ProxyCmp({
  inputs: ['color', 'disabled', 'mode', 'outline']
})], IonChip);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonChip,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'mode', 'outline']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonCol = class IonCol {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonCol.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCol,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonCol.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonCol,
  selector: "ion-col",
  inputs: {
    offset: "offset",
    offsetLg: "offsetLg",
    offsetMd: "offsetMd",
    offsetSm: "offsetSm",
    offsetXl: "offsetXl",
    offsetXs: "offsetXs",
    pull: "pull",
    pullLg: "pullLg",
    pullMd: "pullMd",
    pullSm: "pullSm",
    pullXl: "pullXl",
    pullXs: "pullXs",
    push: "push",
    pushLg: "pushLg",
    pushMd: "pushMd",
    pushSm: "pushSm",
    pushXl: "pushXl",
    pushXs: "pushXs",
    size: "size",
    sizeLg: "sizeLg",
    sizeMd: "sizeMd",
    sizeSm: "sizeSm",
    sizeXl: "sizeXl",
    sizeXs: "sizeXs"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonCol = __decorate([ProxyCmp({
  inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
})], IonCol);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonCol,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-col',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['offset', 'offsetLg', 'offsetMd', 'offsetSm', 'offsetXl', 'offsetXs', 'pull', 'pullLg', 'pullMd', 'pullSm', 'pullXl', 'pullXs', 'push', 'pushLg', 'pushMd', 'pushSm', 'pushXl', 'pushXs', 'size', 'sizeLg', 'sizeMd', 'sizeSm', 'sizeXl', 'sizeXs']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonContent = class IonContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionScrollStart', 'ionScroll', 'ionScrollEnd']);
  }
};
/** @nocollapse */
IonContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonContent,
  selector: "ion-content",
  inputs: {
    color: "color",
    forceOverscroll: "forceOverscroll",
    fullscreen: "fullscreen",
    scrollEvents: "scrollEvents",
    scrollX: "scrollX",
    scrollY: "scrollY"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonContent = __decorate([ProxyCmp({
  inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY'],
  methods: ['getScrollElement', 'scrollToTop', 'scrollToBottom', 'scrollByPoint', 'scrollToPoint']
})], IonContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'forceOverscroll', 'fullscreen', 'scrollEvents', 'scrollX', 'scrollY']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonDatetime = class IonDatetime {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionCancel', 'ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonDatetime.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetime,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonDatetime.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonDatetime,
  selector: "ion-datetime",
  inputs: {
    cancelText: "cancelText",
    clearText: "clearText",
    color: "color",
    dayValues: "dayValues",
    disabled: "disabled",
    doneText: "doneText",
    firstDayOfWeek: "firstDayOfWeek",
    formatOptions: "formatOptions",
    highlightedDates: "highlightedDates",
    hourCycle: "hourCycle",
    hourValues: "hourValues",
    isDateEnabled: "isDateEnabled",
    locale: "locale",
    max: "max",
    min: "min",
    minuteValues: "minuteValues",
    mode: "mode",
    monthValues: "monthValues",
    multiple: "multiple",
    name: "name",
    preferWheel: "preferWheel",
    presentation: "presentation",
    readonly: "readonly",
    showClearButton: "showClearButton",
    showDefaultButtons: "showDefaultButtons",
    showDefaultTimeLabel: "showDefaultTimeLabel",
    showDefaultTitle: "showDefaultTitle",
    size: "size",
    titleSelectedDatesFormatter: "titleSelectedDatesFormatter",
    value: "value",
    yearValues: "yearValues"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonDatetime = __decorate([ProxyCmp({
  inputs: ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'formatOptions', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues'],
  methods: ['confirm', 'reset', 'cancel']
})], IonDatetime);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetime,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-datetime',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cancelText', 'clearText', 'color', 'dayValues', 'disabled', 'doneText', 'firstDayOfWeek', 'formatOptions', 'highlightedDates', 'hourCycle', 'hourValues', 'isDateEnabled', 'locale', 'max', 'min', 'minuteValues', 'mode', 'monthValues', 'multiple', 'name', 'preferWheel', 'presentation', 'readonly', 'showClearButton', 'showDefaultButtons', 'showDefaultTimeLabel', 'showDefaultTitle', 'size', 'titleSelectedDatesFormatter', 'value', 'yearValues']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonDatetimeButton = class IonDatetimeButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonDatetimeButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetimeButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonDatetimeButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonDatetimeButton,
  selector: "ion-datetime-button",
  inputs: {
    color: "color",
    datetime: "datetime",
    disabled: "disabled",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonDatetimeButton = __decorate([ProxyCmp({
  inputs: ['color', 'datetime', 'disabled', 'mode']
})], IonDatetimeButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonDatetimeButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-datetime-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'datetime', 'disabled', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFab = class IonFab {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFab.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFab,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFab.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFab,
  selector: "ion-fab",
  inputs: {
    activated: "activated",
    edge: "edge",
    horizontal: "horizontal",
    vertical: "vertical"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFab = __decorate([ProxyCmp({
  inputs: ['activated', 'edge', 'horizontal', 'vertical'],
  methods: ['close']
})], IonFab);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFab,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'edge', 'horizontal', 'vertical']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFabButton = class IonFabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonFabButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFabButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFabButton,
  selector: "ion-fab-button",
  inputs: {
    activated: "activated",
    closeIcon: "closeIcon",
    color: "color",
    disabled: "disabled",
    download: "download",
    href: "href",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    show: "show",
    size: "size",
    target: "target",
    translucent: "translucent",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFabButton = __decorate([ProxyCmp({
  inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
})], IonFabButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'closeIcon', 'color', 'disabled', 'download', 'href', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'show', 'size', 'target', 'translucent', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFabList = class IonFabList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFabList.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabList,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFabList.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFabList,
  selector: "ion-fab-list",
  inputs: {
    activated: "activated",
    side: "side"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFabList = __decorate([ProxyCmp({
  inputs: ['activated', 'side']
})], IonFabList);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFabList,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-fab-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'side']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonFooter = class IonFooter {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonFooter.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFooter,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonFooter.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonFooter,
  selector: "ion-footer",
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonFooter = __decorate([ProxyCmp({
  inputs: ['collapse', 'mode', 'translucent']
})], IonFooter);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonFooter,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-footer',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonGrid = class IonGrid {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonGrid.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonGrid,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonGrid.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonGrid,
  selector: "ion-grid",
  inputs: {
    fixed: "fixed"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonGrid = __decorate([ProxyCmp({
  inputs: ['fixed']
})], IonGrid);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonGrid,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-grid',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['fixed']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonHeader = class IonHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonHeader,
  selector: "ion-header",
  inputs: {
    collapse: "collapse",
    mode: "mode",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonHeader = __decorate([ProxyCmp({
  inputs: ['collapse', 'mode', 'translucent']
})], IonHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['collapse', 'mode', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonIcon = class IonIcon {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonIcon.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonIcon,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonIcon.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonIcon,
  selector: "ion-icon",
  inputs: {
    color: "color",
    flipRtl: "flipRtl",
    icon: "icon",
    ios: "ios",
    lazy: "lazy",
    md: "md",
    mode: "mode",
    name: "name",
    sanitize: "sanitize",
    size: "size",
    src: "src"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonIcon = __decorate([ProxyCmp({
  inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
})], IonIcon);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonIcon,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'flipRtl', 'icon', 'ios', 'lazy', 'md', 'mode', 'name', 'sanitize', 'size', 'src']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonImg = class IonImg {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionImgWillLoad', 'ionImgDidLoad', 'ionError']);
  }
};
/** @nocollapse */
IonImg.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonImg,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonImg.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonImg,
  selector: "ion-img",
  inputs: {
    alt: "alt",
    src: "src"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonImg = __decorate([ProxyCmp({
  inputs: ['alt', 'src']
})], IonImg);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonImg,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-img',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alt', 'src']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInfiniteScroll = class IonInfiniteScroll {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInfinite']);
  }
};
/** @nocollapse */
IonInfiniteScroll.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScroll,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInfiniteScroll.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonInfiniteScroll,
  selector: "ion-infinite-scroll",
  inputs: {
    disabled: "disabled",
    position: "position",
    threshold: "threshold"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInfiniteScroll = __decorate([ProxyCmp({
  inputs: ['disabled', 'position', 'threshold'],
  methods: ['complete']
})], IonInfiniteScroll);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScroll,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'position', 'threshold']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInfiniteScrollContent = class IonInfiniteScrollContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonInfiniteScrollContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScrollContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInfiniteScrollContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonInfiniteScrollContent,
  selector: "ion-infinite-scroll-content",
  inputs: {
    loadingSpinner: "loadingSpinner",
    loadingText: "loadingText"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInfiniteScrollContent = __decorate([ProxyCmp({
  inputs: ['loadingSpinner', 'loadingText']
})], IonInfiniteScrollContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInfiniteScrollContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-infinite-scroll-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['loadingSpinner', 'loadingText']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonInput = class IonInput {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonInput.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInput,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonInput.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonInput,
  selector: "ion-input",
  inputs: {
    accept: "accept",
    autocapitalize: "autocapitalize",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    autofocus: "autofocus",
    clearInput: "clearInput",
    clearOnEdit: "clearOnEdit",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    maxlength: "maxlength",
    min: "min",
    minlength: "minlength",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    pattern: "pattern",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    shape: "shape",
    size: "size",
    spellcheck: "spellcheck",
    step: "step",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonInput = __decorate([ProxyCmp({
  inputs: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'shape', 'size', 'spellcheck', 'step', 'type', 'value'],
  methods: ['setFocus', 'getInputElement']
})], IonInput);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonInput,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-input',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accept', 'autocapitalize', 'autocomplete', 'autocorrect', 'autofocus', 'clearInput', 'clearOnEdit', 'color', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'max', 'maxlength', 'min', 'minlength', 'mode', 'multiple', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'shape', 'size', 'spellcheck', 'step', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItem = class IonItem {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItem.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItem,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItem.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItem,
  selector: "ion-item",
  inputs: {
    button: "button",
    color: "color",
    counter: "counter",
    counterFormatter: "counterFormatter",
    detail: "detail",
    detailIcon: "detailIcon",
    disabled: "disabled",
    download: "download",
    fill: "fill",
    href: "href",
    lines: "lines",
    mode: "mode",
    rel: "rel",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection",
    shape: "shape",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItem = __decorate([ProxyCmp({
  inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
})], IonItem);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItem,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['button', 'color', 'counter', 'counterFormatter', 'detail', 'detailIcon', 'disabled', 'download', 'fill', 'href', 'lines', 'mode', 'rel', 'routerAnimation', 'routerDirection', 'shape', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemDivider = class IonItemDivider {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemDivider.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemDivider,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemDivider.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemDivider,
  selector: "ion-item-divider",
  inputs: {
    color: "color",
    mode: "mode",
    sticky: "sticky"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemDivider = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'sticky']
})], IonItemDivider);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemDivider,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'sticky']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemGroup = class IonItemGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemGroup,
  selector: "ion-item-group",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemGroup = __decorate([ProxyCmp({})], IonItemGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemOption = class IonItemOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonItemOption.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOption,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemOption.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemOption,
  selector: "ion-item-option",
  inputs: {
    color: "color",
    disabled: "disabled",
    download: "download",
    expandable: "expandable",
    href: "href",
    mode: "mode",
    rel: "rel",
    target: "target",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemOption = __decorate([ProxyCmp({
  inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
})], IonItemOption);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOption,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'download', 'expandable', 'href', 'mode', 'rel', 'target', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemOptions = class IonItemOptions {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSwipe']);
  }
};
/** @nocollapse */
IonItemOptions.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOptions,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemOptions.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemOptions,
  selector: "ion-item-options",
  inputs: {
    side: "side"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemOptions = __decorate([ProxyCmp({
  inputs: ['side']
})], IonItemOptions);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemOptions,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-options',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['side']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonItemSliding = class IonItemSliding {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionDrag']);
  }
};
/** @nocollapse */
IonItemSliding.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemSliding,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonItemSliding.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonItemSliding,
  selector: "ion-item-sliding",
  inputs: {
    disabled: "disabled"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonItemSliding = __decorate([ProxyCmp({
  inputs: ['disabled'],
  methods: ['getOpenAmount', 'getSlidingRatio', 'open', 'close', 'closeOpened']
})], IonItemSliding);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonItemSliding,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-item-sliding',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonLabel = class IonLabel {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonLabel.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLabel,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonLabel.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonLabel,
  selector: "ion-label",
  inputs: {
    color: "color",
    mode: "mode",
    position: "position"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonLabel = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'position']
})], IonLabel);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLabel,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-label',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'position']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonList = class IonList {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonList.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonList,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonList.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonList,
  selector: "ion-list",
  inputs: {
    inset: "inset",
    lines: "lines",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonList = __decorate([ProxyCmp({
  inputs: ['inset', 'lines', 'mode'],
  methods: ['closeSlidingItems']
})], IonList);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonList,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['inset', 'lines', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonListHeader = class IonListHeader {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonListHeader.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonListHeader,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonListHeader.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonListHeader,
  selector: "ion-list-header",
  inputs: {
    color: "color",
    lines: "lines",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonListHeader = __decorate([ProxyCmp({
  inputs: ['color', 'lines', 'mode']
})], IonListHeader);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonListHeader,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-list-header',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'lines', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonLoading = class IonLoading {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionLoadingDidPresent', 'ionLoadingWillPresent', 'ionLoadingWillDismiss', 'ionLoadingDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonLoading.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLoading,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonLoading.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonLoading,
  selector: "ion-loading",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    showBackdrop: "showBackdrop",
    spinner: "spinner",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonLoading = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonLoading);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonLoading,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-loading',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'message', 'mode', 'showBackdrop', 'spinner', 'translucent', 'trigger']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenu = class IonMenu {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionWillOpen', 'ionWillClose', 'ionDidOpen', 'ionDidClose']);
  }
};
/** @nocollapse */
IonMenu.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenu,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenu.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMenu,
  selector: "ion-menu",
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    maxEdgeStart: "maxEdgeStart",
    menuId: "menuId",
    side: "side",
    swipeGesture: "swipeGesture",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenu = __decorate([ProxyCmp({
  inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type'],
  methods: ['isOpen', 'isActive', 'open', 'close', 'toggle', 'setOpen']
})], IonMenu);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenu,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'maxEdgeStart', 'menuId', 'side', 'swipeGesture', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenuButton = class IonMenuButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenuButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMenuButton,
  selector: "ion-menu-button",
  inputs: {
    autoHide: "autoHide",
    color: "color",
    disabled: "disabled",
    menu: "menu",
    mode: "mode",
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenuButton = __decorate([ProxyCmp({
  inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
})], IonMenuButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'color', 'disabled', 'menu', 'mode', 'type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonMenuToggle = class IonMenuToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonMenuToggle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuToggle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonMenuToggle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMenuToggle,
  selector: "ion-menu-toggle",
  inputs: {
    autoHide: "autoHide",
    menu: "menu"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonMenuToggle = __decorate([ProxyCmp({
  inputs: ['autoHide', 'menu']
})], IonMenuToggle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMenuToggle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-menu-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoHide', 'menu']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonNavLink = class IonNavLink {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNavLink.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNavLink,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNavLink.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonNavLink,
  selector: "ion-nav-link",
  inputs: {
    component: "component",
    componentProps: "componentProps",
    routerAnimation: "routerAnimation",
    routerDirection: "routerDirection"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonNavLink = __decorate([ProxyCmp({
  inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
})], IonNavLink);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNavLink,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-nav-link',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['component', 'componentProps', 'routerAnimation', 'routerDirection']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonNote = class IonNote {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonNote.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNote,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNote.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonNote,
  selector: "ion-note",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonNote = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonNote);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNote,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-note',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonPicker = class IonPicker {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionPickerDidPresent', 'ionPickerWillPresent', 'ionPickerWillDismiss', 'ionPickerDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonPicker.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPicker,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonPicker.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonPicker,
  selector: "ion-picker",
  inputs: {
    animated: "animated",
    backdropDismiss: "backdropDismiss",
    buttons: "buttons",
    columns: "columns",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    htmlAttributes: "htmlAttributes",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    leaveAnimation: "leaveAnimation",
    mode: "mode",
    showBackdrop: "showBackdrop",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonPicker = __decorate([ProxyCmp({
  inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss', 'getColumn']
})], IonPicker);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPicker,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-picker',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'backdropDismiss', 'buttons', 'columns', 'cssClass', 'duration', 'enterAnimation', 'htmlAttributes', 'isOpen', 'keyboardClose', 'leaveAnimation', 'mode', 'showBackdrop', 'trigger']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonProgressBar = class IonProgressBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonProgressBar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonProgressBar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonProgressBar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonProgressBar,
  selector: "ion-progress-bar",
  inputs: {
    buffer: "buffer",
    color: "color",
    mode: "mode",
    reversed: "reversed",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonProgressBar = __decorate([ProxyCmp({
  inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
})], IonProgressBar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonProgressBar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-progress-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['buffer', 'color', 'mode', 'reversed', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRadio = class IonRadio {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonRadio.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadio,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRadio.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRadio,
  selector: "ion-radio",
  inputs: {
    alignment: "alignment",
    color: "color",
    disabled: "disabled",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRadio = __decorate([ProxyCmp({
  inputs: ['alignment', 'color', 'disabled', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
})], IonRadio);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadio,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'color', 'disabled', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRadioGroup = class IonRadioGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonRadioGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadioGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRadioGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRadioGroup,
  selector: "ion-radio-group",
  inputs: {
    allowEmptySelection: "allowEmptySelection",
    compareWith: "compareWith",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRadioGroup = __decorate([ProxyCmp({
  inputs: ['allowEmptySelection', 'compareWith', 'name', 'value']
})], IonRadioGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRadioGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-radio-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['allowEmptySelection', 'compareWith', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRange = class IonRange {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionFocus', 'ionBlur', 'ionKnobMoveStart', 'ionKnobMoveEnd']);
  }
};
/** @nocollapse */
IonRange.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRange,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRange.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRange,
  selector: "ion-range",
  inputs: {
    activeBarStart: "activeBarStart",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    dualKnobs: "dualKnobs",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    max: "max",
    min: "min",
    mode: "mode",
    name: "name",
    pin: "pin",
    pinFormatter: "pinFormatter",
    snaps: "snaps",
    step: "step",
    ticks: "ticks",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRange = __decorate([ProxyCmp({
  inputs: ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'label', 'labelPlacement', 'legacy', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value']
})], IonRange);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRange,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-range',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activeBarStart', 'color', 'debounce', 'disabled', 'dualKnobs', 'label', 'labelPlacement', 'legacy', 'max', 'min', 'mode', 'name', 'pin', 'pinFormatter', 'snaps', 'step', 'ticks', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRefresher = class IonRefresher {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionRefresh', 'ionPull', 'ionStart']);
  }
};
/** @nocollapse */
IonRefresher.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresher,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRefresher.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRefresher,
  selector: "ion-refresher",
  inputs: {
    closeDuration: "closeDuration",
    disabled: "disabled",
    mode: "mode",
    pullFactor: "pullFactor",
    pullMax: "pullMax",
    pullMin: "pullMin",
    snapbackDuration: "snapbackDuration"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRefresher = __decorate([ProxyCmp({
  inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration'],
  methods: ['complete', 'cancel', 'getProgress']
})], IonRefresher);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresher,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-refresher',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeDuration', 'disabled', 'mode', 'pullFactor', 'pullMax', 'pullMin', 'snapbackDuration']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRefresherContent = class IonRefresherContent {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRefresherContent.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresherContent,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRefresherContent.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRefresherContent,
  selector: "ion-refresher-content",
  inputs: {
    pullingIcon: "pullingIcon",
    pullingText: "pullingText",
    refreshingSpinner: "refreshingSpinner",
    refreshingText: "refreshingText"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRefresherContent = __decorate([ProxyCmp({
  inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
})], IonRefresherContent);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRefresherContent,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-refresher-content',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['pullingIcon', 'pullingText', 'refreshingSpinner', 'refreshingText']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonReorder = class IonReorder {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonReorder.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorder,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonReorder.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonReorder,
  selector: "ion-reorder",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonReorder = __decorate([ProxyCmp({})], IonReorder);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorder,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-reorder',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonReorderGroup = class IonReorderGroup {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionItemReorder']);
  }
};
/** @nocollapse */
IonReorderGroup.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorderGroup,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonReorderGroup.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonReorderGroup,
  selector: "ion-reorder-group",
  inputs: {
    disabled: "disabled"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonReorderGroup = __decorate([ProxyCmp({
  inputs: ['disabled'],
  methods: ['complete']
})], IonReorderGroup);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonReorderGroup,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-reorder-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRippleEffect = class IonRippleEffect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRippleEffect.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRippleEffect,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRippleEffect.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRippleEffect,
  selector: "ion-ripple-effect",
  inputs: {
    type: "type"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRippleEffect = __decorate([ProxyCmp({
  inputs: ['type'],
  methods: ['addRipple']
})], IonRippleEffect);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRippleEffect,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-ripple-effect',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['type']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonRow = class IonRow {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonRow.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRow,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonRow.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRow,
  selector: "ion-row",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonRow = __decorate([ProxyCmp({})], IonRow);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRow,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-row',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSearchbar = class IonSearchbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionInput', 'ionChange', 'ionCancel', 'ionClear', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonSearchbar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSearchbar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSearchbar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSearchbar,
  selector: "ion-searchbar",
  inputs: {
    animated: "animated",
    autocapitalize: "autocapitalize",
    autocomplete: "autocomplete",
    autocorrect: "autocorrect",
    cancelButtonIcon: "cancelButtonIcon",
    cancelButtonText: "cancelButtonText",
    clearIcon: "clearIcon",
    color: "color",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    inputmode: "inputmode",
    maxlength: "maxlength",
    minlength: "minlength",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    searchIcon: "searchIcon",
    showCancelButton: "showCancelButton",
    showClearButton: "showClearButton",
    spellcheck: "spellcheck",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSearchbar = __decorate([ProxyCmp({
  inputs: ['animated', 'autocapitalize', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value'],
  methods: ['setFocus', 'getInputElement']
})], IonSearchbar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSearchbar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-searchbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'autocapitalize', 'autocomplete', 'autocorrect', 'cancelButtonIcon', 'cancelButtonText', 'clearIcon', 'color', 'debounce', 'disabled', 'enterkeyhint', 'inputmode', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'searchIcon', 'showCancelButton', 'showClearButton', 'spellcheck', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSegment = class IonSegment {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange']);
  }
};
/** @nocollapse */
IonSegment.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegment,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSegment.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSegment,
  selector: "ion-segment",
  inputs: {
    color: "color",
    disabled: "disabled",
    mode: "mode",
    scrollable: "scrollable",
    selectOnFocus: "selectOnFocus",
    swipeGesture: "swipeGesture",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSegment = __decorate([ProxyCmp({
  inputs: ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value']
})], IonSegment);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegment,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-segment',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'disabled', 'mode', 'scrollable', 'selectOnFocus', 'swipeGesture', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSegmentButton = class IonSegmentButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSegmentButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegmentButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSegmentButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSegmentButton,
  selector: "ion-segment-button",
  inputs: {
    disabled: "disabled",
    layout: "layout",
    mode: "mode",
    type: "type",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSegmentButton = __decorate([ProxyCmp({
  inputs: ['disabled', 'layout', 'mode', 'type', 'value']
})], IonSegmentButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSegmentButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-segment-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'layout', 'mode', 'type', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSelect = class IonSelect {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionCancel', 'ionDismiss', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonSelect.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelect,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSelect.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSelect,
  selector: "ion-select",
  inputs: {
    cancelText: "cancelText",
    color: "color",
    compareWith: "compareWith",
    disabled: "disabled",
    expandedIcon: "expandedIcon",
    fill: "fill",
    interface: "interface",
    interfaceOptions: "interfaceOptions",
    justify: "justify",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    multiple: "multiple",
    name: "name",
    okText: "okText",
    placeholder: "placeholder",
    selectedText: "selectedText",
    shape: "shape",
    toggleIcon: "toggleIcon",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSelect = __decorate([ProxyCmp({
  inputs: ['cancelText', 'color', 'compareWith', 'disabled', 'expandedIcon', 'fill', 'interface', 'interfaceOptions', 'justify', 'label', 'labelPlacement', 'legacy', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'shape', 'toggleIcon', 'value'],
  methods: ['open']
})], IonSelect);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelect,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['cancelText', 'color', 'compareWith', 'disabled', 'expandedIcon', 'fill', 'interface', 'interfaceOptions', 'justify', 'label', 'labelPlacement', 'legacy', 'mode', 'multiple', 'name', 'okText', 'placeholder', 'selectedText', 'shape', 'toggleIcon', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSelectOption = class IonSelectOption {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSelectOption.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelectOption,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSelectOption.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSelectOption,
  selector: "ion-select-option",
  inputs: {
    disabled: "disabled",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSelectOption = __decorate([ProxyCmp({
  inputs: ['disabled', 'value']
})], IonSelectOption);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSelectOption,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-select-option',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSkeletonText = class IonSkeletonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSkeletonText.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSkeletonText,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSkeletonText.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSkeletonText,
  selector: "ion-skeleton-text",
  inputs: {
    animated: "animated"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSkeletonText = __decorate([ProxyCmp({
  inputs: ['animated']
})], IonSkeletonText);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSkeletonText,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-skeleton-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSpinner = class IonSpinner {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonSpinner.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSpinner,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSpinner.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSpinner,
  selector: "ion-spinner",
  inputs: {
    color: "color",
    duration: "duration",
    name: "name",
    paused: "paused"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSpinner = __decorate([ProxyCmp({
  inputs: ['color', 'duration', 'name', 'paused']
})], IonSpinner);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSpinner,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-spinner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'duration', 'name', 'paused']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonSplitPane = class IonSplitPane {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionSplitPaneVisible']);
  }
};
/** @nocollapse */
IonSplitPane.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSplitPane,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonSplitPane.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonSplitPane,
  selector: "ion-split-pane",
  inputs: {
    contentId: "contentId",
    disabled: "disabled",
    when: "when"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonSplitPane = __decorate([ProxyCmp({
  inputs: ['contentId', 'disabled', 'when']
})], IonSplitPane);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonSplitPane,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-split-pane',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['contentId', 'disabled', 'when']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTabBar = class IonTabBar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabBar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabBar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabBar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTabBar,
  selector: "ion-tab-bar",
  inputs: {
    color: "color",
    mode: "mode",
    selectedTab: "selectedTab",
    translucent: "translucent"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTabBar = __decorate([ProxyCmp({
  inputs: ['color', 'mode', 'selectedTab', 'translucent']
})], IonTabBar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabBar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tab-bar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode', 'selectedTab', 'translucent']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTabButton = class IonTabButton {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTabButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabButton,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTabButton,
  selector: "ion-tab-button",
  inputs: {
    disabled: "disabled",
    download: "download",
    href: "href",
    layout: "layout",
    mode: "mode",
    rel: "rel",
    selected: "selected",
    tab: "tab",
    target: "target"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTabButton = __decorate([ProxyCmp({
  inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
})], IonTabButton);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tab-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'download', 'href', 'layout', 'mode', 'rel', 'selected', 'tab', 'target']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonText = class IonText {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonText.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonText,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonText.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonText,
  selector: "ion-text",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonText = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonText);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonText,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-text',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTextarea = class IonTextarea {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionInput', 'ionBlur', 'ionFocus']);
  }
};
/** @nocollapse */
IonTextarea.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTextarea,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTextarea.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTextarea,
  selector: "ion-textarea",
  inputs: {
    autoGrow: "autoGrow",
    autocapitalize: "autocapitalize",
    autofocus: "autofocus",
    clearOnEdit: "clearOnEdit",
    color: "color",
    cols: "cols",
    counter: "counter",
    counterFormatter: "counterFormatter",
    debounce: "debounce",
    disabled: "disabled",
    enterkeyhint: "enterkeyhint",
    errorText: "errorText",
    fill: "fill",
    helperText: "helperText",
    inputmode: "inputmode",
    label: "label",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    maxlength: "maxlength",
    minlength: "minlength",
    mode: "mode",
    name: "name",
    placeholder: "placeholder",
    readonly: "readonly",
    required: "required",
    rows: "rows",
    shape: "shape",
    spellcheck: "spellcheck",
    value: "value",
    wrap: "wrap"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTextarea = __decorate([ProxyCmp({
  inputs: ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'shape', 'spellcheck', 'value', 'wrap'],
  methods: ['setFocus', 'getInputElement']
})], IonTextarea);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTextarea,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoGrow', 'autocapitalize', 'autofocus', 'clearOnEdit', 'color', 'cols', 'counter', 'counterFormatter', 'debounce', 'disabled', 'enterkeyhint', 'errorText', 'fill', 'helperText', 'inputmode', 'label', 'labelPlacement', 'legacy', 'maxlength', 'minlength', 'mode', 'name', 'placeholder', 'readonly', 'required', 'rows', 'shape', 'spellcheck', 'value', 'wrap']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonThumbnail = class IonThumbnail {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonThumbnail.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonThumbnail,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonThumbnail.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonThumbnail,
  selector: "ion-thumbnail",
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonThumbnail = __decorate([ProxyCmp({})], IonThumbnail);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonThumbnail,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-thumbnail',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonTitle = class IonTitle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonTitle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTitle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTitle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTitle,
  selector: "ion-title",
  inputs: {
    color: "color",
    size: "size"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonTitle = __decorate([ProxyCmp({
  inputs: ['color', 'size']
})], IonTitle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTitle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-title',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'size']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonToast = class IonToast {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionToastDidPresent', 'ionToastWillPresent', 'ionToastWillDismiss', 'ionToastDidDismiss', 'didPresent', 'willPresent', 'willDismiss', 'didDismiss']);
  }
};
/** @nocollapse */
IonToast.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToast,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToast.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonToast,
  selector: "ion-toast",
  inputs: {
    animated: "animated",
    buttons: "buttons",
    color: "color",
    cssClass: "cssClass",
    duration: "duration",
    enterAnimation: "enterAnimation",
    header: "header",
    htmlAttributes: "htmlAttributes",
    icon: "icon",
    isOpen: "isOpen",
    keyboardClose: "keyboardClose",
    layout: "layout",
    leaveAnimation: "leaveAnimation",
    message: "message",
    mode: "mode",
    position: "position",
    positionAnchor: "positionAnchor",
    swipeGesture: "swipeGesture",
    translucent: "translucent",
    trigger: "trigger"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToast = __decorate([ProxyCmp({
  inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger'],
  methods: ['present', 'dismiss', 'onDidDismiss', 'onWillDismiss']
})], IonToast);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToast,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toast',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['animated', 'buttons', 'color', 'cssClass', 'duration', 'enterAnimation', 'header', 'htmlAttributes', 'icon', 'isOpen', 'keyboardClose', 'layout', 'leaveAnimation', 'message', 'mode', 'position', 'positionAnchor', 'swipeGesture', 'translucent', 'trigger']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonToggle = class IonToggle {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['ionChange', 'ionFocus', 'ionBlur']);
  }
};
/** @nocollapse */
IonToggle.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToggle,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToggle.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonToggle,
  selector: "ion-toggle",
  inputs: {
    alignment: "alignment",
    checked: "checked",
    color: "color",
    disabled: "disabled",
    enableOnOffLabels: "enableOnOffLabels",
    justify: "justify",
    labelPlacement: "labelPlacement",
    legacy: "legacy",
    mode: "mode",
    name: "name",
    value: "value"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToggle = __decorate([ProxyCmp({
  inputs: ['alignment', 'checked', 'color', 'disabled', 'enableOnOffLabels', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
})], IonToggle);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToggle,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toggle',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignment', 'checked', 'color', 'disabled', 'enableOnOffLabels', 'justify', 'labelPlacement', 'legacy', 'mode', 'name', 'value']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});
let IonToolbar = class IonToolbar {
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
};
/** @nocollapse */
IonToolbar.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToolbar,
  deps: [{
    token: i0.ChangeDetectorRef
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonToolbar.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonToolbar,
  selector: "ion-toolbar",
  inputs: {
    color: "color",
    mode: "mode"
  },
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
IonToolbar = __decorate([ProxyCmp({
  inputs: ['color', 'mode']
})], IonToolbar);
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonToolbar,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-toolbar',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'mode']
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ChangeDetectorRef
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }];
  }
});

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonRouterOutlet extends IonRouterOutlet$1 {
  /**
   * We need to pass in the correct instance of IonRouterOutlet
   * otherwise parentOutlet will be null in a nested outlet context.
   * This results in APIs such as NavController.pop not working
   * in nested outlets because the parent outlet cannot be found.
   */
  constructor(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet) {
    super(name, tabs, commonLocation, elementRef, router, zone, activatedRoute, parentOutlet);
    this.parentOutlet = parentOutlet;
  }
}
/** @nocollapse */
IonRouterOutlet.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterOutlet,
  deps: [{
    token: 'name',
    attribute: true
  }, {
    token: 'tabs',
    attribute: true,
    optional: true
  }, {
    token: i1.Location
  }, {
    token: i0.ElementRef
  }, {
    token: i2.Router
  }, {
    token: i0.NgZone
  }, {
    token: i2.ActivatedRoute
  }, {
    token: IonRouterOutlet,
    optional: true,
    skipSelf: true
  }],
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonRouterOutlet.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonRouterOutlet,
  selector: "ion-router-outlet",
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonRouterOutlet,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-router-outlet'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: undefined,
      decorators: [{
        type: Attribute,
        args: ['name']
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Attribute,
        args: ['tabs']
      }]
    }, {
      type: i1.Location
    }, {
      type: i0.ElementRef
    }, {
      type: i2.Router
    }, {
      type: i0.NgZone
    }, {
      type: i2.ActivatedRoute
    }, {
      type: IonRouterOutlet,
      decorators: [{
        type: SkipSelf
      }, {
        type: Optional
      }]
    }];
  }
});

// eslint-disable-next-line @angular-eslint/component-class-suffix
class IonTabs extends IonTabs$1 {}
/** @nocollapse */
IonTabs.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabs,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonTabs.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonTabs,
  selector: "ion-tabs",
  queries: [{
    propertyName: "tabBar",
    first: true,
    predicate: IonTabBar,
    descendants: true
  }, {
    propertyName: "tabBars",
    predicate: IonTabBar
  }],
  viewQueries: [{
    propertyName: "outlet",
    first: true,
    predicate: ["outlet"],
    descendants: true,
    read: IonRouterOutlet
  }],
  usesInheritance: true,
  ngImport: i0,
  template: `
    <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner" #tabsInner>
      <ion-router-outlet
        #outlet
        tabs="true"
        (stackWillChange)="onStackWillChange($event)"
        (stackDidChange)="onStackDidChange($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>
  `,
  isInline: true,
  styles: [":host{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner{position:relative;flex:1;contain:layout size style}\n"],
  dependencies: [{
    kind: "directive",
    type: IonRouterOutlet,
    selector: "ion-router-outlet"
  }]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonTabs,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-tabs',
      template: `
    <ng-content select="[slot=top]"></ng-content>
    <div class="tabs-inner" #tabsInner>
      <ion-router-outlet
        #outlet
        tabs="true"
        (stackWillChange)="onStackWillChange($event)"
        (stackDidChange)="onStackDidChange($event)"
      ></ion-router-outlet>
    </div>
    <ng-content></ng-content>
  `,
      styles: [":host{display:flex;position:absolute;inset:0;flex-direction:column;width:100%;height:100%;contain:layout size style}.tabs-inner{position:relative;flex:1;contain:layout size style}\n"]
    }]
  }],
  propDecorators: {
    outlet: [{
      type: ViewChild,
      args: ['outlet', {
        read: IonRouterOutlet,
        static: false
      }]
    }],
    tabBar: [{
      type: ContentChild,
      args: [IonTabBar, {
        static: false
      }]
    }],
    tabBars: [{
      type: ContentChildren,
      args: [IonTabBar]
    }]
  }
});

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonBackButton extends IonBackButton$1 {
  constructor(routerOutlet, navCtrl, config, r, z, c) {
    super(routerOutlet, navCtrl, config, r, z, c);
  }
}
/** @nocollapse */
IonBackButton.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackButton,
  deps: [{
    token: IonRouterOutlet,
    optional: true
  }, {
    token: i2$1.NavController
  }, {
    token: i2$1.Config
  }, {
    token: i0.ElementRef
  }, {
    token: i0.NgZone
  }, {
    token: i0.ChangeDetectorRef
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonBackButton.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonBackButton,
  selector: "ion-back-button",
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonBackButton,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-back-button',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }],
  ctorParameters: function () {
    return [{
      type: IonRouterOutlet,
      decorators: [{
        type: Optional
      }]
    }, {
      type: i2$1.NavController
    }, {
      type: i2$1.Config
    }, {
      type: i0.ElementRef
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }
});

// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonNav extends IonNav$1 {
  constructor(ref, environmentInjector, injector, angularDelegate, z, c) {
    super(ref, environmentInjector, injector, angularDelegate, z, c);
  }
}
/** @nocollapse */
IonNav.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNav,
  deps: [{
    token: i0.ElementRef
  }, {
    token: i0.EnvironmentInjector
  }, {
    token: i0.Injector
  }, {
    token: i2$1.AngularDelegate
  }, {
    token: i0.NgZone
  }, {
    token: i0.ChangeDetectorRef
  }],
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonNav.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonNav,
  selector: "ion-nav",
  usesInheritance: true,
  ngImport: i0,
  template: '<ng-content></ng-content>',
  isInline: true,
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonNav,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-nav',
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.EnvironmentInjector
    }, {
      type: i0.Injector
    }, {
      type: i2$1.AngularDelegate
    }, {
      type: i0.NgZone
    }, {
      type: i0.ChangeDetectorRef
    }];
  }
});

/**
 * Adds support for Ionic routing directions and animations to the base Angular router link directive.
 *
 * When the router link is clicked, the directive will assign the direction and
 * animation so that the routing integration will transition correctly.
 */
class RouterLinkDelegateDirective extends RouterLinkDelegateDirective$1 {}
/** @nocollapse */
RouterLinkDelegateDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: RouterLinkDelegateDirective,
  deps: null,
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
RouterLinkDelegateDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: RouterLinkDelegateDirective,
  selector: ":not(a):not(area)[routerLink]",
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: RouterLinkDelegateDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: ':not(a):not(area)[routerLink]'
    }]
  }]
});
class RouterLinkWithHrefDelegateDirective extends RouterLinkWithHrefDelegateDirective$1 {}
/** @nocollapse */
RouterLinkWithHrefDelegateDirective.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: RouterLinkWithHrefDelegateDirective,
  deps: null,
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
RouterLinkWithHrefDelegateDirective.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: RouterLinkWithHrefDelegateDirective,
  selector: "a[routerLink],area[routerLink]",
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: RouterLinkWithHrefDelegateDirective,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'a[routerLink],area[routerLink]'
    }]
  }]
});
class IonModal extends IonModal$1 {}
/** @nocollapse */
IonModal.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonModal,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonModal.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonModal,
  selector: "ion-modal",
  usesInheritance: true,
  ngImport: i0,
  template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`,
  isInline: true,
  dependencies: [{
    kind: "directive",
    type: i1.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }, {
    kind: "directive",
    type: i1.NgTemplateOutlet,
    selector: "[ngTemplateOutlet]",
    inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonModal,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<div class="ion-delegate-host ion-page" *ngIf="isCmpOpen || keepContentsMounted">
    <ng-container [ngTemplateOutlet]="template"></ng-container>
  </div>`
    }]
  }]
});
class IonPopover extends IonPopover$1 {}
/** @nocollapse */
IonPopover.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPopover,
  deps: null,
  target: i0.ɵɵFactoryTarget.Component
});
/** @nocollapse */
IonPopover.ɵcmp = i0.ɵɵngDeclareComponent({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonPopover,
  selector: "ion-popover",
  usesInheritance: true,
  ngImport: i0,
  template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`,
  isInline: true,
  dependencies: [{
    kind: "directive",
    type: i1.NgIf,
    selector: "[ngIf]",
    inputs: ["ngIf", "ngIfThen", "ngIfElse"]
  }, {
    kind: "directive",
    type: i1.NgTemplateOutlet,
    selector: "[ngTemplateOutlet]",
    inputs: ["ngTemplateOutletContext", "ngTemplateOutlet", "ngTemplateOutletInjector"]
  }],
  changeDetection: i0.ChangeDetectionStrategy.OnPush
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonPopover,
  decorators: [{
    type: Component,
    args: [{
      selector: 'ion-popover',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: `<ng-container [ngTemplateOutlet]="template" *ngIf="isCmpOpen || keepContentsMounted"></ng-container>`
    }]
  }]
});

/**
 * @description
 * Provider which adds `MaxValidator` to the `NG_VALIDATORS` multi-provider list.
 */
const ION_MAX_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IonMaxValidator),
  multi: true
};
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonMaxValidator extends MaxValidator {}
/** @nocollapse */
IonMaxValidator.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMaxValidator,
  deps: null,
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonMaxValidator.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMaxValidator,
  selector: "ion-input[type=number][max][formControlName],ion-input[type=number][max][formControl],ion-input[type=number][max][ngModel]",
  host: {
    properties: {
      "attr.max": "_enabled ? max : null"
    }
  },
  providers: [ION_MAX_VALIDATOR],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMaxValidator,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-input[type=number][max][formControlName],ion-input[type=number][max][formControl],ion-input[type=number][max][ngModel]',
      providers: [ION_MAX_VALIDATOR],
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        '[attr.max]': '_enabled ? max : null'
      }
    }]
  }]
});

/**
 * @description
 * Provider which adds `MinValidator` to the `NG_VALIDATORS` multi-provider list.
 */
const ION_MIN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IonMinValidator),
  multi: true
};
// eslint-disable-next-line @angular-eslint/directive-class-suffix
class IonMinValidator extends MinValidator {}
/** @nocollapse */
IonMinValidator.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMinValidator,
  deps: null,
  target: i0.ɵɵFactoryTarget.Directive
});
/** @nocollapse */
IonMinValidator.ɵdir = i0.ɵɵngDeclareDirective({
  minVersion: "14.0.0",
  version: "14.2.12",
  type: IonMinValidator,
  selector: "ion-input[type=number][min][formControlName],ion-input[type=number][min][formControl],ion-input[type=number][min][ngModel]",
  host: {
    properties: {
      "attr.min": "_enabled ? min : null"
    }
  },
  providers: [ION_MIN_VALIDATOR],
  usesInheritance: true,
  ngImport: i0
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonMinValidator,
  decorators: [{
    type: Directive,
    args: [{
      selector: 'ion-input[type=number][min][formControlName],ion-input[type=number][min][formControl],ion-input[type=number][min][ngModel]',
      providers: [ION_MIN_VALIDATOR],
      // eslint-disable-next-line @angular-eslint/no-host-metadata-property
      host: {
        '[attr.min]': '_enabled ? min : null'
      }
    }]
  }]
});
class AlertController extends OverlayBaseController {
  constructor() {
    super(alertController);
  }
}
/** @nocollapse */
AlertController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AlertController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
AlertController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AlertController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AlertController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class AnimationController {
  /**
   * Create a new animation
   */
  create(animationId) {
    return createAnimation(animationId);
  }
  /**
   * EXPERIMENTAL
   *
   * Given a progression and a cubic bezier function,
   * this utility returns the time value(s) at which the
   * cubic bezier reaches the given time progression.
   *
   * If the cubic bezier never reaches the progression
   * the result will be an empty array.
   *
   * This is most useful for switching between easing curves
   * when doing a gesture animation (i.e. going from linear easing
   * during a drag, to another easing when `progressEnd` is called)
   */
  easingTime(p0, p1, p2, p3, progression) {
    return getTimeGivenProgression(p0, p1, p2, p3, progression);
  }
}
/** @nocollapse */
AnimationController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AnimationController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
AnimationController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AnimationController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: AnimationController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }]
});
class ActionSheetController extends OverlayBaseController {
  constructor() {
    super(actionSheetController);
  }
}
/** @nocollapse */
ActionSheetController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ActionSheetController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ActionSheetController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ActionSheetController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ActionSheetController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class GestureController {
  constructor(zone) {
    this.zone = zone;
  }
  /**
   * Create a new gesture
   */
  create(opts, runInsideAngularZone = false) {
    if (runInsideAngularZone) {
      Object.getOwnPropertyNames(opts).forEach(key => {
        if (typeof opts[key] === 'function') {
          const fn = opts[key];
          opts[key] = (...props) => this.zone.run(() => fn(...props));
        }
      });
    }
    return createGesture(opts);
  }
}
/** @nocollapse */
GestureController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: GestureController,
  deps: [{
    token: i0.NgZone
  }],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
GestureController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: GestureController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: GestureController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [{
      type: i0.NgZone
    }];
  }
});
class LoadingController extends OverlayBaseController {
  constructor() {
    super(loadingController);
  }
}
/** @nocollapse */
LoadingController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: LoadingController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
LoadingController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: LoadingController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: LoadingController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class MenuController extends MenuController$1 {
  constructor() {
    super(menuController);
  }
}
/** @nocollapse */
MenuController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: MenuController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
MenuController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: MenuController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: MenuController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class ModalController extends OverlayBaseController {
  constructor() {
    super(modalController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'modal')
    });
  }
}
/** @nocollapse */
ModalController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ModalController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ModalController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ModalController
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ModalController,
  decorators: [{
    type: Injectable
  }],
  ctorParameters: function () {
    return [];
  }
});
class PickerController extends OverlayBaseController {
  constructor() {
    super(pickerController);
  }
}
/** @nocollapse */
PickerController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: PickerController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
PickerController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: PickerController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: PickerController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});
class PopoverController extends OverlayBaseController {
  constructor() {
    super(popoverController);
    this.angularDelegate = inject(AngularDelegate);
    this.injector = inject(Injector);
    this.environmentInjector = inject(EnvironmentInjector);
  }
  create(opts) {
    return super.create({
      ...opts,
      delegate: this.angularDelegate.create(this.environmentInjector, this.injector, 'popover')
    });
  }
}
class ToastController extends OverlayBaseController {
  constructor() {
    super(toastController);
  }
}
/** @nocollapse */
ToastController.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ToastController,
  deps: [],
  target: i0.ɵɵFactoryTarget.Injectable
});
/** @nocollapse */
ToastController.ɵprov = i0.ɵɵngDeclareInjectable({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ToastController,
  providedIn: 'root'
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: ToastController,
  decorators: [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }],
  ctorParameters: function () {
    return [];
  }
});

// TODO(FW-2827): types
const appInitialize = (config, doc, zone) => {
  return () => {
    const win = doc.defaultView;
    if (win && typeof window !== 'undefined') {
      setupConfig({
        ...config,
        _zoneGate: h => zone.run(h)
      });
      const aelFn = '__zone_symbol__addEventListener' in doc.body ? '__zone_symbol__addEventListener' : 'addEventListener';
      return applyPolyfills().then(() => {
        return defineCustomElements(win, {
          exclude: ['ion-tabs', 'ion-tab'],
          syncQueue: true,
          raf,
          jmp: h => zone.runOutsideAngular(h),
          ael(elm, eventName, cb, opts) {
            elm[aelFn](eventName, cb, opts);
          },
          rel(elm, eventName, cb, opts) {
            elm.removeEventListener(eventName, cb, opts);
          }
        });
      });
    }
  };
};
const DIRECTIVES = [IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMenu, IonMenuButton, IonMenuToggle, IonNavLink, IonNote, IonPicker, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar];
const DECLARATIONS = [
// generated proxies
...DIRECTIVES,
// manual proxies
IonModal, IonPopover,
// ngModel accessors
BooleanValueAccessorDirective, NumericValueAccessorDirective, RadioValueAccessorDirective, SelectValueAccessorDirective, TextValueAccessorDirective,
// navigation
IonTabs, IonRouterOutlet, IonBackButton, IonNav, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective,
// validators
IonMinValidator, IonMaxValidator];
class IonicModule {
  static forRoot(config) {
    return {
      ngModule: IonicModule,
      providers: [{
        provide: ConfigToken,
        useValue: config
      }, {
        provide: APP_INITIALIZER,
        useFactory: appInitialize,
        multi: true,
        deps: [ConfigToken, DOCUMENT, NgZone]
      }, provideComponentInputBinding()]
    };
  }
}
/** @nocollapse */
IonicModule.ɵfac = i0.ɵɵngDeclareFactory({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonicModule,
  deps: [],
  target: i0.ɵɵFactoryTarget.NgModule
});
/** @nocollapse */
IonicModule.ɵmod = i0.ɵɵngDeclareNgModule({
  minVersion: "14.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonicModule,
  declarations: [IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMenu, IonMenuButton, IonMenuToggle, IonNavLink, IonNote, IonPicker, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar,
  // manual proxies
  IonModal, IonPopover,
  // ngModel accessors
  BooleanValueAccessorDirective, NumericValueAccessorDirective, RadioValueAccessorDirective, SelectValueAccessorDirective, TextValueAccessorDirective,
  // navigation
  IonTabs, IonRouterOutlet, IonBackButton, IonNav, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective,
  // validators
  IonMinValidator, IonMaxValidator],
  imports: [CommonModule],
  exports: [IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMenu, IonMenuButton, IonMenuToggle, IonNavLink, IonNote, IonPicker, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar,
  // manual proxies
  IonModal, IonPopover,
  // ngModel accessors
  BooleanValueAccessorDirective, NumericValueAccessorDirective, RadioValueAccessorDirective, SelectValueAccessorDirective, TextValueAccessorDirective,
  // navigation
  IonTabs, IonRouterOutlet, IonBackButton, IonNav, RouterLinkDelegateDirective, RouterLinkWithHrefDelegateDirective,
  // validators
  IonMinValidator, IonMaxValidator]
});
/** @nocollapse */
IonicModule.ɵinj = i0.ɵɵngDeclareInjector({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonicModule,
  providers: [AngularDelegate, ModalController, PopoverController],
  imports: [CommonModule]
});
i0.ɵɵngDeclareClassMetadata({
  minVersion: "12.0.0",
  version: "14.2.12",
  ngImport: i0,
  type: IonicModule,
  decorators: [{
    type: NgModule,
    args: [{
      declarations: DECLARATIONS,
      exports: DECLARATIONS,
      providers: [AngularDelegate, ModalController, PopoverController],
      imports: [CommonModule]
    }]
  }]
});

// DIRECTIVES

/**
 * Generated bundle index. Do not edit.
 */

export { ActionSheetController, AlertController, AnimationController, BooleanValueAccessorDirective as BooleanValueAccessor, GestureController, ION_MAX_VALIDATOR, ION_MIN_VALIDATOR, IonAccordion, IonAccordionGroup, IonActionSheet, IonAlert, IonApp, IonAvatar, IonBackButton, IonBackButton as IonBackButtonDelegate, IonBackdrop, IonBadge, IonBreadcrumb, IonBreadcrumbs, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCheckbox, IonChip, IonCol, IonContent, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonFabList, IonFooter, IonGrid, IonHeader, IonIcon, IonImg, IonInfiniteScroll, IonInfiniteScrollContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, IonList, IonListHeader, IonLoading, IonMaxValidator, IonMenu, IonMenuButton, IonMenuToggle, IonMinValidator, IonModal, IonNav, IonNavLink, IonNote, IonPicker, IonPopover, IonProgressBar, IonRadio, IonRadioGroup, IonRange, IonRefresher, IonRefresherContent, IonReorder, IonReorderGroup, IonRippleEffect, IonRouterOutlet, IonRow, IonSearchbar, IonSegment, IonSegmentButton, IonSelect, IonSelectOption, IonSkeletonText, IonSpinner, IonSplitPane, IonTabBar, IonTabButton, IonTabs, IonText, IonTextarea, IonThumbnail, IonTitle, IonToast, IonToggle, IonToolbar, IonicModule, LoadingController, MenuController, ModalController, NumericValueAccessorDirective as NumericValueAccessor, PickerController, PopoverController, RadioValueAccessorDirective as RadioValueAccessor, RouterLinkDelegateDirective as RouterLinkDelegate, RouterLinkWithHrefDelegateDirective as RouterLinkWithHrefDelegate, SelectValueAccessorDirective as SelectValueAccessor, TextValueAccessorDirective as TextValueAccessor, ToastController };
