"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BankTransactionType = void 0;
exports.BankTransactionType = Object.freeze({
  DEPOSIT: 0,
  //acconto
  BALANCE: 1 //saldo
});
