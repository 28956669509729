/* src/app/components/search-panel/search-panel.component.scss */
.container {
  position: fixed;
  top: 6px;
  left: 8px;
  width: calc(100vw - 16px);
  height: 50px;
  border-radius: 14px;
  z-index: 2;
}
@media (max-width: 576px) {
  .container {
    margin-top: env(safe-area-inset-top);
  }
}
@media (min-width: 576px) {
  .container {
    margin-top: env(safe-area-inset-top);
  }
}
ion-toolbar {
  height: 100%;
  border-radius: var(--yf-spacing1);
}
ion-toolbar ion-searchbar {
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  display: none;
}
.recent-searches-container {
  margin: 4px;
  border-radius: var(--yf-spacing1);
  background-color: whitesmoke !important;
  box-shadow: var(--yf-shadow);
  display: block;
}
.recent-searches-container .recent-search {
  color: #555;
  cursor: pointer;
}
.recent-searches-container ion-icon {
  float: right;
}
.center-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
/*# sourceMappingURL=search-panel.component.css.map */
