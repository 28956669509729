<app-master-detail class="yf-master-detail" icon="person-outline" title="{{'PROFILE.PROFILE'|translate}}"
  [titleDetail]="titleDetail" [(show)]="masterDetailShow" filter="" (compactModeChange)="compactModeChange($event)"
  [forceCompact]="false">
  <div class="master">
    <div class="yf-md-container">
      <ion-list-header>
        <ion-label>{{ "PROFILE.USER" | translate }}</ion-label>
      </ion-list-header>
      <div class="m-6 pl-6 list-item">
        <ion-item button detail="true" (click)="showDetail(DetailTypes.User, DetailTitles.User)"
          [ngClass]="{ selected: detailType === DetailTypes.User}">
          <ion-avatar class="item-icon" slot="start">
            <img alt="Silhouette of a person's head" src="https://ionicframework.com/docs/img/demos/avatar.svg" />
          </ion-avatar>
          <ion-label>
            <h3 class="fw-600">{{ mainStateService.$user().name + ' ' + mainStateService.$user().surname }}</h3>
            <p>{{ mainStateService.$user().email }}</p>
          </ion-label>
        </ion-item>
      </div>

      <!-- VEICOLI -->
      <ion-list-header>
        <ion-label>{{ "PROFILE.CARS" | translate }}</ion-label>
      </ion-list-header>
      <!-- LISTA AUTO -->
      <div class="scroll-content">
        <ion-list>
          <div class="m-6 pl-6 list-item" *ngFor="let car of mainStateService.$userCars()">
            <ion-item button detail="true"
              [ngClass]="{ selected: (selectedCar$|async)?.id===car.id && detailType === DetailTypes.Car}">
              <ion-icon src="assets/app-icons/macchina.svg" class="item-icon" slot="start"></ion-icon>
              <ion-label (click)="showDetail(DetailTypes.Car, DetailTitles.Car, car)">
                <h3 class="fw-600">{{ car?.model?.brand?.name ? car.model.brand.name : '???'}}</h3>
                <p>{{ car?.model?.name ? car.model.name : '???' }}</p>
              </ion-label>
              <ion-icon [name]="car.isSelected? 'star':'star-outline'" class="item-car-icon pr-16"
                color="{{car.isSelected ? 'warning' : 'medium'}}" (click)="selectCar(car.id)"> </ion-icon>
            </ion-item>
          </div>
        </ion-list>
      </div>


      <!-- VEICOLO DA AGGIUNGERE, SALVATO MOMENTANEAMENTE -->
      <ion-list-header *ngIf="savingNewCar">
        <ion-label>
          {{ "PROFILE.NEW_CAR" | translate }} <span style="color: grey" class="fw-500 fs-16">({{
            "PROFILE.NEW_CAR_NOT_SAVED" | translate }})</span>
        </ion-label>
      </ion-list-header>
      <div class="m-6 pl-6 list-item" *ngIf="!!savingNewCar"
        [ngClass]="{ selected: !selectedCar$ && detailType === DetailTypes.Car }">
        <ion-item button detail="true" (click)="showDetail(DetailTypes.Car, DetailTitles.Car, null)"
          [ngClass]="{ selected: !(selectedCar$ | async) && detailType === DetailTypes.Car }">
          <ion-icon src="assets/app-icons/macchina.svg" class="item-icon" slot="start"></ion-icon>
          <ion-label>
            <h3 class="fw-600">{{newVehicle?.model?.brand?.name ? newVehicle.model.brand.name : '???'}}</h3>
            <p>{{newVehicle?.model?.name ? newVehicle.model.name : '???'}}</p>
          </ion-label>
          <ion-icon name="create-outline" class="item-icon" color="secondary"> </ion-icon>
        </ion-item>
      </div>

    </div>

    <ion-footer class="ion-no-border yf-md-footer" *ngIf="!savingNewCar">
      <div>
        <ion-button class="btn large grow" color="primary" slot="end"
          (click)="showDetail(DetailTypes.Car, DetailTitles.Car, null)">{{ "PROFILE.ADD_NEW_CAR" | translate}}
          <!-- <ion-icon slot="start" src="assets/app-icons/aggiungi.svg"></ion-icon> -->
        </ion-button>
      </div>
    </ion-footer>
  </div>

  <div class="pl-6 detail">
    <div *ngIf="detailType === DetailTypes.User">
      <yf-user-edit> </yf-user-edit>
    </div>
    <div *ngIf="detailType === DetailTypes.Car">
      <yf-car-edit [car$]="selectedCar$" [savingNewCar]="savingNewCar" (cancelEvent)="handleCancelEvent($event)"
        (deleteEvent)="handleDeleteEvent()" (formChanges)="handleFormChanges($event)"></yf-car-edit>
    </div>
  </div>
</app-master-detail>