"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IClientSocket = void 0;
var IClientSocket;
(function (IClientSocket) {
  var Dto;
  (function (Dto) {
    var HandshakeData = /** @class */function () {
      function HandshakeData() {}
      return HandshakeData;
    }();
    Dto.HandshakeData = HandshakeData;
  })(Dto = IClientSocket.Dto || (IClientSocket.Dto = {}));
  IClientSocket.ClientEvents = Object.freeze({
    Handshake: 'handshake',
    GetInstallationById: 'GetInstallationById',
    CurrentBounds: "current_bounds",
    GetClusterByIds: 'GetClusterByIds'
  });
  IClientSocket.ServerEvents = Object.freeze({
    SessionUpdates: "session_updates",
    InstallationsUpdates: "installations_updates",
    RfidUpdates: "rfid_updates"
  });
})(IClientSocket || (exports.IClientSocket = IClientSocket = {}));
