"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OCPIPowerType = void 0;
exports.OCPIPowerType = Object.freeze({
  AC_1_PHASE: 'AC_1_PHASE',
  AC_3_PHASE: 'AC_3_PHASE',
  DC: 'DC'
});
