"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IMAGE_CATEGORY = void 0;
exports.IMAGE_CATEGORY = Object.freeze({
  CHARGER: "CHARGER",
  ENTRANCE: "ENTRANCE",
  LOCATION: "LOCATION",
  NETWORK: "NETWORK",
  OPERATOR: "OPERATOR",
  OTHER: "OTHER",
  OWNER: "OWNER"
});
