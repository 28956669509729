<ion-spinner *ngIf="isLoading"></ion-spinner>
<ion-card>
  <ion-card-header>
    <yf-header [label]="'Commenti'" (rightButtonEvent)="closeMe()"></yf-header>
    <div class="station-info">
      <yf-logo-thumbnail [stationCpo]="station?.cpo"></yf-logo-thumbnail>
      <div class="info-review">
        <ion-label>{{ station?.name }}</ion-label>
        <ion-label>{{ station?.id }}</ion-label>
      </div>
    </div>
    <div class="input-container" *ngIf="!isLoading">
      <ion-button *ngIf="showComment" class="btn transparent square close" (click)="changeState()">
        <ion-icon name="close-outline" color="secondary" slot="icon-only"></ion-icon>
      </ion-button>
      <ion-label class="ion-text-center ion-padding">Valuta questa colonnina</ion-label>
      <yf-star-container [isClickable]="true"></yf-star-container>
      <div class="text-review" *ngIf="showComment">
        <form [formGroup]="commentFrm.form">
          <formly-form
            [model]="commentFrm.model"
            [fields]="commentFrm.fields"
            [options]="commentFrm.options"
            [form]="commentFrm.form"></formly-form>
        </form>
        <div class="btns">
          <ion-button class="btn large" color="secondary" (click)="newComment()">
            {{'BUTTONS.SAVE'|translate}}
          </ion-button>
          <br />
        </div>
      </div>
    </div>
  </ion-card-header>
  <ion-card-content>
    <div class="comments-container" *ngIf="!isLoading">
      <div
        class="review"
        *ngFor="let review of stationReviews"
        [ngClass]="{ myBackground: myReview && review.userId === myReview.userId }">
        <ion-item lines="none" class="item-review">
          <ion-label class="avatar-user-date">
            <ion-avatar slot="start">
              <img
                [src]="app.apiUrl + '/photo/userPhoto/' + review.userId"
                onerror="this.onerror=null; this.src='assets/app-icons/User-Placeholder.png'" />
            </ion-avatar>
            <div class="user-date">
              <ion-label color="secondary"
                >{{ review?.user.name }} {{ review?.user.surname }}</ion-label
              >
              <ion-label color="medium">{{ review?.createdAt | date: 'dd-MM-yyyy' }}</ion-label>
            </div>
          </ion-label>
          <yf-star-container [rating]="review?.rating"></yf-star-container>
        </ion-item>
        <p [id]="review.id" class="review-text">{{ review?.comment }}</p>
        <ion-button
          *ngIf="textOverflowCalc(review.id)"
          [id]="'btn' + review.id"
          class="btn transparent small"
          (click)="viewMore(review.id)">
          <ion-label class="ion-text-center">{{'PHRASE.KEEP_READING'|translate}}</ion-label>
          <ion-icon name="chevron-down-outline" color="secondary" slot="end"></ion-icon>
        </ion-button>
        <div class="divider"></div>
      </div>

      <!-- <ion-row
        class="comment"
        *ngFor="let review of stationReviews"
        [ngClass]="{ myBackground: myReview && review.userId === myReview.userId }">
        <div class="rounded"></div>
        <div class="review-container">
          <p>{{ review?.user.name }} {{ review?.user.surname }}</p>
          <div class="subtitle">
            <p>{{ review?.createdAt | date: 'dd-MM-yyyy' }}</p>
            <ion-icon id="star" color="warning" name="star"></ion-icon>
            <p>{{ review?.rating }}</p>
          </div>
        </div>

        <p>{{ review?.comment }}</p>
      </ion-row> -->
    </div>
  </ion-card-content>
</ion-card>

<!-- <div class="comments-container" *ngIf="!isLoading">
  <ion-row
    class="comment"
    *ngFor="let review of stationReviews"
    [ngClass]="{ myBackground: myReview && review.userId === myReview.userId }">
    <div class="review-container">
      <p>{{ review?.user.name }} {{ review?.user.surname }}</p>
      <div class="subtitle">
        <p>{{ review?.createdAt | date: 'dd-MM-yyyy' }}</p>
        <ion-icon id="star" color="warning" name="star"></ion-icon>
        <p>{{ review?.rating }}</p>
      </div>
    </div>
    <div class="rounded"></div>
    <p>{{ review?.comment }}</p>
  </ion-row>
</div> -->
<!-- <ion-fab *ngIf="!showComment" vertical="bottom" horizontal="end" slot="fixed">
  <ion-fab-button *ngIf="!modifyReviewFab" color="primary" (click)="changeState()">
    <ion-icon name="add-outline"></ion-icon>
  </ion-fab-button>
  <ion-fab-button *ngIf="modifyReviewFab" color="primary" (click)="changeState()">
    <ion-icon name="create-outline"></ion-icon>
  </ion-fab-button>
</ion-fab> -->
