import { Component, OnInit, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import { IInfoChapter, IUser } from 'common_library';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/entities/user.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { StoreService, StoreChapter } from 'src/app/services/utils/store.service';
import { _ } from '../../consts';
import { InfoService } from 'src/app/services/entities/info.service';
import { OverlayEventDetail } from '@ionic/core/components';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

@Component({
    selector: 'app-modal-chapter-updated',
    templateUrl: 'modal-chapter-updated.component.html',
    styleUrls: ['./modal-chapter-updated.component.scss'],
    standalone: false
})
export class ModalChapterUpdatedComponent implements OnInit {

  @ViewChild(IonModal) modal: IonModal;
  chapterToAccept$ = new BehaviorSubject<IInfoChapter>(null);
  confirmButtonText$ = new BehaviorSubject<string>('');
  selectedChapterId: string;
  saveToLocalSotrage: boolean;
  showCancelButton: boolean;
  logoutOnDenial: boolean;

  constructor(
    public modalCtrl: ModalController,
    public auth: AuthService,
    public userService: UserService,
    private toast: ToastService,
    private storeService: StoreService,
    private infoService: InfoService,
    private HIO: HttpIoService,
    private translate: TranslateService,
    private mainStateService: MainStateService
  ) {
  }

  async ngOnInit() {
    var chapter: IInfoChapter = this.mainStateService.getCmsContent().infoChapters.find(c => c.id === this.selectedChapterId);
    if (chapter) {
      this.chapterToAccept$.next(chapter);
      if (chapter.userAccepted) {
        this.showCancelButton = true;
        this.confirmButtonText$.next(this.translate.instant("BUTTONS.ACCEPT"));
      } else {
        this.confirmButtonText$.next(this.translate.instant("BUTTONS.CLOSE"));
      }
    }
  }

  async update() {
    try {
      if (this.saveToLocalSotrage) {
        let chapterComposition = this.infoService.populateChapterComposition(this.chapterToAccept$.getValue());
        let chapters = this.storeService.get<StoreChapter[]>(_.CHAPTERS_KEY);
        if (!chapters) {
          chapters = [];
        }
        let dtoChapter: StoreChapter = {
          chapterid: this.selectedChapterId,
          chapterComposition: chapterComposition
        }
        chapters.push(dtoChapter);
        this.storeService.set(_.CHAPTERS_KEY, chapters);
      }
      else {
        const rv = await this.userService.createUserChapterAcceptance(this.mainStateService.getUser().id, this.selectedChapterId);
        if (!rv) {
          this.toast.presentBasicToast({ message: this.translate.instant("TOAST_MESSAGES.ERROR_DURING_SAVE"), color: 'danger', icon: 'warning' });
        }
      }
      this.modalCtrl.dismiss();
    } catch (err) {
      this.toast.presentBasicToast({ message: err.message, color: 'danger', icon: 'warning' });
    }
  }

  async cancel() {
    this.modalCtrl.dismiss();
    if (this.logoutOnDenial) {
      await this.auth.logout();
    }
  }

  getImageUrl(fileName: string): string {
    return this.HIO.endPoint('multimedia/image/' + fileName);
  }

  onWillDismiss(event: Event) {
    const ev = event as CustomEvent<OverlayEventDetail<string>>;
    if (ev.detail.role === 'confirm') {
    }
  }
}