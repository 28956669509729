import { Component, Inject, Input, OnInit, computed } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { ActionResponse, ActionType, ActionTypeValues, CU, ChapterType, IInfoChapter, IPaymentAuth, IUser, ICreditCardDTO } from 'common_library';
import { UserService } from 'src/app/services/entities/user.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { MasterDetailShowType } from '../master-detail/master-detail.component';
import { RxState } from '@rx-angular/state';
import { BehaviorSubject, map } from 'rxjs';
import { GLOBAL_RX_STATE, GlobalState, Nullable } from 'src/app/app.module';
import { InAppBrowser, InAppBrowserEvent, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/';
import { HttpIoService } from 'src/app/services/communication/http-io.service';
import { environment } from 'src/environments/environment';
import { InfoService } from 'src/app/services/entities/info.service';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';

export interface IResValidationCard { type: 'success' | 'failed', data: any }

interface StepItem {
  id: number,
  title?: string;
  subtitle?: string;
  icon?: string,
  chapterId: string,
  previuosStepsAccepted: boolean;
  mustBeAccetped: boolean,
  accepted: boolean,
  sections: StepSectionItem[],
  stepFromCms: boolean,
  type: string
}

interface StepSectionItem {
  title?: string;
  subtitle?: string;
  icon?: string,
  html?: string[] | null
}

interface ILocalState {
  paymentAuth: Nullable<Partial<IPaymentAuth>>,
  waiting: boolean
}


@Component({
    selector: 'yf-cdc-auth',
    templateUrl: './cdc-auth.component.html',
    styleUrls: ['./cdc-auth.component.scss'],
    providers: [RxState],
    standalone: false
})
export class CdcAuthComponent implements OnInit {
  chapters$ = new BehaviorSubject<IInfoChapter[]>([]);
  stepItems$ = new BehaviorSubject<StepItem[]>([]);
  currentStepDetail$ = new BehaviorSubject<StepItem>(null);
  currentStepDetailAccepted$ = new BehaviorSubject<boolean>(false);
  loadingNextStep$ = new BehaviorSubject<boolean>(false);
  compactMode: boolean;
  user: IUser;
  CU = CU
  @Input() resValidationCard: IResValidationCard;

  readonly paymentAuth$ = this.localState.select("paymentAuth");
  $sessionInProgress = computed(() => this.mainStateService.$sessions()?.length > 0); // Right operand of ?? is unreachable because the left operand is never nullish
  masterOrDetail: MasterDetailShowType = 'detail';
  readonly waiting$ = this.localState.select("waiting");
  urlFromSella: string;

  constructor(
    private localState: RxState<ILocalState>,
    private toastService: ToastService,
    private modalCtrl: ModalController,
    private userService: UserService,
    private alertCtrl: AlertController,
    private HIO: HttpIoService,
    private mainStateService: MainStateService,
    private infoService: InfoService,
    private translate: TranslateService
  ) {
    this.localState.set({ waiting: true });
  }
  async ngOnInit() {
    const paymentAuth = await this.userService.getMyPaymentAuth();
    this.localState.set({ waiting: false, paymentAuth });
    this.user = this.mainStateService.getUser();

    await this.populateStepItems();

    if (this.resValidationCard) {
      this.showStepItemByType("CREDIT_CARD");
      if (this.resValidationCard.type === 'success') {
        const alertCartConvalidated = await this.alertCtrl.create({
          header: this.translate.instant("TOAST_MESSAGES.OPERATION_SUCCESSFULL"),
          message: this.translate.instant("CDC_AUTHENTICATION.CARD_SUCCESSFULLY_CONVALIDATED"),
          buttons: [{ text: 'Ok' }]
        })
        await alertCartConvalidated.present();
      }
      if (this.resValidationCard.type === 'failed') {
        const alertCartConvalidated = await this.alertCtrl.create({
          header: this.translate.instant("TOAST_MESSAGES.OPERATION_FAILED"),
          message: this.translate.instant("CDC_AUTHENTICATION.CARD_CONVALIDATION_FAILED"),
          buttons: [{ text: 'Ok' }],
          cssClass: 'card-auth-failed-alert',
        })
        await alertCartConvalidated.present();
      }
    }
    else {
      if (this.userHasAcceptedTerms()) {
        this.showStepItemByType("CREDIT_CARD")
      }
      else {
        this.showStepItem(this.stepItems$.getValue()[0]);
      }
    }
  }

  showStepItemByType(type: string) {
    let typeStep = this.stepItems$.getValue().find(c => c.type === type);
    this.showStepItem(typeStep);
  }

  async populateStepItems(forceRefreshContent: boolean = false) {
    const cmsContent = this.mainStateService.getCmsContent();
    let stepsChapters: StepItem[] = [];
    var currentIndex = 0;
    let mustRefreshCmsContent = false;

    // ISTRUZIONI
    //let instructionChapter = res.infoChapters.find(c => c.type === ChapterType.PAYMENT_INSTRUCTIONS);
    let instructionChapter = cmsContent.infoChapters.find(c => c.type === ChapterType.PAYMENT_INSTRUCTIONS);
    if (instructionChapter) {
      currentIndex++;
      let instructionItem = this.mapInfoChapterToStepItem(instructionChapter, currentIndex);
      if (instructionItem.mustBeAccetped) {
        // check per verificare se l'utente ha mai accettato in passato tale step
        const rv = await this.userService.checkUserChapterAcceptance(instructionChapter.id);
        if (rv) {
          instructionItem.accepted = true;
        }
        else {
          if (!forceRefreshContent) {
            mustRefreshCmsContent = true;
          }
        }
      }
      instructionItem.previuosStepsAccepted = true;
      stepsChapters.push(instructionItem);
    }

    // TERMINI E CONDIZIONI
    let termsChapter = cmsContent.infoChapters.find(c => c.type === ChapterType.TERMS_AND_CONDITIONS);
    if (termsChapter) {
      currentIndex++;
      let termsItem = this.mapInfoChapterToStepItem(termsChapter, currentIndex);
      if (termsItem.mustBeAccetped) {
        const rv = await this.userService.checkUserChapterAcceptance(termsChapter.id);
        if (rv) {
          termsItem.accepted = true;
        }
        else {
          if (!forceRefreshContent) {
            mustRefreshCmsContent = true;
          }
        }
      }
      termsItem.previuosStepsAccepted = stepsChapters.filter(c => c.mustBeAccetped && !c.accepted).length === 0;
      stepsChapters.push(termsItem);

      if (mustRefreshCmsContent) {
        await this.infoService.getCmsContent();
        this.populateStepItems(true);
      }
    }

    // CARTA DI CREDITO: STEP SCOLPITO
    currentIndex++;
    let creditCardItem: StepItem = {
      chapterId: null,
      accepted: true,
      mustBeAccetped: true,
      id: currentIndex,
      previuosStepsAccepted: stepsChapters.filter(c => c.mustBeAccetped && !c.accepted).length === 0,
      sections: [],
      stepFromCms: false,
      type: "CREDIT_CARD",
      title: this.translate.instant("CDC_AUTHENTICATION.DEBIT_AUTHORIZATION"),
      icon: "card-outline"
    }
    stepsChapters.push(creditCardItem);

    this.stepItems$.next(stepsChapters);
  }

  showStepItem(item: StepItem) {
    this.currentStepDetailAccepted$.next(item?.accepted ? true : false);
    this.masterOrDetail = !!item ? 'detail' : 'master';
    this.currentStepDetail$.next(item);
  }

  async showNextStepItem(item: StepItem) {
    this.loadingNextStep$.next(true);
    let canGoNextStep = false;
    if (item.mustBeAccetped) {
      if (!item.accepted) {
        if (this.currentStepDetailAccepted$.getValue()) {
          const rv = await this.userService.createUserChapterAcceptance(this.user.id, item.chapterId);
          if (rv) {
            this.populateStepItems();
            canGoNextStep = true;
          }
        }
      }
      else { canGoNextStep = true; }
    }
    else {
      canGoNextStep = true;
    }

    if (canGoNextStep) {
      let nextStep = this.stepItems$.getValue().find(c => c.id === item.id + 1);
      if (nextStep) {
        this.showStepItem(nextStep);
      }
      this.loadingNextStep$.next(false);
    }
  }

  toggleAcceptStepItem() {
    this.currentStepDetailAccepted$.next(!this.currentStepDetailAccepted$.getValue());
  }

  showWaiting() {
    this.masterOrDetail = 'master';
    this.localState.set({ waiting: true });
  }

  compactModeChange(compactMode: boolean) {
    this.compactMode = compactMode;
  }

  async cdcRevocation() {
    if (!this.mainStateService.getSessions() || this.mainStateService.getSessions().length === 0) {
      const alert = await this.alertCtrl.create({
        header: this.translate.instant("CDC_AUTHENTICATION.REVOKE_AUTHORIZATION"),
        message: this.translate.instant("CDC_AUTHENTICATION.REVOKE_AUTHORIZATION_MESSAGE"),
        buttons: [
          {
            text: this.translate.instant("BUTTONS.CANCEL"),
            role: 'cancel',
          },
          {
            text: this.translate.instant("BUTTONS.CONFIRM"),
            role: 'confirm',
            handler: async () => {
              await this.cdcRevocarionConfirmed();
            },
            cssClass: 'alert-button-red'
          }
        ],
      })
      await alert.present();
    }
  }

  private async cdcRevocarionConfirmed() {
    const result = await this.userService.removeMyPaymentAuth();
    if (result) {
      this.localState.set({ paymentAuth: null });
      await this.toastService.presentBasicToast({ icon: "close-circle-outline", header: this.translate.instant("TOAST_MESSAGES.OPERATION_SUCCESSFULL"), message: this.translate.instant("CDC_AUTHENTICATION.REVOKE_AUTHORIZATION_SUCCESSFULL"), color: "secondary" });
    } else {
      await this.toastService.presentBasicToast({ icon: "waring-outline", header: this.translate.instant("TOAST_MESSAGES.WARNING"), message: this.translate.instant("CDC_AUTHENTICATION.CANNOT_REVOKE_AUTHORIZATION"), color: 'danger' });
    }
  }

  async cdcSubmitted(cdc: ICreditCardDTO) {

    if (!this.userHasAcceptedTerms()) {
      await this.toastService.presentBasicToast({ icon: "warning", header: this.translate.instant("TOAST_MESSAGES.WARNING"), message: this.translate.instant("CDC_AUTHENTICATION.ACCEPT_TERMS_MESSAGE"), color: 'warning' });
      this.showStepItemByType(ChapterType.TERMS_AND_CONDITIONS);
    }
    else {
      this.showStepItem(null);
      this.localState.set({ waiting: true });
      const rv = await this.userService.addMeCreditCard(cdc);
      //TODO pensare se è giusto non far sparire il waitingToast
      // this.appService.hideWaitingToast();
      if (rv?.href) {
        if (this.mainStateService.getNative()) {

          const target = "_blank";
          const opts: InAppBrowserOptions = {
            location: 'no',
            toolbar: 'yes',
            toolbarposition: 'top',
            closebuttoncaption: this.translate.instant("PHRASE.CLOSE"),
            hidenavigationbuttons: 'yes',
            zoom: 'yes',
            enableViewportScale: 'yes'
          }

          const browser = InAppBrowser.create(rv.href, target, opts)

          if (browser.on('loadstart').subscribe)
            browser.on('loadstart').subscribe(async (e: InAppBrowserEvent) => {
              const url = environment.url
              await browser.executeScript(
                {
                  code:
                    `
                    if(window.location.href.indexOf('native_action_uid=') > 0) {
                      window.stop();
                    }

                    `
                });

              if (e && e.url) {
                // if(e.url.indexOf('native_action_uid=') > 0) {
                //   //Aggiungere delay prima di chiudere
                //   this.urlFromSella = e.url;
                //   let newUrl = new URL(this.urlFromSella);
                //   let action_uid = newUrl.searchParams.get("native_action_uid");
                //   browser.close();
                //   this.modalCtrl.dismiss();
                //   await this.executeActionByUid(action_uid);
                // }
              }
            });

          browser.on('loadstop').subscribe(async (e: InAppBrowserEvent) => {
            if (e.url.indexOf('native_action_uid=') > 0) {
              this.urlFromSella = e.url;
              let newUrl = new URL(this.urlFromSella);
              let action_uid = newUrl.searchParams.get("native_action_uid");
              browser.close();
              setTimeout(async () => {
                this.modalCtrl.dismiss();
                await this.executeActionByUid(action_uid);
              }, 1000);
            }
          });

          browser.on('exit').subscribe((e: InAppBrowserEvent) => {
            if (e.type === 'exit' && !this.urlFromSella) {
              setTimeout(() => {
                this.modalCtrl.dismiss();
                this.toastService.presentBasicToast({ message: this.translate.instant("TOAST_MESSAGES.OPERATION_CANCELLED"), color: 'danger', icon: 'warning' });
              }, 1000);
            }
          });
        } else {
          //Qui devo fare la modifica
          window.location.href = rv.href
        }
      } else {
        this.toastService.presentBasicToast({ header: 'Operazione fallita', message: 'Non è stato possibile associare la tua carta, controlla i dati e riprova', color: 'danger' });
        this.showStepItemByType("CREDIT_CARD");
        this.localState.set({ waiting: false });
      }
    }
  }

  async executeActionByUid(actionUid: string) {
    try {

      const { type } = await this.HIO.get<{ type: ActionTypeValues }>(`actions/get-type/${actionUid}`);

      let modal: HTMLIonModalElement | undefined;

      const rv = await this.HIO.get<ActionResponse>(`actions/execute/${actionUid}`);

      if (modal) modal.dismiss();

      console.log('action_uid', actionUid, "response", rv);
      if (rv.data) {
        switch (rv.type) {
          case ActionType.CdCAuthFailed:
            await this.creditCardDataPresent({ type: 'failed', data: rv.data });
            //this.globalState.set({cdcComponentState: {currentDetail: 0, authCharge: { type: 'failed', data: null }}})
            break;
          case ActionType.CdCAuthSuccess:
            await this.creditCardDataPresent({ type: 'success', data: rv.data });
            // this.globalState.set({cdcComponentState: {currentDetail: 2, authCharge: { type: 'success', data: null }}})
            break;
        }
      }

      if (rv.err) {
        throw new Error('Azione già eseguita.');
      }
    } catch (err) {
      console.log('🐱️ : err', err);
      // this.toast.presentBasicToast({
      //   header: 'Operazione non riuscita.',
      //   message: err.message,
      //   color: 'danger',
      // });
    }
  }

  async creditCardDataPresent(resValidationCard: { type: 'success' | 'failed', data: any } = null) {
    const modal = await this.modalCtrl.create({
      component: CdcAuthComponent,
      cssClass: 'yf-master-detail-modal',
      componentProps: { resValidationCard }
    });
    await modal.present();
  }

  mapInfoChapterToStepItem(chapter: IInfoChapter, index: number): StepItem {
    let stepItem: StepItem = {
      id: index,
      title: chapter.title,
      subtitle: chapter.subtitle,
      icon: chapter.icon,
      sections: this.populateStepSections(chapter),
      chapterId: chapter.id,
      mustBeAccetped: chapter.userAccepted,
      accepted: false,
      previuosStepsAccepted: false,
      stepFromCms: true,
      type: chapter.type
    }
    return stepItem;
  }

  private populateStepSections(chapters: any): StepSectionItem[] {
    const sections: any[] = chapters.infoSections;
    let sectionItems: StepSectionItem[] = [];
    for (let j = 0; j < sections.length; j++) {
      let stepSectionItem: StepSectionItem = {
        icon: sections[j]?.icon,
        html: sections[j]?.html,
        title: sections[j]?.title,
        subtitle: sections[j]?.subtitle
      };
      sectionItems.push(stepSectionItem);
    }
    return sectionItems;
  }

  userHasAcceptedTerms() {
    let termsStep = this.stepItems$.getValue().find(c => c.type === ChapterType.TERMS_AND_CONDITIONS);
    if (termsStep && termsStep.mustBeAccetped && !termsStep.accepted) {
      return false;
    }
    return true;
  }
}
