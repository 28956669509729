/* src/app/components/value/value.component.scss */
.container {
  height: 28px;
  line-height: 28px;
}
.icon {
  font-size: 24px;
  vertical-align: middle;
  margin-right: 2px;
  color: black;
}
.value {
  font-size: 20px;
  vertical-align: middle;
  margin: 2px;
  font-weight: bold;
  color: black;
}
.title-value {
  font-size: 20px;
  vertical-align: middle;
  margin: 2px;
  font-weight: bold;
  color: #297EB2;
}
.no-marked {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.text {
  font-size: 14px;
  vertical-align: middle;
  margin: 2px;
  font-weight: bold;
  color: black;
}
.label {
  font-size: 14px;
  vertical-align: middle;
  margin: 2px;
  color: #333;
}
/*# sourceMappingURL=value.component.css.map */
