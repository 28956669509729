"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Currency = void 0;
exports.Currency = Object.freeze({
  EUR: 0,
  USD: 1,
  GBP: 2
});
