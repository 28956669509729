import { Component, OnInit } from '@angular/core';
import { CU, ISessionUserHistoryMonth, OCPITariffDimension, TariffUtil, ISession_FE_SessionHistory } from 'common_library';
import { CoreService } from 'src/app/services/core.service';
import { SessionService } from 'src/app/services/entities/session.service';
import { MasterDetailShowType } from '../master-detail/master-detail.component';
import { DatePipe } from '@angular/common';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { StoreService } from 'src/app/services/utils/store.service';
import { _ } from 'src/app/consts';
import { UserService } from 'src/app/services/entities/user.service';
import { ToastService } from 'src/app/services/utils/toast.service';
import { TranslateConfigService } from 'src/app/services/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

export interface Timer {
  year: number,
  mounth: number,
  week: number,
  d: number,
  h: number,
  m: number,
  s: number
}

@Component({
    selector: 'yf-sessions',
    templateUrl: './sessions.component.html',
    styleUrls: ['./sessions.component.scss'],
    standalone: false
})

export class SessionsComponent implements OnInit {
  utilityFuncs = CU;
  masterDetailShow: MasterDetailShowType = 'master';
  compactMode: boolean = false;

  sessions: ISessionUserHistoryMonth[] = [];
  selectedSession: ISession_FE_SessionHistory;
  lastMonthVisualized: string = null;
  loading: boolean = false;
  tryngPayment$ = new BehaviorSubject<boolean>(false);

  constructor(public sessionService: SessionService,
    public CS: CoreService,
    private datePipe: DatePipe,
    private storage: StoreService,
    private userService: UserService,
    private toast: ToastService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService) { }

  async ngOnInit() {
    await this.getMonthHistorySessions();
    await this.selectFirstIfNeeds();
  }

  selectFirstIfNeeds() {
    if (!this.compactMode && this.sessions.length > 0 && !this.selectedSession) this.details(this.sessions[0].sessions[0]);
  }

  compactModeChange(compactMode: boolean) {
    this.compactMode = compactMode;
    this.selectFirstIfNeeds();
  }

  dateRange(start: Date, end: Date, session: ISession_FE_SessionHistory) {
    if (start != null && end != null) {
      if (this.checkSameDate(session)) {
        const date_start = this.datePipe.transform((new Date(start)), 'HH:mm');
        const date_end = this.datePipe.transform((new Date(end)), 'HH:mm');
        return `${date_start} - ${date_end}`;
      } else {
        if (this.checkSameDateRange(start, end)) {
          const date_start = this.datePipe.transform((new Date(start)), 'd MMM HH:mm');
          const date_end = this.datePipe.transform((new Date(end)), 'HH:mm');
          return `${date_start} - ${date_end}`;
        } else {
          const date_start = this.datePipe.transform((new Date(start)), 'd MMM HH:mm');
          const date_end = this.datePipe.transform((new Date(end)), 'd MMM HH:mm');
          return `${date_start} - ${date_end}`;
        }

      }
    }
    return '--:--';
  }

  checkSameDate(session: ISession_FE_SessionHistory): boolean {
    if (
      (new Date(session?.createdAt)).getFullYear() === (new Date(session?.endedAt)).getFullYear() &&
      (new Date(session?.createdAt)).getMonth() === (new Date(session?.endedAt)).getMonth() &&
      (new Date(session?.createdAt)).getDate() === (new Date(session?.endedAt)).getDate()
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkSameDateRange(start: Date, end: Date): boolean {
    if (
      (new Date(start)).getFullYear() === (new Date(end)).getFullYear() &&
      (new Date(start)).getMonth() === (new Date(end)).getMonth() &&
      (new Date(start)).getDate() === (new Date(end)).getDate()
    ) return true;
    return false;
  }

  details(session: ISession_FE_SessionHistory) {
    this.selectedSession = session;
    this.masterDetailShow = 'detail';
  }

  duration(session: ISession_FE_SessionHistory): number | string {
    return session ? this.calcDate(session.createdAt, session.endedAt) : 0;
  }

  occpuationTimeDuration(session: ISession_FE_SessionHistory) {
    const minuteOccupation = session?.occupationTime * 60;
    const calcParkingStartDate = CU.addMinutes(new Date(), -minuteOccupation);
    return this.sessionService.calcDate(calcParkingStartDate);
  }

  checkNull(value: string | number) {
    if (!!value) {
      return 0;
    }
  }

  calcDate(start: Date | string, end: Date | string, booking?: Date | string): number | string {
    if (booking != null) {
      const date_start = new Date(start)
      const date_end = end ? new Date(end) : new Date(booking);
      const time = this.utilityFuncs.timeSpan(date_start, date_end, true);

      return time.toString();
    }
    if (start == null || end == null) {
      //SO CHE NON HO QUEL CAMPO
      return '--:--';
    }
    if (typeof start === 'string' && typeof end === 'string') {
      //NON HO UN BISOGNO DELLA TERZA VARIABILE  
      const date_start = new Date(start)
      const date_end = new Date(end)
      const time = this.utilityFuncs.timeSpan(date_start, date_end, true);

      return time.toString();
    }
    return 0;
  }

  truncate(str, n) {
    if (!str) return "";
    return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
  };

  getMonthForTitle(month: string): string {
    const monthDate = new Date(parseInt(month.split('_')[0]), parseInt(month.split('_')[1]), 1, 0);
    const monthString = CU.capitalizeFirstLetter(CU.getYearMonthDescription(monthDate))
    const monthAsTitle = monthString.split(" ");
    const monthTranslated = this.translate.instant(`MONTHS.${monthAsTitle[0].toUpperCase()}`)
    return monthTranslated + " " + monthAsTitle[1];
  }

  async onIonInfinite(evt) {
    await this.getMonthHistorySessions();
    (evt as InfiniteScrollCustomEvent).target.complete();
  }

  async handleRefresh(event) {
    this.lastMonthVisualized = null;
    await this.getMonthHistorySessions(true);
    event.target.complete();
  }

  getConnectorPower(session: ISession_FE_SessionHistory) {
    return TariffUtil.getConnectorPower(session.connector);
  }

  getTariffDescription(session: ISession_FE_SessionHistory): string {
    const lang = this.translateConfigService.getCurrentLang();
    const descriptions = TariffUtil.getTariffAltTextForConnectorPower(session.tariffJson, lang, this.getConnectorPower(session));
    const reservationDescription = descriptions.find(c => c.dimension === OCPITariffDimension.RESERVATION);
    const energyDescription = descriptions.find(c => c.dimension === OCPITariffDimension.ENERGY);
    const parkingDescription = descriptions.find(c => c.dimension === OCPITariffDimension.PARKING_TIME);
    let textDescrption = `${reservationDescription.description} ${energyDescription.description} ${parkingDescription.description}`.trim();
    return textDescrption;
  }

  getReservationVATValue(session: ISession_FE_SessionHistory): number {
    return session?.cdr?.totalReservationCost.inclVat - session?.cdr?.totalReservationCost.exclVat;
  }

  getEnergyVATValue(session: ISession_FE_SessionHistory): number {
    return session?.cdr?.totalEnergyCost.inclVat - session?.cdr?.totalEnergyCost.exclVat;
  }

  getParkingVATValue(session: ISession_FE_SessionHistory): number {
    return session?.cdr?.totalParkingCost.inclVat - session?.cdr?.totalParkingCost.exclVat;
  }

  /**
   * Il metodo si occupa di mostrare la cronologia delle sessioni effettuate dall'utente.
   * Le sessioni che vengono mostrate possono provenire dal backend (nel caso non siano mai state visualizzate prima)
   * o dal local storage (nel caso siano già state recuperate in precedenza), in modo da limitare il traffico di dati
   */
  async getMonthHistorySessions(resetSessions: boolean = false) {
    this.loading = true;
    if (resetSessions) {
      this.sessions = [];
    }
    let _isLastMonth = false;
    if (this.sessions.length > 0) {
      _isLastMonth = this.sessions[this.sessions.length - 1].isLastMonth;
    }
    const sessionOnTheStorage: ISessionUserHistoryMonth[] = [];
    if (this.storage.get(_.SESSION_HISTORY)) {
      sessionOnTheStorage.push(...this.storage.get(_.SESSION_HISTORY) as ISessionUserHistoryMonth[]);
    }
    // La prima chiamata, quando lastMonth è null
    let lastMonth: string = null;
    if (this.lastMonthVisualized) {
      lastMonth = this.lastMonthVisualized;
    }

    let foundInlocalStorage = false;
    const sessionFound = sessionOnTheStorage.find(el => el.prevMonth === this.lastMonthVisualized);
    // se si trova nel local storage prendo quella session
    if (lastMonth && sessionFound) {
      foundInlocalStorage = true;
      this.lastMonthVisualized = sessionFound.month;
      // l'aggiungo alle sessioni da visualizzare
      this.sessions.push(sessionFound);
    }

    // se non è stato trovato nel local storage faccio una chiamata al back end
    if (!foundInlocalStorage && !_isLastMonth) {
      const newSessionsFetched = await this.sessionService.fetchMyLastMonthHistorySessions(lastMonth ?? null);
      if (newSessionsFetched && newSessionsFetched.length > 0) {
        this.lastMonthVisualized = newSessionsFetched[newSessionsFetched.length - 1].month;
        this.sessions.push(...newSessionsFetched);
        if (!newSessionsFetched.find(session => !session.prevMonth || session.prevMonth === undefined)) {
          sessionOnTheStorage.push(...newSessionsFetched);
          if (lastMonth) {
            this.storage.set(_.SESSION_HISTORY, sessionOnTheStorage);
          }
        }
      }
    }
    this.loading = false;
  }

  async payNowHandler() {
    this.tryngPayment$.next(true);
    await this.toast.presentBasicToast({
      message: this.translate.instant("SESSIONS_HISTORY.PAY_UNPAID_CDR_MESSAGE"),
      duration: 5000,
      color: 'success'
    });
    const rv = await this.userService.payUnpaidCdr(this.selectedSession.cdr?.id);
    if (typeof rv === 'boolean') {
      await this.toast.presentBasicToast({
        message: this.translate.instant("SESSIONS_HISTORY.PAYMENT_FOR_THIS_CHARGING_SESSION_SUCCESS"),
        duration: 5000,
        color: 'success'
      });
    } else {
      await this.toast.presentBasicToast({
        message: rv.message,
        duration: 5000,
        color: 'danger'
      });
    }
    this.selectedSession = null;
    this.masterDetailShow = 'master';
    await this.getMonthHistorySessions(true);
    this.tryngPayment$.next(false);
  }
}
