@if(mainStateService.$searchPanelVisible()){
<div class="container">
  <form type="search">
    <ion-toolbar color="transparent">
      <ion-searchbar enterkeyhint="search" inputmode="search" #ionSearchBar search-icon="chevron-back-outline"
        [(ngModel)]="value" [ngModelOptions]="{standalone: true}" [placeholder]="'APP.FILTER.PLACEHOLDER' | translate"
        show-clear-button="always" [debounce]="1000" (ionInput)="handleChange($event)" color="light"
        (search)="searchByKeyword($event)">
      </ion-searchbar>
    </ion-toolbar>
  </form>
  @if($results().entities.length > 0){
  <ion-list class="recent-searches-container" lines="full" [ngStyle]="$height()">
    <ion-item class="recent-search" *ngFor="let search of $results().entities; let i = index; trackBy: trackByFn; "
      [attr.data-index]="i">
      <ion-avatar class="center-icon">
        <ion-icon [name]="search.icon"></ion-icon>
      </ion-avatar>
      <ion-label class="ion-text-nowrap" (click)="moveMapToResult(search)">
        <h2>{{ search.title }}</h2>
        <p>{{ search.subtitle }}</p>
      </ion-label>
      @if($results().type==="History"){<ion-icon name="close-circle" aria-hidden="true"
        (click)="removeRecentSearch(i)"></ion-icon>}
    </ion-item>
  </ion-list>
  }
  @else if ($results().type==="Search"){
  <ion-list class="recent-searches-container">
    <ion-item class="recent-search">
      <ion-avatar class="center-icon">
        <ion-icon name="ban-outline"></ion-icon>
      </ion-avatar>
      <ion-label class="ion-text-nowrap">
        <h2>{{'APP.FILTER.NO_RESULT'|translate}}</h2>
      </ion-label>
    </ion-item>
  </ion-list>
  }

</div>
}