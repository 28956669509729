import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { ButtonStateService } from 'src/app/services/state/button-state.service';

@Component({
    selector: 'yf-interrompi-ricarica',
    template: `
    <ion-button [disabled]="this.buttonStateService.$buttonStopCharge().disabled" (click)="stop()" class="btn large grow" [ngClass]="{
            white: this.mainStateService.$selectedConnectorHasMineSession(),
            disabled: this.buttonStateService.$buttonStopCharge().disabled
            }">
      <ion-icon slot="start" src="assets/app-icons/unplug.svg"></ion-icon>
      {{ "APP.INSTALLATION.BUTTONS.STOP_CHARGING" | translate }}
  </ion-button>
  `,
    styles: [`    
  `],
    standalone: false
})
export class InterrompiRicaricaComponent {
  @Output() stopEvent: EventEmitter<void> = new EventEmitter<void>();  

  constructor(
    public mainStateService: MainStateService,
    public buttonStateService: ButtonStateService,
    private translate: TranslateService
  ) {
    
  }

  stop() {
    this.stopEvent.emit();
  }
}
