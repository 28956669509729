<div class="yf-row centered">
  <div class="group">
    <ion-card class="yf-container gap-12 p-24">
      <div class="yf-container gap-6">
        <ion-text color="medium"> {{ "APP.FILTER.CONNECTORS" | translate }} </ion-text>
        <yf-filter-connector-selector [selectedConnector]="$filterState().plugs"
          (filterEvent)="updateModel($event, 'plugs')"></yf-filter-connector-selector>
      </div>
      <hr />
      <div class="yf-container gap-6">
        <ion-text color="medium"> {{ "APP.FILTER.PLUG_POWER" | translate }} </ion-text>
        <app-power-range [selectedRange]="$filterState().range"
          (filterEvent)="updateModel($event, 'range')"></app-power-range>
      </div>
      <hr />
      <div class="yf-container gap-6">
        <ion-text color="medium"> {{ "APP.FILTER.CPO" | translate }} </ion-text>
        <yf-filter-cpo-selector [selectedCpos]="$filterState().cpos" (cposOutput)="updateModel($event, 'cpos')"
          [cpos]="cpos"></yf-filter-cpo-selector>
      </div>
      @if($text()){
      <div class="error">
      <ion-icon name="alert-circle-outline" class="mr-10 fs-40" ></ion-icon>
      <ion-text class="fs-12 fw-600">
        <b> {{"TOAST_MESSAGES.WARNING" | translate}}:</b> {{ "APP.FILTER.NO_RESULT" | translate}} {{$text() | translate}}
      </ion-text>
    </div>
    }
    </ion-card>
  </div>
</div>
