"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DAY_OF_WEEk = void 0;
exports.DAY_OF_WEEk = Object.freeze({
  MONDAY: "MONDAY",
  TUESDAY: "TUESDAY",
  WEDNESDAY: "WEDNESDAY",
  THURSDAY: "THURSDAY",
  FRIDAY: "FRIDAY",
  SATURDAY: "SATURDAY",
  SUNDAY: "SUNDAY"
});
