<div class="main">
  <ion-toolbar color="light" class="mt-20">
    <ion-buttons slot="start" *ngIf="!!icon">
      <ion-button *ngIf="!compactMode || show === 'master'" [disabled]="toolbarButtonDisabled">
        <ion-icon slot="icon-only" [attr.src]="svg? './../assets/app-icons/' + icon : 'null'" [name]="icon"
          size="large"></ion-icon>
      </ion-button>
      <ion-button *ngIf="compactMode && show == 'detail'" [disabled]="toolbarButtonDisabled" (click)="showMaster()">
        <ion-icon slot="icon-only" name="chevron-back-outline" size="large"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
      <div class="yf-row gap-1 centered">
        <div class="yf-container gap-1">
          @if(!typeValue){
          <ion-text class="fs-16">{{ compactMode && show !== 'master' ? titleDetail || title : title }}</ion-text>
          }
          @else{
          <yf-value [type]="typeValue" [value]="title"></yf-value>
          }
        </div>
      </div>
    </ion-title>
    <ion-buttons slot="end">
      <ion-button [disabled]="toolbarButtonDisabled" color="dark" (click)="close()">
        <ion-icon slot="icon-only" name="close-outline" size="large"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div class="container">

    <div class="master w50" [ngClass]="{ hide: compactMode && show !== 'master', w100: compactMode }">
      <ion-toolbar color="light" *ngIf="compactMode && titleMaster">
        <div class="yf-row centered">
          <ion-text color="dark" class="yf-container centered fs-20"><b>{{ titleMaster }}</b></ion-text>
        </div>
        <ion-searchbar *ngIf="!!filter" mode="ios"></ion-searchbar>
      </ion-toolbar>
      <div class="master-content">
        <ng-content select="div.master"></ng-content>
      </div>
    </div>

    <div class="detail" [ngClass]="{ hide: compactMode && show !== 'detail', w100: compactMode }">
      <ion-toolbar color="light" *ngIf="!compactMode && titleDetail">
        <div class="yf-row centered">
          <ion-text color="dark" class="yf-container centered fs-18"><b>{{ titleDetail }}</b></ion-text>
        </div>
      </ion-toolbar>
      <div class="detail-content">
        <ng-content select="div.detail"></ng-content>
      </div>
    </div>
  </div>

</div>