"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Mapper = void 0;
var Mapper;
(function (Mapper) {
  function map_ISession_to_ISessionUserDto(session) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    var dest = {
      id: session.id,
      createdAt: session.createdAt,
      endedAt: session.endedAt,
      energy: session.energy,
      cpo: session.cpo,
      power: session.power
    };
    if (session.user) {
      dest.user = {
        id: session.user.id,
        name: session.user.name,
        surname: session.user.surname
      };
    }
    if (session.connector) {
      dest.station = {
        id: (_a = session.connector.station) === null || _a === void 0 ? void 0 : _a.id,
        name: (_b = session.connector) === null || _b === void 0 ? void 0 : _b.station.name,
        installation: {
          id: (_e = (_d = (_c = session.connector) === null || _c === void 0 ? void 0 : _c.station) === null || _d === void 0 ? void 0 : _d.installation) === null || _e === void 0 ? void 0 : _e.id,
          name: (_h = (_g = (_f = session.connector) === null || _f === void 0 ? void 0 : _f.station) === null || _g === void 0 ? void 0 : _g.installation) === null || _h === void 0 ? void 0 : _h.name
        }
      };
    }
    return dest;
  }
  Mapper.map_ISession_to_ISessionUserDto = map_ISession_to_ISessionUserDto;
  // #region Classi per storico sessioni mobile
  function map_ISession_to_ISession_FE_SessionHistory(session) {
    var dest = {
      id: session.id,
      createdAt: session.createdAt,
      bookingEndAt: session.bookingEndAt,
      reservedAt: session.reservedAt,
      fullyChargedAt: session.fullyChargedAt,
      cancelledAt: session.cancelledAt,
      startedAt: session.startedAt,
      stoppedAt: session.stoppedAt,
      endedAt: session.endedAt,
      energy: session.energy,
      occupationTime: session.occupationTime,
      cpo: session.cpo,
      user: session.user ? map_IUser_to_IUser_FE_SessionHistory(session.user) : session.user,
      car: session.car,
      cdr: session.cdr ? map_IChargeDetailRecord_to_IChargeDetailRecord_FE_SessionHistory(session.cdr) : null,
      tariffJson: session.tariffJson,
      connector: session.connector ? map_IConnector_to_IConnector_FE_SessionHistory(session.connector) : null
    };
    return dest;
  }
  Mapper.map_ISession_to_ISession_FE_SessionHistory = map_ISession_to_ISession_FE_SessionHistory;
  function map_IChargeDetailRecord_to_IChargeDetailRecord_FE_SessionHistory(cdr) {
    var dest = {
      id: cdr.id,
      partyId: cdr.partyId,
      cdrToken: cdr.cdrToken,
      totalCost: cdr.totalCost,
      totalEnergy: cdr.totalEnergy,
      totalEnergyCost: cdr.totalEnergyCost,
      totalParkingCost: cdr.totalParkingCost,
      totalParkingTime: cdr.totalParkingTime,
      totalReservationCost: cdr.totalReservationCost,
      totalReservationTime: cdr.totalReservationTime,
      countryCode: cdr.countryCode,
      sessionId: cdr.sessionId,
      state: cdr.state,
      bankTransaction: cdr.bankTransaction ? map_IBankTransaction_to_IBankTransaction_FE_History(cdr.bankTransaction) : null,
      totalTime: cdr.totalTime,
      totalTimeCost: cdr.totalTimeCost
    };
    return dest;
  }
  Mapper.map_IChargeDetailRecord_to_IChargeDetailRecord_FE_SessionHistory = map_IChargeDetailRecord_to_IChargeDetailRecord_FE_SessionHistory;
  function map_IBankTransaction_to_IBankTransaction_FE_History(bank) {
    var dest = {
      id: bank === null || bank === void 0 ? void 0 : bank.id,
      createdAt: bank === null || bank === void 0 ? void 0 : bank.createdAt,
      sessionId: bank === null || bank === void 0 ? void 0 : bank.sessionId,
      type: bank === null || bank === void 0 ? void 0 : bank.type,
      value: bank === null || bank === void 0 ? void 0 : bank.value,
      state: bank === null || bank === void 0 ? void 0 : bank.state
    };
    return dest;
  }
  Mapper.map_IBankTransaction_to_IBankTransaction_FE_History = map_IBankTransaction_to_IBankTransaction_FE_History;
  function map_IUser_to_IUser_FE_SessionHistory(user) {
    var dest = {
      id: user === null || user === void 0 ? void 0 : user.id,
      name: user === null || user === void 0 ? void 0 : user.name,
      surname: user === null || user === void 0 ? void 0 : user.surname
    };
    return dest;
  }
  Mapper.map_IUser_to_IUser_FE_SessionHistory = map_IUser_to_IUser_FE_SessionHistory;
  function map_IConnector_to_IConnector_FE_SessionHistory(connector) {
    var dest = {
      id: connector === null || connector === void 0 ? void 0 : connector.id,
      name: connector === null || connector === void 0 ? void 0 : connector.name,
      plugTypes: connector.plugTypes,
      power: connector.power,
      powerLimit: connector.powerLimit,
      station: (connector === null || connector === void 0 ? void 0 : connector.station) ? map_IStation_to_IStation_FE_SessionHistory(connector.station) : null
    };
    return dest;
  }
  Mapper.map_IConnector_to_IConnector_FE_SessionHistory = map_IConnector_to_IConnector_FE_SessionHistory;
  function map_IStation_to_IStation_FE_SessionHistory(station) {
    var dest = {
      id: station === null || station === void 0 ? void 0 : station.id,
      name: station === null || station === void 0 ? void 0 : station.name,
      cpo: station.cpo,
      installation: (station === null || station === void 0 ? void 0 : station.installation) ? map_IInstallation_to_IIstallation_FE_SessionHistory(station.installation) : null
    };
    return dest;
  }
  Mapper.map_IStation_to_IStation_FE_SessionHistory = map_IStation_to_IStation_FE_SessionHistory;
  function map_IInstallation_to_IIstallation_FE_SessionHistory(installation) {
    var dest = {
      id: installation === null || installation === void 0 ? void 0 : installation.id,
      name: installation === null || installation === void 0 ? void 0 : installation.name
    };
    return dest;
  }
  Mapper.map_IInstallation_to_IIstallation_FE_SessionHistory = map_IInstallation_to_IIstallation_FE_SessionHistory;
  // #endregion
  // #region Classi per info utente mobile
  function map_IUser_to_IUser_FE_Login(user) {
    var dest = {
      id: user.id,
      name: user.name,
      surname: user.surname,
      email: user.email,
      wantsInvoice: user.wantsInvoice,
      addressNumber: user.addressNumber,
      addressStreet: user.addressStreet,
      postalCode: user.postalCode,
      city: user.city,
      province: user.province,
      country: user.country,
      countryId: user.countryId,
      pIva: user.pIva,
      pIvaUniCode: user.pIvaUniCode,
      taxCode: user.taxCode,
      userChapter: user.userChapter,
      userType: user.userType,
      cars: user.cars,
      visibilityGroups: user.visibilityGroups
    };
    return dest;
  }
  Mapper.map_IUser_to_IUser_FE_Login = map_IUser_to_IUser_FE_Login;
  // #endregion
})(Mapper || (exports.Mapper = Mapper = {}));
