"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GestoreEntityType = void 0;
exports.GestoreEntityType = Object.freeze({
  INSTALLATION: 'Installation',
  STATION: 'Station',
  CONNECTOR: 'Connector',
  POD: 'Pod',
  TARIFF: 'Tariff',
  GROUP: 'Group'
});
