import { Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MainStateService } from 'src/app/services/state/app-main-state.service';
import { ButtonStateService } from 'src/app/services/state/button-state.service';


@Component({
    selector: 'yf-annulla-prenotazione',
    template: `
  <ion-button class="btn large grow"
        [ngClass]="{ 
          primary:!this.mainStateService.$selectedConnectorHasMineSession(),
          disabled: this.buttonStateService.$buttonCancelReservation().disabled
          }"
        (click)="cancelReservation()" [disabled]="this.buttonStateService.$buttonCancelReservation().disabled">
        <ion-icon slot="start" src="assets/app-icons/annulla_prenotazione.svg"></ion-icon>
        {{ "APP.INSTALLATION.BUTTONS.CANCEL_RESERVATION" | translate }}
  </ion-button>
  `,
    styles: [`
  `],
    standalone: false
})
export class AnnullaPrenotazioneComponent {
  @Output() cancelReservationEvent: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(
    public mainStateService: MainStateService,
    public buttonStateService: ButtonStateService,
    private translate: TranslateService
  ) {
    
  }

  cancelReservation() {
    this.cancelReservationEvent.emit();
  }
}