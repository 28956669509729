import { Component, Input, computed, input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ISession, OCPITariffDimension, TariffUtil } from 'common_library';
import { TranslateConfigService } from 'src/app/services/translate.service';
import { UiService } from 'src/app/services/utils/ui.service';

@Component({
    selector: 'yf-parking-text',
    templateUrl: './parking-text.component.html',
    styleUrls: ['./parking-text.component.scss'],
    standalone: false
})
export class ParkingTextComponent {  

  $textClass = input<string>('fs-13');

  constructor(public UI: UiService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService) { }

  $currentSession = input<ISession>(null);

  $parkingQuickText = computed(() => {
    if (this.$currentSession()) {
      const lang = this.translateConfigService.getCurrentLang();
      const descriptions = TariffUtil.getTariffAltTextForConnectorPower(this.$currentSession()?.tariff, lang, TariffUtil.getConnectorPower(this.$currentSession()?.connector));
      const parkingDescription = descriptions.find(c => c.dimension === OCPITariffDimension.PARKING_TIME);
      return parkingDescription?.quickDescription;
    }
    return null;
  });
}
