/* src/app/components/mini-station/mini-station.component.scss */
.text-container {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--yf-white);
  color: black;
}
.text-container.isMine {
  background-color: var(--ion-color-secondary);
  color: white;
}
.text-container ion-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
ion-card {
  width: 100px;
  height: 52px;
  padding: 0px;
  margin: 0px !important;
  cursor: pointer;
}
ion-card-content {
  width: 100%;
  padding: 0;
}
yf-station-info {
  display: block;
  margin-top: 36px;
  margin-bottom: 24px;
}
ion-button {
  height: 48px;
  margin: 5px;
  border-radius: var(--yf-border-radius);
  --padding-end: 0;
  --padding-start: 0;
  --padding-bottom: 10px;
}
.selected-tab-dark {
  background-color: var(--ion-color-secondary);
  border-top-left-radius: var(--yf-spacing1);
  border-top-right-radius: var(--yf-spacing1);
}
.selected-tab-light {
  background-color: #fff;
  border-top-left-radius: var(--yf-spacing1);
  border-top-right-radius: var(--yf-spacing1);
}
.btn {
  --box-shadow: none;
}
.border {
  border: 1px solid lightgray;
}
/*# sourceMappingURL=mini-station.component.css.map */
