/* src/app/components/filter-cpo-selector/filter-cpo-selector.component.scss */
.cpo-button {
  height: 56px;
  width: auto;
  flex-basis: 25%;
  --border-radius: 0px;
  --box-shadow: transparent;
  --padding-start: 0;
  --padding-end: 0;
  --padding-bottom: 0;
  --padding-top: 0;
  margin: 0;
}
.cpo-button ion-icon {
  font-size: 28px;
}
/*# sourceMappingURL=filter-cpo-selector.component.css.map */
