/* src/app/components/barcode-scanning-modal/barcode-scanning-modal.component.scss */
ion-content {
  --background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
}
.square {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 16px;
  width: 200px;
  height: 200px;
  border: 6px solid white;
  box-shadow: 0 0 0 4000px rgba(0, 0, 0, 0.3);
}
ion-card {
  background: white;
  color: black;
}
ion-content {
  --background: transparent;
}
.content-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
}
/*# sourceMappingURL=barcode-scanning-modal.component.css.map */
