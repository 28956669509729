"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SoapServiceUrl = void 0;
var SoapServiceUrl;
(function (SoapServiceUrl) {
  SoapServiceUrl["Scontrini"] = "https://eportale.eu/service-ejb/CorrispettivoElettronicoService?wsdl";
  SoapServiceUrl["Fatture"] = "https://cloud.mysond.it/service-ejb/FatturaElettronicaService?wsdl";
})(SoapServiceUrl || (exports.SoapServiceUrl = SoapServiceUrl = {}));
