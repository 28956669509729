/* src/app/components/car-button/car-button.component.scss */
.car-button {
  height: 50.4px;
  width: 100%;
  font-size: 16px;
  --padding-start: 16px;
  --padding-end: 16px;
}
.car-button ion-icon {
  min-height: 28px;
  min-width: 28px;
}
/*# sourceMappingURL=car-button.component.css.map */
