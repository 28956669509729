"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RevenueEntity = void 0;
exports.RevenueEntity = Object.freeze({
  cpo: 0,
  emsp: 1,
  brandOwner: 2,
  podOwner: 3,
  deviceOwner: 4,
  installationOwner: 5
});
