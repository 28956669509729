import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: '[app-star]',
    templateUrl: './star.component.html',
    styleUrls: ['./star.component.scss'],
    standalone: false
})
export class StarComponent implements OnChanges {
  @Input() isClickable: boolean = false;
  @Input() rating: number;
  @Input() starNumber: number;
  @Input() color: string;
  @Output() starEnter: EventEmitter<number> = new EventEmitter();
  @Output() starLeave: EventEmitter<void> = new EventEmitter();
  @Output() starClicked: EventEmitter<number> = new EventEmitter();
  ngOnChanges(changes: SimpleChanges): void {
    document.documentElement.style.setProperty('--star-color', `var(--ion-color-${this.color})`);
    console.log('🐱️ : StarComponent : this.color', this.color)
  }
}
