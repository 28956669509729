/* src/app/components/profile-deletion/profile-deletion.component.scss */
.delete-profile-container {
  max-height: 80vh;
  padding: 24px;
}
.checkbox-square {
  --size: 20px;
  margin-right: 10px;
  min-width: 20px;
  min-height: 20px;
}
ion-checkbox::part(container) {
  border-radius: 6px;
  border: 2px solid #92949c;
}
/*# sourceMappingURL=profile-deletion.component.css.map */
