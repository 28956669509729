"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RevenueDimension = void 0;
exports.RevenueDimension = Object.freeze({
  DEFAULT: 'DEFAULT',
  RESERVATION: 'RESERVATION',
  ENERGY: 'ENERGY',
  PARKING: 'PARKING'
});
