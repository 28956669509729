/* src/app/components/master-details-rfid/rfid-details.component.scss */
:root {
  --ion-font-family: "Montserrat" !important;
  --ion-color-primary: #297eb2;
  --ion-color-primary-rgb:
    41,
    126,
    178;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb:
    255,
    255,
    255;
  --ion-color-primary-shade: #246f9d;
  --ion-color-primary-tint: #3e8bba;
  --ion-color-secondary: #002945;
  --ion-color-secondary-rgb:
    13,
    44,
    64;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb:
    255,
    255,
    255;
  --ion-color-secondary-shade: #0b2738;
  --ion-color-secondary-tint: #044D7A;
  --ion-color-tertiary: #c2fc84;
  --ion-color-tertiary-rgb:
    194,
    252,
    132;
  --ion-color-tertiary-contrast: #0d2c40;
  --ion-color-tertiary-contrast-rgb:
    0,
    0,
    0;
  --ion-color-tertiary-shade: #abde74;
  --ion-color-tertiary-tint: #c8fc90;
  --ion-color-success: #98e249;
  --ion-color-success-rgb:
    152,
    226,
    73;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb:
    0,
    0,
    0;
  --ion-color-success-shade: #86c740;
  --ion-color-success-tint: #a2e55b;
  --ion-color-warning: #ffe121;
  --ion-color-warning-rgb:
    255,
    225,
    33;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb:
    0,
    0,
    0;
  --ion-color-warning-shade: #e0c61d;
  --ion-color-warning-tint: #ffe437;
  --ion-color-danger: #e92626;
  --ion-color-danger-rgb:
    233,
    38,
    38;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb:
    255,
    255,
    255;
  --ion-color-danger-shade: #cd2121;
  --ion-color-danger-tint: #eb3c3c;
  --ion-color-dark: #212527;
  --ion-color-dark-rgb:
    33,
    37,
    39;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb:
    255,
    255,
    255;
  --ion-color-dark-shade: #1d2122;
  --ion-color-dark-tint: #373b3d;
  --ion-color-medium: #a2a5ad;
  --ion-color-medium-rgb:
    162,
    165,
    173;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb:
    0,
    0,
    0;
  --ion-color-medium-shade: #8f9198;
  --ion-color-medium-tint: #abaeb5;
  --ion-color-light: #fcfcff;
  --ion-color-light-rgb:
    252,
    252,
    255;
  --ion-color-light-contrast: #212527;
  --ion-color-light-contrast-rgb:
    0,
    0,
    0;
  --ion-color-light-shade: #EDEEF3;
  --ion-color-light-tint: #fcfcff;
  --ion-color-bluegreen: #0d98ba;
  --ion-color-bluegreen-rgb:
    13,
    152,
    186;
  --ion-color-bluegreen-contrast: #ffffff;
  --ion-color-bluegreen-contrast-rgb:
    255,
    255,
    255;
  --ion-color-bluegreen-shade: #0b86a4;
  --ion-color-bluegreen-tint: #25a2c1;
  --ion-color-bianco-yourfill: #FCFCFF;
  --ion-color-bianco-yourfill-rgb:
    252,
    252,
    255;
  --ion-color-bianco-yourfill-contrast: #000000;
  --ion-color-bianco-yourfill-contrast-rgb:
    0,
    0,
    0;
  --ion-color-bianco-yourfill-shade: #dedee0;
  --ion-color-bianco-yourfill-tint: #fcfcff;
}
.ion-color-bluegreen {
  --ion-color-base: var(--ion-color-bluegreen);
  --ion-color-base-rgb: var(--ion-color-bluegreen-rgb);
  --ion-color-contrast: var(--ion-color-bluegreen-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bluegreen-contrast-rgb);
  --ion-color-shade: var(--ion-color-bluegreen-shade);
  --ion-color-tint: var(--ion-color-bluegreen-tint);
}
.ion-color-bianco-yourfill {
  --ion-color-base: var(--ion-color-bianco-yourfill);
  --ion-color-base-rgb: var(--ion-color-bianco-yourfill-rgb);
  --ion-color-contrast: var(--ion-color-bianco-yourfill-contrast);
  --ion-color-contrast-rgb: var(--ion-color-bianco-yourfill-contrast-rgb);
  --ion-color-shade: var(--ion-color-bianco-yourfill-shade);
  --ion-color-tint: var(--ion-color-bianco-yourfill-tint);
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 100;
  src: url("./media/Montserrat-Thin.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  src: url("./media/Montserrat-ExtraLight.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("./media/Montserrat-Light.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./media/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("./media/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("./media/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("./media/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("./media/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  src: url("./media/Montserrat-Black.ttf");
}
body.barcode-scanning-active {
  visibility: hidden;
  --background: transparent;
  --ion-background-color: transparent;
}
.barcode-scanning-modal {
  visibility: visible;
}
@media (prefers-color-scheme: dark) {
  .barcode-scanning-modal {
    --background: transparent;
    --ion-background-color: transparent;
  }
}
.master {
  min-width: 360px;
  position: relative;
  height: 100%;
}
.detail {
  min-width: 360px;
  height: 100%;
  position: relative;
}
.yf-master-detail .master {
  width: 100% !important;
}
.yf-master-detail .detail {
  width: 100% !important;
}
.master-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  border: 1px solid rgb(99, 99, 99);
  padding: 10px;
}
.detail-item {
  font-size: medium;
  color: var(--ion-color-secondary);
  padding: 10px;
  height: 100%;
  position: relative;
}
.selected {
  background: #e4ffe6;
}
.item-rfid-icon {
  margin-inline-end: 10px !important;
}
.item-car-icon {
  margin-inline-end: 10px !important;
}
ion-card {
  padding-top: 0px;
}
ion-item-divider {
  padding-left: 0px;
}
ion-toolbar {
  --background: none;
}
.yf-footer {
  position: absolute;
  background: white;
  left: 0;
  bottom: calc(env(safe-area-inset-bottom) + 48px);
  right: 0;
}
.yf-footer ion-button {
  padding-left: var(--yf-spacing2);
  padding-right: var(--yf-spacing2);
}
@media (min-width: 576px) {
  .yf-footer {
    position: fixed !important;
  }
}
@media (max-width: 450px) {
  .yf-footer {
    position: fixed !important;
  }
}
@media (min-width: 768px) {
  .yf-footer {
    position: absolute !important;
  }
}
/*# sourceMappingURL=rfid-details.component.css.map */
