/* src/app/components/update-screen/update-screen.component.scss */
.app-update-screen {
  background: var(--ion-color-secondary);
  position: fixed;
  display: flex;
  z-index: 100;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: calc(env(safe-area-inset-top) + 32px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 32px);
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  height: 100%;
}
.white-text {
  color: white;
  text-align: center;
}
/*# sourceMappingURL=update-screen.component.css.map */
